export const messageTonality = [
  {
    emoji: '🌟',
    name: 'friendly',
    label: 'Amigable',
  },
  {
    emoji: '😊',
    name: 'personal',
    label: 'Cercano',
  },
  {
    emoji: '💼',
    name: 'formal',
    label: 'Formal',
  },
  {
    emoji: '👩‍💼',
    name: 'professional',
    label: 'Profesional',
  },
  {
    emoji: '🎯',
    name: 'motivating',
    label: 'Motivador',
  },
  {
    emoji: '🚀',
    name: 'creative',
    label: 'Creativo',
  },
];

export const jobLevelOptions = [
  {
    label: 'Junior',
    value: 'junior',
  },
  {
    label: 'Mid',
    value: 'mid',
  },
  {
    label: 'Senior',
    value: 'senior',
  },
];

export const jobModalityOptions = [
  {
    label: 'Remoto',
    value: 'remoto',
  },
  {
    label: 'Presencial',
    value: 'presencial',
  },
  {
    label: 'Híbrido',
    value: 'híbrido',
  },
];

export const jobDescriptionPlaceholder = `Ejemplo:
1.Diseñar, prototipar y mejorar la experiencia de usuario.
2.Crear diseños intuitivos y efectivos que faciliten la navegación y el flujo del proceso de pago
3.Trabajar con los demás equipos para obtener retroalimentación y mejorar la experiencia del usuario`;

export const dependecyDescriptionPlaceholder = `Ejemplo:
1. Trabajarás directamente con el equipo de producto y desarrollo.
2. Reportarás directamente al CTO.
3. Trabajarás en conjunto con el equipo de marketing y ventas.`;


export const companyDescriptionPlaceholder = `Ejemplo: 
1.Marca personal como canal de marketing 
2.Activación de empleados en redes sociales 
3.Escalabilidad de la estrategia de contenidos`

export const colorPrincipal = '#0F73C1';
export const colorSecundario = '#69a6da';