import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { JobDescriptionInput } from '../../../__generated__/graphql';
import FormActions from '../../components/FormActions';
import InputLabel from '../../components/InputLabel';
import TextField from '../../components/TextField';
import { useFormContext } from '../../contexts/FormContext';

const Step4 = ({
  onSubmit,
  loading,
}: {
  onSubmit: (data: JobDescriptionInput) => void;
  loading: boolean;
}) => {
  const { updateFormData, formData, prevStep } = useFormContext();
  const { control, handleSubmit, formState, getValues } = useForm<JobDescriptionInput>({
    defaultValues: formData,
  });

  const handleGoBack = () => {
    const currentData = getValues();
    updateFormData(currentData);
    prevStep();
  };

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      overflow={'auto'}
      gap={'32px'}
    >
      <InputLabel
        required
        label="Facilítanos tu nombre, apellido y email para que podamos enviarte la oferta completa."
      />
      <Stack width={'100%'} display={'flex'} direction={'row'} columnGap={'60px'}>
        <TextField
          control={control}
          name="name"
          inputLabel="Nombre"
          autoFocus
          required
          disabled={loading}
        />
        <TextField
          control={control}
          name="surname"
          inputLabel="Apellido"
          required
          disabled={loading}
        />
      </Stack>
      <TextField
        control={control}
        name="email"
        inputLabel="Email"
        required
        disabled={loading}
      />
      <FormActions
        isValid={formState.isValid}
        handleGoBack={handleGoBack}
        loading={loading}
        lastStep
        buttonId='lm-job-description-submit-button'
      />
    </Stack>
  );
};

export default Step4;
