import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { useContext } from 'react';
import { PostContext } from '../../context/PostContext';

const CharCounter = () => {
  const { postState } = useContext(PostContext);
  const theme = useTheme();
  const status = postState?.status;

  let charsCountColor = theme.palette.text.secondary;
  if ((status?.charsCount || 0) > 2500 && (status?.charsCount || 0) < 3000)
    charsCountColor = theme.palette.warning.main;
  else if ((status?.charsCount || 0) > 3000) charsCountColor = theme.palette.error.main;

  const progress = Math.round(((status?.charsCount || 0) / 3000) * 100);

  if ((status?.charsCount || 0) > 2750) {
    return (
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <Typography fontWeight="700" color={charsCountColor}>
          {3000 - status?.charsCount!}
        </Typography>
      </Box>
    );
  }

  return (
    <Tooltip title={`${status?.charsCount || 0} / 3000`} enterTouchDelay={0}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <>
          <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) => theme.palette.grey[200],
            }}
            size={15}
            thickness={10}
            value={100}
          />
          <CircularProgress
            variant="determinate"
            sx={{
              animationDuration: '550ms',
              position: 'absolute',
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
              color: charsCountColor,
            }}
            size={15}
            thickness={10}
            value={progress}
          />
        </>
      </Box>
    </Tooltip>
  );
};

export default CharCounter;
