import React from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

const Layout: React.FC<{
  stepper: React.ReactNode;
  forms: React.ReactNode;
}> = ({ stepper, forms }) => {
  const mediaQuery = useMediaQuery('(min-width:1200px)');
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap-reverse',
        width: '100%',
        minHeight: '100%',
        padding: 1,
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: mediaQuery ? '727px' : '100%',
          minHeight: '100%',
          overflow: 'auto',
        }}
      >
        {forms}
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: mediaQuery ? '30%' : '100%',
          mb: '24px',
          pl: mediaQuery ? 3.4 : 0,
        }}
      >
        {stepper}
      </Box>
    </Box>
  );
};

export default Layout;
