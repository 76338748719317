import { Delete } from '@mui/icons-material';
import {
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Brand } from '../../__generated__/graphql';
import AvatarCell from '../Avatar/AvatarCell';
import { formatFullDateShort } from '../../utils';

const HeaderCell = ({ text, sx = {} }: { text: string; sx?: SxProps }) => (
  <TableCell sx={{ color: 'black', fontWeight: 'bold', ...sx }}>
    <Typography fontSize="14px" fontWeight="bold">
      {text}
    </Typography>
  </TableCell>
);

interface Props {
  brandsInGroup: Brand[];
  handleModalDelete: (brandId: string) => void;
}

const GroupsMembersBrandTable = ({ brandsInGroup, handleModalDelete }: Props) => {
  return (
    <>
      <Typography
        variant="h6"
        sx={{
          marginBottom: '10px',
          fontWeight: 'bold',
        }}
      >
        Members
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '20px',
        }}
      >
        <Table
          sx={{
            width: '100%',
          }}
        >
          <TableHead>
            <TableRow>
              <HeaderCell
                text="Name"
                sx={{
                  color: 'primary.main',
                }}
              />
              <HeaderCell
                text="Create"
                sx={{
                  color: 'primary.main',
                }}
              />
              <HeaderCell
                text="Actions"
                sx={{
                  color: 'primary.main',
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {brandsInGroup?.map((brand) => (
              <TableRow
                key={brand._id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 'none' },
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                }}
                data-testid="user-row"
              >
                <AvatarCell avatar={brand?.account?.avatar || ''} name={brand.name} />
                <TableCell>{formatFullDateShort(brand.createdOn)}</TableCell>
                <TableCell>
                  <Delete
                    onClick={() => handleModalDelete(brand._id)}
                    sx={{
                      color: 'rgba(0, 0, 0, 0.56)',
                      cursor: 'pointer',
                      textAlign: 'center',
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GroupsMembersBrandTable;
