import { useMutation } from '@apollo/client';
import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Modal, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import {
  CheckboxButtonGroup,
  FormContainer,
  TextFieldElement,
} from 'react-hook-form-mui';
import { PostFeedbackOption } from '../../__generated__/graphql';
import { SnackbarContext } from '../../context/SnackbarContext';
import { DELETE_POST } from '../../graphql/mutations';
import { useTranslation } from 'react-i18next';

const feedbackOptionLabel = {
  [PostFeedbackOption.DislikeWriting]: "I don't like the writing",
  [PostFeedbackOption.IncorrectInformation]: 'The information is incorrect',
  [PostFeedbackOption.LackDepth]: 'There is a lack of depth in the content',
  [PostFeedbackOption.NoInterest]: "I'm not interested in the topic",
  [PostFeedbackOption.NotSharedOpinion]:
    "I don't share the opinion reflected in the post",
  [PostFeedbackOption.Other]: 'Other',
};

export const DiscardPost = ({
  postId,
  onCloseModal,
}: {
  postId: string;
  onCloseModal: () => void;
}) => {
  const { setSuccessMessage } = useContext(SnackbarContext);

  const [deletePost] = useMutation(DELETE_POST, {
    update: (cache) => {
      cache.evict({ id: cache.identify({ __typename: 'Post', id: postId! }) });
      cache.gc();
    },
  });

  const onSuccess = ({
    options,
    other,
  }: {
    options: PostFeedbackOption[];
    other: string;
  }) => {
    const feedback = options.length > 0 || other ? { options, other } : undefined;
    deletePost({ variables: { postId: postId!, feedback } });
    setSuccessMessage(t('Thank you for your feedback'));
    onCloseModal();
  };

  const onSkipFeedback = () => {
    deletePost({ variables: { postId: postId! } });
    setSuccessMessage(t('Post deleted successfully'));
    onCloseModal();
  };

  const { t } = useTranslation();
  return (
    <Modal
      open={!!postId}
      onClose={onCloseModal}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      data-testid="delete-post-modal"
    >
      <Box
        sx={{
          bgcolor: '#FFF',
          padding: '25px',
          borderRadius: '4px',
        }}
      >
        <Stack direction="row" justifyContent="space-between" mb="20px" textAlign="right">
          <Typography variant="h5">{t('Discard Post')}</Typography>
          <IconButton onClick={onCloseModal}>
            <Close />
          </IconButton>
        </Stack>
        <FormContainer
          onSuccess={onSuccess}
          defaultValues={{
            options: [],
            other: '',
          }}
        >
          <CheckboxButtonGroup
            name="options"
            options={Object.values(PostFeedbackOption).map((option) => ({
              id: option,
              label: t(feedbackOptionLabel[option]),
            }))}
          />
          <TextFieldElement
            label={t('Let us know more')}
            name="other"
            type="text"
            multiline
            rows={2}
            sx={{ marginY: '20px', display: 'block' }}
            InputProps={{ style: { width: '100%' } }}
          />
          <Stack direction="row" spacing={1} display="flex" justifyContent="flex-end">
            <Button variant="outlined" type="submit" onClick={onSkipFeedback}>
              {t('Skip')}
            </Button>
            <Button variant="contained" type="submit">
              {t('Submit feedback')}
            </Button>
          </Stack>
        </FormContainer>
      </Box>
    </Modal>
  );
};
