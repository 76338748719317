import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Stack, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import useMediaQuery from '@mui/material/useMediaQuery';

type FormSteps = {
  label: string;
  description: string;
};

const FormStepper = ({ steps, currentStep, colorActive="primary.main", colorBg = "#FFEBF5"  }: { steps: FormSteps[], currentStep: number, colorActive?: string, colorBg?: string }) => {

  const mediaQuery = useMediaQuery('(min-width:1200px)');

  const styleItem = (isActive: boolean) => {
    return {
      mb: 3.2,
      backgroundColor: isActive ? colorBg : '#FFFF',
      borderRadius: '8px',
      color: isActive ? 'black' : '#000000',
      fontSize: '16px',
      width: '304px',
      padding: '8px 22px',
    };
  };

  return !mediaQuery ? (
    <Stack width={'100%'}>
      <Stepper activeStep={currentStep} alternativeLabel>
        {steps.map(({ label, description }) => (
          <Step key={label}>
            <StepLabel>{mediaQuery ? description : ''}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  ) : (
    <List>
      {steps.map(({ label, description }, index) => (
        <ListItem
          sx={() => styleItem(index === currentStep)}
          key={`${index} desktop`}
        >
          <ListItemText>
            <Typography
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              fontWeight={700}
            >
              {index + 1}. {description}
              {(currentStep > index && currentStep !== index) &&(
                <CheckCircleIcon sx={{ color: '#4CAF50' }} />
              )}
            </Typography>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default FormStepper;
