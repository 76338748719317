import { Close, FavoriteBorder } from '@mui/icons-material';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
} from '@mui/material';

const PostSkeleton = () => {
  const skeletonMessageHeight = Math.floor(Math.random() * (250 - 150 + 1)) + 150;
  const skeletonImageHeight = Math.floor(Math.random() * (250 - 150 + 1)) + 150;
  const hasImage = Math.random() > 0.5;
  return (
    <div>
      <Card
        sx={{
          border: 'solid 1px #EBEBEB',
          minHeight: '250px',
        }}
        data-testid="post-card"
      >
        <CardHeader
          action={
            <>
              <IconButton disabled>
                <FavoriteBorder data-testid="favorite-outlined-icon" />
              </IconButton>
              <IconButton disabled>
                <Close />
              </IconButton>
            </>
          }
          title={<Skeleton variant="text" width={70} />}
          subheader={<Skeleton variant="text" width={90} />}
        />
        <CardContent>
          <Skeleton variant="rounded" width={'100%'} height={skeletonMessageHeight} />
          <Skeleton variant="text" width={'100%'} height={20} />
          <Skeleton variant="text" width={'60%'} height={20} />
        </CardContent>
        {hasImage && (
          <Skeleton variant="rectangular" width={'100%'} height={skeletonImageHeight} />
        )}

        <CardActions sx={{ paddingX: '10px', paddingBottom: '10px' }}>
          <Skeleton variant="rectangular" width={'100%'} height={35} />
        </CardActions>
      </Card>
    </div>
  );
};

export default PostSkeleton;
