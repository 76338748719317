import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { CssBaseline } from '@mui/material';
import { Analytics } from 'analytics';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation } from 'react-router-dom';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { DeviceProvider } from './context/DeviceContext';
import { PostsFilterProvider } from './context/PostsFilterContext';
import { SnackbarProvider } from './context/SnackbarContext';
import reportWebVitals from './reportWebVitals';
import App from './screens/App';
import { MagnettuThemeProvider } from './themes';
import '../src/languages/init';
// @ts-ignore
import segmentPlugin from '@analytics/segment';
import { BrandProvider } from './context/BrandContext';
import { IdeasFilterProvider } from './context/IdeasFilterContext';
import { PopupNotificationProvider } from './context/PopupNotificationContext';
import { PostsFilterAdminProvider } from './context/PostsFilterAdminContext';
import { RunsFilterProvider } from './context/RunsFilterContext';

const authLink = setContext(async (_, { headers }) => {
  const token = await getAuth().currentUser?.getIdToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
  credentials: 'include',
});

const cache = new InMemoryCache({
  typePolicies: {
    Brand: {
      merge(existing, incoming) {
        return incoming;
      },
    },
    PostAdvocacy: {
      merge(existing, incoming) {
        return incoming;
      },
    },
    PostContent: {
      merge(existing, incoming) {
        return incoming;
      },
    },
    PaginatedPosts: {
      merge(existing, incoming) {
        return incoming;
      },
    },
    Insight: {
      keyFields: ['articleId'],
    },
    PostLifecycleStateDisplay: {
      merge(existing, incoming) {
        return incoming;
      },
    },
    Notifications: {
      merge(existing, incoming) {
        return incoming;
      },
    },
    Query: {
      fields: {
        currentBrandPosts: {
          keyArgs: ['filter', 'orderBy'],
          merge(existing, incoming, { args }) {
            if (args?.page === 0) {
              return {
                total: incoming.total,
                posts: [...incoming.posts],
              };
            }

            return {
              total: incoming.total,
              posts: [...(existing?.posts || []), ...(incoming?.posts || [])],
            };
          },
        },
        postsByShell: {
          keyArgs: ['filter', 'orderBy'],
          merge(existing, incoming, { args }) {
            if (args?.page === 0) {
              return {
                total: incoming.total,
                posts: [...incoming.posts],
              };
            }

            return {
              total: incoming.total,
              posts: [...(existing?.posts || []), ...(incoming?.posts || [])],
            };
          },
        },
      },
    },
  },
});

export const client = new ApolloClient({
  uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
  link: from([authLink, errorLink, httpLink]),
  cache,
});

const prodConfig = {
  apiKey: 'AIzaSyDyJrOkHW6-ywBeXEVpUbFTtfZ7VBg8FJ8',
  authDomain: 'magnettu-app.firebaseapp.com',
  projectId: 'magnettu-app',
  storageBucket: 'magnettu-app.appspot.com',
  messagingSenderId: '552337324753',
  appId: '1:552337324753:web:561178de3f023fcd9b63f0',
  measurementId: 'G-K03PLSHSTF',
};

const devConfig = {
  apiKey: 'AIzaSyCU4ecbRNkDxMFzdzb0uWjViIky2H_mLQY',
  authDomain: 'magnettu-beta.firebaseapp.com',
  projectId: 'magnettu-beta',
  storageBucket: 'magnettu-beta.appspot.com',
  messagingSenderId: '525232034161',
  appId: '1:525232034161:web:bfb861f15de62c512226d0',
};

// Initialize Firebase app
const config = process.env.REACT_APP_NODE_ENV === 'production' ? prodConfig : devConfig;
export const firebaseApp = initializeApp(config);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

export const analytics = Analytics({
  app: 'magnettu-app',
  debug: process.env.REACT_APP_NODE_ENV !== 'production',
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
    }),
  ],
});

export function usePageTracking() {
  let location = useLocation();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    // Track page view on route change
    if (!location.pathname.includes('/lm/')) {
      analytics.page({
        path: location.pathname,
        search: location.search,
        ...(user?._id ? { userId: user._id } : {}),
      });
    }
  }, [location, user?._id]);
}

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <MagnettuThemeProvider>
        <CssBaseline>
          <ApolloProvider client={client}>
            <AuthProvider>
              <DeviceProvider>
                <PostsFilterProvider>
                  <PostsFilterAdminProvider>
                    <RunsFilterProvider>
                      <IdeasFilterProvider>
                        <SnackbarProvider>
                          <BrandProvider>
                            <PopupNotificationProvider>
                              <App />
                            </PopupNotificationProvider>
                          </BrandProvider>
                        </SnackbarProvider>
                      </IdeasFilterProvider>
                    </RunsFilterProvider>
                  </PostsFilterAdminProvider>
                </PostsFilterProvider>
              </DeviceProvider>
            </AuthProvider>
          </ApolloProvider>
        </CssBaseline>
      </MagnettuThemeProvider>
    </React.StrictMode>,
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
