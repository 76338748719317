import { Divider, Stack, Typography } from '@mui/material';

interface Props {
  children: React.ReactNode;
  title: string;
  subtitle: string;
  divider?: boolean;
}

const SettingsSection = ({ children, title, subtitle, divider = true }: Props) => {
  return (
    <>
      <Stack direction="column" spacing={3}>
        <Typography variant="subtitle1">
          <span style={{ fontWeight: 'bold' }}>{title}</span>
          <br /> {subtitle}
        </Typography>
        {children}
      </Stack>
      {divider &&
        <Divider sx={{ margin: 5, marginLeft:0, width: "100%" }}></Divider>
      }
    </>
  );
};

export default SettingsSection;
