import { EventContentArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Close, Lightbulb } from '@mui/icons-material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import { useContext, useRef, useState } from 'react';
import { PostLifecycleState } from '../../__generated__/graphql';
import { DeviceContext } from '../../context/DeviceContext';
import '../../styles/Calendar.css';
import { CalendarEvent } from '../../types';
import { cleanAndSliceText } from '../../utils';
import Avatar from '../Avatar/Avatar';
import { useTranslation } from 'react-i18next';

interface Props {
  events: CalendarEvent[];
  handleEditPost?: (postId: string, userId?: string) => void;
  handleOpenNewPost?: (date: Date) => void;
  handleDateRangeChange?: (start: Date, end: Date) => void;
  handleDiscard: (postId: string) => void;
}

const Calendar = ({
  events,
  handleEditPost = () => {},
  handleOpenNewPost = () => {},
  handleDateRangeChange = () => {},
  handleDiscard = () => {},
}: Props) => {
  const { isMobile, isLargeDevice } = useContext(DeviceContext);
  const calendarRef = useRef<FullCalendar>(null);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const getHeaderClassNames = (date: Date) =>
    date.getDay() === new Date().getDay() ? 'today' : '';

  const renderEventContent = (eventInfo: EventContentArg) => {
    const {
      lifecycleState,
      lifecycleStateDisplay,
      postingAccountName,
      avatar,
      isIdea,
      preview,
    } = eventInfo.event.extendedProps as CalendarEvent;

    let eventStateIcon = <></>;

    if (lifecycleState === PostLifecycleState.Posted) {
      eventStateIcon = (
        <TaskAltIcon
          className="state-icon"
          sx={{ color: '#65CE87', width: '15px', marginLeft: '-15px' }}
        />
      );
    } else if (lifecycleState === PostLifecycleState.Scheduled) {
      eventStateIcon = (
        <ScheduleIcon
          className="state-icon"
          sx={{ color: '#467BF7', width: '15px', marginLeft: '5px' }}
        />
      );
    }

    return (
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: '100%',
          backgroundColor: '#FEF8FD',
          paddingTop: '5px',
          paddingBottom: '7px',
          cursor: 'pointer',
          paddingX: '5px',
          '&:hover .close-icon': {
            display: 'block',
          },
          '&:hover .idea-icon': {
            display: 'none',
          },
          // '&:hover .state-icon': {
          //   display: 'none',
          // },
        }}
        data-testid="calendar-event-content"
      >
        <Stack
          direction="column"
          gap={1}
          onClick={() => handleEventClick(eventInfo)}
          sx={{
            width: '100%',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            justifyContent="space-between"
            width={'100%'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              gap={0.5}
              sx={{
                width: '85%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Avatar
                size={25}
                name={postingAccountName}
                avatar={avatar}
                backgroundColor={eventInfo.backgroundColor}
              />
              <Typography
                fontSize="12px"
                fontWeight={700}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {eventInfo.event?.title
                  ? cleanAndSliceText(eventInfo.event?.title, 50)
                  : cleanAndSliceText(preview, 50)}
              </Typography>
            </Stack>

            {isIdea && (
              <Lightbulb
                sx={{
                  color: '#ffb906',
                  width: '20px',
                }}
                className="idea-icon"
              />
            )}

            {lifecycleState !== PostLifecycleState.Posted && (
              <Close
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDiscard(eventInfo.event.id);
                }}
                className="close-icon"
                sx={{
                  display: 'none',
                  color: '#BCBCBC',
                  background: 'transparent',
                  marginLeft: '5px',
                }}
              />
            )}
          </Stack>

          <Stack
            direction={lifecycleStateDisplay.name!.length > 10 ? 'column' : 'row'}
            gap={1}
            alignItems={'center'}
            justifyContent="space-between"
          >
            <Chip
              label={
                <Typography fontSize="12px">
                  {t(lifecycleStateDisplay.name || '')}
                </Typography>
              }
              sx={{
                backgroundColor: lifecycleStateDisplay?.color,
                height: '24px',
              }}
            />

            <Typography fontSize="12px">{eventInfo.timeText}</Typography>
          </Stack>
        </Stack>
        {eventStateIcon}
      </Stack>
    );
  };

  const handleSlide = (type: 'prev' | 'next') => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      type === 'prev' ? calendarApi.prev() : calendarApi.next();
      setCurrentDate(calendarApi.getDate());

      const startDate = calendarApi.view.activeStart;
      const endDate = calendarApi.view.activeEnd;
      handleDateRangeChange(startDate, endDate);
    }
  };

  const getCalendarTitle = () => {
    let month = currentDate.toLocaleDateString('en-US', {
      month: isMobile ? 'short' : 'long',
      year: 'numeric',
    });

    return month;
  };

  const handleEventClick = (event: EventContentArg) => {
    const getBrandIdPost = events.find((e) => e.id === event.event.id)?.brandId;
    if (getBrandIdPost === undefined) return;
    handleEditPost(event.event.id, getBrandIdPost);
  };

  const { t } = useTranslation();

  return (
    <>
      <Stack direction="row" width="100%" marginBottom={1} textAlign="center">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          width="100%"
        >
          <IconButton
            sx={{ color: '#000' }}
            onClick={() => handleSlide('prev')}
            data-testid="calendar-prev-button"
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography
            variant="h5"
            fontWeight="bold"
            color="primary"
            data-testid="calendar-title"
          >
            {getCalendarTitle()}
          </Typography>
          <IconButton
            sx={{ color: '#000' }}
            onClick={() => handleSlide('next')}
            data-testid="calendar-next-button"
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Box
        width="100%"
        overflow={{
          xs: 'auto',
          sm: 'hidden',
        }}
        px={{
          xs: 2,
          sm: 0,
        }}
      >
        <Box
          width={{ xs: '800px', sm: '100%' }}
          height={{
            sm: 'auto',
          }}
        >
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            dateClick={(info) => {
              handleOpenNewPost(info.date);
            }}
            initialView="dayGridMonth"
            firstDay={1}
            headerToolbar={false}
            defaultTimedEventDuration={'00:01'}
            allDaySlot={false}
            slotLabelFormat={{
              hour: 'numeric',
              hour12: false,
              minute: '2-digit',
              meridiem: false,
            }}
            fixedWeekCount={false}
            events={events}
            eventContent={renderEventContent}
            eventTimeFormat={{
              hour: 'numeric',
              hour12: true,
              minute: '2-digit',
              meridiem: 'short',
            }}
            eventBackgroundColor="#FEF8FD"
            eventBorderColor="transparent"
            eventTextColor={'black'}
            dayHeaderClassNames={(d) => getHeaderClassNames(d.date)}
            dayMaxEvents={3}
            height={'auto'}
            moreLinkContent={(e) => (
              <Stack
                direction="row"
                flex={1}
                justifyContent="space-between"
                alignItems="center"
                sx={{ backgroundColor: '#F9D8EB', padding: '3px' }}
                data-testid="calendar-more-link"
              >
                <Typography fontSize="14px">{e.num} t(more)</Typography>
                <ArrowForwardIosIcon sx={{ width: '15px' }} />
              </Stack>
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default Calendar;
