import { Controller } from 'react-hook-form';
import { Select as MuiSelect, OutlinedInput, Stack } from '@mui/material';

import InputLabel from './InputLabel';

type OptionType = {
  label: string;
  value: string | number;
};

interface SelectProps {
  control: any;
  name: string;
  label?: string;
  options: OptionType[];
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  borderColor?: string;
  disabled? : boolean;
}

const Select = ({
  control,
  options,
  required,
  name,
  label,
  autoFocus,
  placeholder = 'Select an option',
  borderColor = "primary.main",
  disabled = false
}: SelectProps) => {
  return (
    <Stack direction={'column'} gap={'32px'} width={'100%'}>
      {label && <InputLabel required={required} label={label} colorRequired={borderColor} />}
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={{ required }}
        render={({ field }) => (
          <MuiSelect
          sx={{
            borderRadius: '8px',
            color: field.value ? 'inherit' : '#BBB',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: borderColor
            },
          }}
            native
            input={<OutlinedInput />}
            id={`select-${name}`}
            {...field}
            autoFocus={autoFocus}
            disabled={disabled}
          >
            <option value="" disabled style={{ color: 'green' }}>
              {placeholder}
            </option>
            {options.map(({ label, value }: OptionType) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </MuiSelect>
        )}
      />
    </Stack>
  );
};

export default Select;
