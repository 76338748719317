import {
  Add,
  CheckCircle,
  Close,
  Delete,
  InfoOutlined,
  PersonAdd,
  Send,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  Stack,
  Typography,
  IconButton,
  Divider,
  Box,
  Button,
  TextField,
  DialogActions,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { getFullName } from '../../utils';
import { Brand, User, UserRole } from '../../__generated__/graphql';
import Avatar from '../Avatar/Avatar';
import * as yup from 'yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { INVITE_USERS } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { BrandContext } from '../../context/BrandContext';
import { SnackbarContext } from '../../context/SnackbarContext';

const inputStyle = {
  width: '100%',
  borderRadius: '8px',
  marginBottom: '10px',
  '&.MuiInputBase-root': {
    borderRadius: '8px',
  },
};

interface EditMemberModalProps {
  openModal: boolean;
  handleCloseModal: () => void;
  listNotManagers: User[];
  handleAddNewManagers: (userIds: string[]) => void;
  loadingAddManagers: boolean;
  brandSelected: Brand;
}

const AddNewBrandManagersModal: FC<EditMemberModalProps> = ({
  openModal,
  handleCloseModal,
  listNotManagers,
  handleAddNewManagers,
  loadingAddManagers,
  brandSelected,
}) => {
  const [idsMember, setIdsMember] = useState<string[]>([]);
  const [step, setStep] = useState(0);
  const [emailsExist, setEmailsExist] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);
  const [inviteUsers, { loading: loadingInviteUsers }] = useMutation(INVITE_USERS);
  const [successInvite, setSuccessInvite] = useState(false);
  const { refetch } = useContext(BrandContext);
  const { setErrorMessage, setSuccessMessage } = useContext(SnackbarContext);

  const schema = yup.object().shape({
    emails: yup
      .array()
      .of(
        yup
          .string()
          .email('Must be a valid email')
          .required('This field is required')
          .test('email-exists', 'This email already exists', function (value) {
            return !emailsExist.includes(value || '');
          }),
      )
      .required('Must have at least one email'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      emails: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails' as never,
  });

  const handleSelectMember = (id: string) => {
    if (idsMember.includes(id)) {
      setIdsMember(idsMember.filter((item) => item !== id));
    } else {
      setIdsMember([...idsMember, id]);
    }
  };

  const handleRemove = (index: number) => {
    const values = getValues();

    remove(index);

    if (values.emails[1] === '') {
      append('');
    }
  };

  const handleInvite = () => {
    handleAddNewManagers(idsMember);
    handleCloseModal();
  };

  const handleClose = () => {
    handleCloseModal();
    reset();
    setStep(0);
    setIdsMember([]);
    setShowErrors(false);
    setEmailsExist([]);
  };

  const handleAppend = async () => {
    const values = getValues();
    const lastIndex = values.emails.length - 1;

    const isValid = await trigger(`emails.${lastIndex}`);

    if (isValid) {
      append('');
      setShowErrors(false);
    } else {
      setShowErrors(true);
    }
  };

  const handleInviteNewMember = () => {
    setStep(1);
    append('');
  };

  const handleBack = () => {
    setStep(0);
    reset();
    setIdsMember([]);
    setShowErrors(false);
  };

  const onSubmit = async (data: any) => {
    setShowErrors(true);

    const usersDetails = data.emails.map((email: string) => ({
      email,
      role: UserRole.LimitedBrandManager,
      brands: [brandSelected._id],
    }));

    inviteUsers({
      variables: {
        input: {
          usersDetails: usersDetails,
        },
      },
      onCompleted: (data) => {
        if (!data.inviteUsers?.success && data.inviteUsers?.emailsExist?.length) {
          setErrorMessage(data.inviteUsers?.message || 'Error inviting users');
          setEmailsExist(data.inviteUsers.emailsExist as string[]);
          setShowErrors(true);
          return;
        }
        refetch();
        handleClose();
        setSuccessMessage('Invitation sent successfully');
        setSuccessInvite(true);
      },
    });
  };

  useEffect(() => {
    if (emailsExist.length) {
      setShowErrors(true);
      trigger('emails');
    }
  }, [emailsExist, trigger]);

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: step === 0 ? '548px' : '868px' },
            height: 'auto',
            maxWidth: '100%',
            minHeight: '300px',
            borderRadius: '10px',
            padding: 2,
          },
        }}
      >
        {step === 0 && (
          <Stack>
            <Stack>
              <Typography fontWeight={700} fontSize={25}>
                Who do you want to add?
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <Close />
              </IconButton>
            </Stack>
            <Divider
              sx={{
                marginY: '1rem',
              }}
            />

            <Typography fontWeight={700}>New member</Typography>

            <Box
              onClick={handleInviteNewMember}
              sx={{
                marginY: 2,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                gap: 1,
                backgroundColor: '#ECECEC',
                height: '56px',
                padding: '10px 0 10px 17px',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#ECECEC',
                },
              }}
            >
              <PersonAdd /> Invite new member
            </Box>

            <Divider
              sx={{
                marginBottom: 2,
                marginTop: 2,
              }}
            />

            <Typography fontWeight={700} marginBottom={1}>
              Members within magnettu
            </Typography>

            <Box maxHeight={400} overflow="auto">
              {listNotManagers.map((member) => (
                <Box
                  display="flex"
                  key={member?._id}
                  width="100%"
                  alignItems="center"
                  gap={1}
                  marginTop="0.3rem"
                  onClick={() => handleSelectMember(member?._id || '')}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: idsMember.includes(member?._id || '')
                      ? 'rgba(255, 0, 122, 0.08)'
                      : 'white',
                    height: '56px',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 0, 122, 0.08)',
                    },
                  }}
                >
                  <Avatar
                    name={getFullName(member) || ''}
                    backgroundColor={member?.brand?.backgroundColor || undefined}
                    avatar={member?.brand?.account?.avatar || undefined}
                    size={50}
                  />
                  <Typography
                    fontSize={'14px'}
                    sx={{
                      color: 'black',
                      fontWeight: 'normal',
                    }}
                  >
                    {getFullName(member)}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Divider />
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              onClick={handleInvite}
              loading={loadingAddManagers}
              sx={{
                width: '156px',
                height: '42px',
                marginTop: '20px',
                marginX: 'auto',
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        )}
        {step === 1 && (
          <>
            <Typography fontWeight={700} fontSize={25}>
              Invite new member for this brand
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
            <Typography marginY={2}>
              Invite a new member so they can collaborate within the{' '}
              <b>{brandSelected.name}</b> brand.
            </Typography>
            <Stack gap={2} flexDirection="row" marginBottom={2}>
              <InfoOutlined sx={{ ml: 1 }} fontSize="small" />
              <Typography>
                This member will become a <b>"brand manager"</b> and will be able to
                create content and distribute it solely on this brand.
              </Typography>
            </Stack>

            <Box>
              <Box maxHeight={300} overflow={'auto'}>
                {fields.map((item, index) => (
                  <Box key={item.id} display="flex" gap={2} alignItems="center">
                    <Controller
                      name={`emails.${index}`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          placeholder="Email address"
                          type="email"
                          label="Email"
                          sx={inputStyle}
                          variant="outlined"
                          error={showErrors && !!errors.emails?.[index]}
                          helperText={showErrors && errors.emails?.[index]?.message}
                          {...field}
                        />
                      )}
                    />
                    {fields.length > 1 && (
                      <Delete
                        sx={{
                          color: '#C62828',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleRemove(index)}
                      />
                    )}
                  </Box>
                ))}
                <Button
                  onClick={handleAppend}
                  sx={{
                    borderRadius: '100px',
                    height: '50px',
                    width: '139px',
                    backgroundColor: 'hsla(0, 0%, 88%, 1)',
                    padding: '12px, 16px, 12px, 16px',
                    color: 'hsla(0, 0%, 0%, 0.87)',
                    fontWeight: 700,
                    fontSize: '15px',
                  }}
                  endIcon={<Add />}
                >
                  Add more
                </Button>
              </Box>
            </Box>
            <DialogActions
              sx={{ p: '0px 24px 20px 24px', marginTop: '20px' }}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Button onClick={handleBack} disabled={loadingInviteUsers}>
                Back
              </Button>

              <LoadingButton
                loading={loadingInviteUsers}
                variant={'contained'}
                onClick={handleSubmit(onSubmit, (errors) => {
                  setShowErrors(true);
                })}
                endIcon={<Send />}
              >
                Send invite
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog
        open={successInvite}
        onClose={() => {
          setSuccessInvite(false);
        }}
        disableEscapeKeyDown
        maxWidth={false}
        PaperProps={{
          sx: {
            borderRadius: '10px',
            width: { xs: '100%', sm: '700px' },
            maxHeight: { xs: '100vh', sm: 'calc(100vh - 64px)' },
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <CheckCircle sx={{ color: 'hsla(122, 39%, 49%, 1)', fontSize: 60 }} />
          <Typography fontWeight={700} fontSize={23} marginY={2}>
            You have successfully added the new member for this brand!
          </Typography>

          <DialogActions sx={{ p: '0px 24px 20px 24px' }}>
            <Button
              onClick={() => {
                setSuccessInvite(false);
              }}
              variant={'contained'}
              sx={{
                width: '325px',
              }}
            >
              Accept
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default AddNewBrandManagersModal;
