import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import {
  OrderByDirection,
  OrderByField,
  PrompterRun,
  PrompterRunLifecycleState,
} from '../../__generated__/graphql';
import { SnackbarContext } from '../../context/SnackbarContext';
import { DELETE_RUN } from '../../graphql/mutations';
import { OrderBy } from '../../types';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

const LifecycleStateColor = {
  [PrompterRunLifecycleState.Error]: '#FFB3B3',
  [PrompterRunLifecycleState.Finished]: '#CAECBE',
  [PrompterRunLifecycleState.Queued]: '#F9F09E',
  [PrompterRunLifecycleState.Running]: '#FCE8E0',
  [PrompterRunLifecycleState.Draft]: '#DAE5FD',
};

const HeaderCell = ({
  text,
  isOrderable = false,
  active = false,
  direction = OrderByDirection.Asc,
  onClick = () => {},
  sx = {},
}: {
  text: string;
  isOrderable?: boolean;
  active?: boolean;
  direction?: OrderByDirection;
  onClick?: () => void;
  sx?: SxProps;
}) => (
  <TableCell sx={{ color: 'primary.main', fontWeight: 'bold', ...sx }}>
    {isOrderable ? (
      <TableSortLabel
        active={active}
        direction={direction === OrderByDirection.Asc ? 'asc' : 'desc'}
        onClick={onClick}
        sx={{ color: 'primary.main', fontWeight: 'bold' }}
      >
        <Typography fontSize="14px" fontWeight="bold">
          {text}
        </Typography>
      </TableSortLabel>
    ) : (
      <Typography fontSize="14px" fontWeight="bold">
        {text}
      </Typography>
    )}
  </TableCell>
);

const Cell = ({
  text,
  sx,
  children,
}: {
  text?: string;
  sx?: SxProps;
  children?: React.ReactNode;
}) => (
  <TableCell sx={sx}>
    {children || (
      <Typography fontSize="12px" fontWeight="bold">
        {text}
      </Typography>
    )}
  </TableCell>
);

interface Props {
  runs: PrompterRun[];
  page?: number;
  limit?: number;
  setLimit?: (limit: number) => void;
  orderBy?: OrderBy;
  setOrderBy?: (orderBy: OrderBy) => void;
  onPageChange?: (page: number) => void;
  total?: number;
}

const RunsTable = ({
  runs,
  page = 0,
  limit = 15,
  setLimit = () => {},
  orderBy = { field: OrderByField.CreatedOn, direction: OrderByDirection.Desc },
  setOrderBy = () => {},
  onPageChange = () => {},
  total = -1,
}: Props) => {
  const navigate = useNavigate();
  const [deleteRunId, setDeleteRunId] = useState<string | null>(null);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);
  const [openRow, setOpenRow] = useState<number | null>();

  const [hardDeleteRun] = useMutation(DELETE_RUN, {
    onCompleted: () => {
      setSuccessMessage('Run deleted successfully');
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
    update: (cache, result) => {
      const normalizedId = cache.identify(result.data?.deleteRun.run!);
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const onOrderByChange = (field: OrderByField) => {
    setOrderBy({
      field,
      direction:
        orderBy.field === field && orderBy.direction === OrderByDirection.Asc
          ? OrderByDirection.Desc
          : OrderByDirection.Asc,
    });
  };

  const handleDeleteRun = (runId: string) => {
    hardDeleteRun({
      variables: { runId },
    });
    setDeleteRunId(null);
  };

  return (
    <>
      <ConfirmationDialog
        title="Delete Run"
        content="Are you sure you want to delete this run forever? You will not be able to recover it."
        onConfirm={() => handleDeleteRun(deleteRunId!)}
        open={deleteRunId !== null}
        onClose={() => setDeleteRunId(null)}
        okText="Delete"
        cancelText="Cancel"
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <HeaderCell text="Brand" />
              <HeaderCell
                text="Created"
                isOrderable
                active={orderBy.field === OrderByField.CreatedOn}
                direction={orderBy.direction}
                onClick={() => onOrderByChange(OrderByField.CreatedOn)}
              />
              <HeaderCell text="Created by" />
              <HeaderCell
                text="Status"
                isOrderable
                active={orderBy.field === OrderByField.LifecycleState}
                direction={orderBy.direction}
                onClick={() => onOrderByChange(OrderByField.LifecycleState)}
              />
              <HeaderCell text="Chain" />
              <HeaderCell text="Actions" sx={{ textAlign: 'center' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {runs.map((run, index) => (
              <React.Fragment key={run._id}>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    borderLeft: '5px solid',
                    borderColor: 'primary.main',
                  }}
                  onClick={() =>
                    setOpenRow((prevIndex) => (prevIndex === index ? null : index))
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <Cell text={run.brandName || ''} />
                  <Cell
                    text={
                      new Date(run.createdOn)
                        .toLocaleString('es-ES', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false,
                        })
                        .replace(',', '')
                        .replace(' ', ' - ') + 'h'
                    }
                  />
                  <Cell text={run.createdByFullName || ''} />
                  <Cell>
                    <Chip
                      size="small"
                      sx={{ backgroundColor: LifecycleStateColor[run.lifecycleState] }}
                      label={run.lifecycleState}
                    ></Chip>
                  </Cell>
                  <Cell>{run.chainName || ''}</Cell>
                  <Cell sx={{ textAlign: 'center' }}>
                    <IconButton
                      disabled={run.lifecycleState === PrompterRunLifecycleState.Running}
                      onClick={() =>
                        navigate(`/content-manager/prompter-runs/${run._id}`)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => setDeleteRunId(run._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Cell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      paddingBottom: 0,
                      paddingTop: 0,
                      maxWidth: '100px',
                      ...(openRow === index ? {} : { border: 'none' }),
                    }}
                  >
                    <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="body2">{run.topicContext}</Typography>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                rowsPerPageOptions={[15, 25, 50]}
                rowsPerPage={limit}
                page={page}
                onPageChange={(_, page) => onPageChange(page)}
                onRowsPerPageChange={(event) => {
                  onPageChange(0);
                  setLimit(+event.target.value);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default RunsTable;
