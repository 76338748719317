import {
  Box,
  Card,
  CircularProgress,
  Divider,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Language, UserLifecycleState } from '../../__generated__/graphql';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { DisplayLanguage } from '../../types';
import { CREATE_BRAND_SELF_SERVICE } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const inputStyle = {
  width: '100%',
  maxWidth: '800px',
  height: '40px',
  backgroundColor: 'white',
};

const Onboarding = () => {
  const { t, i18n } = useTranslation();
  const { user, loading: loadingAuth, refetchCurrentUser } = useContext(AuthContext);
  // const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const [createBrandSelfService, { loading }] = useMutation(CREATE_BRAND_SELF_SERVICE, {
    onCompleted: ({ createBrandSelfService }) => {
      if (createBrandSelfService?.success) {
        refetchCurrentUser();
        // navigate('/');
      }
    },
  });

  const schema = yup.object().shape({
    urlProfile: yup
      .string()
      .nullable()
      .notRequired()
      .matches(
        /^$|^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9-_áéíóúÁÉÍÓÚñÑüÜçÇÀ-ÿ]+\/?(?:\?.*)?$/,
        t('Error Url')
      ),
    themeTalk: yup.string().required(t('This field is required')),
    position: yup.string().required(t('This field is required')),
    idiomaSite: yup.string().required(t('This field is required')),
    idiomaPublish: yup.string().required(t('This field is required')),
  });

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      urlProfile: null,
      themeTalk: '',
      position: '',
      idiomaSite: '',
      idiomaPublish: '',
    },
    values: {
      urlProfile: null,
      themeTalk: '',
      position: '',
      idiomaSite: user?.language || Language.English,
      idiomaPublish: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    createBrandSelfService({
      variables: {
        input: {
          urlProfile: data.urlProfile,
          language: data.idiomaSite,
          contentCreation: {
            position: data.position,
            language: data.idiomaPublish,
            themeTalk: data.themeTalk,
          },
        },
      },
    });
  };

  const idiomaSite = watch('idiomaSite');

  useEffect(() => {
    // Mapea el valor de idiomaSite a los códigos de idioma esperados por i18n
    const mapLanguage = {
      Spanish: 'es',
      English: 'en',
    };

    // Obtén el idioma del usuario o el seleccionado en el formulario
    const languageUser = mapLanguage[idiomaSite as keyof typeof mapLanguage] as string;
    // Lista de idiomas soportados por la app
    const availableLanguages = ['en', 'es'];

    // Cambia el idioma si es uno de los soportados
    if (availableLanguages.includes(languageUser)) {
      i18n.changeLanguage(languageUser);
    } else {
      i18n.changeLanguage('en'); // Cambia a inglés si el idioma no está soportado
    }
  }, [i18n, user?.language, idiomaSite]);

  useEffect(() => {
    if (!user) {
      refetchCurrentUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingAuth && user) {
      try {
        if (user?.lifecycleState === UserLifecycleState.OnboardingPaymentPending) {
          navigate('/trial');
        } else if (user?.lifecycleState === UserLifecycleState.Active) {
          navigate('/');
        }
      } catch (error) {
        console.error('Error during navigation:', error);
      }

      setValue('idiomaSite', user?.language || Language.English);
    }
  }, [loadingAuth, navigate, user, refetchCurrentUser, setValue]);

  return (
    <>
      {!user || user?.lifecycleState === UserLifecycleState.OnboardingPaymentPending ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction={'column'}
            bgcolor="white"
            height={'100vh'}
            minHeight={'100vh'}
          >
            <Box display="flex" justifyContent="center" marginY="25px" height={'5vh'}>
              <img src="../images/magnettu_logo_new.svg" alt="logo" />
            </Box>

            <Divider />
            {/* {step === 0 && ( */}
            <Box height="100%" overflow="auto">
              <Typography
                fontWeight={600}
                fontSize={{ xs: 26, md: 32 }}
                align="center"
                marginTop={3}
              >
                {t('Hablemos de ti')}
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={{ xs: 18, md: 21 }}
                align="center"
                color="text.secondary"
                marginTop={2.5}
              >
                {t('Esta información nos ayudará a adaptar tu experiencia.')}
              </Typography>
              <Card
                sx={{
                  maxWidth: '768px',
                  width: { xs: '100%', sm: '90%' },
                  margin: 'auto',
                  marginTop: '42px',
                  padding: { xs: '20px', sm: '34px 60px' },
                  borderRadius: '10px',
                  marginBottom: '20px',
                  height: 'auto',
                  bgcolor: '#F5F5F5',
                  boxShadow: 'none',
                }}
              >
                <Typography fontWeight={500} marginY={2}>
                {t('Idioma plataforma')}
                </Typography>

                <Controller
                  name="idiomaSite"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      required
                      value={value || ''}
                      onChange={onChange}
                      sx={inputStyle}
                    >
                      <MenuItem
                        value={Language.English}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <ListItemText>English</ListItemText>
                      </MenuItem>
                      <MenuItem value={Language.Spanish}>Spanish</MenuItem>
                    </Select>
                  )}
                />

                <Typography fontSize={14} color="#00000099" marginY="1rem">
                  {t('Siempre podrás cambiarlo en la configuración de tu perfil.')}
                </Typography>
                <Divider />

                <Stack direction="row" alignItems="center" marginY="1rem" gap={2}>
                  <Box width="29px" height="29px">
                    <img src="../images/icons/cirlceuser.svg" alt="circle" />
                  </Box>
                  <Typography fontWeight={600}>{t('Sobre ti')}</Typography>
                </Stack>

                <Typography fontWeight={500} marginY={2}>
                  {t('Mi posicion es')}
                </Typography>

                <Controller
                  name={'position'}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        key={'position'}
                        size="small"
                        fullWidth
                        // required
                        sx={inputStyle}
                        variant="outlined"
                        {...register('position')}
                        onChange={onChange}
                        placeholder={t('Markting director')}
                        error={!!errors.position} // Indicar si hay un error
                        helperText={errors.position?.message}
                      />
                    );
                  }}
                />

                <Typography fontWeight={500} marginY={2}>
                  {t('Quiero que el idioma de las publicaciones sea')}
                </Typography>

                <Controller
                  name="idiomaPublish"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      // required
                      value={value || ''}
                      onChange={onChange}
                      sx={inputStyle}
                      error={!!errors.idiomaPublish} // Indicar si hay un error
                      // helperText={errors.idiomaPublish?.message}
                    >
                      {Object.entries(Language).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {DisplayLanguage[value as keyof typeof DisplayLanguage]}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {!!errors.idiomaPublish && (
                  <Typography color="error" fontSize={12}>
                    {errors.idiomaPublish.message}
                  </Typography>
                )}
                <Typography fontWeight={500} marginY={2}>
                  {t('Me gustaria hablar de ... (opcional)')}
                </Typography>

                <Controller
                  name={'themeTalk'}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        // key={'position'}
                        size="small"
                        sx={inputStyle}
                        fullWidth
                        // required
                        variant="outlined"
                        {...register('themeTalk')}
                        onChange={onChange}
                        placeholder={t('Marketing investments, global leads')}
                        error={!!errors.themeTalk} // Indicar si hay un error
                        helperText={errors.themeTalk?.message}
                      />
                    );
                  }}
                />

                <Typography fontWeight={500} marginY={2}>
                  {t('URL LinkedIn')}
                </Typography>

                <Controller
                  name={'urlProfile'}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        sx={inputStyle}
                        variant="outlined"
                        {...register('urlProfile')}
                        onChange={onChange}
                        placeholder={t('Placeholder url')}
                        error={!!errors.urlProfile} // Indicar si hay un error
                        helperText={errors.urlProfile?.message}
                      />
                    );
                  }}
                />
              </Card>
            </Box>
            {/* )} */}

            <Box marginTop="auto">
              <Divider />

              <Stack
                direction="row"
                justifyContent={'flex-end'}
                paddingX="10%"
                paddingY="20px"
                gap={2}
              >
                <LoadingButton
                  variant="contained"
                  type="submit"
                  color="primary"
                  loading={loading}
                  sx={{
                    width: { xs: 'auto', sm: '287px' },
                  }}
                >
                  {t('Next')}
                </LoadingButton>
              </Stack>
            </Box>
          </Stack>
        </form>
      )}
    </>
  );
};

export default Onboarding;
