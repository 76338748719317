import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form-mui';
import ButtonsGroups from '../../components/ButtonsGroup';
import FormActions from '../../components/FormActions';
import InputLabel from '../../components/InputLabel';
import Select from '../../components/Select';
import TextField from '../../components/TextField';
import { useFormContext } from '../../contexts/FormContext';
import { propertyAttributes, selectOneToTwentyFive } from './consts';
import { RealEstateDescriptionInput } from '../../../__generated__/graphql';

const Step2 = ({ onSubmit }: { onSubmit: (data: RealEstateDescriptionInput) => void}) => {
  const { updateFormData, formData, prevStep } = useFormContext();
  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues: formData,
  });

  const handleGoBack= () => {
    const currentData = getValues();
    updateFormData(currentData); 
    prevStep(); 
  }

  return (
    <Stack component={'form'} onSubmit={handleSubmit(onSubmit)} spacing={'32px'}>
      <TextField
        label="¿Cuál es la dirección o zona en la que se encuentra?"
        name="propertyAddress"
        autoFocus
        placeholder="Ej: Calle Balmes, 3"
        control={control}
      />
      <Select
        name="propertyRooms"
        required
        placeholder="Ej: 2"
        label="¿Cuál es el número de habitaciones disponibles en la propiedad?"
        options={selectOneToTwentyFive}
        control={control}
      />
      <InputLabel label="¿Cuántos baños tiene?" required/>
      <Stack width={'100%'} display={'flex'} direction={'row'} columnGap={'60px'}>
        <Select
          required
          options={selectOneToTwentyFive}
          control={control}
          name="propertyBathrooms"
          placeholder="Ej: 2 baños completos"
        />
        <Select
          options={selectOneToTwentyFive}
          control={control}
          name="propertyServiceBathrooms"
          placeholder="Ej: 0 baños de servicio"
        />
      </Stack>
      <ButtonsGroups
        name="propertyAttributes"
        control={control}
        options={propertyAttributes}
        required
        label="¿Cuáles son los atributos o detalles interesantes del piso que destacarías?"
      />
      <TextField name="propertyOtherAttributes" placeholder="Otros" control={control} />
      <TextField
        label="¿Qué condiciones tiene el alquiler o compra del piso?"
        name="propertyConditions"
        placeholder="Ejemplo: 1200 euros mensuales, 2 meses de fianza y solo se arrienda a gente que esté trabajando en la ciudad."
        multiline
        control={control}
      />
      <FormActions isValid={formState.isValid} handleGoBack={handleGoBack} buttonId='lm-real-estate-description-step-2-button'/>
    </Stack>
  );
};

export default Step2;
