import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack, Typography } from '@mui/material';

interface FormActionsProps {
  buttonId?: string;
  handleGoBack?: () => void;
  isValid: boolean;
  loading?: boolean;
  disabled?: boolean;
  firstStep?: boolean;
  lastStep?: boolean;
  colorButton?: string;
  colorHover?: string;
}

const FormActions = ({
  buttonId = '',
  handleGoBack = () => {},
  isValid,
  loading,
  disabled = false,
  firstStep = false,
  lastStep = false,
  colorButton = "primary.main",
  colorHover = "primary.dark"
}: FormActionsProps) => {
  const styleButton = {
    padding: '15px 38px',
    borderRadius: '8px',
  };

  const justifyContent = firstStep ? 'flex-end' : 'space-between';

  return (
    <>
    <Stack justifyContent={justifyContent} display={'flex'} flexDirection={'row'}>
      {!firstStep && (
        <LoadingButton
          sx={{ ...styleButton,
            borderColor: colorButton,
            color: colorButton,
            '&:hover': {
              backgroundColor: colorHover,
              borderColor: colorHover,
            }
          
          }}
          type="button"
          variant={'outlined'}
          disabled={disabled || loading}
          onClick={handleGoBack}
          
        >
          <KeyboardDoubleArrowLeftIcon /> Atrás
        </LoadingButton>
      )}
      <LoadingButton
        id={buttonId}
        sx={{
          ...styleButton,
          backgroundColor: colorButton,
          color: 'white',
          '&:hover': {
            backgroundColor: colorHover,
          },
        }}
        loading={loading}
        disabled={!isValid || disabled}
        type="submit"
        variant="contained"
        
      >
        {lastStep ? 'Generar oferta' : 'Continuar'}{' '}
        <KeyboardDoubleArrowRightIcon />
      </LoadingButton>

    </Stack>
    {loading && <Typography textAlign="center" fontWeight={600} >
      No cierres la ventana, el sistema podrá tardar algunos segundos mientras se genera el resultado.

      </Typography>
      }
    </>

  );
};

export default FormActions;
