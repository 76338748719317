import { useState, useContext } from 'react';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { AuthContext } from '../../context/AuthContext';
import { Language } from '../../__generated__/graphql';
import { useTranslation } from 'react-i18next';

interface LanguageSwitcherProps {
  onChange: (language: Language) => void;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext); // Get the user from AuthContext
  const [language, setLanguage] = useState(user?.language || Language.English); // Set initial language from user context

  const changeLanguage = (lng: Language) => {
    setLanguage(lng); // Update local state
    onChange(lng); // Notify parent (SettingsProfile) of the language change
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={language === Language.English}
            onChange={() => changeLanguage(Language.English)}
          />
        }
        label={t('English')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={language === Language.Spanish}
            onChange={() => changeLanguage(Language.Spanish)}
          />
        }
        label={t('Spanish')}
      />
    </FormGroup>
  );
};

export default LanguageSwitcher;
