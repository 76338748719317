import { useMutation } from '@apollo/client';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { useContext, useState } from 'react';
import { FormContainer } from 'react-hook-form-mui';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../context/AuthContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { UNSUBSCRIBE_USER, SUBSCRIBE_USER } from '../../graphql/mutations';
import SettingsSection from '../SettingsSection/SettingsSection';
import { NotificationType } from '../../__generated__/graphql';

const inputStyle = {
  width: '100%',
  maxWidth: '800px',
};

// Define all notification types here
const notificationTypes: NotificationType[] = [
  NotificationType.LinkedinConnectionExpires,
  NotificationType.NewMessage,
  NotificationType.NewPost,
  NotificationType.PostStatusChange,
];

const groupedNotificationTypes = {
  linkedinConnectionExpires: [NotificationType.LinkedinConnectionExpires],
  activityOnYourPosts: [NotificationType.NewPost, NotificationType.PostStatusChange],
  newFeedbackOnYourPosts: [NotificationType.NewMessage],
};

// Define the shape of notificationsEnabled
type NotificationsEnabledState = {
  [key in NotificationType]: boolean;
};

const SettingsNotifications = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);

  // Initialize notificationsEnabled state
  const userNotifications = user?.notifications?.email ?? [];
  const initialNotificationsEnabled = notificationTypes.reduce((acc, type) => {
    acc[type] = userNotifications.includes(type);
    return acc;
  }, {} as NotificationsEnabledState);

  const [notificationsEnabled, setNotificationsEnabled] =
    useState<NotificationsEnabledState>(initialNotificationsEnabled);

  const [UnsubscribeUser] = useMutation(UNSUBSCRIBE_USER, {
    onCompleted: () => {
      setSuccessMessage(t('Notifications settings updated'));
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
  });

  const [SubscribeUser] = useMutation(SUBSCRIBE_USER, {
    onCompleted: () => {
      setSuccessMessage(t('Notifications settings updated'));
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
  });

  const onSuccess = () => {
    const userId = user?._id || '';

    // Handle LinkedinConnectionExpires separately
    const isLinkedinConnectionExpiresEnabled =
      notificationsEnabled[NotificationType.LinkedinConnectionExpires];
    isLinkedinConnectionExpiresEnabled
      ? SubscribeUser({
          variables: { userId, type: NotificationType.LinkedinConnectionExpires },
        })
      : UnsubscribeUser({
          variables: { userId, type: NotificationType.LinkedinConnectionExpires },
        });

    // Handle NewPost and PostStatusChange together
    const isNewPostEnabled = notificationsEnabled[NotificationType.NewPost];
    const isPostStatusChangeEnabled =
      notificationsEnabled[NotificationType.PostStatusChange];
    if (isNewPostEnabled || isPostStatusChangeEnabled) {
      SubscribeUser({ variables: { userId, type: NotificationType.NewPost } });
      SubscribeUser({ variables: { userId, type: NotificationType.PostStatusChange } });
    } else {
      UnsubscribeUser({ variables: { userId, type: NotificationType.NewPost } });
      UnsubscribeUser({ variables: { userId, type: NotificationType.PostStatusChange } });
    }

    // Handle NewMessage separately
    const isNewMessageEnabled = notificationsEnabled[NotificationType.NewMessage];
    isNewMessageEnabled
      ? SubscribeUser({ variables: { userId, type: NotificationType.NewMessage } })
      : UnsubscribeUser({ variables: { userId, type: NotificationType.NewMessage } });
  };

  const handleToggleGroup = (group: keyof typeof groupedNotificationTypes) => {
    setNotificationsEnabled((prev) => {
      const newState = { ...prev };
      groupedNotificationTypes[group].forEach((type) => {
        newState[type] = !prev[groupedNotificationTypes[group][0]];
      });
      return newState;
    });
  };

  return (
    <>
      <FormContainer onSuccess={onSuccess}>
        <SettingsSection
          title={t('Notification Email')}
          subtitle={t('Opt out from receiving email updates')}
          divider={true}
        >
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={notificationsEnabled[NotificationType.LinkedinConnectionExpires]}
                  onChange={() => handleToggleGroup('linkedinConnectionExpires')}
                  data-testid="notifications-checkbox-linkedinConnectionExpires"
                />
              }
              label={t('LinkedIn account status')}
            />
            <p style={{ marginLeft: '32px' }}>
              {t('Your LinkedIn connection is about to expire or has expired.')}
            </p>
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    notificationsEnabled[NotificationType.NewPost] ||
                    notificationsEnabled[NotificationType.PostStatusChange]
                  }
                  onChange={() => handleToggleGroup('activityOnYourPosts')}
                  data-testid="notifications-checkbox-activityOnYourPosts"
                />
              }
              label={t('Activity on your posts')}
            />
            <p style={{ marginLeft: '32px' }}>
              {t('A post is ready to share, someone requests your approval or a post changed status.')}
            </p>
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={notificationsEnabled[NotificationType.NewMessage]}
                  onChange={() => handleToggleGroup('newFeedbackOnYourPosts')}
                  data-testid="notifications-checkbox-newFeedbackOnYourPosts"
                />
              }
              label={t('New feedback on your posts')}
            />
            <p style={{ marginLeft: '32px' }}>
              {t('Get notified when new comments are available on your posts.')}
            </p>
          </div>
        </SettingsSection>

        <Button
          variant="contained"
          type="submit"
          sx={inputStyle}
          data-testid="save-button-content-settings"
        >
          {t('Save')}
        </Button>
      </FormContainer>
    </>
  );
};

export default SettingsNotifications;
