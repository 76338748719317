import {
  AddBox,
  ArrowDownward,
  ArrowUpward,
  RemoveCircleOutline,
  Thermostat,
} from '@mui/icons-material';
import {
  Button,
  Divider,
  IconButton,
  Slider,
  SliderValueLabelProps,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Control, UseFormRegister, useFieldArray } from 'react-hook-form';
import { Chain, Chat as ChatType, OpenAiRole } from '../../__generated__/graphql';
import Message from './Message';
import OutputKey from './OutputKey';

function ValueLabelComponent(props: SliderValueLabelProps) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

interface Props {
  chat: ChatType;
  register: UseFormRegister<Chain | any>;
  control: Control<Chain | any>;
  chainId: 'chainA' | 'chainB';
  index: number;
  move: (from: number, to: number) => void;
  remove: (index: number) => void;
  disabled: boolean;
  disableMoveDown?: boolean;
  outputKeys?: boolean;
}

const Chat = ({
  chat,
  register,
  control,
  chainId,
  index,
  move,
  remove,
  disabled,
  disableMoveDown,
  outputKeys = true,
}: Props) => {
  const [temperature, setTemperature] = useState(chat.temperature || 0);

  const messagesField = useFieldArray({
    control: control,
    name: `${chainId}.${index}.messages`,
  });

  const outputKeysField = useFieldArray({
    control: control,
    name: `${chainId}.${index}.outputKeys`,
  });

  return (
    <Stack
      direction={'column'}
      spacing={1.5}
      sx={{
        border: 'solid 1px #EBEBEB',
        borderRadius: 1,
        p: 1.5,
        width: '100%',
        '&:hover': { borderColor: 'primary.main' },
      }}
    >
      <Stack direction={'row'} justifyContent={'space-between'} alignContent={'top'}>
        <Typography variant="body1" fontWeight="bold">
          {`Chat ${index + 1}`}
        </Typography>
        <Stack direction={'row'}>
          <IconButton
            disabled={index === 0}
            disableRipple
            onClick={() => move(index, index - 1)}
            sx={{ '&:hover': { color: 'primary.main' } }}
          >
            <ArrowUpward sx={{ width: 20 }} />
          </IconButton>
          <IconButton
            disabled={disableMoveDown || disabled}
            disableRipple
            onClick={() => move(index, index + 1)}
            sx={{ '&:hover': { color: 'primary.main' } }}
          >
            <ArrowDownward sx={{ width: 20 }} />
          </IconButton>
          <Tooltip title={`Remove chat ${index + 1}`} placement="top">
            <IconButton
              disableRipple
              disabled={disabled}
              onClick={() => remove(index)}
              sx={{ '&:hover': { color: 'primary.main' } }}
            >
              <RemoveCircleOutline sx={{ width: 20 }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack direction={'row'} spacing={1}>
        <Thermostat sx={{ color: '#666666' }} />
        <Slider
          {...register(`${chainId}.${index}.temperature` as const)}
          onChange={(_, value) => setTemperature(value as number)}
          valueLabelDisplay="auto"
          slots={{
            valueLabel: ValueLabelComponent,
          }}
          min={0}
          value={temperature}
          step={0.1}
          max={2}
          disabled={disabled}
        />
      </Stack>
      {messagesField.fields.map((message, messageIndex) => (
        <Message
          key={`${chainId}-${index}-${message.id}`}
          control={control}
          register={register}
          chainId={chainId}
          index={index}
          messageIndex={messageIndex}
          move={messagesField.move}
          remove={messagesField.remove}
          disabled={disabled}
          disableMoveDown={messageIndex === messagesField.fields.length - 1}
        />
      ))}
      <Button
        variant="text"
        type="button"
        startIcon={<AddBox />}
        size="small"
        disabled={disabled}
        onClick={() => {
          messagesField.append({
            role: OpenAiRole.User,
            content: '',
          });
        }}
      >
        Add message
      </Button>
      {outputKeys && (
        <>
          <Divider />
          {outputKeysField.fields.map((outputKey, outputKeyIndex) => (
            <OutputKey
              key={`${chainId}-${index}-${outputKey.id}`}
              register={register}
              chainId={chainId}
              index={index}
              outputKeyIndex={outputKeyIndex}
              move={outputKeysField.move}
              remove={outputKeysField.remove}
              disabled={disabled}
              disableMoveDown={outputKeyIndex === outputKeysField.fields.length - 1}
            />
          ))}

          <Button
            variant="text"
            type="button"
            size="small"
            disabled={disabled}
            startIcon={<AddBox />}
            onClick={() => {
              outputKeysField.append({
                type: 'string',
                key: '',
                description: '',
              });
            }}
          >
            Add output key
          </Button>
        </>
      )}
    </Stack>
  );
};

export default Chat;
