import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { RoleChat, UserOrBrand } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import Avatar from '../Avatar/Avatar';

const TailOut = ({ backgroundColor }: { backgroundColor: string }) => {
  return (
    <svg viewBox="0 0 8 13" height="13" width="8" preserveAspectRatio="xMidYMid meet">
      <path
        opacity="0.13"
        fill="#000000"
        d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"
      ></path>
      <path
        fill={backgroundColor}
        d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z"
      ></path>
    </svg>
  );
};

const TailIn = ({ backgroundColor }: { backgroundColor: string }) => {
  return (
    <svg viewBox="0 0 8 13" height="13" width="8" preserveAspectRatio="xMidYMid meet">
      <path
        opacity="0.13"
        d="M5.188,1H0v11.193l6.467-8.625 C7.526,2.156,6.958,1,5.188,1z"
      ></path>
      <path
        fill={backgroundColor}
        d="M5.188,0H0v11.193l6.467-8.625C7.526,1.156,6.958,0,5.188,0z"
      ></path>
    </svg>
  );
};

const Message = ({
  userOrBrand,
  avatar,
  showAvatarAndName = true,
  role,
  text,
  mt = 0,
  time,
  name,
}: {
  showAvatarAndName?: Boolean;
  userOrBrand?: UserOrBrand | null;
  avatar: string | null;
  role: RoleChat;
  text: string;
  mt: number;
  time: Date;
  name: string | null;
}) => {
  const theme = useTheme();
  const { user: currentUser } = useContext(AuthContext);

  const backgroundColor = role === RoleChat.User ? theme.palette.primary.main : 'white';
  const textColor = role === RoleChat.User ? 'white' : 'white';

  const messageFromCurrentUser = userOrBrand && userOrBrand?._id === currentUser?._id;
  const stackDirection = messageFromCurrentUser ? 'row-reverse' : 'row';
  const formattedTime = new Date(time).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <Stack
      direction={stackDirection}
      spacing={1}
      mt={mt}
      mr={messageFromCurrentUser ? 0 : 3}
      ml={messageFromCurrentUser ? 3 : 0}
    >
      <Box minWidth={'30px'} maxWidth={'30px'}>
        {showAvatarAndName && (
          <Avatar name={name || ''} avatar={avatar ? avatar : undefined} />
        )}
      </Box>

      <Box
        sx={{
          backgroundColor,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '8px',
          // ...(showAvatarAndName && messageFromCurrentUser) ? {borderTopRightRadius: '0px'} : {},
          // ...(showAvatarAndName && !messageFromCurrentUser) ? {borderTopLeftRadius: '0px'} : {},
          padding: '8px',
          position: 'relative',
          paddingBottom: '24px',
          marginBottom: name ? '24px' : '0px',
          minWidth: '70px',
          textJustify: 'right',
        }}
      >
        {showAvatarAndName && (
          <Typography
            variant="caption"
            sx={{
              color: textColor,
              textAlign: messageFromCurrentUser ? 'right' : 'left',
              fontWeight: 'bold',
            }}
          >
            {name}
          </Typography>
        )}
        {text && (
          <Typography
            variant="body2"
            sx={{
              color: textColor,
              wordBreak: 'break-word',
              textAlign: messageFromCurrentUser ? 'right' : 'left',
            }}
          >
            {text.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                {index !== text.split('\n').length - 1 && <br />}
              </span>
            ))}
          </Typography>
        )}
        <Box
          position="absolute"
          top={'-4.5px'}
          right={messageFromCurrentUser ? '-7px' : 'none'}
          left={messageFromCurrentUser ? 'nonw' : '-7px'}
          style={{ width: '8px', height: '13px' }}
        >
          {/* {showAvatarAndName &&
            (messageFromCurrentUser ? (
              <TailIn backgroundColor={backgroundColor} />
              ) : (
              <TailOut backgroundColor={backgroundColor} />
            ))} */}
        </Box>
        <Typography
          variant="caption"
          sx={{
            color: textColor,
            position: 'absolute',
            bottom: '5px',
            right: '8px',
            fontSize: '0.75rem',
          }}
        >
          {formattedTime}
        </Typography>
      </Box>
    </Stack>
  );
};

export default Message;
