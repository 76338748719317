import { Stack, TableCell, Typography } from '@mui/material';
import Avatar from './Avatar';

const AvatarCell = ({
  avatar,
  name,
  lastName,
  backgroundColor,
  email,
}: {
  avatar: string;
  name: string;
  lastName?: string;
  backgroundColor?: string;
  email?: string;
}) => (
  <TableCell>
    <Stack direction="row" alignItems="center" gap={1}>
      <Avatar
        name={name}
        lastName={lastName}
        avatar={avatar}
        size={50}
        backgroundColor={backgroundColor}
      />
      <Stack>
        <Typography fontSize="12px" fontWeight="bold">
          {name}
        </Typography>
        {email && lastName && (
          <Typography fontSize="12px" color="text.secondary">
            {email}
          </Typography>
        )}
      </Stack>
    </Stack>
  </TableCell>
);

export default AvatarCell;
