import { Box, CardMedia, Stack, Typography } from '@mui/material';
import { formatFullDate } from '../../utils';
import { useContext, useEffect, useRef } from 'react';
import { Insight } from '../../__generated__/graphql';
import { DeviceContext } from '../../context/DeviceContext';

interface Props {
  insight: Insight;
}

export const InsightContent = ({ insight }: Props) => {
  const media = insight.imgUrl || insight.videoUrl || [];
  const boxRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useContext(DeviceContext);

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTop = 0;
    }
  }, [insight]);

  const imgSize = isMobile ? '20px' : '65px';

  return (
    <Stack
      direction="column"
      spacing={1}
      width={'100%'}
      flexGrow={1}
      sx={{ maxHeight: 'calc(100vh - 250px)' }}
    >
      <Box
        ref={boxRef}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #BBBBBB',
          p: '1px',
          borderRadius: '4px',
          padding: '10px',
          overflowY: 'scroll',
          minHeight: { xs: 'calc(100vh - 250px)', md: 'auto' },
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <Box marginTop="0.3rem">
              <img
                src={insight.author?.profilePicture || './images/icons/news.png'}
                style={{
                  width: imgSize,
                  height: imgSize,
                }}
                alt="linkedin"
              />
            </Box>
            <Box marginLeft="15px">
              <Typography fontWeight={700} fontSize={isMobile ? "18px" : "26px"}>
                {insight.title}
              </Typography>
              <Typography marginBottom="1rem" fontSize={isMobile ? "14px" : "18px"}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: 'black',
                  }}
                  href={insight.link ? insight.link : '#'}
                >
                  {insight.source}
                </a>{' '}
                by {insight.creator} / {formatFullDate(insight.pubDate)}
              </Typography>
            </Box>
          </Box>
              {!!media.length && (
                <Box width="100%" maxWidth="600px" marginX="auto" marginBottom="1rem">
                  <CardMedia
                    component={insight.imgUrl?.includes('mp4') ? 'video' : 'img'}
                    controls
                    image={insight.imgUrl || insight.videoUrl || undefined}
                    sx={{ marginBottom: 1, height: 'auto' }}
                    data-testid="post-card-media"
                  />
                </Box>
              )}
          <Typography sx={{ marginBottom: 1 }} lineHeight="34px">
            {insight?.content || insight.description}{' '}
            <a target="_blank" rel="noreferrer" href={insight.link ? insight.link : '#'}>
              View full article
            </a>
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};
