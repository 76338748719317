import { createContext, ReactNode } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

export interface DeviceContextType {
  isMobile: boolean;
  isSmallDevice: boolean;
  isLargeDevice?: boolean;
}

const initialState: DeviceContextType = {
  isMobile: false,
  isSmallDevice: false,
  isLargeDevice: false,
};

export const DeviceContext = createContext<DeviceContextType>(initialState);

export const DeviceProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeDevice = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <DeviceContext.Provider value={{ isMobile, isSmallDevice, isLargeDevice }}>
      {children}
    </DeviceContext.Provider>
  );
};
