import { ReactRenderer } from '@tiptap/react';
import tippy, { Instance } from 'tippy.js';

import TaggerSelector from '../SelectorComponents/TaggerSelector';

export const suggestion = {
  render: () => {
    let component: ReactRenderer;
    let popup: Instance[];

    return {
      onStart: (props: any) => {
        const handleChange = (tag: string, tagId: string) => {
          props.command({ id: tagId, label: tag });
          component.updateProps({
            open: false,
          });
        };

        const handleClose = () => {
          component.updateProps({
            open: false,
          });
        };

        component = new ReactRenderer(TaggerSelector, {
          props: {
            open: true,
            anchorElement: document.querySelector('span.suggestion'),
            onChange: handleChange,
            onClose: handleClose,
          },
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },
      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
