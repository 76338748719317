import { useMutation } from '@apollo/client';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ActionTaken, Brand, Messages, User, UserRole } from '../__generated__/graphql';
import { UPDATE_NOTIFICATION_MESSAGE } from '../graphql/mutations';
import { AuthContext } from './AuthContext';
import { BrandContext } from './BrandContext';

interface PopupNotificationContextProps {
  brandsToReconnect: Brand[];
  handleReconnectLinkedinReminder: () => void;
  popupMessages: Messages[];
  handleClose: (message: Messages) => void;
  handleReview: (message: Messages) => void;
  handleReviewLater: (message: Messages) => void;
}

export const PopupNotificationContext = createContext<PopupNotificationContextProps>({
  handleReconnectLinkedinReminder: () => {},
  brandsToReconnect: [],
  popupMessages: [],
  handleClose: () => {},
  handleReview: () => {},
  handleReviewLater: () => {},
});

export const PopupNotificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useContext(AuthContext);
  const { dataBrands: brands } = useContext(BrandContext);

  const [popupMessages, setPopupMessages] = useState<Messages[]>([]);
  const [updateNotificationMessage] = useMutation(UPDATE_NOTIFICATION_MESSAGE);

  const [brandsToReconnect, setBrandsToReconnect] = useState<Brand[]>([]);
  const getActivePopupMessages = useCallback((messages: Messages[]): Messages[] => {
    const sixHours = 6 * 60 * 60 * 1000;

    return messages.filter((message) => {
      if (message.show) {
        return true;
      } else if (
        message.actionTaken === ActionTaken.Later &&
        new Date().getTime() - new Date(message.date).getTime() >= sixHours
      ) {
        return true;
      }

      return false;
    });
  }, []);

  const getBrandsToReconnect = useCallback((user: User, brands: Brand[]) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const currentDate = new Date();

    const remindMeLater = localStorage.getItem('reconnect_linkedin_reminder');

    if (remindMeLater) {
      const remindMeLaterDate = new Date(remindMeLater);
      if (remindMeLaterDate > currentDate) {
        return [];
      }
    }

    let brandsToCheck: Brand[] = [];
    if (
      user.role.includes(UserRole.BrandManager) ||
      user.role.includes(UserRole.LimitedBrandManager)
    ) {
      brandsToCheck = brands;
    } else if (user.brand) {
      brandsToCheck = [user.brand];
    }

    return brandsToCheck.filter((brand) => {
      if (brand.account && brand.account.exp) {
        const expDate = new Date(brand.account.exp);
        const diffDays = Math.abs((expDate.getTime() - currentDate.getTime()) / oneDay);
        return diffDays <= 7;
      }
      return false;
    });
  }, []);

  useEffect(() => {
    if (user?.notifications?.messages) {
      setPopupMessages(getActivePopupMessages(user?.notifications?.messages));
      setBrandsToReconnect(getBrandsToReconnect(user, brands));
    }
  }, [user, getActivePopupMessages, brands, getBrandsToReconnect]);

  const handleClose = (message: Messages) => {
    updateNotificationMessage({
      variables: {
        input: {
          _id: message._id,
          actionTaken: ActionTaken.Closed,
          date: new Date(),
        },
      },
    });
  };

  const handleReconnectLinkedinReminder = () => {
    // in 24 hours
    const reminderDate = new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString();
    localStorage.setItem('reconnect_linkedin_reminder', reminderDate);
    setBrandsToReconnect([]);
  };

  const handleReview = (message: Messages, expDate?: Date) => {
    setPopupMessages((prevMessages) =>
      prevMessages.filter(
        (messageAux) =>
          messageAux.type !== message.type && messageAux._id !== message._id,
      ),
    );

    updateNotificationMessage({
      variables: {
        input: {
          _id: message._id,
          actionTaken: ActionTaken.Visited,
          date: new Date(),
        },
      },
    });

    if (message.url) {
      window.location.href = message.url;
    }
  };

  const handleReviewLater = (message: Messages) => {
    setPopupMessages((prevMessages) =>
      prevMessages.filter(
        (messageAux) =>
          messageAux.type !== message.type && messageAux._id !== message._id,
      ),
    );

    updateNotificationMessage({
      variables: {
        input: {
          _id: message._id,
          actionTaken: ActionTaken.Later,
          date: new Date(),
        },
      },
    });
  };

  return (
    <PopupNotificationContext.Provider
      value={{
        brandsToReconnect,
        handleReconnectLinkedinReminder,
        popupMessages,
        handleClose,
        handleReview,
        handleReviewLater,
      }}
    >
      {children}
    </PopupNotificationContext.Provider>
  );
};
