import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { AutocompleteElement, FormContainer } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import { DeviceContext } from '../../context/DeviceContext';
import { GET_SHELLS } from '../../graphql/queries';
import ShellTable from '../../components/Tables/ShellTable';
import { useTranslation } from 'react-i18next';

const inputStyle = { minWidth: '250px', heighth: '10px' };

const ShellPanel = () => {
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceContext);
  const navigate = useNavigate();
  const [nameFilter, setNameFilter] = useState<string>('');

  const { data: shellsData } = useQuery(GET_SHELLS);
  const shells = shellsData?.shells || [];

  const filteredShells = shells.filter(
    (shell) =>
      shell?.name !== undefined &&
      shell.name !== null &&
      shell.name.toLowerCase().includes(nameFilter.toLowerCase()),
  );

  return (
    <>
      <Stack
        direction="column"
        p="40px 30px"
        spacing={3}
        alignItems={isMobile ? 'center' : 'start'}
      >
        <Stack direction="row" width="100%" textAlign="center">
          <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
            {t('Clients')}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" flex={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('new')}
              data-testid="new-company-button"
            >
              <AddIcon sx={{ marginRight: '10px' }} />
              <Typography fontWeight="bold">{t('New Client')}</Typography>
            </Button>
          </Stack>
        </Stack>
        <FormContainer
          FormProps={{
            style: {
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              gap: '10px',
            },
          }}
        >
          <AutocompleteElement
            label={t('Search clients by Name')}
            name="name"
            options={filteredShells.map((shell) => ({
              label: shell.name,
              value: shell.name,
            }))}
            autocompleteProps={{
              onChange: (e, value) => setNameFilter(value?.value || ''),
              size: 'small',
            }}
            textFieldProps={{
              sx: inputStyle,
            }}
            data-testid="search-shell-name-input"
          />
        </FormContainer>
        <ShellTable shells={filteredShells} />
      </Stack>
    </>
  );
};

export default ShellPanel;
