import { useQuery } from '@apollo/client';
import { Chip } from '@mui/material';
import { useContext } from 'react';
import {
  PostLifecycleState,
  PostLifecycleStateDisplay,
} from '../../__generated__/graphql';
import { DeviceContext } from '../../context/DeviceContext';
import { GET_POST_LIFECYCLE_STATES } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';

export function PostStateSelector({
  lifecycleState,
  lifecycleStateDisplay,
}: {
  lifecycleState?: PostLifecycleState;
  lifecycleStateDisplay?: PostLifecycleStateDisplay;
}) {
  const { t } = useTranslation();
  const { data } = useQuery(GET_POST_LIFECYCLE_STATES);
  const { isMobile } = useContext(DeviceContext);

  const states = data?.getPostLifecycleStates || [];
  const currentStateDisplay = (lifecycleStateDisplay ||
    states.find((state) => lifecycleState === state?.lifecycleState)
      ?.lifecycleStateDisplay)!;

  return (
    <Chip
      size={isMobile ? 'medium' : undefined}
      variant="filled"
      label={t(currentStateDisplay.name ?? '')}
      sx={{
        p: 1.2,
        fontSize: { xs: '1rem' },
        fontWeight: 'bold',
        backgroundColor: currentStateDisplay.color,
      }}
    />
  );
}
