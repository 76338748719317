import { EventSourceMessage, fetchEventSource } from '@microsoft/fetch-event-source';
import { getAuth } from 'firebase/auth';

const handleOpen = (res: Response): Promise<void> => {
  return new Promise<void>((resolve) => {
    if (res.status >= 200 && res.status < 300) {
    } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
      console.error('Client-side error ', res);
    }
    resolve();
  });
};

const handleClose = () => {};

interface fetchDataInterface {
  input: string;
  chainId?: string;
  brandId?: string;
  userId?: string;
}

export const useStreamedCompletion = (
  onPartialCompletion = (content: string) => {},
  onError = (error: string) => {},
  onFinishCompletion = () => {},
): [(request: fetchDataInterface) => Promise<void>] => {
  const handleMessage = (event: EventSourceMessage) => {
    if (event.data) {
      const parsedData = JSON.parse(event.data);
      if (!parsedData.success) {
        onError(parsedData.error || 'Something went wrong.');
        return;
      }

      onPartialCompletion(parsedData['completion']);
    }
  };
  const fetchData = async (input: fetchDataInterface): Promise<void> => {
    const token = await getAuth().currentUser?.getIdToken();

    await fetchEventSource(
      `${process.env.REACT_APP_STREAMED_COMPLETIONS_URL}/completions`,
      {
        method: 'POST',
        body: JSON.stringify(input),
        headers: {
          Accept: 'text/event-stream',
          authorization: token ? `Bearer ${token}` : '',
          'Content-Type': 'application/json',
        },
        onopen: async (res) => await handleOpen(res),
        onmessage: (event) => handleMessage(event),
        onclose: () => handleClose(),
        onerror: (err) => {
          throw new Error(err);
        },
        openWhenHidden: true
      },
    );

    onFinishCompletion();
  };

  return [fetchData];
};
