import { Stack } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';
import { Control, Controller } from 'react-hook-form';

interface SwitchProps {
  label: string;
  name: string;
  required?: boolean;
  control: Control<any>;
}

const Switch = ({ label, name, required, control }: SwitchProps) => {
  const inputStyle = {
    marginLeft: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
    },
    '@media (min-width:600px)': {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
    },
  };
  return (
    <Stack width="100%" justifyContent="flex-start" alignItems="flex-start">
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <FormControlLabel
            control={<MuiSwitch {...field} checked={field.value} sx={{ ml: '16px' }} />}
            label={label}
            sx={inputStyle}
            labelPlacement={'start'}
          />
        )}
      />
    </Stack>
  );
};

export default Switch;
