import {
  createContext,
  useContext,
  ReactNode,
  useReducer,
  Dispatch,
  useMemo,
} from 'react';
// types
import { SelectMembersActions, InitialStateType } from '../types/index';
// reducers
import { PostAdvocacyReducer } from '../reducers/PostAdvocacyReducer';

const initialState: InitialStateType = {
  members: [],
};

const PostAdvocacyMembersContext = createContext<{
  state: InitialStateType;
}>({
  state: initialState,
});

const PostAdvocacyMembersDispatchContext = createContext<{
  dispatch: Dispatch<SelectMembersActions>;
}>({
  dispatch: () => null,
});

export const PostAdvocacyMembersProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(PostAdvocacyReducer, initialState);
  const value = useMemo(() => ({ state }), [state]);
  return (
    <PostAdvocacyMembersContext.Provider value={value}>
      <PostAdvocacyMembersDispatchContext.Provider value={{ dispatch }}>
        {children}
      </PostAdvocacyMembersDispatchContext.Provider>
    </PostAdvocacyMembersContext.Provider>
  );
};

export function usePostAdvocacyMembers() {
  const context = useContext(PostAdvocacyMembersContext);
  if (context === undefined) {
    throw new Error(
      'usePostAdvocacyMembers  must be used within a PostAdvocacyMembersProvider',
    );
  }
  const { members } = context.state;
  return {
    members,
  };
}

export function useDispatchPostAdvocacyMembersContext() {
  const context = useContext(PostAdvocacyMembersDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useDispatchMembersContext must be used within a PostAdvocacyMembersProvider ',
    );
  }
  return context.dispatch;
}
