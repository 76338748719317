import React, { FC, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';
import { PostContentMedia } from '../../__generated__/graphql';

interface EditTitleDialogProps {
  open: boolean;
  onClose: () => void;
  media: PostContentMedia | PostContentMedia[];
  index: number;
  mediaType: string;
  onUploadedMedia: (media: PostContentMedia[]) => void;
  postMedia: PostContentMedia[];
}

const EditTitleDialog: FC<EditTitleDialogProps> = ({
  open,
  onClose,
  media,
  index,
  mediaType,
  onUploadedMedia,
  postMedia,
}) => {
  const [altTitle, setAltTitle] = useState((media as PostContentMedia)?.alt || '');

  const handleSave = () => {
    if (altTitle !== (media as PostContentMedia)?.alt) {
      const newMedia = [...postMedia];
      newMedia[index].alt = altTitle;
      onUploadedMedia(newMedia);
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
      }}
      PaperProps={{
        style: {
          boxShadow: 'none',
        },
      }}
    >
      <DialogTitle>{`Edit ${mediaType} Title`}</DialogTitle>
      <DialogContent>
        <TextField
          required
          label={`${mediaType} Title`}
          sx={{ width: '300px' }}
          size="small"
          value={altTitle}
          variant="outlined"
          onChange={(e) => setAltTitle(e.target.value)}
          // onBlur={handleSave}
          InputLabelProps={{ shrink: true }}
          margin="dense"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSave();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTitleDialog;
