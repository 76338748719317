import { AutoAwesome, Close } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { Language } from '../../__generated__/graphql';
import { GENERATE_CONTENT_AI } from '../../graphql/mutations';
import LoaderGif from '../../assets/spinner.gif';
import { useTranslation } from 'react-i18next';

const textareaStyle = {
  width: '100%',
  resize: 'none' as const,
  borderColor: '#BBBBBB',
  fontFamily: 'inherit',
  fontSize: '16px',
  lineHeight: 1.5,
  padding: '10px 10px 10px 10px',
  borderRadius: '4px',
  outline: 'none',
};

interface Props {
  open: boolean;
  onAddText: (text: string) => void;
  onClose: () => void;
}

const AIAssistantModal = ({ open, onClose, onAddText }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceContext);
  const { postState } = useContext(PostContext);

  const contentRef = useRef<HTMLTextAreaElement>(null);

  const [loading, setLoading] = useState(false);
  const [userPrompt, setUserPrompt] = useState('');
  const [content, setContent] = useState('');
  const [contentLength, setContentLength] = useState<'short' | 'long' | null>('short');

  const [error, setError] = useState<string | null>(null);

  const [generateContentAI] = useMutation(GENERATE_CONTENT_AI, {
    onCompleted: (data) => {
      if (data.generateContentAI?.success) {
        setContent(data.generateContentAI.message || '');
        setLoading(false);
      } else {
        const errorMessage =
          data?.generateContentAI?.message ??
          'Error: Failed to create posts for the brand.';
        setError(errorMessage);
        setLoading(false);
      }
    },
  });

  const language = postState?.post.brand?.contentCreation?.language || Language.English;

  const handleGenerateContent = async () => {
    setContent('');
    setLoading(true);
    if (postState?.post.brandId) {
      generateContentAI({
        variables: {
          brandId: postState.post.brandId,
          contentIdea: userPrompt,
          longContent: contentLength === 'long',
        },
      });
    } else {
      setError('Error: Brand ID is missing.');
      setLoading(false);
    }
  };

  const handleContentLengthChange = (length: 'short' | 'long') => {
    setContentLength(length);
  };

  return (
    <Dialog open={open} fullScreen={isMobile} disableEscapeKeyDown maxWidth={'xl'}>
      <DialogContent>
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(250, 251, 252, 0.9)', // Semi-transparent overlay
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10,
              flexDirection: 'column',
            }}
          >
            <img
              src={LoaderGif}
              alt="Loading"
              style={{
                width: '300px', // Adjust based on your needs
                height: '100px',
                objectFit: 'contain',
              }}
            />
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {t('Give us a moment to work our magic.')}
            </Typography>
          </Box>
        )}

        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">{t('Generate Content')}</Typography>
            <IconButton onClick={onClose} disabled={loading}>
              <Close />
            </IconButton>
          </Stack>
          <Typography variant="body2">
            {t(
              'Your content will be generated in {{language}}. You can change this preference in your ',
              { language },
            )}
            <Link
              href="/settings"
              color="inherit"
              underline="always"
              style={{ textDecoration: 'underline' }}
            >
              {t('Settings')}
            </Link>
            .
          </Typography>
          <textarea
            rows={isMobile ? 5 : 3}
            style={textareaStyle}
            data-testid="ai-assistant-textarea"
            placeholder={t(
              'Example: Write a post about the climate change. Use a bullet list of 3 items with statistics and a quote from a famous person.',
            )}
            value={userPrompt}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              setUserPrompt(e.target.value);
            }}
            disabled={loading}
          />

          <Stack direction="column" spacing={2} justifyContent="flex-start">
            <Typography variant="body2">{t('Choose how you want your post')}</Typography>
            <Stack direction="row" spacing={2} justifyContent="flex-start">
              <Button
                variant="outlined"
                onClick={() => handleContentLengthChange('short')}
                disabled={loading}
                sx={{
                  bgcolor: contentLength === 'short' ? 'primary.light' : 'transparent',
                  borderColor:
                    contentLength === 'short' ? 'primary.main' : 'rgba(0, 0, 0, 0.3)',
                  color:
                    contentLength === 'short' ? 'primary.main' : 'rgba(0, 0, 0, 0.7)',
                }}
              >
                {t('Short')}
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleContentLengthChange('long')}
                disabled={loading}
                sx={{
                  bgcolor: contentLength === 'long' ? 'primary.light' : 'transparent',
                  borderColor:
                    contentLength === 'long' ? 'primary.main' : 'rgba(0, 0, 0, 0.3)',
                  color: contentLength === 'long' ? 'primary.main' : 'rgba(0, 0, 0, 0.7)',
                }}
              >
                {t('Long')}
              </Button>
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent={isMobile ? 'flex-end' : 'space-between'}>
            {!isMobile && (
              <Alert
                severity={error ? 'error' : 'info'}
                style={{ backgroundColor: 'transparent', padding: 0 }}
              >
                {error
                  ? error
                  : t('The more precise you are, the better the result will be')}
              </Alert>
            )}
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<AutoAwesome />}
              variant="outlined"
              onClick={handleGenerateContent}
              disabled={userPrompt.length === 0 || contentLength === null}
              data-testid="ai-assistant-generate-button"
            >
              {t('Generate')}
            </LoadingButton>
          </Stack>
          {!!content && (
            <>
              <Divider />
              <textarea
                disabled
                ref={contentRef}
                rows={10}
                style={textareaStyle}
                data-testid="ai-assistant-post-generated"
                value={content}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setContent(e.target.value);
                }}
              />
              <Stack direction="row" justifyContent={'flex-end'}>
                <Button
                  variant="contained"
                  onClick={() => {
                    onAddText(content || '');
                    onClose();
                  }}
                >
                  {t('Add to post')}
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AIAssistantModal;
