import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputBase,
  MobileStepper,
  Paper,
  Typography,
} from '@mui/material';
import { GET_VIDEO_URL } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';

const DownloadVideo = () => {
  const { t } = useTranslation();
  const [sendVideo] = useMutation(GET_VIDEO_URL);
  const [loading, setLoading] = useState(false);
  const [linkVideo, setLinkVideo] = useState('' as string);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [url, setUrl] = useState('');
  const videoRef = useRef<HTMLVideoElement | null>(null);

  // Función para manejar los cambios en el input
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const downloadVideo = async () => {
    const response = await fetch(linkVideo);
    const blob = await response.blob(); // Descarga el video como un Blob
    const blobUrl = window.URL.createObjectURL(blob); // Crea una URL de objeto para el Blob

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = 'linkedin-video'; // Especifica el nombre del archivo aquí
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = () => {
    setLoading(true);
    sendVideo({
      variables: {
        url: url,
      },
      onCompleted: (data) => {
        setLinkVideo(data.getVideoUrl.videoUrl);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (linkVideo && videoRef.current) {
      videoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [linkVideo]);

  return (
    <Box
      width="100vw"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={4}
      marginTop="4rem"
    >
      <Paper
        component="form"
        elevation={0}
        sx={{
          p: '8px 12px',
          display: 'flex',
          alignItems: 'center',
          width: '95%',
          marginX: 'auto',
          borderRadius: '15px',
          border: '1px solid #ccc',
        }}
      >
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
          }}
          placeholder={t('Pega la URL de Linkedin aquí')}
          inputProps={{
            'aria-label': 'search google maps',
            sx: {
              fontSize: { xs: '14px', md: '20px' },
              fontWeight: 700,
              color: 'rgba(0, 0, 0, 0.87)',
            },
          }}
          value={url}
          onChange={handleInputChange}
        />
        <Button
          variant="contained"
          color="primary"
          id="solicitud_descarga_video"
          onClick={handleSubmit}
          disabled={!url.includes('https://www.linkedin.com/posts')}
          sx={{
            marginX: 'auto',
            display: 'block',
            paddingX: '15px',
            borderRadius: '20px',
          }}
        >
          {t('Descargar')}
        </Button>
      </Paper>
      <Box
        width={{ xs: '100%', md: '70%' }}
        marginX="auto"
        paddingX={{
          xs: '25px',
          md: '0',
        }}
      >
        <Typography
          ref={videoRef}
          variant="body1"
          textAlign="center"
          fontSize={{ xs: '18px', md: '35px' }}
          fontWeight={900}
          marginTop="2rem"
          letterSpacing="1.5px"
        >
          {t('Descarga vídeos de LinkedIn online y ahora.')}
        </Typography>

        <Typography
          variant="body1"
          textAlign="center"
          fontSize={{ xs: '13px', md: '20px' }}
          fontWeight={{
            xs: 500,
            md: 700,
          }}
          marginTop="2rem"
          letterSpacing="1.5px"
        >
          {t(
            'Descarga cualquier vídeo de Linkedin con nuestra herramienta. Puedes obtener vídeos de forma sencilla',
          )}
        </Typography>
      </Box>

      <Paper
        sx={{
          display: 'flex',
          width: { xs: '100%', md: '97%' },
          marginX: 'auto',
          borderRadius: '10px',
          background: { xs: 'rgba(248, 248, 248, 1)', md: 'white' },
          padding: !!linkVideo
            ? { xs: '0', md: '2rem 8rem' }
            : { xs: '2rem 0', md: '2rem' },
          boxShadow: { xs: 'none', md: '0px 10px 24px 4px rgba(79, 79, 79, 0.12)' },
          minHeight: '356px',
        }}
      >
        {loading ? (
          <CircularProgress
            size={80}
            sx={{
              margin: 'auto',
            }}
          />
        ) : !!linkVideo ? (
          <Box
            display="flex"
            width="100%"
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
          >
            <Typography
              variant="subtitle1"
              textAlign="center"
              color="rgba(104, 94, 100, 1)"
              display={{ xs: 'block', sm: 'none' }}
              // marginBottom="2rem"
              fontSize={{ xs: '14px', md: '1rem' }}
              marginTop={{ xs: '2rem', md: 0 }}
            >
              {t('El vídeo se ha descargado correctamente')}
            </Typography>
            <Box width="100%" maxWidth="600px" height="320px" marginX="auto">
              <video
                width="100%"
                controls
                height="100%"
                style={{
                  borderRadius: '10px',
                }}
              >
                <source src={linkVideo} type="video/mp4" />
                Your browser does not support HTML video.
              </video>
            </Box>
            <Box marginX="auto" marginTop={{ xs: '2rem', md: 0 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={downloadVideo}
                id="descarga_final_video"
                sx={{
                  marginX: 'auto',
                  width: '285px',
                  height: '56px',
                  marginBottom: '20px',
                  display: 'block',
                  fontWeight: 700,
                  padding: '15px 20px',
                  borderRadius: '15px',
                }}
              >
                {t('Descargar vídeo')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                id="publicar_video_downloader"
                onClick={() => {
                  window.open(
                    'https://app.magnettu.com/sign-up',
                    '_blank',
                    'noopener,noreferrer',
                  );
                }}
                sx={{
                  marginX: 'auto',
                  display: 'block',
                  width: '285px',
                  height: '56px',
                  marginBottom: '20px',
                  fontWeight: 700,
                  padding: '15px 20px',
                  borderRadius: '15px',
                }}
              >
                {t('Publicarlo en las redes sociales')}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box marginX="auto">
            <Typography
              variant="subtitle1"
              textAlign="center"
              color="rgba(104, 94, 100, 1)"
              display={{ xs: 'block', sm: 'none' }}
              marginBottom="2rem"
              fontSize={{ xs: '14px', md: '1rem' }}
              fontWeight={800}
            >
              {t('Mira como hacerlo por pasos')}
            </Typography>
            <MobileStepper
              variant="dots"
              steps={4}
              position="static"
              activeStep={activeStep}
              sx={{
                maxWidth: { xs: '90%', sm: '600px' },
                marginX: 'auto',
                background: 'transparent',
                flexGrow: 1,
                display: { xs: 'flex', sm: 'none' },
              }}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === 3}>
                  {t('Next')}
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  {t('Back')}
                </Button>
              }
            />

            {activeStep === 0 && (
              <Box
                sx={{
                  p: '12px 12px',
                  display: { xs: 'flex', sm: 'none' },
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  width: '90%',
                  height: { xs: 'auto', md: '356px' },
                  marginX: 'auto',
                  borderRadius: '10px',
                }}
              >
                <Box display="flex" marginY="1.5rem">
                  <Box
                    sx={{
                      backgroundColor: 'rgba(255, 0, 122, 1)',
                      borderRadius: '100px',
                      padding: '0 7px',
                      color: 'white',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginX: '1rem',
                      marginTop: '5px',
                      fontSize: '12px',
                    }}
                  >
                    1
                  </Box>
                  <Typography variant="body1" fontSize="15px" fontWeight={500}>
                    {t('Dirígete a')}
                    <a
                      href="https://www.linkedin.com/feed/"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: 'none',
                        fontWeight: 700,
                      }}
                    >
                      {' '}
                      LinkedIn{' '}
                    </a>
                    {t(
                      'y selecciona el vídeo que mas te guste. Clica en los 3 puntos de la derecha.',
                    )}
                  </Typography>
                </Box>
                <Box width="100%" marginX="auto">
                  <img
                    src="/images/lm/paso1Mobile.jpg"
                    alt="imagen"
                    style={{
                      width: '100%',
                      margin: 'auto',
                    }}
                  />
                </Box>
              </Box>
            )}
            {activeStep === 1 && (
              <Box
                sx={{
                  p: '12px 12px',
                  display: { xs: 'flex', sm: 'none' },
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  width: '90%',
                  height: { xs: 'auto', md: '356px' },
                  marginX: 'auto',
                  borderRadius: '10px',
                }}
              >
                <Box display="flex" marginY="1.5rem">
                  <Box
                    sx={{
                      backgroundColor: 'rgba(255, 0, 122, 1)',
                      borderRadius: '100px',
                      padding: '0 7px',
                      color: 'white',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginX: '1rem',
                      marginTop: '5px',
                      fontSize: '12px',
                    }}
                  >
                    2
                  </Box>
                  <Typography variant="body1" fontSize="15px" fontWeight={500}>
                    {t('Selecciona en “Compartir vía”.')}
                  </Typography>
                </Box>
                <Box width="100%" marginX="auto">
                  <img
                    src="/images/lm/paso2Mobile.jpg"
                    alt="imagen"
                    style={{
                      width: '100%',
                      margin: 'auto',
                      // marginTop: '1rem',
                    }}
                  />
                </Box>
              </Box>
            )}
            {activeStep === 2 && (
              <Box
                sx={{
                  p: '12px 12px',
                  display: { xs: 'flex', sm: 'none' },
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  width: '90%',
                  height: { xs: 'auto', sm: '356px' },
                  marginX: 'auto',
                  borderRadius: '10px',
                }}
              >
                <Box display="flex" marginY="1.5rem">
                  <Box
                    sx={{
                      backgroundColor: 'rgba(255, 0, 122, 1)',
                      borderRadius: '100px',
                      padding: '0 7px',
                      color: 'white',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginX: '1rem',
                      marginTop: '5px',
                      fontSize: '12px',
                    }}
                  >
                    3
                  </Box>
                  <Typography variant="body1" fontSize="15px" fontWeight={500}>
                    {t('Dale en “copiar”')}
                  </Typography>
                </Box>
                <Box width="100%" marginX="auto">
                  <img
                    src="/images/lm/paso3Mobile.jpg"
                    alt="imagen"
                    style={{
                      width: '100%',
                      margin: 'auto',
                      // marginTop: '1rem',
                    }}
                  />
                </Box>
              </Box>
            )}
            {activeStep === 3 && (
              <Box
                sx={{
                  p: '12px 12px',
                  display: { xs: 'flex', sm: 'none' },
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  width: '90%',
                  height: { xs: 'auto', sm: '356px' },
                  marginX: 'auto',
                  borderRadius: '10px',
                }}
              >
                <Box display="flex" marginY="1.5rem">
                  <Box
                    sx={{
                      backgroundColor: 'rgba(255, 0, 122, 1)',
                      borderRadius: '100px',
                      padding: '0 7px',
                      color: 'white',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginX: '1rem',
                      marginTop: '5px',
                      fontSize: '12px',
                    }}
                  >
                    4
                  </Box>
                  <Typography variant="body1" fontSize="16px" fontWeight={500}>
                    {t(
                      'Y por último vuelve a la página y pega la URL que has copiado previamente.',
                    )}
                  </Typography>
                </Box>
                <Box width="100%" marginX="auto">
                  <img
                    src="/images/lm/paso4Mobile.jpg"
                    alt="imagen"
                    style={{
                      width: '100%',
                      margin: 'auto',
                    }}
                  />
                </Box>
              </Box>
            )}

            <Grid
              container
              spacing={3.5}
              display={{
                xs: 'none',
                sm: 'flex',
              }}
              flexDirection={{
                sm: 'column',
                md: 'row',
              }}
            >
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    p: '12px 12px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'rgba(248, 248, 248, 1)',
                    width: '100%',
                    height: '356px',
                    marginX: 'auto',
                    borderRadius: '10px',
                  }}
                >
                  <Box display="flex" marginY="1.5rem">
                    <Box
                      sx={{
                        backgroundColor: 'rgba(255, 0, 122, 1)',
                        borderRadius: '100px',
                        padding: '0 7px',
                        color: 'white',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginX: '1rem',
                        marginTop: '5px',
                        fontSize: '12px',
                      }}
                    >
                      1
                    </Box>
                    <Typography variant="body1" fontSize="15px" fontWeight={500}>
                      {t('Dirígete a')}
                      <a
                        href="https://www.linkedin.com/feed/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: 'none',
                          // color: "rgba(0, 0, 0, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        {' '}
                        LinkedIn{' '}
                      </a>{' '}
                      {t('y selecciona el vídeo que mas te guste.')}
                    </Typography>
                  </Box>
                  <Box
                    width={{ xs: '420px', md: '100%' }}
                    marginX="auto"
                    marginTop="1rem"
                  >
                    <img
                      src="/images/lm/paso1.jpg"
                      alt="imagen"
                      style={{
                        width: '100%',
                        margin: 'auto',
                        // marginTop: '1rem',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    p: '12px 12px',
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'center',
                    backgroundColor: 'rgba(248, 248, 248, 1)',
                    width: '100%',
                    marginX: 'auto',
                    height: '356px',
                    borderRadius: '10px',
                  }}
                >
                  <Box display="flex" marginTop="1.5rem" marginBottom="0.8rem">
                    <Box
                      sx={{
                        backgroundColor: 'rgba(255, 0, 122, 1)',
                        borderRadius: '100px',
                        padding: '0 7px',
                        color: 'white',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginX: '1rem',
                        marginTop: '5px',
                        fontSize: '12px',
                      }}
                    >
                      2
                    </Box>
                    <Typography variant="body1" fontSize="15px" fontWeight={500}>
                      {t(
                        'Haz clic en los tres puntos de la publicación y selecciona "Copiar enlace de la publicación"',
                      )}
                    </Typography>
                  </Box>
                  <Box
                    width={{
                      xs: '420px',
                      md: '225px',
                    }}
                    marginX="auto"
                    marginTop="10px"
                    height="100%"
                  >
                    <img
                      src="/images/lm/paso2.jpg"
                      alt="imagen"
                      style={{
                        width: '100%',
                        margin: 'auto',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    p: '12px 12px',
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'center',
                    backgroundColor: 'rgba(248, 248, 248, 1)',
                    width: '100%',
                    marginX: 'auto',
                    height: '356px',

                    borderRadius: '10px',
                  }}
                >
                  <Box display="flex" marginTop="1.5rem" marginBottom="0.8rem">
                    <Box
                      sx={{
                        backgroundColor: 'rgba(255, 0, 122, 1)',
                        borderRadius: '100px',
                        padding: '0 7px',
                        color: 'white',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginX: '1rem',
                        marginTop: '5px',
                        fontSize: '12px',
                      }}
                    >
                      3
                    </Box>
                    <Typography variant="body1" fontSize="15px" fontWeight={500}>
                      {t(
                        'Vuelve a la página y pega la URL. ¡Y ya, listo para descargar!',
                      )}
                    </Typography>
                  </Box>
                  <Box
                    width={{ xs: '420px', md: '100%' }}
                    height="135px"
                    marginX="auto"
                    marginTop="1.5rem"
                  >
                    <img
                      src="/images/lm/paso3.png"
                      alt="imagen"
                      style={{
                        width: '100%',
                        margin: 'auto',
                        // marginTop: '1rem',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default DownloadVideo;
