import { Box, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { FC } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  info?: string;
  subtitle?: boolean;
  comparation?: string | number;
  lastDays?: string;
  logo?: boolean;
  children?: React.ReactNode;
  otherComparation?: boolean;
  infoPrevius?: boolean;
  differenceInDays?: number;
}

const CardReport: FC<Props> = ({
  title,
  info,
  comparation,
  lastDays,
  subtitle = false,
  logo = true,
  children,
  otherComparation = false,
  infoPrevius = true,
  differenceInDays = 30,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      padding="10px"
      flexDirection="column"
      justifyContent={infoPrevius ? 'space-between' : 'start'}
      width="100%"
      minWidth="160px"
      height="240px"
      sx={{ background: '#F8F8F8' }}
      borderRadius="10px"
      boxShadow={2}
    >
      <Box display="flex" justifyContent="space-between">
        <Stack direction={'column'} width={'100%'}>
          <Stack direction="row" justifyContent={'space-between'} width={'100%'}>
            <Typography fontSize="1rem">{title}</Typography>
            {info && (
              <Tooltip title={info}>
                <InfoOutlined sx={{ ml: 1 }} fontSize="small" />
              </Tooltip>
            )}
          </Stack>
          {subtitle && (
            <Typography
              sx={{
                fontSize: '0.8rem',
                fontStyle: 'italic',
              }}
            >
              {/* Last 30 days */}
              {`${t('last')} ${differenceInDays} ${t('days')}`}
            </Typography>
          )}
        </Stack>
        {logo && <LinkedInIcon sx={{ color: '#0077B7' }} />}
      </Box>
      <Box>{children}</Box>
      {infoPrevius && (
        <Box textAlign="center">
          <Typography
            color={
              (comparation &&
                typeof comparation === 'string' &&
                comparation.includes('-')) ||
              otherComparation
                ? 'red'
                : 'green'
            }
          >
            {comparation || 'N/A'}
          </Typography>
          <Typography fontSize="0.8rem">{lastDays}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CardReport;
