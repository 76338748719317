import { MenuItem, Select } from '@mui/material';
import React, { Dispatch, FC } from 'react';
import { OrderByDirection, OrderByField } from '../../__generated__/graphql';
import { OrderBy } from '../../types';
import { useTranslation } from 'react-i18next';

interface Props {
  orderBy: {
    field: string;
    direction: string;
  };
  setOrderBy: Dispatch<React.SetStateAction<OrderBy>>
}

const SelectOrderPost: FC<Props> = ({ orderBy, setOrderBy }) => {
  const { t } = useTranslation();
  
  return (
    <div>
      {' '}
      <Select
        value={`${orderBy.field} ${orderBy.direction}`}
        sx={{ width: 'auto', marginLeft: 'auto', borderRadius: '8px', height: '40px' }}
      >
        <MenuItem
          value={`${OrderByField.CreatedOn} ${OrderByDirection.Desc}`}
          onClick={() =>
            setOrderBy({
              field: OrderByField.CreatedOn,
              direction: OrderByDirection.Desc,
            })
          }
        >
           {t('Creation date (newest to oldest)')}
        </MenuItem>
        <MenuItem
          value={`${OrderByField.CreatedOn} ${OrderByDirection.Asc}`}
          onClick={() =>
            setOrderBy({
              field: OrderByField.CreatedOn,
              direction: OrderByDirection.Asc,
            })
          }
        >
          {t('Creation date (oldest to newest)')}
        </MenuItem>
        <MenuItem
          value={`${OrderByField.PostedAt} ${OrderByDirection.Desc}`}
          onClick={() =>
            setOrderBy({
              field: OrderByField.PostedAt,
              direction: OrderByDirection.Desc,
            })
          }
        >
           {t('Publication date (descending)')}
        </MenuItem>
        <MenuItem
          value={`${OrderByField.PostedAt} ${OrderByDirection.Asc}`}
          onClick={() =>
            setOrderBy({
              field: OrderByField.PostedAt,
              direction: OrderByDirection.Asc,
            })
          }
        >
           {t('Publication date (ascending)')}
        </MenuItem>
      </Select>
    </div>
  );
};

export default SelectOrderPost;
