import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormActions from '../../components/FormActions';
import Select from '../../components/Select';
import TextField from '../../components/TextField';
import { useFormContext } from '../../contexts/FormContext';
import {
  companyDescriptionPlaceholder,
  jobDescriptionPlaceholder,
  jobLevelOptions,
} from './consts';
import { JobDescriptionInput } from '../../../__generated__/graphql';

const Step2 = ({ onSubmit }: { onSubmit: (data: JobDescriptionInput) => void}) => {
  const { updateFormData, formData, prevStep } = useFormContext();
  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues: formData,
  });

  const handleGoBack= () => {
    const currentData = getValues();
    updateFormData(currentData); 
    prevStep(); 
  }

  return (
    <Stack component={'form'} onSubmit={handleSubmit(onSubmit)} overflow={'auto'} gap={'32px'}>
      <TextField
        control={control}
        name="companyDescription"
        multiline
        autoFocus
        placeholder={companyDescriptionPlaceholder}
        label="Proporciona una descripción de tu empresa, negocio y su actividad."
      />
      <TextField
        control={control}
        name="jobDescription"
        multiline
        placeholder={jobDescriptionPlaceholder}
        label="Proporciona una breve descripción de las actividades que realizará la persona en el cargo."
      />
      <Select
        options={jobLevelOptions}
        control={control}
        name="jobLevel"
        label="¿A qué nivel corresponde este puesto?"
        placeholder='Ej: Junior'
      />

      <FormActions isValid={formState.isValid} handleGoBack={handleGoBack} buttonId='lm-job-description-step-2-button'/>
    </Stack>
  );
};

export default Step2;
