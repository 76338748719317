import { useMutation, useQuery } from '@apollo/client';
import { PlayArrow } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Chip,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { Idea, OrderByDirection, OrderByField } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { CREATE_RUN_ASYNC } from '../../graphql/mutations';
import { GET_BRANDS, GET_IDEAS } from '../../graphql/queries';
import { getFullName } from '../../utils';
import { useTranslation } from 'react-i18next';

const Prompter = () => {
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceContext);
  const { setErrorMessage } = useContext(SnackbarContext);
  const { user } = useContext(AuthContext);
  const [brandSelectedId, setBrandSelectedId] = useState<string | null>(null);
  const [ideasRun, setIdeasRun] = useState<string[]>([]);

  const { data: brandsData, loading: loadingBrands } = useQuery(GET_BRANDS, {
    variables: { allBrands: true },
  });

  const [createRunAsync] = useMutation(CREATE_RUN_ASYNC);

  const brandSelected = brandsData?.getBrands.find(
    (brand) => brand?._id === brandSelectedId,
  );

  const { data: dataIdeas } = useQuery(GET_IDEAS, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        brandIds: [brandSelectedId],
      },
      orderBy: { field: OrderByField.Priority, direction: OrderByDirection.Desc },
    },
    skip: !brandSelectedId,
  });

  const ideas = dataIdeas?.getIdeas.ideas || [];

  const handleRunPrompt = (idea: Idea) => {
    setIdeasRun((ideas) => [...ideas, idea._id!]);

    createRunAsync({
      variables: {
        input: {
          brandId: idea.brandId,
          brandName: idea.brandName,
          createdById: user?._id,
          createdByFullName: getFullName(user),
          chainId: idea.chainId,
          chainName: idea.chainName,
          topicContext: idea.content,
          ideaId: idea._id,
        },
      },
      onCompleted: (data) => {
        if (!data.createRunAsync.success) {
          setErrorMessage(data.createRunAsync.message || t('Something went wrong'));
          return;
        }
      },
    });
  };

  return (
    <>
      <Stack
        direction="column"
        p={isMobile ? '40px 30px 80px 30px' : '40px 80px 80px 80px'}
        spacing={4}
        alignItems={'center'}
      >
        <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
          {t('Prompter')} 🚀
        </Typography>
        <Autocomplete
          value={brandSelected || null}
          options={brandsData?.getBrands || []}
          disabled={loadingBrands}
          getOptionLabel={(option) => option.name || t('Unknown')}
          renderInput={(params) => (
            <TextField
              required
              label={t('Brand')}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingBrands ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          data-testid="new-post-brand-select"
          sx={{ width: '100%' }}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          onChange={(_, brand) => setBrandSelectedId(brand?._id || null)}
          filterOptions={(options, { inputValue }) =>
            options.filter(
              (option) =>
                option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                (option.user &&
                  option.user.email.toLowerCase().includes(inputValue.toLowerCase())),
            )
          }
          renderOption={(props, option) => {
            return (
              <li {...props} key={option._id}>
                <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                  <Typography fontWeight={500}>{option.name}</Typography>
                  <Typography fontSize={14}>{option.user?.email}</Typography>
                </Stack>
              </li>
            );
          }}
        />
        {!!brandSelected && ideas && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                    {t('Priority')}
                  </TableCell>
                  <TableCell sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                    {t('Content')}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('Times used')}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {t('Actions')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ideas.map((idea, index) => (
                  <React.Fragment key={idea._id}>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        borderLeft: '5px solid',
                        borderColor: 'primary.main',
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                        <Chip
                          size="small"
                          sx={{ width: 'fit-content' }}
                          label={
                            idea.priority === 0
                              ? t('Low')
                              : idea.priority === 1
                              ? t('Medium')
                              : t('High')
                          }
                          color={
                            idea.priority === 0
                              ? 'success'
                              : idea.priority === 1
                              ? 'warning'
                              : 'error'
                          }
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: 'bold', width: '100%' }}
                      >
                        {idea.content}
                      </TableCell>
                      <TableCell>{`${(idea.prompterRunIds || []).length}`}</TableCell>
                      <TableCell>
                        <LoadingButton
                          startIcon={<PlayArrow />}
                          disabled={ideasRun.includes(idea._id!)}
                          sx={{ minWidth: 0, whiteSpace: 'nowrap' }}
                          variant="outlined"
                          onClick={() => handleRunPrompt(idea)}
                        >
                          {t('Run')}
                        </LoadingButton>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
};

export default Prompter;
