import { useMutation } from '@apollo/client';
import {
  Alert,
  AlertColor,
  Box,
  Button,
  CardMedia,
  Dialog,
  Grid,
  Slide,
  Snackbar,
  Stack,
  Toolbar,
  Typography,
  Zoom,
} from '@mui/material';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Language, UserPlan } from '../../__generated__/graphql';
import NavBar from '../../components/NavBar.tsx/NavBar';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { HIDE_VIDEO_TUTORIAL } from '../../graphql/mutations';
import { RoutesNames } from '../../types';
import { getDrawerWidth } from '../../utils';
import { useTranslation } from 'react-i18next';
import { TransitionProps } from '@mui/material/transitions';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface customSnackbarProps {
  severity: AlertColor;
  message: string | null;
  handleClose: () => void;
}

const urlVideoES =
  'https://firebasestorage.googleapis.com/v0/b/magnettu-app.appspot.com/o/media%2F6426da4151ca85327335e632%2F1731443612190_Espa%C3%B1ol%20Onboarding%20video.mp4?alt=media&token=0282717b-54e9-4752-8f86-76985f95dc27';

const urlVideoEN =
  'https://firebasestorage.googleapis.com/v0/b/magnettu-app.appspot.com/o/media%2F672e8f381c619143324414cc%2F1731358914054_Ingle%CC%81s%20Onboarding%20video.mp4?alt=media&token=205dd90e-be27-483c-b2e8-dba10ebf85a1';

const Dashboard = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState<RoutesNames>('');
  const { user, setUser } = useContext(AuthContext);
  const { isMobile, isSmallDevice } = useContext(DeviceContext);
  const [openNavbar, setOpenNavbar] = useState(!isSmallDevice);
  const drawerWidth = getDrawerWidth(isMobile, isSmallDevice, openNavbar);
  const snackbar = useContext(SnackbarContext);

  useEffect(() => {
    setActive(pathname.split('/')[1] as RoutesNames);
  }, [pathname]);

  const snackbarTypes = [
    { severity: 'success', message: snackbar.successMessage },
    { severity: 'error', message: snackbar.errorMessage },
    { severity: 'warning', message: snackbar.warningMessage },
  ];

  const CustomSnackbar = ({ severity, message, handleClose }: customSnackbarProps) => (
    <Snackbar
      open={!!message}
      onClose={handleClose}
      data-cy={`${severity}-snackbar`}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      TransitionComponent={severity === 'error' ? Zoom : undefined}
    >
      <Alert severity={severity} sx={{ width: '100%' }} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    snackbar.setSuccessMessage(null);
    snackbar.setErrorMessage(null);
    snackbar.setWarningMessage(null);
  };

  const [hideVideoTutorial] = useMutation(HIDE_VIDEO_TUTORIAL, {
    onCompleted: (data) => {
      if (data.hideVideoTutorial?.success)
        setUser({ ...user!, showVideoTutorial: false });
    },
  });

  return (
    <>
      {!!user?.showVideoTutorial && (
        <Dialog
          disableEscapeKeyDown
          open={!!user?.showVideoTutorial}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {}}
          fullWidth
          fullScreen={isMobile}
          sx={{
            width: '100%',
            '& .MuiDialog-paper': {
              borderRadius: { xs: 0, md: 1.5 },
              // padding: '20px',
              width: { xs: '100%', md: '98vw' },
              maxWidth: { xs: '100%', md: '98vw' },
              height: { xs: '100%', md: '98%' },
              overflow: { xs: 'auto', md: 'hidden' },
            },
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={3.5}
              bgcolor="primary.main"
              height={{ xs: 'auto', md: '95vh' }}
              padding={{ xs: 2, md: '60px 70px', lg: '70px 40px' }}
              color="white"
            >
              <Typography
                sx={{ fontWeight: 700, marginBottom: 1 }}
                fontSize={35}
                color="white"
              >
                {t('Welcome to Magnettu')}!
              </Typography>

              {!isMobile && (
                <Typography fontSize={18} fontWeight={500} width="90%">
                  {t('We are thrilled')}
                </Typography>
              )}

              <Typography
                sx={{ marginTop: { xs: 2, md: 10 }, marginBottom: 3 }}
                width={{
                  xs: '100%',
                  md: '80%',
                }}
              >
                {t('Before getting started')}
              </Typography>

              {!isMobile && (
                <>
                  <Typography fontSize={20} variant="body1" sx={{ marginBottom: 2 }}>
                    <Box
                      sx={{
                        display: 'inline-block',
                        backgroundColor: 'white',
                        padding: '0px 10px',
                        borderRadius: '90px',
                        margin: '0px 10px',
                        fontWeight: 700,
                        color: 'primary.main',
                      }}
                    >
                      1
                    </Box>
                    {t('Tutoriales')}
                  </Typography>

                  <Typography fontSize={20} sx={{ marginBottom: 2 }}>
                    <Box
                      sx={{
                        display: 'inline-block',
                        backgroundColor: 'white',
                        padding: '0px 10px',
                        borderRadius: '90px',
                        margin: '0px 10px',
                        fontWeight: 700,
                        color: 'primary.main',
                      }}
                    >
                      2
                    </Box>
                    {t('Start working')}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={8.5}
              textAlign="center"
              padding={{
                xs: 2,
                md: '60px 70px',
                lg: 8,
              }}
              // overflow={{ xs: 'auto', md: 'hidden' }}
            >
              <Typography fontSize={27} fontWeight={600}>
                {t('Learn how to make the most of magnettu')}
              </Typography>

              <Typography fontSize={20} fontWeight={400} marginY={2}>
                {t('Show you how to unlock')}
              </Typography>
              <CardMedia
                component="video"
                controls
                autoPlay
                image={
                  user?.language === Language.Spanish ||
                  user?.language === Language.SpanishLatam
                    ? urlVideoES
                    : urlVideoEN
                }
                sx={{
                  marginBottom: 1,
                  height: { xs: 'auto', md: '50%', lg: '70%' },
                  width: { xs: '100%', md: '80%', lg: '80%' },
                  display: 'flex',
                  marginX: 'auto',
                }}
                data-testid="post-card-media"
              />
              {/* {!isMobile && ( */}
              <Stack
                flexDirection="column"
                width="100%"
                // height="auto"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  sx={{
                    width: { xs: '100%', md: '181px' },
                    marginTop: { xs: 2, md: 4 },
                    marginLeft: { xs: 'auto', md: 'auto' },
                    marginRight: { xs: 'auto', md: '20px' },
                  }}
                  onClick={() => {
                    hideVideoTutorial();
                  }}
                >
                  {t('Let go')}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Dialog>
      )}

      <NavBar
        currentPage={active}
        isSmallDevice={isSmallDevice}
        isMobile={isMobile}
        openNavbar={openNavbar}
        setOpenNavbar={setOpenNavbar}
      />
      <Box
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          transition: 'all 0.2s ease-in-out',
        }}
      >
        {isMobile && <Toolbar />}
        <Outlet />
      </Box>
      {snackbarTypes.map((snackbarType) => (
        <CustomSnackbar
          key={snackbarType.severity}
          severity={snackbarType.severity as AlertColor}
          message={snackbarType.message}
          handleClose={handleClose}
        />
      ))}

      {user?.plan && user?.plan.includes(UserPlan.Inactive) && (
        <Snackbar
          open={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Alert
            severity="info"
            action={
              <Button color="inherit" onClick={() => navigate('/settings')}>
                {t('Go to Settings')}
              </Button>
            }
          >
            <strong>{t("You don't have any plan assigned.")}</strong>{' '}
            {t(
              'Go to settings to upgrade your account or contact us at info@magnettu.com',
            )}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Dashboard;
