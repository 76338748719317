import { InitialStateType } from '../types/index';
import { ACTIONS_POST_ADVOCACY, SelectMembersActions } from '../types/index';
export const PostAdvocacyReducer = (
  state: InitialStateType,
  action: SelectMembersActions,
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS_POST_ADVOCACY.ADD_MEMBERS: {
      const { members } = payload;
      return { ...state, members: members };
    }
    default:
      return state;
  }
};
