import { AddBox, CalendarToday, ConnectWithoutContact } from '@mui/icons-material';
import GridViewIcon from '@mui/icons-material/GridView';
import InsightsIcon from '@mui/icons-material/Insights';
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Drawer as MuiDrawer,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Brand, UserRole } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { RoutesNames } from '../../types';
import { getDrawerWidth, getFullName } from '../../utils';
import Avatar from '../Avatar/Avatar';
import DrawerListItem from '../DrawerListItem/DrawerListItem';
import { ChangeContentBoardButton } from './ChangeContentBoardButton';

interface Props {
  brandSelect: Brand | null;
  userSelectBackgroundColor: string;
  onBrandSelect: (brand: Brand, backgroundColor: string) => void;
  currentPage: RoutesNames;
  onClickLogout: () => void;
  onChangeContentBoardClick: (event: React.MouseEvent<HTMLElement>) => void;
  anchorElement: null | HTMLElement;
}

export const NavBarMobile = ({
  brandSelect,
  userSelectBackgroundColor,
  onBrandSelect,
  currentPage,
  onClickLogout,
  onChangeContentBoardClick,
  anchorElement,
}: Props) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const onClickDrawerItem = (route: RoutesNames) => {
    setDrawerOpen(false);
    navigate(route);
  };

  const additionalItems = [
    ...(user?.role?.includes(UserRole.Admin)
      ? [
          {
            text: 'Client',
            iconName: 'PersonAdd',
            route: 'clients',
          },
          {
            text: 'Users',
            iconName: 'Group',
            route: 'users',
          },
          {
            text: 'Companies',
            iconName: 'Apartment',
            route: 'companies',
          },
          {
            text: 'Content Admin',
            iconName: 'Collections',
            route: 'content-manager',
          },
        ]
      : []),
  ];

  const name = getFullName(user);
  const avatar = user?.avatar;
  return (
    <>
      <AppBar elevation={0} sx={{ borderBottom: '1px solid', borderColor: '#EBEBEB' }}>
        <Toolbar
          sx={{
            backgroundColor: '#FFF',
            paddingX: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <IconButton
            edge="start"
            onClick={() => setDrawerOpen((mobileOpen) => !mobileOpen)}
          >
            <Avatar name={name} avatar={avatar || undefined} />
          </IconButton>
          {(user?.role?.includes(UserRole.BrandManager) ||
            user?.role?.includes(UserRole.LimitedBrandManager)) && (
            <ChangeContentBoardButton
              variant="standard"
              onClick={onChangeContentBoardClick}
              anchorElement={anchorElement}
              brand={brandSelect || null}
            />
          )}
        </Toolbar>
      </AppBar>
      <MuiDrawer
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            backgroundColor: '#EBEBEB',
            width: getDrawerWidth(true, true),
          },
        }}
      >
        <Stack justifyContent="space-between" height="100%">
          <Stack>
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <Box display="flex" gap={1} alignItems="center">
                    <Avatar name={name} avatar={avatar || undefined} size={40} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {name}
                    </Typography>
                  </Box>
                </ListItemButton>
              </ListItem>
            </List>
            <Divider variant="middle" sx={{ marginY: 1 }} />
            <List>
              {user?.brandSelected?.contentCreation?.requireContent && (
                <DrawerListItem
                  tooltip="You can send us content requests through Whatsapp"
                  text={'WhatsApp'}
                  iconName={'WhatsApp'}
                  onClick={() =>
                    window.open(
                      `https://wa.me/34663937183?text=${encodeURIComponent(
                        `Hola magnettu soy ${user.firstName} ${user.lastName} y quería pedirte que me ayudes para que en la marca de ${user.brandSelected?.name} ...`,
                      )}`,
                      '_blank',
                    )
                  }
                />
              )}
              {additionalItems.map(({ text, iconName, route }) => (
                <DrawerListItem
                  key={text}
                  text={text}
                  iconName={iconName}
                  onClick={() => onClickDrawerItem(route as RoutesNames)}
                />
              ))}
            </List>
          </Stack>
          <Stack>
            <Divider variant="middle" sx={{ marginY: 1 }} />
            <List>
              <DrawerListItem
                text={'Help'}
                iconName={'HelpOutline'}
                onClick={() =>
                  window.open(
                    'https://magnettu.notion.site/Ayuda-a7a72297741a4acca98d456591ed31e5',
                    '_blank',
                  )
                }
                // isSmallDevice={isSmallDevice}
              />
              <DrawerListItem
                text="Settings"
                iconName={'Settings'}
                onClick={() => onClickDrawerItem('settings')}
              />
              <DrawerListItem
                text="Logout"
                iconName={'Logout'}
                onClick={() => {
                  setDrawerOpen(false);
                  onClickLogout();
                }}
              />
            </List>
          </Stack>
        </Stack>
      </MuiDrawer>
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 100 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={currentPage}
          onChange={(_event, newValue) => onClickDrawerItem(newValue)}
        >
          <BottomNavigationAction label="Content" value="" icon={<GridViewIcon />} />
          {user?.role?.includes(UserRole.InsightsViewer) && (
            <BottomNavigationAction
              label="Inspiration"
              value="insights"
              icon={<InsightsIcon />}
            />
          )}
          <BottomNavigationAction label="Post" value="new-post" icon={<AddBox />} />

          {user?.role?.includes(UserRole.User) &&
            (!user?.role.includes(UserRole.LimitedBrandManager) ||
              !user?.role.includes(UserRole.BrandManager)) && (
              <BottomNavigationAction
                label="Calendar"
                value="calendar"
                icon={<CalendarToday />}
              />
            )}

          {user?.role?.includes(UserRole.AdvocacyManager) && (
            <BottomNavigationAction
              label="Advocacy"
              value="advocacy"
              icon={<ConnectWithoutContact />}
            />
          )}
        </BottomNavigation>
      </Paper>
    </>
  );
};
