import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Language } from '../../__generated__/graphql';
import { BrandInput, objectivesOptions, suggestionsToneOfVoice } from './BrandData';
import { DisplayLanguage } from '../../types';
import { useTranslation } from 'react-i18next';

interface BrandPersonalFormProps {
  control: Control<BrandInput, any>;
  inputStyle: any;
  styleChip: any;
  errors: FieldErrors<BrandInput>;
  isModal: boolean;
  signatureRef: any;
}

const BrandPersonalForm = ({
  control,
  inputStyle,
  styleChip,
  errors,
  isModal,
  signatureRef,
}: BrandPersonalFormProps) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" gap={1} fontSize="13px">
      <Typography fontSize="inherit">{t('Language preference')}</Typography>
      <Controller
        name="language"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select required value={value || ''} onChange={onChange} sx={inputStyle}>
            {Object.entries(Language).map(([key, value]) => {
              return (
                <MenuItem key={key} value={value}>
                  {DisplayLanguage[value as keyof typeof DisplayLanguage]}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
      <Typography fontSize="inherit">LinkedIn Profile</Typography>
      <Controller
        name="urlProfile"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            required
            value={value || ''}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.position}
            helperText={errors.position?.message}
          />
        )}
      />
      <Typography fontSize="inherit">{t('Position')}</Typography>
      <Controller
        name="position"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            required
            value={value || ''}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.position}
            helperText={errors.position?.message}
          />
        )}
      />
      <Typography fontSize="inherit">
        {t('How would you describe the tone of voice you prefer to use?')}
      </Typography>
      <Controller
        name="toneVoice"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Box display="flex" flexWrap="wrap" gap={1} justifyContent={'center'}>
            {suggestionsToneOfVoice.map((option) => (
              <Chip
                key={option}
                label={t(option)}
                onClick={() =>
                  onChange(
                    value.includes(option)
                      ? value.filter((v) => v !== option)
                      : [...value, option],
                  )
                }
                sx={{
                  ...styleChip,
                  backgroundColor: value?.includes(option) ? '#E7EBF0' : 'white',
                }}
              />
            ))}
          </Box>
        )}
      />
      {errors.toneVoice && (
        <FormHelperText sx={{ textAlign: 'center' }} error>
          {errors.toneVoice.message}
        </FormHelperText>
      )}
      <Typography fontSize="inherit">
        {t('Objective for working on personal branding?')}
      </Typography>
      <Controller
        name="targetBrand"
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControl error={!!errors.targetBrand} sx={inputStyle}>
            <Select
              value={value || ''}
              onChange={onChange}
              sx={inputStyle}
              multiple
              error={!!errors.targetBrand}
            >
              {objectivesOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {t(option)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.targetBrand?.message}</FormHelperText>
          </FormControl>
        )}
      />
      <Typography fontSize="inherit">{t('Who is your target audience?')}</Typography>
      <Controller
        name="targetPublic"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            value={value}
            multiline
            rows={3}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.targetPublic}
            helperText={errors.targetPublic?.message}
          />
        )}
      />
      <Typography fontSize="inherit">
        {t('What topics would you like to talk or write about?')}
      </Typography>
      <Controller
        name="themeTalk"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            required
            value={value}
            multiline
            rows={3}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.themeTalk}
            helperText={errors.themeTalk?.message}
          />
        )}
      />
      <Typography fontSize="inherit">{t('Signature')}</Typography>
      <Controller
        name="signature"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            value={value || ''}
            multiline
            rows={6}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.themeTalk}
            helperText={errors.themeTalk?.message}
            inputRef={signatureRef}
            placeholder={t(
              'Add a signature to use on your posts.\n\nFor example:\n-------\nLooking for more content like this? Connect with me and let’s keep in touch!',
            )}
          />
        )}
      />

      {isModal && (
        <Typography fontSize="inherit">
          {t(
            "Ready to get started! By clicking 'Create', you'll have your new brand set up. Right after that, we'll offer you the option to connect your LinkedIn account. This will allow us to pull information from your profile to help you customize your brand. But no worries, if you'd prefer to do it later, that’s completely fine — you can connect it anytime!",
          )}
        </Typography>
      )}
    </Box>
  );
};

export default BrandPersonalForm;
