import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
  Zoom,
} from '@mui/material';
import {
  GoogleAuthProvider,
  UserCredential,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithPopup,
} from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import {
  CheckboxElement,
  FormContainer,
  PasswordElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { Link, useNavigate } from 'react-router-dom';
import googleLogo from '../../assets/google-logo.svg';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { CREATE_USER_SELF_SERVICE } from '../../graphql/mutations';
import { Language, UserLifecycleState } from '../../__generated__/graphql';

const SignupSelfService = () => {
  const { t, i18n } = useTranslation();
  const [errorMsg, setErrorMsg] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();
  const { user, loading, error: errorAuth } = useContext(AuthContext);
  const [createUserSelfService, { loading: loadingCreate }] = useMutation(
    CREATE_USER_SELF_SERVICE,
    {
      onCompleted: (data) => {
        if (data.createUserSelfService?.success) {
          navigate('/onboarding');
        }
      },
    },
  );
  const [languageSelected, setLanguageSelected] = useState<Language>(Language.English);

  useEffect(() => {
    // Detecta el idioma del navegador
    const browserLanguage = window.navigator.language.split('-')[0]; // Obtiene solo el código del idioma (es, en, etc.)

    // Cambia el idioma en el componente Login, si está disponible en las traducciones
    const availableLanguages = ['en', 'es']; // Lista de idiomas soportados por tu app
    if (availableLanguages.includes(browserLanguage)) {
      i18n.changeLanguage(browserLanguage);
      setLanguageSelected(browserLanguage === 'es' ? Language.Spanish : Language.English);
    } else {
      i18n.changeLanguage('en'); // Cambia a inglés si el idioma no está soportado
      setLanguageSelected(Language.English);
    }
  }, [i18n]);

  useEffect(() => {
    if (!loading && user) {
      try {
        if (
          user.lifecycleState === UserLifecycleState.Onboarding ||
          user.lifecycleState === UserLifecycleState.OnboardingCompleted ||
          user?.lifecycleState === UserLifecycleState.OnboardingPaymentPending
        ) {
          navigate('/onboarding');
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Error during navigation:', error);
      }
    }
  }, [loading, navigate, user]);

  const error = errorMsg || errorAuth;

  useEffect(() => {
    if (!loading && user) {
      navigate('/onboarding');
    }
  }, [loading, navigate, user]);

  const handleSubmit = async ({
    email,
    password,
    name,
  }: {
    email: string;
    password: string;
    name: string;
  }) => {
    try {
      // Crear la cuenta con email y contraseña
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Registrar el usuario en tu base de datos
      await createUserSelfService({
        variables: {
          input: {
            email,
            firstName: name,
            lastName: '',
            fullName: name,
            uid: user.uid,
            language: languageSelected,
          },
        },
      });
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;

      if (errorCode === 'auth/email-already-in-use') {
        setErrorMsg(t('The email address is already in use by another account.'));
      } else {
        setErrorMsg(errorMessage);
      }
    }
  };

  const handleGoogleSignup = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = (await signInWithPopup(auth, provider)) as UserCredential & {
        _tokenResponse: { isNewUser: boolean; firstName: string; lastName: string };
      };
      const isNewUser = result._tokenResponse.isNewUser;
      const email = result.user.email;

      if (!email) {
        await auth.signOut();
        setErrorMsg(
          t('Por favor, utiliza tu cuenta de {{email}}.', {
            // email: inviteValidationData?.validateInvite?.email,
          }),
        );
        return;
      }

      if (isNewUser) {
        await createUserSelfService({
          variables: {
            input: {
              email,
              firstName: result._tokenResponse.firstName,
              lastName: result._tokenResponse.lastName,
              fullName: result.user.displayName,
              uid: result.user.uid,
              language: languageSelected,
            },
          },
        });
      }

      // Redirige al usuario
      // navigate('/');
    } catch (error) {
      const errorMessage = 'Error al iniciar sesión con Google';
      setErrorMsg(errorMessage);
    }
  };
  const onCloseSnackbar = () => {
    setErrorMsg('');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Grid container justifyContent="center" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={12}
          md={6}
          bgcolor="primary.main"
          display={{ xs: 'none', md: 'flex' }}
          sx={{
            flexDirection: 'column',
            maxWidth: '70%',
            padding: '50px 40px',
            alignItems: 'center',
          }}
        >
          <Box marginY="25px">
            <img src="../images/logoregistro.png" alt="logo" />
          </Box>
          <Typography
            variant="h4"
            sx={{
              fontSize: '30px',
              color: 'white',
              maxWidth: '450px',
            }}
          >
            {t('Mas alcance, más notoriedad')}
            <b>{t('Mas oportunidades de negocio')}</b>
          </Typography>

          <Box
            marginTop="25px"
            width={{
              md: 250,
              lg: 400,
            }}
          >
            <img
              src={
                languageSelected === Language.English
                  ? '../images/onboarding/viral_en.png'
                  : '../images/onboarding/viral_es.png'
              }
              alt="logo"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} marginY="auto" md={6}>
          <FormContainer
            values={{
              email: '',
              password: '',
              name: '',
              terms: false,
            }}
            onSuccess={handleSubmit}
          >
            <Grid container justifyContent="center">
              <Grid item>
                <Stack direction="column" spacing={2} sx={{ maxWidth: '350px' }}>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: 700, fontSize: '32px', textAlign: 'center' }}
                  >
                    {t('Sign up')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      fontSize: '18px',
                      color: 'text.secondary',
                      textAlign: 'center',
                    }}
                  >
                    {t('Y disfruta la herramienta')}
                  </Typography>
                  <TextFieldElement
                    label={t('Your name')}
                    name="name"
                    variant="outlined"
                    required
                    sx={{ minWidth: '100%' }}
                    type="text"
                    data-cy="name-input"
                  />
                  <TextFieldElement
                    label={t('Email')}
                    name="email"
                    variant="outlined"
                    required
                    sx={{ minWidth: '100%' }}
                    type="email"
                    data-cy="email-input"
                  />
                  <PasswordElement
                    label={t('Password')}
                    name="password"
                    autoComplete="new-password"
                    required
                    sx={{ minWidth: '100%' }}
                    data-cy="password-input"
                    validation={{
                      required: t('Password is required'),
                      minLength: {
                        value: 6,
                        message: t('Password must have at least 6 characters'),
                      },
                    }}
                  />

                  {/* <Stack flexDirection="row"> */}
                  <CheckboxElement
                    name="terms"
                    required
                    label={
                      <Typography fontSize={14} fontWeight={500}>
                        {t('I have read and agree to the')}{' '}
                        <a
                          href={
                            languageSelected === Language.English
                              ? 'https://magnettu.notion.site/Terms-Conditions-11c4a0b58cd880b982cae5dd316eebbb'
                              : 'https://magnettu.notion.site/T-rminos-y-condiciones-70c07071e206479daf62b74514719053'
                          }
                          style={{
                            color: '#30527C',
                            textDecoration: 'none',
                            display: 'inline',
                            fontWeight: 700,
                          }}
                          target="_blank" // Esto abrirá el enlace en una nueva pestaña
                          rel="noopener noreferrer" // Para seguridad
                        >
                          {t('Terms and Conditions')}
                        </a>{' '}
                        {t('and')}{' '}
                        <a
                          href={
                            languageSelected === Language.English
                              ? 'https://magnettu.notion.site/Privacy-policy-11c4a0b58cd8800c8b4bf4aab051d235'
                              : 'https://magnettu.notion.site/Pol-tica-de-privacidad-11c4a0b58cd880e7841fdc25de0fb6ce?pvs=4'
                          }
                          style={{
                            color: '#30527C',
                            textDecoration: 'none',
                            display: 'inline',
                            fontWeight: 700,
                          }}
                          target="_blank" // Esto abrirá el enlace en una nueva pestaña
                          rel="noopener noreferrer" // Para seguridad
                        >
                          {t('Privacy Policy')}
                        </a>
                      </Typography>
                    }
                    data-cy="terms-checkbox"
                    validation={{
                      required: t('You must agree to the terms and conditions'),
                    }}
                  />

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ minWidth: '100%' }}
                    data-cy="signup-button"
                    loading={loadingCreate}
                    loadingPosition="start"
                    startIcon={<></>}
                  >
                    <Typography fontWeight="600">{t('Continue')}</Typography>
                  </LoadingButton>
                  <Divider sx={{ minWidth: '100%' }}>{t('Or')}</Divider>
                  <LoadingButton
                    variant="outlined"
                    onClick={handleGoogleSignup}
                    sx={{ minWidth: '100%' }}
                    loading={loadingCreate}
                  >
                    <img
                      src={googleLogo}
                      alt="Google Logo"
                      style={{ width: '24px', marginRight: '8px' }}
                    />
                    <Typography fontWeight="600">{t('Sign up with Google')}</Typography>
                  </LoadingButton>

                  <Typography textAlign={'center'}>
                    {t('Already have an account')}{' '}
                    <Link
                      to="/login"
                      style={{ color: '#30527C', textDecoration: 'underline' }}
                    >
                      {t('Sign in')}
                    </Link>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </FormContainer>
        </Grid>
      </Grid>

      <Snackbar
        open={!!error}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        TransitionComponent={Zoom}
        onClose={onCloseSnackbar}
        data-cy="error-snackbar"
      >
        <Alert severity="error" sx={{ width: '100%' }} onClose={onCloseSnackbar}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SignupSelfService;
