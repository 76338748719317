import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormActions from '../../components/FormActions';
import Select from '../../components/Select';
import TextField from '../../components/TextField';
import { useFormContext } from '../../contexts/FormContext';
import {
  colorPrincipal,
  colorSecundario,
  companyDescriptionPlaceholder,
  jobDescriptionPlaceholder,
  jobLevelOptions,
} from './consts';
import { JobDescriptionIsproxInput } from '../../../__generated__/graphql';

const Step2 = ({ onSubmit }: { onSubmit: (data: JobDescriptionIsproxInput) => void }) => {
  const { updateFormData, formData, prevStep } = useFormContext();
  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues: formData,
  });

  const handleGoBack = () => {
    const currentData = getValues();
    updateFormData(currentData);
    prevStep();
  };

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      overflow={'auto'}
      gap={'32px'}
    >
      <TextField
        control={control}
        name="companyDescription"
        multiline
        autoFocus
        placeholder={companyDescriptionPlaceholder}
        label="Proporciona una descripción de la empresa y su actividad de tu cliente."
        borderColor={colorPrincipal}
      />
      <TextField
        control={control}
        name="jobDescription"
        multiline
        placeholder={jobDescriptionPlaceholder}
        label="Proporciona una breve descripción de las actividades que realizará la persona en el cargo."
        borderColor={colorPrincipal}
      />
      <Select
        options={jobLevelOptions}
        control={control}
        name="jobLevel"
        label="¿A qué nivel corresponde este puesto?"
        placeholder="Ej: Junior"
        borderColor={colorPrincipal}
      />

      <FormActions
        isValid={formState.isValid}
        handleGoBack={handleGoBack}
        buttonId="lm-job-description-step-2-button"
        colorButton={colorPrincipal}
        colorHover={colorSecundario}
      />
    </Stack>
  );
};

export default Step2;
