import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import {
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FollowersMonthlyDetail } from '../../__generated__/graphql';
import { formatNumber, months } from '../../utils';
import { useTranslation } from 'react-i18next';

interface Metrics {
  followersMonthlyDetail: FollowersMonthlyDetail[];
  followers: number;
}

const GraphicReportCompanyTeamPostsFollowersForDays: FC<Metrics> = ({
  followersMonthlyDetail,
  followers,
  
}) => {

  const totalChangeInFollowers = followersMonthlyDetail?.reduce((sum, detail) => sum + detail!.followers!, 0);
  const totalFollowers = followers!;

  const minY = totalFollowers - totalChangeInFollowers;
  const maxY = totalFollowers;
  const minYCalculated = Math.floor(minY / 40) * 40;
  const maxYCalculated = Math.ceil(maxY / 40) * 40;

    function generateData() {
      const data = [];
      let cumulativeFollowers = totalFollowers;
    
      // Ordenar los detalles por fecha en caso de que no estén ordenados
      const sortedFollowers = followersMonthlyDetail!.sort(
        (a, b) => new Date(a!.day!).getTime() - new Date(b!.day!).getTime()
      );
    
      for (let i = sortedFollowers.length - 1; i >= 0; i--) {
        const detail = sortedFollowers[i];
        const date = new Date(detail!.day!);
        date.setDate(date.getDate() + 1);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const dateString = `${day} ${month.length > 3 ? month.substring(0, 3) : month}`;
    
        const newData = {
          name: dateString,
          Followers: cumulativeFollowers,
        };
    
        cumulativeFollowers -= detail!.followers!;
    
        data.push(newData);
      }
    
      return data.reverse(); // Revertimos los datos para que estén en orden cronológico
    }

  const data = !!followersMonthlyDetail?.length ? generateData() : [];
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      style={{
        backgroundColor: '#F8F8F8',
        borderRadius: '10px',
        height: '600px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box marginTop="2rem" marginLeft="2rem">
        <Typography fontSize="1rem" textAlign="left">
          {t('Followers last 30 days')}
        </Typography>
      </Box>
      <ResponsiveContainer>
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 60,
            left: 40,
          }}
        >
          {/* <YAxis domain={[0, maxPosts]} ticks={[1, 2, 3, 4, 5]} /> */}
          <YAxis
            orientation="right"
            yAxisId="right"
            ticks={[minYCalculated, maxYCalculated]}
            domain={[minYCalculated, maxYCalculated]}
            tickFormatter={(value) => (value === 0 ? '' : formatNumber(value).split('.')[0])}
            allowDataOverflow={false}
          />
          {/* <Bar
            dataKey="Company Posts"
            barSize={10}
            fill="#CD0061"
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill="#CD0061" />
            ))}
          </Bar> */}
          <Line
            type="monotone"
            dataKey="Followers"
            stroke="#FF007A"
            strokeWidth={3}
            isAnimationActive={false}
            yAxisId="right"
          />
          <XAxis dataKey="name" dy={10} angle={-90} textAnchor="end" interval={0} />

          {/* <Legend verticalAlign="top" align="center" layout="horizontal" /> */}
          <Tooltip />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default GraphicReportCompanyTeamPostsFollowersForDays;