import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Popper,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import emojis from '../../assets/emoji.json';

interface Emoji {
  emoji: string;
  description: string;
  category: string;
  tags: string[];
}

interface Props {
  open: boolean;
  anchorElement: HTMLButtonElement | null;
  onClose: () => void;
  onChange: (emoji: string) => void;
}

const EmojiSelector = ({ open, anchorElement, onClose, onChange }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [recentEmojis, setRecentEmojis] = useState<string[]>([]);

  // Load recently used emojis from local storage on mount
  useEffect(() => {
    const savedRecentEmojis = localStorage.getItem('recentEmojis');
    if (savedRecentEmojis) {
      setRecentEmojis(JSON.parse(savedRecentEmojis));
    }
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filterEmojisByCategory = (emojis: Emoji[]) => {
    const categories = new Set(emojis.map((emoji) => emoji.category));

    const emojisByCategory = Array.from(categories).map((category) => ({
      category,
      emojis: emojis.filter((emoji) => emoji.category === category),
    }));

    return emojisByCategory;
  };

  const filteredEmojis = emojis.filter(
    (emoji) =>
      emoji.tags.some((tag) => tag.includes(searchTerm)) ||
      emoji.description.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const emojisByCategory = filterEmojisByCategory(filteredEmojis);

  const handleEmojiClick = (emoji: string) => {
    setRecentEmojis((prevRecent) => {
      const updatedRecent = [emoji, ...prevRecent.filter((e) => e !== emoji)];
      const recentList = updatedRecent.slice(0, 10); // Limit to the last 10 recently used emojis

      localStorage.setItem('recentEmojis', JSON.stringify(recentList));

      return recentList;
    });
    onChange(emoji);
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorElement}
      sx={{ zIndex: 'modal', maxHeight: '350px' }}
      placement="bottom"
    >
      <ClickAwayListener onClickAway={onClose}>
        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '8px',
            width: '370px',
            height: '350px',
            overflowY: 'auto',
          }}
        >
          <OutlinedInput
            type="text"
            autoFocus
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              marginBottom: '8px',
              width: '100%',
              fontSize: '1rem',
              height: '2.5rem',
            }}
            startAdornment={
              <InputAdornment position="start">
                <IconButton size="small" disabled>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          {!searchTerm && recentEmojis.length > 0 && (
            <Box mt={2}>
              <Typography variant="body2">Recently Used</Typography>
              <Box
                style={{
                  listStyle: 'none',
                  padding: 0,
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {recentEmojis.map((emoji, index) => (
                  <Box
                    key={index}
                    sx={{
                      cursor: 'pointer',
                      marginRight: '4px',
                      marginBottom: '4px',
                      fontSize: '1.8rem',
                      maxWidth: '2rem',
                      // ...
                    }}
                    onClick={() => handleEmojiClick(emoji)}
                  >
                    {emoji}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {emojisByCategory.map(({ category, emojis }, index) => (
            <Box key={category}>
              <Typography variant="body2">{category}</Typography>
              <Box
                style={{
                  listStyle: 'none',
                  padding: 0,
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {emojis.map((emoji, index) => (
                  <Box
                    key={index}
                    sx={{
                      cursor: 'pointer',
                      marginRight: '4px',
                      marginBottom: '4px',
                      fontSize: '1.8rem',
                      maxWidth: '2rem', // Establecemos un tamaño máximo para los emojis
                      // maxHeight: "1.6rem",
                      transition: 'transform 0.2s', // Añadimos una transición suave al estilo "hover"
                      '&:hover': {
                        transform: 'scale(1.2)', // Al hacer hover sobre el emoji, lo aumentamos de tamaño
                      },
                    }}
                    onClick={() => handleEmojiClick(emoji.emoji)} // Pasamos el emoji seleccionado a la función handleEmojiClick
                  >
                    {emoji.emoji}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </ClickAwayListener>
    </Popper>
  );
};

export default EmojiSelector;
