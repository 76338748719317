import { Stack, ToggleButton, ToggleButtonGroup, styled } from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped, & .MuiToggleButtonGroup-grouped:not(:first-of-type), & .MuiToggleButtonGroup-grouped:first-of-type':
    {
      marginRight: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      borderSize: 0.5,
      borderColor: theme.palette.grey[100],
      color: theme.palette.grey[100],
      backgroundColor: 'transparent',
      width: '45px',
      height: '45px',
      '&:hover, &:hover:not(:first-of-type), &.Mui-selected, &.Mui-selected:not(:first-of-type)':
        {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
    },
}));

interface Props {
  magnets: number;
  onChange: (value: number) => void;
}

const MagnetsSelector = ({ magnets = 0, onChange }: Props) => {
  return (
    <Stack direction="row" spacing={1}>
      <StyledToggleButtonGroup
        value={magnets}
        exclusive
        onChange={(_, value) => {
          onChange(value);
        }}
      >
        {[0, 10, 20, 50].map((i) => (
          <ToggleButton key={`magnetsSelector-${i}`} color="secondary" value={i}>
            {i}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Stack>
  );
};

export default MagnetsSelector;
