import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { RealEstateDescriptionInput } from '../../../__generated__/graphql';
import FormActions from '../../components/FormActions';
import InputLabel from '../../components/InputLabel';
import TextField from '../../components/TextField';
import { useFormContext } from '../../contexts/FormContext';

const Step4 = ({
  onSubmit,
  loading,
}: {
  onSubmit: (data: RealEstateDescriptionInput) => void;
  loading: boolean;
}) => {
  const { updateFormData, formData, prevStep } = useFormContext();
  const { control, handleSubmit, formState, getValues } =
    useForm<RealEstateDescriptionInput>({
      defaultValues: formData,
    });

  const handleGoBack = () => {
    const currentData = getValues();
    updateFormData(currentData);
    prevStep();
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email) || "Invalid email address";
  };
  

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      overflow={'auto'}
      gap={'32px'}
    >
      <InputLabel
        required
        label="Facilítanos tu nombre, apellido y email para que podamos enviarte la descripción completa."
      />
      <Stack width={'100%'} display={'flex'} direction={'row'} columnGap={'60px'}>
        <TextField
          control={control}
          name="name"
          inputLabel="Nombre"
          autoFocus
          required
          disabled={loading}
        />
        <TextField
          control={control}
          name="surname"
          inputLabel="Apellido"
          required
          disabled={loading}
        />
      </Stack>
      <TextField
        control={control}
        name="email"
        inputLabel="Email"
        required
        disabled={loading}
        rules={{ validate: validateEmail }}
      />
      <FormActions
        isValid={formState.isValid}
        handleGoBack={handleGoBack}
        loading={loading}
        lastStep
        buttonId='lm-real-estate-description-submit-button'
      />
    </Stack>
  );
};

export default Step4;
