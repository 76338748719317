import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  Divider,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Group } from '../../__generated__/graphql';
import { useMutation } from '@apollo/client';
import { ADD_BRANDS_IN_GROUP, REMOVE_BRANDS_FROM_GROUP } from '../../graphql/mutations';
import { BrandContext } from '../../context/BrandContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { Controller, useForm } from 'react-hook-form';
import HeaderGroupEditor from './HeaderGroupEditor';
import GroupsMembersBrandTable from '../Tables/GroupsMembersBrandTable ';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '../Avatar/Avatar';
import { LoadingButton } from '@mui/lab';
import { PersonAdd } from '@mui/icons-material';

const inputStyle = {
  width: '100%',
  borderRadius: '8px',
  marginBottom: '10px',
  '&.MuiInputBase-root': {
    borderRadius: '8px',
  },
};

interface Props {
  groupSelected: Group | null;
  // isBrandPage: boolean;
}

const BrandGroupsForm = ({ groupSelected }: Props) => {
  // Context
  const { refetch, dataBrands } = useContext(BrandContext);
  const { setErrorMessage, setSuccessMessage } = useContext(SnackbarContext);

  // const

  const brandsInGroup = dataBrands?.filter((brand) =>
    brand.groups?.find((group) => group?._id === groupSelected?._id),
  );

  const brandsNotInGroup = dataBrands?.filter(
    (brand) => !brand.groups?.find((group) => group?._id === groupSelected?._id),
  );

  // State

  const [name, setName] = useState<string>('');
  const [openModalNewMember, setOpenModalNewMember] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [brandIdToRemove, setBrandIdToRemove] = useState<string>('');

  // Mutation

  const [addBrandsInGroup] = useMutation(ADD_BRANDS_IN_GROUP);
  const [removeBrandFromGroup] = useMutation(REMOVE_BRANDS_FROM_GROUP);

  // Form

  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
    defaultValues: {
      brandIds: [] as string[],
    },
  });

  // functions

  const handleBrandChange = (brandId: string) => {
    const currentBrands = getValues('brandIds');
    const exists = currentBrands?.includes(brandId);
    const newBrands = exists
      ? currentBrands?.filter((id) => id !== brandId)
      : [...(currentBrands || []), brandId];
    setValue('brandIds', newBrands);
  };

  const onSuccess = (input: { brandIds: string[] }) => {
    addBrandsInGroup({
      variables: {
        brandIds: input.brandIds,
        groupId: groupSelected?._id || '',
      },
      onCompleted: (data) => {
        if (data.addBrandsInGroup?.success) {
          refetch();
          setSuccessMessage('Members added successfully');
          handleCloseModal();
          return;
        }
        setErrorMessage(data.addBrandsInGroup?.message || 'Error adding members');
      },
    });
  };

  const handleCloseModal = () => {
    setOpenModalNewMember(false);
    reset();
  };

  const handleCloseModalRemove = () => {
    setOpenModalDelete(false);
    setBrandIdToRemove('');
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const filteredBrands = brandsNotInGroup?.filter((brand) =>
    brand!.name.toLowerCase().includes(name.toLowerCase()),
  );

  const handleOpenModalRemove = (brandId: string) => {
    setBrandIdToRemove(brandId);
    setOpenModalDelete(true);
  };

  const handleRemoveBrand = () => {
    removeBrandFromGroup({
      variables: {
        brandId: brandIdToRemove,
        groupId: groupSelected?._id || '',
      },
      onCompleted: (data) => {
        if (data.removeBrandsFromGroup?.success) {
          refetch();
          setSuccessMessage(
            data.removeBrandsFromGroup.message || 'Member removed successfully',
          );
          handleCloseModalRemove();
          return;
        }
        setErrorMessage(data.removeBrandsFromGroup?.message || 'Error removing member');
      },
    });
  };

  return (
    <>
      <form style={inputStyle}>
        <Box
          bgcolor={'rgba(244, 244, 244, 1)'}
          borderRadius={'10px'}
          marginBottom="10px"
          padding={2}
        >
          <HeaderGroupEditor groupSelected={groupSelected} inputStyle={inputStyle} />
        </Box>
        {brandsInGroup?.length > 0 && (
          <Button
            onClick={() => setOpenModalNewMember(true)}
            variant="contained"
            sx={{
              marginBottom: '10px',
            }}
          >
            <PersonAdd
            sx={{
              marginRight: '10px',
            
            }}
            />
            Add brand
          </Button>
        )}

        {brandsInGroup?.length === 0 && (
          <Card
            sx={{
              borderRadius: '20px',
              padding: '20px',
              width: '100%',
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img
              src="../images/cajaBrand.png"
              alt="onboarding"
              style={{ width: '85px', height: '85px' }}
            />
            <Typography
              sx={{
                width: '50%',
                textAlign: 'center',
                marginTop: '10px',
              }}
            >
              {dataBrands?.length > 0
                ? "Ready to enhance your group's collaboration? Add members to your group to start making the most of our features and streamline your workflows."
                : 'It looks like you don’t have any members in your group yet. Add some now to begin collaborating effectively and unlock all our tools.'}
            </Typography>
            <Button
              onClick={() => setOpenModalNewMember(true)}
              variant="contained"
              sx={{
                marginTop: '10px',
              }}
            >
              <Typography>Add new member</Typography>
            </Button>
          </Card>
        )}
        {brandsInGroup?.length > 0 && (
          <GroupsMembersBrandTable
            brandsInGroup={brandsInGroup}
            handleModalDelete={handleOpenModalRemove}
          />
        )}

        <Dialog
          open={openModalNewMember}
          onClose={() => setOpenModalNewMember(false)}
          PaperProps={{
            sx: {
              borderRadius: '10px',
              padding: '20px',
              width: '400px',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          {/* <> */}
          <Typography
            variant="h6"
            sx={{
              marginBottom: '10px',
              fontWeight: 'bold',
            }}
          >
            Add new member
          </Typography>
          <TextField
            placeholder="Search"
            onChange={onSearch}
            InputProps={{
              style: {
                height: '40px',
                borderRadius: '8px',
                width: '320px',
                backgroundColor: 'rgba(244, 244, 244, 1)',
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
          <Divider
            sx={{
              marginY: '10px',
            }}
          />
          <Box maxHeight={400} overflow="auto">
            {filteredBrands.map((brand) => (
              <MenuItem
                key={brand._id}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Stack flexDirection="row" gap={2} alignItems="center">
                  <Avatar avatar={brand.account?.avatar || ''} name={brand.name} />
                  <Typography>{brand.name}</Typography>
                </Stack>
                <Controller
                  name="brandIds"
                  control={control}
                  render={({ field: { value } }) => (
                    <Checkbox
                      sx={{ marginRight: '5px' }}
                      checked={value?.includes(brand._id)}
                      onChange={() => handleBrandChange(brand._id)}
                    />
                  )}
                />
              </MenuItem>
            ))}
          </Box>
          <Stack flexDirection="row" gap={2} alignSelf="flex-end" marginTop={2}>
            <Button
              variant="outlined"
              sx={{
                marginTop: '10px',
              }}
              onClick={handleCloseModal}
            >
              <Typography fontWeight="bold">Cancel</Typography>
            </Button>
            <LoadingButton
              variant="contained"
              sx={{
                marginTop: '10px',
              }}
              disabled={watch('brandIds').length === 0}
              onClick={handleSubmit(onSuccess)}
            >
              <Typography fontWeight="bold">Add members</Typography>
            </LoadingButton>
          </Stack>

          {/* </> */}
        </Dialog>
        <Dialog
          open={openModalDelete}
          onClose={() => setOpenModalDelete(false)}
          PaperProps={{
            sx: {
              borderRadius: '10px',
              padding: '20px',
              width: '550px',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: '10px',
              fontWeight: 'bold',
            }}
          >
            Remove brand
          </Typography>
          <Typography
            sx={{
              marginBottom: '10px',
            }}
          >
            Are you sure you want to remove this brand from the group?
          </Typography>
          <Stack flexDirection="row" gap={2} alignSelf="flex-end" marginTop={2}>
            <Button
              variant="outlined"
              sx={{
                marginTop: '10px',
              }}
              onClick={handleCloseModalRemove}
            >
              <Typography fontWeight="bold">Cancel</Typography>
            </Button>
            <Button
              variant="contained"
              sx={{
                marginTop: '10px',
              }}
              onClick={handleRemoveBrand}
            >
              <Typography fontWeight="bold">Remove brand</Typography>
            </Button>
          </Stack>
        </Dialog>
      </form>
    </>
  );
};

export default BrandGroupsForm;
