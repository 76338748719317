import { Avatar as MuiAvatar, Box } from '@mui/material';

interface Props {
  name: string;
  avatar?: string;
  size?: number;
  backgroundColor?: string | null;
  lastName?: string;
  withPadding?: boolean;
}

const AvatarNavBar = ({
  name,
  avatar,
  size = 32,
  backgroundColor = '#BDBDBD',
  lastName,
}: Props) => (
  <Box
    sx={{
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      backgroundColor: 'white', // Color blanco para el círculo exterior
      marginRight: 1,
      borderColor: 'primary.main',
      borderWidth: 1,
      borderStyle: 'solid',
    }}
  >
    <MuiAvatar
      alt={`${name} avatar`}
      src={avatar}
      sx={{
        width: size,
        height: size,
        backgroundColor: backgroundColor,
        fontSize: lastName ? `${size * 0.3}px` : `${size * 0.5}px`,
      }}
    >
      {name[0]}
      {lastName && lastName[0]}
    </MuiAvatar>
  </Box>
);

export default AvatarNavBar;
