import { useMutation } from '@apollo/client';
import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { BillingPeriod, UserPlan } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { GET_STRIPE_CHECKOUT_SESSION } from '../../graphql/mutations';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped, & .MuiToggleButtonGroup-grouped:not(:first-of-type), & .MuiToggleButtonGroup-grouped:first-of-type':
    {
      marginRight: theme.spacing(1),
      borderRadius: '10px',
      borderSize: 0.5,
      borderColor: 'transparent',
      color: 'rgba(0, 0, 0, 0.87)',
      backgroundColor: 'FAFAFA',
      width: '100%',
      minWidth: '100px',
      maxWidth: '210px',
      height: '45px',
      '&:hover, &:hover:not(:first-of-type), &.Mui-selected, &.Mui-selected:not(:first-of-type)':
        {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          fontWeight: 700,
          // borderColor: theme.palette.primary.main,
        },
    },
}));

const Payment = () => {
  const [getStripeCheckoutSession, { loading }] = useMutation(
    GET_STRIPE_CHECKOUT_SESSION,
  );
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [alignment, setAlignment] = useState('Mensual');

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleOpenCheckoutSession = (plan: UserPlan) => {
    const billingPeriod =
      alignment === 'Mensual' ? BillingPeriod.Monthly : BillingPeriod.Yearly;
    getStripeCheckoutSession({
      variables: {
        plans: [plan],
        adjustableQuantity: false,
        billingPeriod,
        successUrl: `?payment=success&billingPeriod=${billingPeriod}`,
      },
      onCompleted: ({ getStripeCheckoutSession: data }) => {
        if (!data.success) {
          alert('There was an error. Please try again later.');
        }
        if (data.success && data.sessionUrl) window.location.href = data.sessionUrl;
      },
      onError: () => {
        alert('There was an error. Please try again later.');
      },
    });
  };

  return (
    <Stack
      bgcolor="white"
      height={{
        xs: '100vh',
        // sm: 'auto',
      }}
      minHeight={{
        xs: '100vh',
        // sm: 'auto',
      }}
    >
      <Box display="flex" justifyContent="center" marginY="25px" height={'5vh'}>
        <img src="../images/magnettu_logo_new.svg" alt="logo" />
      </Box>
      <Divider />
      <Box height="100%" overflow="auto">
        <Box
          width={{
            xs: '90%',
            sm: '100%',
          }}
          marginX="auto"
        >
          <Typography fontWeight={700} fontSize="32px" textAlign="center" marginTop={3}>
            {t('A un paso de')}
          </Typography>

          <Typography
            fontSize="21px"
            textAlign="center"
            marginTop={2.5}
            color={'text.secondary'}
          >
            {t('Iniciando un trial')}
          </Typography>
        </Box>
        <Box width="100%" textAlign="center">
          <FormControl>
            <Box marginX="auto" marginTop={2.5}>
              <StyledToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
              >
                <ToggleButton color="secondary" value={'Mensual'}>
                  {t('Mensual')}
                </ToggleButton>
                <ToggleButton color="secondary" value="Anual">
                  {t('Anual')}
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Box>

          </FormControl>
        </Box>

        <Card
          sx={{
            maxWidth: 780,
            width: { xs: '90%', sm: '100%' },
            borderRadius: '24px',
            padding: '20px 10px',
            marginX: 'auto',
            boxShadow: 'none',
            backgroundColor: '#ffffff',
            border: '1px solid #D3D3D3',
            marginTop: 2.5,
            // height: '100%',
          }}
        >
          <CardContent>
            <Stack
              flexDirection={{
                xs: 'column',
                sm: 'row',
              }}
              gap={{
                xs: 0,
                sm: 3,
              }}
            >
              <Box>
                <Typography variant="h5" component="div" fontWeight="bold">
                  STARTER
                </Typography>
                <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
                  {t('Convierte tu marca')}
                </Typography>
              </Box>

              <Stack sx={{ mt: 3 }} flexDirection="row" gap={1} alignItems="center">
                <Typography component="div" fontWeight={700} fontSize={35}>
                  {alignment === 'Mensual' ? '24,95€' : '239.40€'}
                </Typography>
                <Typography color="textSecondary" fontWeight={500} fontSize={20}>
                  /{alignment === 'Mensual' ? t('Mensual') : t('Anual')}
                </Typography>
              </Stack>
            </Stack>

            <Box sx={{ mt: 1 }}>
              <Typography variant="h6" component="div" fontWeight="bold">
                {t('Funcionalidades')}
              </Typography>

              <Grid
                container
                spacing={{
                  xs: 0,
                  sm: 2,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <List>
                    {[
                      t('Funcionalidades 1'),
                      t('Funcionalidades 2'),
                      t('Funcionalidades 3'),
                    ].map((text, index) => (
                      <ListItem key={index}>
                        <ListItemIcon sx={{ margin: 0, marginLeft: '-1.5rem' }}>
                          <CheckCircleIcon sx={{ color: '#ff007f' }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{ margin: 0, marginLeft: '-1.5rem' }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <List>
                    {[
                      t('Funcionalidades 4'),
                      t('Funcionalidades 5'),
                      t('Funcionalidades 6'),
                    ].map((text, index) => (
                      <ListItem key={index}>
                        <ListItemIcon sx={{ margin: 0, marginLeft: '-1.5rem' }}>
                          <CheckCircleIcon sx={{ color: '#ff007f' }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{ margin: 0, marginLeft: '-1.5rem' }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Box
        marginTop={{
          xs: 'auto',
        }}
      >
        <Divider
          sx={{
            marginTop: '20px',
          }}
        />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          paddingX="10%"
          paddingY="20px"
          gap={2}
          marginTop="auto"
        >
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={() => handleOpenCheckoutSession(user?.plan![0]!)}
            sx={{
              width: { xs: 'auto', sm: '287px' },
            }}
          >
            {t('Next')}
          </LoadingButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Payment;
