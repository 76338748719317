import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Box,
  Collapse,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  Select,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Close, Edit, Save } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import {
  UpdateIdeaInput,
  Idea,
  OrderByDirection,
  OrderByField,
} from '../../__generated__/graphql';
import { SnackbarContext } from '../../context/SnackbarContext';
import { DELETE_IDEA, UPDATE_IDEA } from '../../graphql/mutations';
import { GET_BRANDS, GET_CHAINS, GET_IDEAS } from '../../graphql/queries';
import { OrderBy } from '../../types';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

const HeaderCell = ({
  text,
  isOrderable = false,
  active = false,
  direction = OrderByDirection.Asc,
  onClick = () => {},
  sx = {},
}: {
  text: string;
  isOrderable?: boolean;
  active?: boolean;
  direction?: OrderByDirection;
  onClick?: () => void;
  sx?: SxProps;
}) => (
  <TableCell sx={{ color: 'primary.main', fontWeight: 'bold', ...sx }}>
    {isOrderable ? (
      <TableSortLabel
        active={active}
        direction={direction === OrderByDirection.Asc ? 'asc' : 'desc'}
        onClick={onClick}
        sx={{ color: 'primary.main', fontWeight: 'bold' }}
      >
        <Typography fontSize="14px" fontWeight="bold">
          {text}
        </Typography>
      </TableSortLabel>
    ) : (
      <Typography fontSize="14px" fontWeight="bold">
        {text}
      </Typography>
    )}
  </TableCell>
);

const Cell = ({
  text,
  sx,
  children,
}: {
  text?: string;
  sx?: SxProps;
  children?: React.ReactNode;
}) => (
  <TableCell sx={sx}>
    {children || (
      <Typography fontSize="12px" fontWeight="bold">
        {text}
      </Typography>
    )}
  </TableCell>
);

interface Props {
  ideas: Idea[];
  page?: number;
  limit?: number;
  setLimit?: (limit: number) => void;
  orderBy?: OrderBy;
  setOrderBy?: (orderBy: OrderBy) => void;
  onPageChange?: (page: number) => void;
  total?: number;
}

const IdeasTable = ({
  ideas,
  page = 0,
  limit = 15,
  setLimit = () => {},
  orderBy = { field: OrderByField.CreatedOn, direction: OrderByDirection.Desc },
  setOrderBy = () => {},
  onPageChange = () => {},
  total = -1,
}: Props) => {
  const [deleteIdeaId, setDeleteIdeaId] = useState<string | null>(null);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);
  const [openRow, setOpenRow] = useState<number | null>();
  const [editRow, setEditRow] = useState<number | null>(null);

  const { data: dataChains } = useQuery(GET_CHAINS);
  const { data: dataBrands } = useQuery(GET_BRANDS, { variables: { allBrands: true } });
  const chains = dataChains?.chains || [];
  const brands = dataBrands?.getBrands || [];

  const [hardDeleteIdea] = useMutation(DELETE_IDEA, {
    onCompleted: (data) => {
      if (data.deleteIdea.success) setSuccessMessage('Idea deleted successfully');
      else setErrorMessage(data.deleteIdea.message || 'Something went wrong');
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
    refetchQueries: [GET_IDEAS],
  });

  const [updateIdea] = useMutation(UPDATE_IDEA);

  const onOrderByChange = (field: OrderByField) => {
    setOrderBy({
      field,
      direction:
        orderBy.field === field && orderBy.direction === OrderByDirection.Asc
          ? OrderByDirection.Desc
          : OrderByDirection.Asc,
    });
  };

  const handleDeleteIdea = (ideaId: string) => {
    hardDeleteIdea({
      variables: { ideaId },
    });
    setDeleteIdeaId(null);
  };

  const onSuccess = (data: UpdateIdeaInput) => {
    if (editRow === null) return;
    updateIdea({
      variables: {
        ideaId: ideas[editRow]?._id!,
        input: data,
      },
      onCompleted: ({ updateIdea }) => {
        if (!updateIdea.success) {
          setErrorMessage(updateIdea.message || 'There was an error updating your idea');
        } else {
          setSuccessMessage(updateIdea.message || 'Idea updated successfully');
        }
        setEditRow(null);
      },
      onError: (error) => {
        setErrorMessage(error.message || 'An error occurred during the update');
      },
    });
  };

  const defaultValues = {
    brandId: '',
    brandName: '',
    content: '',
    chainId: '',
    chainName: '',
    priority: 0,
    shellId: '',
  };

  const initialValues =
    editRow !== null
      ? {
          brandId: ideas[editRow].brandId,
          brandName: ideas[editRow].brandName,
          content: ideas[editRow].content,
          chainId: ideas[editRow].chainId,
          chainName: ideas[editRow].chainName,
          priority: ideas[editRow].priority,
          shellId: ideas[editRow].shellId,
        }
      : defaultValues;

  const { control, handleSubmit } = useForm<UpdateIdeaInput>({
    values: initialValues,
  });

  return (
    <>
      <ConfirmationDialog
        title="Delete idea"
        content="Are you sure you want to delete this idea forever? You will not be able to recover it."
        onConfirm={() => handleDeleteIdea(deleteIdeaId!)}
        open={deleteIdeaId !== null}
        onClose={() => setDeleteIdeaId(null)}
        okText="Delete"
        cancelText="Cancel"
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <HeaderCell text="Brand" />
              <HeaderCell text="Content" />
              <HeaderCell
                text="Created"
                isOrderable
                active={orderBy.field === OrderByField.CreatedOn}
                direction={orderBy.direction}
                onClick={() => onOrderByChange(OrderByField.CreatedOn)}
              />
              <HeaderCell text="Chain" />
              <HeaderCell
                isOrderable
                text="Priority"
                active={orderBy.field === OrderByField.Priority}
                direction={orderBy.direction}
                onClick={() => onOrderByChange(OrderByField.Priority)}
              />
              <HeaderCell text="Times used" sx={{ whiteSpace: 'nowrap' }} />
              <HeaderCell text="Actions" sx={{ textAlign: 'center' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {ideas.map((idea, index) => (
              <React.Fragment key={idea._id}>
                {editRow === index ? (
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      borderLeft: '5px solid',
                      borderColor: 'primary.main',
                    }}
                  >
                    <TableCell>
                      <Controller
                        name="brandId"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Brand selection is required' }} // Added rules for required validation
                        render={({ field, fieldState }) => (
                          <Autocomplete
                            {...field}
                            options={brands}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            value={
                              brands.find((chain) => chain._id === field.value) || null
                            }
                            onChange={(_, newValue) =>
                              field.onChange(newValue ? newValue._id : '')
                            }
                            sx={{ flex: 3 }}
                            PopperComponent={(props) => (
                              <Popper {...props} style={{ width: '300px' }} />
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                required
                                error={!!fieldState.error} // Display error state
                                helperText={
                                  fieldState.error ? fieldState.error.message : null
                                } // Display error message
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option._id}>
                                <Stack
                                  direction={'column'}
                                  justifyContent={'space-between'}
                                  width={'100%'}
                                >
                                  <Typography fontWeight={500}>{option.name}</Typography>
                                  <Typography fontSize={14}>
                                    {option.user?.email}
                                  </Typography>
                                </Stack>
                              </li>
                            )}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        name="content"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Content is required' }} // Updated to provide a custom message
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            id="content"
                            multiline
                            variant="outlined"
                            required
                            size="small"
                            sx={{ width: '100%' }}
                            error={!!fieldState.error} // Added to show error state
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            } // Added to show error message
                          />
                        )}
                      />
                    </TableCell>
                    <Cell
                      text={
                        new Date(idea.createdOn)
                          .toLocaleString('es-ES', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                          })
                          .replace(',', '')
                          .replace(' ', ' - ') + 'h'
                      }
                    />
                    <TableCell>
                      <Controller
                        name="chainId"
                        control={control}
                        rules={{ required: 'Chain is required' }}
                        render={({ field, fieldState }) => (
                          <Autocomplete
                            {...field}
                            options={chains}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            PopperComponent={(props) => (
                              <Popper {...props} style={{ width: '300px' }} />
                            )}
                            value={
                              chains.find((chain) => chain._id === field.value) || null
                            }
                            onChange={(_, newValue) =>
                              field.onChange(newValue ? newValue._id : '')
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Chain"
                                variant="outlined"
                                required
                                error={!!fieldState.error}
                                helperText={
                                  fieldState.error ? fieldState.error.message : null
                                }
                                size="small"
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option._id}>
                                {option.name}
                              </li>
                            )}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        name={`priority`}
                        control={control}
                        defaultValue={idea.priority}
                        render={({ field }) => (
                          <FormControl sx={{ flex: 2 }} size="small">
                            <Select {...field} id="idea-priority" required displayEmpty>
                              <MenuItem value={0}>Low</MenuItem>
                              <MenuItem value={1}>Medium</MenuItem>
                              <MenuItem value={2}>High</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </TableCell>
                    <Cell>{`${(idea.prompterRunIds || []).length}`}</Cell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Stack direction="row" spacing={1}>
                        <IconButton type="submit" onClick={handleSubmit(onSuccess)}>
                          <Save />
                        </IconButton>
                        <IconButton onClick={() => setEditRow(null)}>
                          <Close />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      borderLeft: '5px solid',
                      borderColor: 'primary.main',
                    }}
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setOpenRow((prevIndex) => (prevIndex === index ? null : index))
                    }
                  >
                    <Cell text={idea.brandName || ''} />
                    <Cell
                      text={
                        idea.content.length > 100
                          ? `${idea.content.slice(0, 100)}...`
                          : idea.content || ''
                      }
                    />
                    <Cell
                      text={
                        new Date(idea.createdOn)
                          .toLocaleString('es-ES', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                          })
                          .replace(',', '')
                          .replace(' ', ' - ') + 'h'
                      }
                    />
                    <Cell>{idea.chainName || ''}</Cell>
                    <Cell>
                      {idea.priority === 0
                        ? 'Low'
                        : idea.priority === 1
                        ? 'Medium'
                        : 'High'}
                    </Cell>
                    <Cell>{`${(idea.prompterRunIds || []).length}`}</Cell>
                    <Cell sx={{ textAlign: 'center' }}>
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            setEditRow(index);
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => setDeleteIdeaId(idea._id!)}>
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </Cell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    colSpan={7}
                    sx={{
                      paddingBottom: 0,
                      paddingTop: 0,
                      maxWidth: '100px',
                      ...(openRow === index ? {} : { border: 'none' }),
                    }}
                  >
                    <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="body2">{idea.content}</Typography>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                rowsPerPageOptions={[15, 25, 50]}
                rowsPerPage={limit}
                page={page}
                onPageChange={(_, page) => onPageChange(page)}
                onRowsPerPageChange={(event) => {
                  onPageChange(0);
                  setLimit(+event.target.value);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default IdeasTable;
