import { useMutation, useQuery } from '@apollo/client';
import { Schedule } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Brand,
  Post,
  PostContentMedia,
  PostLifecycleState,
  PostType,
  Tag,
  Thumbnail,
} from '../../__generated__/graphql';
import ThumbnailCard from '../../components/Cards/ThumbnailCard';
import DatePicker from '../../components/DatePicker/DatePicker';
import PostMediaDisplay from '../../components/PostComponents/PostMediaDisplay';
import PostTags from '../../components/PostComponents/PostTags';
import TagModal from '../../components/PostComponents/TagModal';
import MagnetsSelector from '../../components/SelectorComponents/MagnetsSelector';
import { TextEditor } from '../../components/TextEditor/TextEditor';
import { DeviceContext } from '../../context/DeviceContext';
import { extractUrlsFromText, PostProvider } from '../../context/PostContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { CREATE_POST, UPDATE_POST } from '../../graphql/mutations';
import { GET_BRANDS, GET_EDIT_POST, GET_POSTS } from '../../graphql/queries';
import { formatDate, getNextMonday9amMadrid, timeString } from '../../utils';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../src/context/AuthContext';

const inputStyle = {
  width: '100%',
  maxWidth: '800px',
};

interface PostInput {
  brand: Brand | null;
  lifecycleState: PostLifecycleState | null;
  type: PostType | null;
  schedule: Date | null;
  body: string | null;
  media: PostContentMedia[];
  magnets: number;
}

const CreatePost = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { postId } = useParams();
  const { isMobile } = useContext(DeviceContext);

  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [thumbnail, setThumbnail] = useState<Thumbnail | null>(null);
  const [showThumbnail, setShowThumbnail] = useState<boolean>(false);
  const [errorThumbnail, setErrorThumbnail] = useState<boolean>(false);
  const [loadingThumbnail, setLoadingThumbnail] = useState<boolean>(false);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [selectTags, setSelectTags] = useState<Tag[]>([]);
  const { user } = useContext(AuthContext);

  const handleAddTags = (tags: Tag[]) => {
    setSelectTags(tags);
  };

  const { data: postData, loading } = useQuery(GET_EDIT_POST, {
    variables: { postId: postId! },
    skip: !postId,
    onCompleted(data) {
      if (!!data.post?.tags?.length) {
        setSelectTags(data.post.tags as Tag[]);
      }
      if (data.post?.content?.thumbnail) {
        setThumbnail(data.post.content.thumbnail);
        setShowThumbnail(true);
      }
    },
  });

  let media: PostContentMedia[] = [];
  if (postData?.post?.advocacy) {
    media = postData?.post?.advocacy?.parentPost?.content?.media || [];
  }

  if (postData?.post?.content && postData?.post?.content?.media) {
    media = postData?.post?.content?.media || [];
  }

  const post: Post = postData?.post!;

  const { data: brandsData, loading: loadingBrands } = useQuery(GET_BRANDS, {
    variables: {
      allBrands: true,
    },
  });

  const [createPost] = useMutation(CREATE_POST, {
    onCompleted: ({ createPost }) => {
      setSuccessMessage(createPost.message || t('Post created successfully'));
      navigate(`/content-manager`);
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
    awaitRefetchQueries: true,
    refetchQueries: [GET_POSTS, GET_EDIT_POST],
  });

  const [updatePost] = useMutation(UPDATE_POST, {
    onCompleted: ({ updatePost }) => {
      setSuccessMessage(updatePost.message || t('Post updated successfully'));
      navigate(`/content-manager`);
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
    awaitRefetchQueries: true,
    refetchQueries: [GET_POSTS, GET_EDIT_POST],
  });

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      brand: null,
      type: null,
      lifecycleState: null,
      schedule: null,
      body: null,
      media: [],
      magnets: 0,
    },
    values: {
      brand: post ? post.brand : state ? state.brand : null,
      lifecycleState: post?.lifecycleState || PostLifecycleState.Draft,
      type: post?.type || PostType.ReadyToShare,
      body: post
        ? post.content?.body
        : state && state.body
        ? (`<p class="tiptap-p">${state.body.replaceAll('\n', '<br>')}</p>` as any)
        : '',
      magnets: post?.magnets || 0,
      editable: false,
      schedule: post?.schedule || getNextMonday9amMadrid(),
      media: media || [],
    },
  });

  const isAdvocacy = post?.type === PostType.Advocacy && post?.advocacy !== null;
  const isAdvocacyParent = isAdvocacy && !post?.advocacy;
  const isScheduledToPublishSelected =
    watch('lifecycleState') === PostLifecycleState.ScheduledToReady;

  const datePublish = watch('schedule');
  const mediaWatch = watch('media');
  const watchBrand = watch('brand');

  const onSuccess = ({
    brand,
    lifecycleState,
    schedule,
    type,
    magnets,
    body,
    media,
  }: PostInput) => {
    let date = new Date(schedule!!);
    const modifiedMedia = media.map((m) => {
      return { ...m, __typename: undefined };
    });

    const input = {
      brandId: brand?._id!,
      brandName: brand?.name || '',
      lifecycleState: lifecycleState!!,
      type: type!!,
      schedule: isScheduledToPublishSelected ? date : null,
      content: {
        body,
        media: modifiedMedia,
        thumbnail:
          modifiedMedia.length || !showThumbnail
            ? null
            : post
            ? {
                description: thumbnail?.description,
                media: {
                  alt: thumbnail?.media?.alt,
                  storagePath: thumbnail?.media?.storagePath,
                  type: thumbnail?.media?.type,
                  url: thumbnail?.media?.url,
                },
                source: thumbnail?.source,
                title: thumbnail?.title,
              }
            : thumbnail,
      },
      magnets: +magnets,
      tags: selectTags.map(({ __typename, ...tag }) => tag),
      shellId: brand?.shellId,
      shellName: brand?.shell?.name,
      ...(state && state.prompterRunId && { prompterRunId: state.prompterRunId }),
      ...(state && state.ideaId && { ideaId: state.ideaId }),
    };

    post
      ? updatePost({ variables: { postId: post._id!, input } })
      : createPost({ variables: { input: { ...input, visibleOnlyToAdmins: true } } });
  };

  const handleScheduleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDatePickerOpen(true);
  };

  const getOptionLabel = (lifecycleState: string) => {
    if (lifecycleState !== PostLifecycleState.ScheduledToReady) return lifecycleState;
    if (datePublish) {
      let date = new Date(datePublish);
      return `${lifecycleState} for ${formatDate(date)} ${t('at')} ${timeString(date)}`;
    }
    return lifecycleState;
  };

  const filteredPostLifecycleState = [
    PostLifecycleState.Ready,
    PostLifecycleState.ScheduledToReady,
    PostLifecycleState.Draft,
    PostLifecycleState.Approved,
    PostLifecycleState.Posted,
  ];

  return (
    <>
      {(postId ? post?._id === postId : true) && (
        <Stack
          direction="column"
          p={isMobile ? '40px 30px 80px 30px' : '40px 80px 80px 80px'}
          spacing={4}
          alignItems={'center'}
        >
          <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
            {post ? t('Edit') : t('New')} {t('Post')}
          </Typography>
          {!loading ? (
            <form onSubmit={handleSubmit(onSuccess)} style={inputStyle}>
              <Stack alignItems={'center'} direction="column" spacing={2}>
                {isAdvocacy && !isAdvocacyParent && (
                  <Typography variant="body2" fontWeight="bold">
                    {t('Advocacy: This post is an advocacy child.')}{' '}
                    <Link
                      to={`/content-manager/edit-post/${post.advocacy?.parentPostId}`}
                    >
                      {t('View parent')}
                    </Link>
                  </Typography>
                )}
                <Controller
                  name="brand"
                  control={control}
                  render={({
                    field: { value, onChange },
                  }: {
                    field: { value: Brand; onChange: (brand: Brand | null) => void };
                  }) => (
                    <Autocomplete
                      value={value}
                      options={brandsData?.getBrands || []}
                      getOptionLabel={(option) => option.name || 'Unknown'}
                      renderInput={(params) => (
                        <TextField
                          required
                          label={t('Brand')}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingBrands ? <CircularProgress size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      data-testid="new-post-brand-select"
                      sx={inputStyle}
                      isOptionEqualToValue={(option, value) => option._id === value._id}
                      onChange={(_, data) => {
                        onChange(data);
                        setSelectTags([]);
                      }}
                      filterOptions={(options, { inputValue }) =>
                        options.filter(
                          (option) =>
                            option.name
                              .toLowerCase()
                              .includes(inputValue.toLowerCase()) ||
                            (option.user &&
                              option.user.email
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())),
                        )
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            width={'100%'}
                          >
                            <Typography fontWeight={500}>{option.name}</Typography>
                            <Typography fontSize={14}>{option.user?.email}</Typography>
                          </Stack>
                        </li>
                      )}
                    />
                  )}
                />
                <Stack sx={inputStyle} alignItems={'center'} direction="row" spacing={2}>
                  <Controller
                    control={control}
                    name="lifecycleState"
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        value={value}
                        onChange={(_, data) => onChange(data)}
                        options={[
                          ...filteredPostLifecycleState,
                          ...Object.values(PostLifecycleState).filter(
                            (state) => !filteredPostLifecycleState.includes(state),
                          ),
                        ]}
                        filterOptions={(options, state) => {
                          return options.filter((option) =>
                            filteredPostLifecycleState.includes(option),
                          );
                        }}
                        getOptionLabel={getOptionLabel}
                        renderInput={(params) => (
                          <TextField required label={t('Lifecycle State')} {...params} />
                        )}
                        sx={inputStyle}
                        data-testid="new-post-lifecycle-state-select"
                      />
                    )}
                  />
                  {isScheduledToPublishSelected && (
                    <IconButton onClick={handleScheduleClick} data-testid="post-schedule">
                      <Schedule />
                    </IconButton>
                  )}
                </Stack>
                <Controller
                  control={control}
                  name="schedule"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      date={value}
                      open={datePickerOpen}
                      anchorElement={anchorEl!}
                      onClose={() => setDatePickerOpen(false)}
                      onSubmit={(date) => {
                        onChange(date);
                        setDatePickerOpen(false);
                      }}
                      primaryActionTitle={t('Schedule')}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      value={value}
                      onChange={(_, data) => onChange(data)}
                      options={Object.values(PostType)}
                      renderInput={(params) => (
                        <TextField required label={t('Post Type')} {...params} />
                      )}
                      sx={inputStyle}
                      data-testid="new-post-type-select"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="magnets"
                  render={({ field: { value, onChange } }) => (
                    <Box sx={inputStyle}>
                      <Typography mb={1}>{t('Magnets')}</Typography>
                      <MagnetsSelector magnets={value} onChange={onChange} />
                    </Box>
                  )}
                />
                <Box width="100%">
                  <Typography>
                    {t('Labels')}{' '}
                    {!watchBrand && (
                      <span style={{ color: 'red', fontSize: 12 }}>
                        {t('(Select a brand first)')}
                      </span>
                    )}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => setOpenTagModal(true)}
                    data-testid="new-post-tags-select"
                    disabled={!watchBrand}
                    sx={{
                      marginY: '1rem',
                      background: selectTags.length ? '#FFD8EB' : 'white',
                    }}
                  >
                    {t('Label')}
                  </Button>

                  {selectTags.length > 0 && <PostTags tags={selectTags} />}
                </Box>
                <Controller
                  control={control}
                  name="body"
                  render={({ field: { value, onChange } }) => {
                    if (value === null) return <></>;
                    return (
                      <PostProvider
                        postId={postId || null}
                        onChange={(html) => {
                          onChange(html);

                          if (!user) return;
                          const urls = extractUrlsFromText(html, false, user);
                          const url = urls[0] as string;

                          if (mediaWatch.length) return;

                          if (!url && !showThumbnail && thumbnail) {
                            setThumbnail(null);
                          }

                          if (thumbnail?.source === url && !showThumbnail) {
                            setShowThumbnail(true);
                          }
                        }}
                        fetchThumbnail={
                          !mediaWatch.length
                            ? (thumbnail) => {
                                if (thumbnail) setShowThumbnail(true);
                                setThumbnail(thumbnail);
                                setErrorThumbnail(false);
                                setLoadingThumbnail(false);
                              }
                            : undefined
                        }
                        content={value}
                        thumbnailData={thumbnail}
                        setErrorThumbnail={setErrorThumbnail}
                        setLoadingThumbnail={setLoadingThumbnail}
                      >
                        <TextEditor
                          onChange={onChange}
                          resizable
                          placeholder={
                            isAdvocacy
                              ? post.advocacy?.parentPost?.content?.body || ''
                              : ''
                          }
                          data-testid="new-post-content-body-input"
                        />
                      </PostProvider>
                    );
                  }}
                />
                {errorThumbnail && (
                  <Alert severity="error">
                    {t('There was an error fetching the thumbnail. Please try again.')}
                  </Alert>
                )}
                {
                  <Box sx={inputStyle}>
                    {loadingThumbnail ? (
                      <Box marginX="auto" marginTop="1rem">
                        <CircularProgress />
                      </Box>
                    ) : !!mediaWatch.length || !thumbnail || !showThumbnail ? (
                      <Controller
                        control={control}
                        name="media"
                        render={({ field: { value, onChange } }) => (
                          <PostMediaDisplay
                            post={post}
                            media={value}
                            onUploadedMedia={onChange}
                            onDeletedMedia={(mediaIndex) => {
                              onChange(
                                mediaWatch.filter((_, index) => index !== mediaIndex),
                              );
                            }}
                            setMessage={(message) => setErrorMessage(message?.text || '')}
                          />
                        )}
                      />
                    ) : (
                      <ThumbnailCard
                        thumbnail={thumbnail as Thumbnail}
                        hideThumbnail={() => setShowThumbnail(false)}
                      />
                    )}
                  </Box>
                }
                <Button
                  variant="contained"
                  type="submit"
                  sx={inputStyle}
                  onClick={handleSubmit(onSuccess)}
                  data-testid="new-user-save-button"
                >
                  {post ? t('Update') : t('Create')}
                </Button>
              </Stack>
            </form>
          ) : (
            <Stack
              alignItems={'center'}
              direction="column"
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Skeleton variant="rectangular" height={50} sx={inputStyle} />
              <Skeleton variant="rectangular" height={50} sx={inputStyle} />
              <Skeleton variant="rectangular" height={50} sx={inputStyle} />
              <Skeleton variant="rectangular" height={50} sx={inputStyle} />
              <Divider sx={{ width: '100%' }} />
              <Box sx={inputStyle}>
                <Typography mb={1}>{t('Magnets')}</Typography>
                <Skeleton variant="rectangular" height={50} width={300} />
              </Box>
              <Skeleton variant="rectangular" height={300} sx={inputStyle} />
              <Skeleton variant="rectangular" height={25} sx={inputStyle} />
            </Stack>
          )}
        </Stack>
      )}

      <TagModal
        key={selectTags.length}
        openTagModal={openTagModal}
        setOpenTagModal={setOpenTagModal}
        handleAddTags={handleAddTags}
        tags={selectTags as Tag[]}
        shellId={watchBrand?.shellId}
      />
    </>
  );
};

export default CreatePost;
