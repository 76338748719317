import { Box, Button, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { TabPanel } from '../../components/Tabs/TabPanel';
import FormStepper from '../../lead-magnets/components/FormStepper';
import FormTitle from '../../lead-magnets/components/FormTitle';
import { useFormContext } from '../../lead-magnets/contexts/FormContext';
import FormStepsIsprox from '../../lead-magnets/forms/job-descriptions-isprox/FormStepsIsprox';
import {
  colorPrincipal,
  colorSecundario,
} from '../../lead-magnets/forms/job-descriptions-isprox/consts';
import Layout from './Layout';
import { useTranslation } from 'react-i18next';

enum IsproxTab {
  Form = 0,
  ChatCreateJobOffer = 1,
  ChatCreateInterviewQuestions = 2,
}

const Isprox = () => {
  const { t } = useTranslation();
  const stackRef = useRef<HTMLDivElement | null>(null);
  const [iframeWidth, setIframeWidth] = useState('900px');

  useEffect(() => {
    if (stackRef.current) {
      setIframeWidth(`${stackRef.current.offsetWidth}px`);
    }
  }, []);

  const { currentStep } = useFormContext();
  const [tab, setTab] = useState(
    () => Number(localStorage.getItem('isproxTab')) || IsproxTab.Form,
  );

  const stepsForm = [
    {
      label: t('Información esencial'),
      description: t('Información esencial'),
    },
    {
      label: t('Información opcional'),
      description: t('Información opcional'),
    },
    {
      label: t('Detalles complementarios'),
      description: t('Detalles complementarios'),
    },
    {
      label: t('Resultado'),
      description: t('Resultado'),
    },
  ];
  const mediaQuery = useMediaQuery('(max-width:600px)');

  return (
    <Stack
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
      minHeight={'100%'}
      padding={mediaQuery ? '0px' : '40px'}
    >
      <Stack
        width={'100%'}
        mt={2}
        pb={4}
        overflow={'auto'}
        justifyContent={'center'}
        alignItems={'center'}
        display={'flex'}
        ref={stackRef}
      >
        <Box
          width="100%"
          maxWidth="250px"
          height="auto"
          marginBottom={{
            xs: '20px',
          }}
        >
          <img
            src={'/images/lm/isprox-logo.svg'}
            alt={'isprox'}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} mb={2}>
          {tab === IsproxTab.Form && currentStep === 0 && (
            <>
              <Button
                variant="outlined"
                sx={{
                  padding: '15px 38px',
                  borderRadius: '8px',
                  borderColor: colorPrincipal,
                  color: colorPrincipal,
                  '&:hover': {
                    backgroundColor: colorSecundario,
                    borderColor: colorSecundario,
                  },
                }}
                onClick={() => setTab(IsproxTab.ChatCreateJobOffer)}
              >
                {t('Crear oferta con chat')}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  padding: '15px 38px',
                  borderRadius: '8px',
                  borderColor: colorPrincipal,
                  color: colorPrincipal,
                  '&:hover': {
                    backgroundColor: colorSecundario,
                    borderColor: colorSecundario,
                  },
                }}
                onClick={() => setTab(IsproxTab.ChatCreateInterviewQuestions)}
              >
                {t('Isprox chat')}
              </Button>
            </>
          )}

          {tab !== IsproxTab.Form && (
            <>
              <Button
                variant="outlined"
                sx={{
                  padding: '15px 38px',
                  marginBottom: 2,
                  borderRadius: '8px',
                  borderColor: colorPrincipal,
                  color: colorPrincipal,
                  '&:hover': {
                    backgroundColor: colorSecundario,
                    borderColor: colorSecundario,
                  },
                }}
                onClick={() => setTab(IsproxTab.Form)}
              >
                {t('Volver al formulario')}
              </Button>
            </>
          )}
        </Stack>

        <TabPanel value={tab} index={IsproxTab.Form} sx={{ p: 0 }}>
          <Layout
            stepper={
              <FormStepper
                steps={stepsForm}
                currentStep={currentStep}
                colorActive="#0F73C1"
                colorBg="#69a6da"
              />
            }
            forms={
              <Stack
                mt={mediaQuery ? 2.4 : 0}
                height={'100%'}
                display={'flex'}
                width={'100%'}
                px={1}
              >
                <FormTitle
                  title={`${currentStep + 1}. ${stepsForm[currentStep].label}`}
                  color="#0F73C1"
                />
                <FormStepsIsprox />
              </Stack>
            }
          />
        </TabPanel>
        <TabPanel value={tab} index={IsproxTab.ChatCreateJobOffer} sx={{ p: 0 }}>
          <Stack width={'100%'}>
            {/* @ts-ignore */}
            {stackRef !== null && (
              <iframe
                src="https://interfaces.zapier.com/embed/chatbot/clsj4k5q80005o4z2sz02uaxk"
                height="900px"
                width={iframeWidth}
                allow="clipboard-write *"
                style={{ border: 'none' }}
                title="chatoffert"
              ></iframe>
            )}
          </Stack>
        </TabPanel>
        <TabPanel
          value={tab}
          index={IsproxTab.ChatCreateInterviewQuestions}
          sx={{ p: 0 }}
        >
          <Stack width={'100%'}>
            {/* @ts-ignore */}
            {stackRef !== null && (
              <iframe
                src="https://interfaces.zapier.com/embed/chatbot/clsujp22a000cpilv1tv85uxi"
                height="900px"
                width={iframeWidth}
                allow="clipboard-write *"
                style={{ border: 'none' }}
                title="chatquestions"
              ></iframe>
            )}
          </Stack>
        </TabPanel>
      </Stack>
    </Stack>
  );
};

export default Isprox;
