import { useMutation } from '@apollo/client';
import { PlayCircleFilled } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Chain, Idea, PrompterRunInput } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { CREATE_RUN_ASYNC, UPDATE_IDEA } from '../../graphql/mutations';
import { getFullName } from '../../utils';
import { useNavigate } from 'react-router-dom';

const inputStyle = {
  width: '100%',
  maxWidth: '900px',
};

interface PromptContextFormProps {
  idea: Idea;
  chains: Chain[];
  loading: boolean;
  direction?: 'row' | 'column';
}

const PromptContextForm = ({
  idea,
  chains,
  direction = 'row',
  loading,
}: PromptContextFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Idea>({
    defaultValues: idea,
  });

  const { user } = useContext(AuthContext);
  const { setErrorMessage, setSuccessMessage } = useContext(SnackbarContext);
  const [createRunAsync, { loading: creatingRun }] = useMutation(CREATE_RUN_ASYNC);
  const [updateIdea, { loading: updatingIdea }] = useMutation(UPDATE_IDEA);
  const navigate = useNavigate();

  const handleGeneratePost = (prompterRun: PrompterRunInput, idea: Idea) => {
    createRunAsync({
      variables: {
        input: {
          brandId: prompterRun.brandId,
          brandName: prompterRun.brandName,
          createdById: user?._id,
          createdByFullName: getFullName(user),
          chainId: prompterRun.chainId,
          chainName: chains.find((chain) => chain._id === prompterRun.chainId)?.name,
          model: prompterRun.model,
          topicContext: prompterRun.topicContext,
          ideaId: idea._id,
        },
      },
      onCompleted: (data) => {
        if (!data.createRunAsync.success) {
          setErrorMessage(data.createRunAsync.message || 'Something went wrong');
          return;
        }

        setSuccessMessage('Idea updated and prompt queued successfully');
        navigate(`/content-manager/prompter-runs/${data.createRunAsync.run?._id}`);
      },
    });
  };

  const onSubmit = (data: Idea, generatePost: boolean) => {
    const prompterRun: PrompterRunInput = {
      brandId: data.brandId,
      brandName: data.brandName,
      chainId: data.chainId,
      chainName: data.chainName,
      topicContext: data.content,
    };

    updateIdea({
      variables: {
        ideaId: idea._id!,
        input: {
          brandId: data.brandId,
          brandName: data.brandName,
          chainId: data.chainId,
          chainName: data.chainName,
          content: data.content,
          priority: data.priority,
        },
      },
      onCompleted: (data) => {
        if (!data.updateIdea.success) {
          setErrorMessage(data.updateIdea.message || 'Something went wrong');
          return;
        }

        if (generatePost && data.updateIdea.idea) {
          handleGeneratePost(prompterRun, data.updateIdea.idea);
          return;
        }

        setSuccessMessage(data.updateIdea.message || 'Idea updated successfully');
      },
    });
  };

  return (
    <form style={{ width: '100%' }}>
      <Stack
        sx={{ width: '100%' }}
        alignItems={'center'}
        direction={direction}
        spacing={2}
      >
        <Controller
          name="content"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Topic Context"
              type="text"
              multiline
              required
              value={field.value || ''}
              error={!!errors.content}
              helperText={errors.content ? 'Topic Context is required' : ''}
              disabled={loading}
              sx={{ ...inputStyle, '& textarea': { resize: 'both' }, flex: 6 }}
            />
          )}
        />
        <Controller
          name="chainId"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              sx={{ ...inputStyle, flex: 2 }}
              options={chains}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={chains.find((chain) => chain._id === field.value) || null}
              onChange={(event, item) => {
                field.onChange(item ? item._id : '');
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option._id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Chain"
                  variant="outlined"
                  required
                  error={!!errors.chainId}
                  helperText={errors.chainId ? 'Chain is required' : ''}
                  sx={inputStyle}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              disabled={loading}
            />
          )}
        />
        <Controller
          name="priority"
          rules={{ required: 'Priority is required' }}
          control={control}
          render={({ field }) => (
            <FormControl sx={{ ...inputStyle, flex: 1 }}>
              <InputLabel id="priority-label">Priority</InputLabel>
              <Select
                {...field}
                labelId="priority-label"
                label="Priority"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!errors.priority}
                disabled={loading}
              >
                <MenuItem value={0}>Low</MenuItem>
                <MenuItem value={1}>Medium</MenuItem>
                <MenuItem value={2}>High</MenuItem>
              </Select>
              <FormHelperText>
                {errors.priority ? 'Priority is required' : ''}
              </FormHelperText>
            </FormControl>
          )}
        />
        <LoadingButton
          loading={updatingIdea || creatingRun}
          variant="outlined"
          onClick={handleSubmit((data) => onSubmit(data, false))}
          startIcon={<PlayCircleFilled />}
        >
          Update idea
        </LoadingButton>
        <LoadingButton
          loading={creatingRun || updatingIdea}
          variant="contained"
          onClick={handleSubmit((data) => onSubmit(data, true))}
          startIcon={<PlayCircleFilled />}
        >
          Update idea and run prompt
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default PromptContextForm;
