import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { JobDescriptionInput } from '../../../__generated__/graphql';
import FormActions from '../../components/FormActions';
import Select from '../../components/Select';
import TextField from '../../components/TextField';
import { jobModalityOptions } from './consts';
import { useFormContext } from '../../contexts/FormContext';

const Step1 = ({
  onSubmit,
  defaultValues,
}: {
  onSubmit: (data: JobDescriptionInput) => void;
  defaultValues: JobDescriptionInput;
}) => {
  const { formData } = useFormContext();
  const { control, handleSubmit, formState } = useForm<JobDescriptionInput>({
    defaultValues: {
      ...defaultValues,
      ...formData
    },
  });

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      overflow={'auto'}
      gap={'32px'}
    >
      <TextField
        control={control}
        name="company"
        placeholder="Ej: magnettu"
        label="¿En qué empresa se ofrece este empleo?"
        required
      />
      <TextField
        control={control}
        name="title"
        placeholder="Ej: Diseñador UX/UI"
        label="¿Cuál es el título del puesto para el empleo que estás ofreciendo?"
        required
      />
      <TextField
        control={control}
        name="requirements"
        multiline
        placeholder="Ejemplo: Un mínimo de 1 año. Hablar inglés."
        label="¿Qué requisitos se requieren para esta posición?"
        required
      />
      <TextField
        control={control}
        name="location"
        placeholder="Ej: Barcelona"
        label="¿Cuál es la ubicación de la empresa?"
      />
      <Select
        options={jobModalityOptions}
        control={control}
        name="modality"
        placeholder="Ej: Híbrido"
        label="Selecciona el formato del empleo"
        required
      />
      <TextField
        control={control}
        name="benefits"
        multiline
        placeholder="Ejemplo: Trabajo remoto, días libres en cumpleaños, etc"
        label="¿Cuáles son los beneficios? Puedes incluir rango salarial si lo crees pertinente"
        required
      />
      <FormActions isValid={formState.isValid} firstStep buttonId='lm-job-description-step-1-button'/>
    </Stack>
  );
};

export default Step1;
