import { ArrowDownward, ArrowUpward, RemoveCircleOutline } from '@mui/icons-material';
import {
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { Chain, OpenAiRole } from '../../__generated__/graphql';
import { toTitleCase } from '../../utils';

interface MessageProps {
  control: Control<Chain>;
  register: UseFormRegister<Chain>;
  chainId: 'chainA' | 'chainB';
  index: number;
  messageIndex: number;
  move: (from: number, to: number) => void;
  remove: (index: number) => void;
  disabled: boolean;
  disableMoveDown?: boolean;
}

const Message = ({
  control,
  register,
  chainId,
  index,
  messageIndex,
  move,
  remove,
  disabled,
  disableMoveDown = false,
}: MessageProps) => {
  return (
    <Stack
      direction={'column'}
      spacing={1}
      sx={{ border: 'solid 1px #EBEBEB', borderRadius: 1, p: 1 }}
      key={`${chainId}-${index}-${messageIndex}`}
    >
      <Stack direction={'row'} justifyContent={'space-between'} mb={1}>
        <Controller
          control={control}
          name={`${chainId}.${index}.messages.${messageIndex}.role` as const}
          render={({ field }) => (
            <Select
              value={field.value}
              disabled={disabled}
              onChange={(value) => field.onChange(value)}
              autoWidth
              size="small"
              sx={{ border: 'none' }}
            >
              {Object.values(OpenAiRole).map((role) => (
                <MenuItem value={role}>{toTitleCase(role)}</MenuItem>
              ))}
            </Select>
          )}
        />
        <Stack direction={'row'} spacing={0}>
          <IconButton
            disabled={messageIndex === 0}
            disableRipple
            onClick={() => move(messageIndex, messageIndex - 1)}
            sx={{ '&:hover': { color: 'red' } }}
          >
            <ArrowUpward sx={{ width: 20 }} />
          </IconButton>
          <IconButton
            disabled={disableMoveDown || disabled}
            disableRipple
            onClick={() => move(messageIndex, messageIndex + 1)}
            sx={{ '&:hover': { color: 'red' } }}
          >
            <ArrowDownward sx={{ width: 20 }} />
          </IconButton>
          <Tooltip title="Remove message">
            <IconButton
              disabled={disabled}
              disableRipple
              onClick={() => remove(messageIndex)}
              sx={{ '&:hover': { color: 'red' } }}
            >
              <RemoveCircleOutline sx={{ width: 20 }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <TextField
        key={`${chainId}-${index}-${messageIndex}`}
        variant="standard"
        label="Prompt"
        placeholder="Write your prompt here"
        InputLabelProps={{
          shrink: true,
        }}
        multiline
        InputProps={{
          disableUnderline: true,
          readOnly: disabled
        }}
        {...register(`${chainId}.${index}.messages.${messageIndex}.content`)}
      />
    </Stack>
  );
};

export default Message;
