import { createContext, ReactNode, useEffect, useState } from 'react';
import { PostLifecycleState, PostsBrandsFilter } from '../__generated__/graphql';
import { ContentBoardTabs, getTabsFromLifecycleStates } from '../types';

export enum FilterTab {
  'Status',
  'Brands',
  'Date',
  'Type',
  'Tags',
  'Created',
  'Client',
  'Label'
}

export const AdminFilters = [
  FilterTab.Type,
  FilterTab.Tags,
  FilterTab.Created,
  FilterTab.Client,
  
];

interface PostsFilterContextType {
  filter: PostsBrandsFilter;
  setFilter: (filter: PostsBrandsFilter) => void;
  isFilterActive: boolean;
  initialFilterState: PostsBrandsFilter;
  filterTab: FilterTab;
  setFilterTab: (tab: FilterTab) => void;
  isFilterTabActive: (tab: FilterTab) => boolean;
  currentTabs: ContentBoardTabs[];
}

const defaultFilters: PostLifecycleState[] = [];

const initialState: PostsFilterContextType = {
  filter: {
    lifecycleStates: defaultFilters,
  },
  setFilter: () => {},
  isFilterActive: false,
  isFilterTabActive: (tab: FilterTab) => false,
  initialFilterState: {
    lifecycleStates: defaultFilters,
    brandIds: [],
    dateFrom: null,
    dateTo: null,
    type: null,
    tags: [],
    createdById: null,
    shellId: null,
  },
  filterTab: FilterTab.Status,
  setFilterTab: () => {},
  currentTabs: getTabsFromLifecycleStates(defaultFilters),
};

export const PostsFilterContext = createContext<PostsFilterContextType>(initialState);

export const PostsFilterProvider = ({ children }: { children: ReactNode }) => {
  const [filterTab, setFilterTab] = useState(FilterTab.Status);

  const storedStateRaw = JSON.parse(
    localStorage.getItem('contentManagerFilters') || '{}',
  );

  const storedState = Object.keys(storedStateRaw).reduce<Partial<PostsBrandsFilter>>(
    (acc, key) => {
      if (key in initialState.filter) {
        acc[key as keyof PostsBrandsFilter] = storedStateRaw[key];
      }
      return acc;
    },
    {},
  );

  const validLifecycleStates = Object.values(PostLifecycleState) as PostLifecycleState[];
  if (storedState.lifecycleStates) {
    storedState.lifecycleStates = storedState.lifecycleStates.filter(
      (state: PostLifecycleState | null): state is PostLifecycleState =>
        state !== null && validLifecycleStates.includes(state),
    );
  }

  const defaultState: PostsBrandsFilter = {
    ...initialState.filter,
    ...storedState,
    lifecycleStates: defaultFilters,
  };

  const [filter, setFilter] = useState<PostsBrandsFilter>(
    defaultState as PostsBrandsFilter,
  );

  const lifecycleStates = (filter.lifecycleStates || []).filter(
    (state): state is PostLifecycleState => state !== null,
  );

  const currentTabs = getTabsFromLifecycleStates(lifecycleStates);

  const isFilterActive = Object.keys(filter).some((key) => {
    let filterValue = filter[key as keyof PostsBrandsFilter];
    const initialValue = initialState.filter[key as keyof PostsBrandsFilter];

    if (filterValue === undefined || filterValue === null || filterValue.length === 0)
      return false;

    if (Array.isArray(filterValue) && Array.isArray(initialValue)) {
      if (filterValue.length !== initialValue.length) {
        return true;
      }

      // Compare arrays if both are not empty
      const sortedFilterValue = [...filterValue].sort();
      const sortedInitialValue = [...initialValue].sort();
      return sortedFilterValue.some(
        (value, index) => value !== sortedInitialValue[index],
      );
    } else {
      // Directly compare non-array values if both are not empty
      return filterValue !== initialValue;
    }
  });

  const isFilterTabActive = (tab: FilterTab) => {
    if (tab === FilterTab.Brands) {
      return filter.brandIds && filter.brandIds.length > 0;
    } else if (tab === FilterTab.Client) {
      return filter.shellId !== null;
    } else if (tab === FilterTab.Created) {
      return filter.createdById !== null;
    } else if (tab === FilterTab.Date) {
      return (
        (filter.dateFrom && filter.dateFrom.length > 0) ||
        (filter.dateTo && filter.dateTo.length > 0)
      );
    } else if (tab === FilterTab.Status) {
      return filter.lifecycleStates && filter.lifecycleStates.length > 0;
    } else if (tab === FilterTab.Tags) {
      return filter.tags && filter.tags.length > 0;
    } else if (tab === FilterTab.Type) {
      return filter.tags && filter.tags.length > 0;
    } else {
      return false;
    }
  };

  useEffect(() => {
    localStorage.setItem('contentManagerFilters', JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem('contentManagerFilters') || '');
    if (filter) {
      setFilter(filter);
    }
  }, []);

  return (
    <PostsFilterContext.Provider
      value={{
        filter,
        setFilter,
        isFilterActive,
        initialFilterState: initialState.filter,
        filterTab,
        setFilterTab,
        isFilterTabActive,
        currentTabs,
      }}
    >
      {children}
    </PostsFilterContext.Provider>
  );
};
