import { useMutation } from '@apollo/client';
import {
  AutoDelete,
  Clear,
  ConnectWithoutContact,
  Delete,
  DoneAll,
  FileCopy,
  KeyboardArrowDown,
  Link,
  LinkedIn,
  RemoveDone,
  Save,
  Schedule,
  Send,
  SupervisorAccount,
  Undo,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Divider, Menu, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import { useContext, useEffect, useState } from 'react';
import { PostLifecycleState, PostType, UserRole } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { WebSocketContext } from '../../context/WebSocketContext';
import { ENABLE_SHARE_LINK } from '../../graphql/mutations';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import DatePicker from '../DatePicker/DatePicker';
import ConfirmationPostModal from './ConfirmationPostModal';
import { Message } from './PostEditor';
import RequestApprovalModal from './RequestApprovalModal';
import { useTranslation } from 'react-i18next';

interface Props {
  setError: ({ type, text, action }: Message) => void;
  onClose: (force?: boolean) => void;
  openChat: boolean;
  isLink?: boolean;
}

const styleBoxMobile = {
  marginTop: 5,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  width: '100%',
  backgroundColor: 'background.paper',
};

const CustomMenuItem = ({
  onClick,
  icon,
  text,
  disabled = false,
  textInfo,
}: {
  onClick: () => void;
  icon?: any;
  text: string;
  disabled?: boolean;
  textInfo?: string;
}) => (
  <MenuItem
    disabled={disabled}
    onClick={onClick}
    sx={{
      paddingLeft: 2,
      fontSize: '14px',
      '&:hover': {
        backgroundColor: '#FF007A14',
        '& .text': {
          fontWeight: 'bold',
        },
      },
    }}
  >
    {icon && <ListItemIcon sx={{ minWidth: 40 }}>{icon}</ListItemIcon>}
    <Stack>
      <Typography variant="body2" className="text">
        {text}
      </Typography>
      {textInfo && (
        <Typography
          color="GrayText"
          fontSize="11px"
          sx={{ whiteSpace: 'pre-line', lineHeight: '1.2' }}
        >
          {textInfo}
        </Typography>
      )}
    </Stack>
  </MenuItem>
);

const PostActions = ({ setError, onClose, openChat, isLink }: Props) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { isMobile } = useContext(DeviceContext);
  const { postState } = useContext(PostContext);
  const { setSuccessMessage } = useContext(SnackbarContext);
  const { socket, isSocketConnected } = useContext(WebSocketContext);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [datePickerScheduleOpen, setDatePickerScheduleOpen] = useState<boolean>(false);
  const [unpublishModalOpen, setUnpublishModelOpen] = useState<boolean>(false);
  const [deletePostModalOpen, setDeletePostModalOpen] = useState<boolean>(false);
  const [savingStatus, setSavingStatus] = useState<string>('Syncing...');

  const [enableShareLink] = useMutation(ENABLE_SHARE_LINK);

  const theme = useTheme();

  const { post, status, handlers } = postState ?? {};
  const open =
    Boolean(anchorEl) &&
    !datePickerScheduleOpen &&
    !status?.confirmationPostsDialogOpen &&
    !status?.requestApprovalDialogOpen;

  const postScheduleDefaultDate = new Date(post?.schedule ? post?.schedule : Date.now());
  postScheduleDefaultDate.setHours(12, 0, 0, 0);

  const closeEditor = (force: boolean = false) => {
    if (!user) {
      handleCloseActionMenu();
      return;
    }
    onClose(force);
  };

  const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

  const handleSchedule = () => {
    setDatePickerScheduleOpen(true);
  };

  const handleRemoveSchedule = () => {
    handlers?.handleRemoveSchedule();
    handleCloseActionMenu();
  };

  const handleSubmitButton = () => {
    if (post?.type === PostType.Insights) {
      handlers?.handlePostOnLinkedin();
      return;
    }
    handlers?.handleOpenConfirmationPostsDialog();
  };

  // const handle

  const handleSchedulePostSubmit = (date: Date) => {
    handlers?.handleScheduleChange(date, () => {
      setSuccessMessage(t('Post scheduled successfully 🎉'));
      closeEditor(true);
    });

    setDatePickerScheduleOpen(false);
    handleCloseActionMenu();
  };

  const handleUnpublishPost = () => {
    handlers?.handleUnpublishLinkedinPost();
    setUnpublishModelOpen(false);
    handleCloseActionMenu();
  };

  const handleCloseModalDelete = () => {
    setUnpublishModelOpen(false);
  };

  const handleViewOnLinkedin = () => {
    window.open(
      `https://www.linkedin.com/feed/update/${post?.linkedin?.postUrn}`,
      '_blank',
      'noreferrer',
    );

    handleCloseActionMenu();
  };

  const handleUnpublishLinkedin = () => {
    setUnpublishModelOpen(true);
    handleCloseActionMenu();
  };

  const handleCreateAdvocacyPost = () => {
    handlers?.handleCreateAdvocacy(closeEditor);
    handleCloseActionMenu();
  };

  const handleUpdateAdvocacyPost = () => {
    handlers?.handleUpdateAdvocacy(() => {
      setSuccessMessage(t('Post updated successfully'));
      closeEditor();
    });
    handleCloseActionMenu();
  };

  const handleSaveAsDraft = () => {
    handlers?.handleSaveAsDraft({
      callback: () => {
        setSuccessMessage(t('The post has been saved as a draft.'));
      },
    });
  };

  const handleSendToContentBoard = () => {
    handleCloseActionMenu();

    if (status?.isNew) {
      handlers?.handleSaveAsDraft({
        callback: () => {
          closeEditor(true);
          setSuccessMessage(t('The post has been submitted to the user content board.'));
        },
        lifecycleState: PostLifecycleState.Ready,
      });
      return;
    }

    handlers?.handleStateChange(PostLifecycleState.Ready);
    closeEditor();
    setSuccessMessage(t('The post has been submitted to the user content board.'));
  };

  const handleTurnIntoAdvocacy = () => {
    handlers?.handleTurnIntoAdvocacy();
    handleCloseActionMenu();
  };

  const handleCloseDatepicker = () => {
    setDatePickerScheduleOpen(false);
  };

  const handleCopyLink = () => {
    if (!post || !post._id) {
      setError({
        type: 'error',
        text: t('Post cannot be shared until it has been saved'),
      });
      return;
    }

    navigator.clipboard.writeText(`${window.location.origin}/post/${post._id}`);
    setSuccessMessage(t('Post link copied to your clipboard'));
    handleCloseActionMenu();

    if (!post.shareLink || !post.shareLink.share) {
      enableShareLink({
        variables: {
          postId: post._id,
        },
      });
    }
  };

  const handleRevertApproval = () => {
    handleCloseActionMenu();

    if (status?.isScheduled) {
      handlers?.handleRemoveSchedule(() =>
        handlers?.handleStateChange(PostLifecycleState.PendingApproval),
      );

      return;
    }

    handlers?.handleStateChange(PostLifecycleState.PendingApproval);
  };

  const handleApprove = () => {
    handlers?.handleStateChange(PostLifecycleState.Approved);
    setSuccessMessage(t('The post has been approved'));
    closeEditor();
  };

  const handleDiscard = () => {
    handlers?.handleStateChange(PostLifecycleState.Discarded);
    setSuccessMessage(t('The post has been discarded'));
    closeEditor();
  };

  const handleReturnToDraft = () => {
    handlers?.handleStateChange(PostLifecycleState.Draft);
    handleCloseActionMenu();
  };

  const handleDeletePost = () => {
    handleCloseActionMenu();
    handlers?.handleDeletePost(() => closeEditor());
  };

  const handleRequestApproval = () => {
    if (status?.isNew) {
      handlers?.handleSaveAsDraft({
        callback: () => {
          // closeEditor(true);
          // setSuccessMessage('A content approval request has been sent to the user.');
          handlers?.handleOpenRequestApprovalDialog();
        },
        lifecycleState: PostLifecycleState.PendingApproval,
      });

      return;
    }

    handlers?.handleStateChange(PostLifecycleState.PendingApproval);
    // setSuccessMessage('Post approval has been requested');
    // closeEditor();
    handlers?.handleOpenRequestApprovalDialog();
  };

  const handleDuplicatePost = () => {
    handlers?.handleDuplicate(() => {
      setSuccessMessage('The post has been duplicated and is now available as a draft.');
    });
    handleCloseActionMenu();
  };

  useEffect(() => {
    if (
      postState?.status.isCurrentUserEditing &&
      !postState.status.isEditDisabled &&
      !postState?.status.isNew &&
      !postState?.status.isSaved
    ) {
      setSavingStatus(t('Saving...'));
    } else if (socket && isSocketConnected && !postState?.status.isNew) {
      setSavingStatus(t('Your post has been saved'));
    } else if (!postState?.status.isNew && (!socket || !isSocketConnected)) {
      setSavingStatus(t('You are offline'));
    }
  }, [socket, postState, isSocketConnected, isMobile, theme.palette.grey, t]);

  const unpublishLinkedinPost = status?.isPosted;
  const viewOnLinkedIn = status?.isPosted;
  const convertToAdvocacyPost =
    !status?.isPosted &&
    !status?.isAdvocacyParent &&
    !status?.isScheduled &&
    user?.role.includes(UserRole.AdvocacyManager);

  const userAssignated =
    !user?.role.includes(UserRole.BrandManager) &&
    !user?.role.includes(UserRole.LimitedBrandManager);

  const postOnLinkedin =
    (!isLink && !status?.isAdvocacyParent && !status?.isPosted) ||
    (isLink &&
      post?.lifecycleState !== PostLifecycleState.Discarded &&
      post?.lifecycleState !== PostLifecycleState.PendingApproval);

  const saveAsDraft =
    (!status?.isPosted && !status?.isAdvocacyParent && status?.isNew) ||
    (post?.lifecycleState === PostLifecycleState.Error && userAssignated);

  const returnToDraft =
    !isLink &&
    !userAssignated &&
    !status?.isPosted &&
    !status?.isAdvocacyParent &&
    !status?.isAdvocacy &&
    post?.lifecycleState &&
    [
      PostLifecycleState.Ready,
      PostLifecycleState.Approved,
      PostLifecycleState.Discarded,
      PostLifecycleState.PendingApproval,
      PostLifecycleState.Error,
    ].includes(post?.lifecycleState);

  const sendTo =
    !isLink &&
    !status?.isAdvocacyParent &&
    post?.brandId !== user?.brandId &&
    post?.lifecycleState &&
    [PostLifecycleState.Draft, PostLifecycleState.Error].includes(post?.lifecycleState);

  const baseSchedule = !status?.isPosted && !status?.isAdvocacyParent;

  const schedule = baseSchedule && post?.lifecycleState !== PostLifecycleState.Scheduled;

  const removeSchedule =
    baseSchedule && post?.lifecycleState === PostLifecycleState.Scheduled;

  const createAdvocacy =
    !status?.isPosted &&
    status?.isAdvocacyParent &&
    status.isNew &&
    user?.role.includes(UserRole.AdvocacyManager);

  const updateAdvocacy =
    !status?.isPosted &&
    status?.isAdvocacyParent &&
    !status.isNew &&
    user?.role.includes(UserRole.AdvocacyManager);

  const copyLink =
    !status?.isPosted && !status?.isAdvocacyParent && !status?.isNew && !isLink;

  const requestApproval =
    !isLink &&
    !status?.isPosted &&
    !status?.isAdvocacyParent &&
    !status?.isScheduled &&
    post?.lifecycleState !== PostLifecycleState.Approved;

  const deletePost =
    !status?.isAdvocacyParent &&
    post?.lifecycleState &&
    !isLink &&
    [
      PostLifecycleState.Draft,
      PostLifecycleState.Discarded,
      PostLifecycleState.Error,
    ].includes(post?.lifecycleState);

  const revertApproval =
    !status?.isAdvocacyParent &&
    post?.lifecycleState &&
    [PostLifecycleState.Approved, PostLifecycleState.Scheduled].includes(
      post?.lifecycleState,
    );

  const approve =
    !status?.isAdvocacyParent &&
    post?.lifecycleState &&
    [PostLifecycleState.Discarded, PostLifecycleState.PendingApproval].includes(
      post?.lifecycleState,
    );

  const discard =
    (isLink &&
      post?.lifecycleState &&
      [PostLifecycleState.Approved, PostLifecycleState.PendingApproval].includes(
        post?.lifecycleState,
      )) ||
    (!isLink &&
      !status?.isAdvocacyParent &&
      post?.lifecycleState &&
      [
        PostLifecycleState.Ready,
        PostLifecycleState.Approved,
        PostLifecycleState.PendingApproval,
        PostLifecycleState.Error,
      ].includes(post?.lifecycleState));

  const duplicate = !status?.isAdvocacyParent && !status?.isNew && !isLink;

  // const linkPermission = !isLink || (isLink && status?.isEditDisabled && !status.isCurrentUserEditing)

  let actionButtonText = t('Actions');
  if (status?.isPosting) actionButtonText = t('Posting...');
  else if (status?.isUnposting) actionButtonText = t('Unpublishing...');

  const menuItems: {
    onClick: () => void;
    icon?: JSX.Element;
    text: string;
    disabled?: boolean;
    infoText?: string;
  }[] = [];

  if (
    postOnLinkedin &&
    (isLink
      ? post?.lifecycleState !== PostLifecycleState.Posted
      : post?.lifecycleState !== PostLifecycleState.Posted)
  ) {
    menuItems.push({
      onClick: handleSubmitButton,
      icon: <LinkedIn sx={{ color: '#0963CB' }} fontSize="small" />,
      text: t('Post now'),
      disabled: status?.isSubmitDisabled,
      infoText: t('Publish the post now on LinkedIn'),
    });
  }

  if (schedule) {
    menuItems.push({
      onClick: handleSchedule,
      icon: <Schedule sx={{ color: 'primary.main' }} fontSize="small" />,
      text: t('Schedule'),
      disabled: !status?.isValid || status?.isSubmitDisabled,
      infoText: t('Publish post on scheduled date'),
    });
  }

  if (requestApproval) {
    menuItems.push({
      onClick: handleRequestApproval,
      icon: <SupervisorAccount fontSize="small" />,
      text: t('Request approval'),
      infoText: t('Ask the user to approve the post'),
    });
  }

  if (sendTo) {
    menuItems.push({
      onClick: handleSendToContentBoard,
      icon: <Send sx={{ color: '#8DC391' }} fontSize="small" />,
      text: t('Send'),
      disabled: !status?.isSavable,
      infoText: t('Deliver this post to the final user as ready to be posted'),
    });
  }

  if (removeSchedule) {
    menuItems.push({
      onClick: handleRemoveSchedule,
      icon: <AutoDelete sx={{ color: 'primary.main' }} fontSize="small" />,
      text: t('Remove Schedule'),
      infoText: t('Cancel schedule and continue editing'),
    });
  }

  if (revertApproval) {
    menuItems.push({
      onClick: handleRevertApproval,
      icon: <RemoveDone fontSize="small" />,
      text: t('Revert Approval'),
      infoText: t('Disapprove and notify the creator'),
    });
  }

  if (approve) {
    menuItems.push({
      onClick: handleApprove,
      icon: <DoneAll fontSize="small" />,
      text: t('Approve'),
      infoText: t('Approve and notify the creator'),
    });
  }

  if (discard) {
    menuItems.push({
      onClick: handleDiscard,
      icon: <Clear fontSize="small" />,
      text: t('Discard'),
      infoText: t('Discard this post'),
    });
  }

  if (saveAsDraft) {
    menuItems.push({
      onClick: handleSaveAsDraft,
      icon: <Save fontSize="small" />,
      text: t('Save as Draft'),
      disabled: !status?.isSavable,
      infoText: t('Save as a draft and publish later'),
    });
  }

  if (returnToDraft) {
    menuItems.push({
      onClick: handleReturnToDraft,
      icon: <Undo fontSize="small" />,
      text: t('Return to Draft'),
      infoText: t('Save as a draft and publish later'),
    });
  }

  if (duplicate) {
    menuItems.push({
      onClick: handleDuplicatePost,
      icon: (
        <FileCopy
          fontSize="small"
          sx={{
            marginLeft: { xs: 0, md: status?.isPosted ? -1 : 0 },
          }}
        />
      ),
      text: isMobile || !status?.isPosted ? t('Create copy') : '',
      infoText: t('Create and open a copy of this post'),
    });
  }

  if (copyLink) {
    menuItems.push({
      onClick: handleCopyLink,
      icon: <Link fontSize="small" />,
      text: t('Share via Link'),
    });
  }

  if (convertToAdvocacyPost) {
    menuItems.push({
      onClick: handleTurnIntoAdvocacy,
      icon: <ConnectWithoutContact fontSize="small" />,
      text: t('Create Advocacy Post'),
    });
  }

  if (createAdvocacy) {
    menuItems.push({
      onClick: handleCreateAdvocacyPost,
      text: t('Create'),
    });
  }

  if (updateAdvocacy) {
    menuItems.push({
      onClick: handleUpdateAdvocacyPost,
      text: t('Update'),
    });
  }

  menuItems.reverse();

  useEffect(() => {
    if (
      postState?.status.isCurrentUserEditing &&
      !postState.status.isEditDisabled &&
      !postState?.status.isNew &&
      !postState?.status.isSaved
    ) {
      setSavingStatus(t('Saving...'));
    } else if (socket && isSocketConnected && !postState?.status.isNew) {
      setSavingStatus(t('Your post has been saved'));
    } else if (!postState?.status.isNew && (!socket || !isSocketConnected)) {
      setSavingStatus(t('You are offline'));
    }
  }, [socket, postState, isSocketConnected, isMobile, theme.palette.grey, t]);

  return (
    <>
      <ConfirmationPostModal
        status={status}
        handlers={handlers}
        post={post}
        onClose={closeEditor}
      />

      <RequestApprovalModal
        status={status}
        handlers={handlers}
        onClose={closeEditor}
        handleCopyLink={handleCopyLink}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseActionMenu}
        sx={{
          transform: 'translateY(-10px)',
        }}
        PaperProps={{
          sx: {
            mb: 1,
            borderRadius: '8px',
            boxShadow:
              '0px 1px 5px rgba(0, 0, 0, 0.1), 0px 1px 5px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1)',
            width: { xs: '90%', md: '280px' },
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Typography variant="body2" sx={{ py: 1, px: 2 }}>
          {savingStatus}
        </Typography>
        <Divider />
        {menuItems.map((item, index) => (
          <CustomMenuItem
            key={index}
            onClick={item.onClick}
            icon={item.icon}
            text={item.text}
            disabled={item.disabled}
            textInfo={item.infoText}
          />
        ))}
      </Menu>
      {datePickerScheduleOpen && (
        <DatePicker
          date={postScheduleDefaultDate}
          open={datePickerScheduleOpen}
          maxDate={new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)}
          anchorElement={anchorEl}
          onClose={handleCloseDatepicker}
          onSubmit={handleSchedulePostSubmit}
          primaryActionTitle={datePickerScheduleOpen ? 'Schedule' : 'Save'}
          isMobile={isMobile}
        />
      )}
      <Stack
        direction={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'center' : deletePost ? 'space-between' : 'end'}
        spacing={1}
        sx={isMobile ? styleBoxMobile : {}}
      >
        {deletePost && !isMobile && (
          <Tooltip title="Delete post" placement="top">
            <Delete
              onClick={() => setDeletePostModalOpen(true)}
              sx={{
                cursor: 'pointer',
                color: '#D32F2F',
                marginY: 'auto',
              }}
            />
          </Tooltip>
        )}

        <Divider />
        <Stack
          sx={{
            paddingX: { xs: 2, sm: 0 },
            paddingBottom: { xs: 2, sm: 0 },
            paddingTop: { xs: 1, sm: 0 },
          }}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
          gap={{
            xs: 1,
            md: 1,
          }}
        >
          {menuItems.length > 0 && (
            <>
              {menuItems.length > 1 ? (
                <>
                  {deletePost && isMobile && (
                    <LoadingButton
                      onClick={() => setDeletePostModalOpen(true)}
                      variant="outlined"
                      startIcon={<Delete />}
                      loadingPosition="end"
                      fullWidth
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '168.33px',
                          border: 'none',
                          color: '#D32F2F',
                          paddingX: 0,
                          backgroundColor: '#ececec',
                          '&:hover': {
                            backgroundColor: '#d2d2d2',
                            border: 'none',
                          },
                        },
                      }}
                    >
                      <span>Delete post</span>
                    </LoadingButton>
                  )}

                  <LoadingButton
                    onClick={handleOpenActionMenu}
                    variant="linkedin"
                    endIcon={<KeyboardArrowDown />}
                    loading={status?.isPosting || status?.isUnposting || status?.isSaving}
                    loadingPosition="end"
                    disabled={
                      status?.isPosting || status?.isUnposting || status?.isSaving
                    }
                    fullWidth
                    sx={{
                      width: { xs: '100%', sm: openChat ? '143.4px' : '168.33px' },
                    }}
                  >
                    <span>{actionButtonText}</span>
                  </LoadingButton>
                </>
              ) : duplicate && !isMobile ? (
                <Tooltip title={menuItems[0].infoText} placement="top">
                  <FileCopy
                    fontSize="small"
                    onClick={menuItems[0].onClick}
                    sx={{
                      marginLeft: { xs: 0, md: status?.isPosted ? -1 : 0 },
                      cursor: 'pointer',
                      color: '#757575',
                      marginY: 'auto',
                    }}
                  />
                </Tooltip>
              ) : (
                <LoadingButton
                  onClick={menuItems[0].onClick}
                  variant="outlined"
                  endIcon={menuItems[0].icon}
                  loading={status?.isPosting || status?.isUnposting}
                  loadingPosition="end"
                  disabled={status?.isPosting || status?.isUnposting}
                  sx={{
                    ...(duplicate && {
                      border: 'none',
                      marginRight: { xs: 0, sm: -2 },
                      paddingX: 0,
                      width: 'auto',
                      textDecoration: 'underline',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: 'primary.main',
                        border: 'none',
                      },
                    }),
                  }}
                >
                  {menuItems[0].text}
                </LoadingButton>
              )}
            </>
          )}
          {unpublishLinkedinPost && !isLink && (
            <LoadingButton
              onClick={handleUnpublishLinkedin}
              variant="outlined"
              loading={status?.isUnposting}
              disabled={status?.isUnposting}
            >
              Unpublish LinkedIn post
            </LoadingButton>
          )}
          {viewOnLinkedIn && (
            <LoadingButton
              variant="linkedin"
              onClick={handleViewOnLinkedin}
              sx={{
                width: { xs: '100%', sm: openChat ? '143.4px' : '168.33px' },
                whiteSpace: 'nowrap',
              }}
            >
              View on LinkedIn
            </LoadingButton>
          )}
        </Stack>
      </Stack>
      <ConfirmationDialog
        title={t('Unpublish post from LinkedIn?')}
        content={
          <>
            {t(
              'This action will remove the post from LinkedIn and reset its status to ‘ready’. This means you will permanently lose all the current likes, comments, views and other data associated with this post.',
            )}
            <br />
            <br />
            <b>{t('Are you sure you want to continue?')}</b>
          </>
        }
        deleteButton
        onConfirm={handleUnpublishPost}
        open={unpublishModalOpen}
        onClose={handleCloseModalDelete}
        okText={t('Unpublish')}
        cancelText={t('Cancel')}
      />
      <ConfirmationDialog
        title={t('Delete post forever?')}
        content={
          <>
            {t('This action cannot be undone and will delete your post forever')}
            <br />
            <br />
            <b>{t('Do you still want to continue?')}</b>
          </>
        }
        deleteButton
        onConfirm={handleDeletePost}
        open={deletePostModalOpen}
        onClose={() => setDeletePostModalOpen(false)}
        okText={t('Delete')}
        cancelText={t('Cancel')}
      />
    </>
  );
};

export default PostActions;
