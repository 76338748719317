import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: ReactNode;
  okText?: string;
  cancelText?: string;
  deleteButton?: boolean;
  loading?: boolean;
}

const ConfirmationDialog = (props: Props) => {
  const {
    open,
    onClose,
    onConfirm,
    title,
    content,
    okText = 'Ok',
    cancelText = 'Cancel',
    deleteButton = false,
    loading = false,
  } = props;

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={() => {}}
      PaperProps={{
        sx: {
          maxWidth: '500px',
        },
      }}
    >
      <DialogTitle fontWeight={700}>
        <Box width={"90%"}>

        {title}
        </Box>
        <Close
          style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" sx={{ color: 'text.primary' }}>
          {cancelText}
        </Button>
        <LoadingButton
          variant="contained"
          color={deleteButton ? 'secondary' : 'primary'}
          onClick={onConfirm}
          loading={loading}
        >
          {okText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
