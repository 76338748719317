import { Stack, Typography } from '@mui/material';

const InputLabel = ({ label, required, colorRequired = "primary.main" }: { label: string; required?: boolean, colorRequired?: string }) => {
  const stylesFont = {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 900,
    '@media (min-width:600px)': {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 900,
    },
  };
  
  return (
    <Stack flexDirection={'row'}>
      <Typography sx={stylesFont}>
        {label}
        {required ? (
          <Typography color={colorRequired} fontSize={'25px'} lineHeight={'20px'} component={'span'}>
            {' '}*
          </Typography>
        ) : (
          <Typography fontSize={16} component={'span'}>
            {' '}(Opcional)
          </Typography>
        )}
      </Typography>
    </Stack>
  );
};

export default InputLabel;
