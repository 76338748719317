import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { MetricByWeek } from '../../__generated__/graphql';
import { formatDateMonths } from '../../utils';
import { useTranslation } from 'react-i18next';

interface Metrics {
  data: MetricByWeek[];
}

const GraphicReportCompanyUserImpressionsForWeek: FC<Metrics> = ({ data: metric }) => {
  const { t } = useTranslation();

  function generateData() {
    const sortedData = metric.slice().sort((a, b) => {
      // Ordena por startDate de manera ascendente (de menor a mayor fecha)
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });

    if (sortedData.length === 0) return [];
    if (sortedData.length > 1) sortedData.shift();

    return sortedData.map((metric) => ({
      startDate: metric.startDate,
      endDate: metric.endDate,
      Impressions: metric.numViews,
    }));
  }

  const data = generateData();

  return (
    <Box
      width="100%"
      style={{
        backgroundColor: '#F8F8F8',
        borderRadius: '10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        paddingTop: '1rem',
      }}
      marginY="2rem"
    >
      <Box marginY="1rem" marginLeft="2rem">
        <Typography fontSize="1rem" textAlign="left">
          {t('Weekly Impressions')}
        </Typography>
      </Box>
      <ResponsiveContainer width="100%" height={450}>
        <BarChart
          width={600}
          height={450}
          data={data}
          margin={{
            top: 0,
            right: 40,
            bottom: 50,
            left: 0,
          }}
        >
          <XAxis
            dataKey="startDate"
            dy={20}
            tickFormatter={(date) =>
              formatDateMonths(date).split(' ')[0] +
              ' ' +
              formatDateMonths(date).split(' ')[1]
            }
            angle={-60}
          />
          <YAxis />
          {metric.length > 1 && (
            <Tooltip
              isAnimationActive={false}
              content={({ payload }) => {
                if (!!metric.length && payload!!.length === 0) return null;

                const { startDate, endDate, Impressions } = payload!![0].payload;

                return (
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      border: '1px solid rgb(204, 204, 204)',
                      padding: '10px',
                      whiteSpace: 'nowrap',
                      margin: '0px'
                    }}>
                    <Typography variant="body1">{`${formatDateMonths(startDate)} - ${formatDateMonths(endDate)}`}</Typography>
                    <Typography variant="body1">{`${t('Impressions')}: ${Impressions}`}</Typography>

                  </Box>
                );
              }}
            />
          )}

          <Legend verticalAlign="top" align="center" layout="horizontal" />
          <Bar dataKey="Impressions" fill="#CD0061" isAnimationActive={false} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default GraphicReportCompanyUserImpressionsForWeek;
