import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Shell } from '../../__generated__/graphql';
import AvatarCell from '../Avatar/AvatarCell';

const HeaderCell = ({ text, sx = {} }: { text: string; sx?: SxProps }) => (
  <TableCell sx={{ color: 'primary.main', fontWeight: 'bold', ...sx }}>
    <Typography fontSize="14px" fontWeight="bold">
      {text}
    </Typography>
  </TableCell>
);

const Cell = ({
  text,
  sx,
  children,
}: {
  text?: string;
  sx?: SxProps;
  children?: React.ReactNode;
}) => (
  <TableCell sx={sx}>
    {children || (
      <Typography fontSize="12px" fontWeight="bold">
        {text}
      </Typography>
    )}
  </TableCell>
);

interface Props {
  shells: Shell[];
}

const ShellTable = ({ shells }: Props) => {
  const navigate = useNavigate();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <HeaderCell text="Client" />
            <HeaderCell text="Advocacy" />
            <HeaderCell text="Advanced" />
            <HeaderCell text="Corporate" />
            <HeaderCell text="Actions" sx={{ textAlign: 'center' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {shells.map((shell) => (
            <TableRow
              key={shell._id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                borderLeft: '5px solid',
                borderColor: 'primary.main',
              }}
              data-testid="company-row"
            >
              <AvatarCell avatar={shell.avatar!!}  name={shell?.name || ''} />
              <Cell text={shell.plans?.advanced?.toString()} />
              <Cell text={shell.plans?.advocacy?.toString()} />
              <Cell text={shell.plans?.corporate?.toString()} />
              <Cell sx={{ textAlign: 'center' }}>
                <IconButton
                  onClick={() => navigate(`edit/${shell._id}`, { state: { shell } })}
                  data-testid="edit-companies-button"
                >
                  <EditIcon />
                </IconButton>
              </Cell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShellTable;
