import { useEffect } from 'react';

const usePageConfig = (title: string, faviconURL: string) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    const oldHref = link.href;


    link.href = faviconURL;

    return () => {
      document.title = prevTitle;
      if (link) link.href = oldHref;
    };
  }, [title, faviconURL]);
};

export default usePageConfig;
