import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
  Zoom,
} from '@mui/material';
import {
  GoogleAuthProvider,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import { FormContainer, PasswordElement, TextFieldElement } from 'react-hook-form-mui';
import { Link, useNavigate } from 'react-router-dom';
import { UserLifecycleState, UserPlan } from '../../__generated__/graphql';
import googleLogo from '../../assets/google-logo.svg';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t, i18n } = useTranslation();
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();
  const { user, loading: loadingAuth, error } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Detecta el idioma del navegador
    const browserLanguage = window.navigator.language.split('-')[0]; // Obtiene solo el código del idioma (es, en, etc.)

    // Cambia el idioma en el componente Login, si está disponible en las traducciones
    const availableLanguages = ['en', 'es']; // Lista de idiomas soportados por tu app
    if (availableLanguages.includes(browserLanguage)) {
      i18n.changeLanguage(browserLanguage);
    } else {
      i18n.changeLanguage('en'); // Cambia a inglés si el idioma no está soportado
    }
  }, [i18n]);

  useEffect(() => {
    if (!loadingAuth && user) {
      try {
        if (
          user.lifecycleState?.includes(UserLifecycleState.Active) &&
          user.activeTrial &&
          user.trialEnd &&
          user.plan?.includes(UserPlan.Starter)
        ) {
          const trialEnd = new Date(user.trialEnd);
          const today = new Date();
          if (trialEnd < today) {
            navigate('/payment');
          }
          navigate('/');
        } else if (user.lifecycleState === UserLifecycleState.Onboarding) {
          navigate('/onboarding');
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Error during navigation:', error);
      }
    }
  }, [loadingAuth, navigate, user]);

  useEffect(() => {
    if (error) {
      setErrorMsg(error);
    }
  }, [error]);

  const handleSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    if (forgotPassword) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setSuccessMsg(t('Password reset email sent!'));
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;

          if (errorCode === 'auth/user-not-found') {
            setErrorMsg(t('No user found with this email'));
          } else {
            setErrorMsg(errorMessage);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        // Parse the error code and set the error message to be displayed to the user
        if (errorCode === 'auth/wrong-password') {
          setErrorMsg(t('The password is invalid.'));
        } else if (errorCode === 'auth/user-not-found') {
          setErrorMsg(t('No user found with this email'));
        } else {
          setErrorMsg(errorMessage);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();

    await signInWithPopup(auth, provider)
      .then(() => {
        // Navigate to the home page or dashboard after successful login
        navigate('/');
      })
      .catch((error) => {
        const message = error.message;
        setErrorMsg(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCloseSnackbar = () => {
    setErrorMsg('');
    setSuccessMsg('');
  };

  return (
    <Grid container justifyContent="center" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={12}
        md={6}
        display={{ xs: 'none', md: 'flex' }}
        bgcolor="#E2E8EF"
        overflow="hidden" // Esta propiedad es clave para que la imagen se corte
        position="relative" // Para permitir controlar la posición de la imagen
      >
        <img
          src="../images/login-image.webp"
          alt="loginImage"
          style={{
            width: '130%', // Haz que la imagen sea más grande que el contenedor
            height: 'auto', // Mantén la relación de aspecto
            objectFit: 'cover', // Ajusta la imagen dentro del contenedor, cortando los bordes
            position: 'absolute', // Permite manejar el desbordamiento
            top: '60%',
            left: '85%',
            transform: 'translate(-50%, -50%)', // Centra la imagen
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        direction="column"
        justifyContent="space-between" // Esto separa el contenido entre la parte superior y el centro
        sx={{ height: '100vh', display: 'flex' }}
      >
        <Box display="flex" justifyContent="center" marginY="25px" height={'5vh'}>
          <img src="../images/magnettu_logo_new.svg" alt="logo" />
        </Box>
        <Divider />
        <Container
          sx={{
            marginTop: 'auto', // Esto asegura que el contenido esté centrado verticalmente
            marginBottom: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <FormContainer onSuccess={handleSubmit}>
            <Grid container justifyContent="center">
              <Grid item>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{ maxWidth: '350px' }}
                >
                  <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '32px' }}>
                    {forgotPassword ? t('Forgot password') : t('Log in')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      fontSize: '18px',
                      color: 'text.secondary',
                      textAlign: 'center',
                    }}
                  >
                    {t('Unlock the potential of your personal brand!')}
                  </Typography>
                  <TextFieldElement
                    label={t('Email')}
                    name="email"
                    variant="outlined"
                    required
                    sx={{ minWidth: '100%' }}
                    type="email"
                    data-cy="email-input"
                  />

                  {forgotPassword ? (
                    <>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ minWidth: '100%' }}
                        data-cy="back-button"
                      >
                        <Typography fontWeight="600">{t('Reset Password')}</Typography>
                      </Button>
                      <Button
                        variant="outlined"
                        type="button"
                        sx={{ minWidth: '100%' }}
                        data-cy="back-button"
                        onClick={() => setForgotPassword(false)}
                      >
                        <Typography fontWeight="600">{t('Back')}</Typography>
                      </Button>
                    </>
                  ) : (
                    <>
                      <PasswordElement
                        label={t('Password')}
                        name="password"
                        autoComplete="current-password"
                        required
                        sx={{ minWidth: '100%' }}
                        data-cy="password-input"
                      />
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '14px',
                          color: 'text.secondary',
                          cursor: 'pointer',
                          ':hover': {
                            textDecoration: 'underline',
                          },
                        }}
                        onClick={() => setForgotPassword(true)}
                      >
                        {t('Forgot your password?')}
                      </Typography>
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        sx={{ minWidth: '100%' }}
                        data-cy="login-button"
                        loading={loadingAuth || loading}
                        loadingPosition="start"
                        startIcon={<></>}
                      >
                        <Typography fontWeight="600">{t('Login')}</Typography>
                      </LoadingButton>
                    </>
                  )}

                  {!forgotPassword && (
                    <>
                      <Divider sx={{ minWidth: '100%' }}>{t('Or')}</Divider>
                      <Button
                        variant="outlined"
                        onClick={handleGoogleLogin}
                        sx={{ minWidth: '100%' }}
                      >
                        <img
                          src={googleLogo}
                          alt="Google Logo"
                          style={{ width: '24px', marginRight: '8px' }}
                        />
                        <Typography fontWeight="600">
                          {t('Continue with Google')}
                        </Typography>
                      </Button>
                    </>
                  )}

                  <Typography textAlign={'center'}>
                    {t('Necesitas una cuenta')}{' '}
                    <Link
                      to="/sign-up"
                      style={{ color: '#30527C', textDecoration: 'underline' }}
                    >
                      {t('Create one')}
                    </Link>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </FormContainer>
          <Snackbar
            open={!!errorMsg}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            TransitionComponent={Zoom}
            onClose={onCloseSnackbar}
            data-cy="error-snackbar"
          >
            <Alert severity="error" sx={{ width: '100%' }} onClose={onCloseSnackbar}>
              {errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            open={!!successMsg}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            TransitionComponent={Zoom}
            onClose={onCloseSnackbar}
            data-cy="success-snackbar"
          >
            <Alert severity="success" sx={{ width: '100%' }} onClose={onCloseSnackbar}>
              {successMsg}
            </Alert>
          </Snackbar>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Login;
