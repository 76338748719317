import { Masonry as MuiMasonry } from '@mui/lab';
import { ArticleType, Insight } from '../../__generated__/graphql';
import InsightCard from '../InsightComponents/InsightCard';
import InsightLinkedInCard from '../InsightComponents/InsightLinkedInCard';
import MasonryComponent from './MasonryComponent';

interface Props {
  insights: Insight[];
  onInsightOpen: (index: number) => void;
  setDisabledButtonBack: (value: boolean) => void;
  setDisabledButtonNext: (value: boolean) => void;
}

const MasonryInsights = ({
  insights,
  onInsightOpen = () => {},
  setDisabledButtonBack,
  setDisabledButtonNext,
}: Props) => {
  
  const handleReviewButton = (index: number) => {
    onInsightOpen(index);
    setDisabledButtonBack(index === 0);
    setDisabledButtonNext(index === insights.length - 1);
  };

  return (
    <>
      <MasonryComponent
      >
        {insights.map((insight, index) =>
          insight && insight.type === ArticleType.Linkedin ? (
            <InsightLinkedInCard
              key={insight?._id}
              insight={insight}
              handleReviewButton={() => handleReviewButton(index)}
            />
          ) : (
            <InsightCard
              key={insight?._id}
              insight={insight!}
              handleReviewButton={() => handleReviewButton(index)}
            />
          ),
        )}
      </MasonryComponent>
    </>
  );
};

export default MasonryInsights;
