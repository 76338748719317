import { KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { Brand } from '../../__generated__/graphql';
import AvatarNavBar from '../Avatar/AvatarNavBar';

export const ChangeContentBoardButton = ({
  variant,
  onClick,
  anchorElement,
  brand,
}: {
  variant: 'compact' | 'standard';
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  anchorElement: null | HTMLElement;
  brand: Brand | null;
}) => {
  const nameButton = () => {
    return `${brand?.name.toUpperCase()}`;
  };

  if (variant === 'standard' || !brand) {
    return (
      <Button
        endIcon={variant === 'standard' && <KeyboardArrowRight />}
        variant="outlined"
        fullWidth
        sx={{
          width: '100%',
          backgroundColor: anchorElement ? '#FFD8EB' : '#FFF',
          height: variant === 'standard' ? '35px' : undefined,
          borderRadius: '25px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={onClick}
      >
        {brand && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AvatarNavBar
              name={brand?.name || ''}
              backgroundColor={brand?.backgroundColor}
              avatar={brand?.account?.avatar || undefined}
              size={25}
            />
          </Box>
        )}

<Typography fontSize="10px" fontWeight={700} sx={{ textAlign: 'center', wordWrap: 'break-word', whiteSpace: 'normal' }}>
  {brand ? nameButton() : 'SELECT BRAND'}
</Typography>
      </Button>
    );
  }

  return (
    <ListItemButton sx={{ borderRadius: '9px' }} onClick={onClick}>
      <ListItemIcon sx={{ marginLeft: '6px' }}>
        <AvatarNavBar
          name={brand?.name || ''}
          backgroundColor={brand?.backgroundColor}
          avatar={brand?.account?.avatar || undefined}
          size={25}
        />
      </ListItemIcon>
    </ListItemButton>
  );
};
