import { useQuery } from '@apollo/client';
import { Close, KeyboardArrowRight, Undo } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PrompterRunLifecycleState, RunsFilter } from '../../__generated__/graphql';
import { GET_BRANDS, GET_CHAINS, GET_SHELLS, GET_USERS } from '../../graphql/queries';
import { getFullName } from '../../utils';
import Avatar from '../Avatar/Avatar';

interface Props {
  filter: RunsFilter;
  setFilter: (filter: RunsFilter) => void;
  setPage: (page: number) => void;
  onClose: () => void;
  open: boolean;
}

enum FilterTab {
  'Status',
  'Brands',
  'Chain',
  'Date',
  'Created',
  'Client',
}

export const PrompterRunsFilter = ({
  filter,
  setPage,
  setFilter,
  open,
  onClose,
}: Props) => {
  const [tab, setTab] = useState<FilterTab>(FilterTab.Status);
  const [nameFilter, setNameFilter] = useState<string>('');

  const { data: dataBrands, loading: loadingBrands } = useQuery(GET_BRANDS, {
    variables: {
      allBrands: true,
    },
    skip: tab !== FilterTab.Brands,
  });
  const { data: usersData } = useQuery(GET_USERS, { skip: tab !== FilterTab.Created });
  const { data: shellsData } = useQuery(GET_SHELLS, { skip: tab !== FilterTab.Client });
  const { data: chainsData } = useQuery(GET_CHAINS, { skip: tab !== FilterTab.Chain });

  const FilterTabKeys = Object.keys(FilterTab)
    .filter((value) => !isNaN(Number(value)))
    .map((value) => FilterTab[value as keyof typeof FilterTab]);

  const { control, handleSubmit, reset, getValues, setValue, watch } =
    useForm<RunsFilter>({
      defaultValues: {
        lifecycleState: filter.lifecycleState || null,
        brandIds: filter.brandIds || [],
        dateFrom: filter.dateFrom || '',
        dateTo: filter.dateTo || '',
        createdById: filter.createdById || null,
        shellId: filter.shellId || null,
        chainId: filter.chainId || null,
      },
      values: {
        lifecycleState: filter.lifecycleState || null,
        brandIds: filter.brandIds || [],
        dateFrom: filter.dateFrom || '',
        dateTo: filter.dateTo || '',
        createdById: filter.createdById || null,
        shellId: filter.shellId || null,
        chainId: filter.chainId || null,
      },
    });

  const onResetFilters = () => {
    setPage(0);
    setFilter({
      lifecycleState: null,
      brandIds: [],
      dateFrom: '',
      dateTo: '',
      createdById: null,
      shellId: null,
      chainId: null,
    });

    reset({
      lifecycleState: null,
      brandIds: [],
      dateFrom: '',
      dateTo: '',
      createdById: null,
      shellId: null,
      chainId: null,
    });
  };

  // Form
  const watchLifecycleState = watch('lifecycleState');
  const watchBrandIds = !!(watch('brandIds') || []).length;
  const watchDateFrom = !!watch('dateFrom');
  const watchDateTo = !!watch('dateTo');
  const watchClient = watch('shellId');
  const watchCreatedById = watch('createdById');
  const watchChainId = watch('chainId');

  const onSuccess: SubmitHandler<RunsFilter> = (input) => {
    setPage(0);
    setFilter(input);
    onClose();
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value);
  };

  const filteredBrands =
    dataBrands?.getBrands.filter((brand) =>
      brand!.name.toLowerCase().includes(nameFilter.toLowerCase()),
    ) || [];

  const filteredUsers =
    usersData?.users?.filter((user) =>
      getFullName(user).toLowerCase().includes(nameFilter.toLowerCase()),
    ) || [];

  const filteredShells =
    shellsData?.shells.filter((shell) =>
      shell?.name.toLowerCase().includes(nameFilter.toLowerCase()),
    ) || [];

  const chains = chainsData?.chains || [];

  const activeFilters = [
    filter.lifecycleState || watchLifecycleState,
    filter.brandIds?.length || watchBrandIds,
    filter.chainId || watchChainId,
    filter.dateFrom || filter.dateTo || watchDateFrom || watchDateTo,
    filter.createdById || watchCreatedById,
    filter.shellId || watchClient,
  ];

  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { width: '700px', maxWidth: '700px' } }}
      onClose={() => {
        onClose();
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '15px 15px 0 0',
          marginBottom: -1,
          cursor: 'pointer',
        }}
      >
        <IconButton onClick={() => onClose()}>
          <Close />
        </IconButton>
      </Box>
      <Stack direction="row" sx={{ width: 'auto', p: 3, pt: 0 }} spacing={2}>
        <Stack direction="column" spacing={1}>
          <Typography
            fontWeight="bold"
            sx={{
              color: 'rgba(194, 11, 99, 1)',
              marginY: '0.5rem',
            }}
          >
            Filters
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="column" gap={1}>
              {FilterTabKeys.map((key, i) => {
                return (
                  <Button
                    key={key}
                    variant="outlined"
                    onClick={() => {
                      setNameFilter('');
                      setTab(i);
                    }}
                    sx={{
                      width: '220px',
                      justifyContent: 'space-between',
                      color: tab === i ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.56)',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      backgroundColor: (() => {
                        if (tab === i) return 'rgba(0, 0, 0, 0.04)';
                        return activeFilters[i] ? '#FFD8EB' : 'white';
                      })(),
                    }}
                  >
                    <Typography fontWeight={tab === i ? 700 : 'normal'}>{key}</Typography>
                    <KeyboardArrowRight
                      sx={{
                        color: 'rgba(0, 0, 0, 0.56)',
                      }}
                    />
                  </Button>
                );
              })}
              <Button
                variant="outlined"
                color="primary"
                onClick={onResetFilters}
                disabled={!activeFilters.some((filter) => !!filter)}
                data-testid="reset-filters-button"
              >
                <Undo sx={{ marginRight: '10px' }} />
                <Typography fontWeight="bold">Reset filter</Typography>
              </Button>
            </Stack>

            <Divider
              orientation="vertical"
              sx={{ backgroundColor: 'rgba(0, 0, 0, 0.23)' }}
            />
          </Stack>
        </Stack>
        <form onSubmit={handleSubmit(onSuccess)} style={{ width: '100%' }}>
          <Stack direction="column" flex={1}>
            {tab === FilterTab.Status && (
              <List sx={{ maxHeight: 400, width: '100%', overflow: 'auto', mt: 4 }}>
                {Object.values(PrompterRunLifecycleState).map((state, i) => (
                  <Controller
                    key={state}
                    name="lifecycleState"
                    control={control}
                    render={({ field }) => (
                      <ListItemButton
                        key={state}
                        onClick={() => {
                          field.onChange(state);
                        }}
                        selected={
                          watchLifecycleState
                            ? watchLifecycleState === state
                            : filter.lifecycleState === state
                        }
                      >
                        <Typography>{state}</Typography>
                      </ListItemButton>
                    )}
                  />
                ))}
              </List>
            )}
            {tab === FilterTab.Date && (
              <Stack flexDirection="column" spacing={2} pt={3}>
                <Controller
                  name="dateFrom"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Start Date"
                      {...field}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
                <Controller
                  name="dateTo"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="End Date"
                      {...field}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Stack>
            )}
            {tab === FilterTab.Brands && (
              <>
                <TextField
                  placeholder="Search"
                  onChange={onSearch}
                  InputProps={{
                    style: {
                      height: '40px',
                      borderRadius: '8px',
                      width: '320px',
                      backgroundColor: 'rgba(244, 244, 244, 1)',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                <Divider
                  sx={{
                    marginY: '10px',
                  }}
                />
                <Box maxHeight={400} overflow="auto">
                  <Box display="flex" justifyContent="center">
                    {loadingBrands && <CircularProgress />}
                  </Box>
                  {filteredBrands.map((brand) => (
                    <Controller
                      key={brand._id}
                      name="brandIds"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <MenuItem
                          sx={{ display: 'flex', justifyContent: 'space-between' }}
                          onClick={() => {
                            const currentIndex = value?.indexOf(brand._id);
                            const newValue = [...(value || [])]; // Creates a copy of the current value

                            if (currentIndex === -1) {
                              newValue.push(brand._id); // Agrega si no existe
                            } else {
                              newValue.splice(currentIndex!, 1); // Elimina si existe
                            }

                            onChange(newValue); // Updates with the new array
                          }}
                        >
                          <Stack flexDirection="row" gap={2} alignItems="center">
                            <Avatar
                              avatar={brand.account?.avatar || ''}
                              name={brand.name}
                            />
                            <Typography>
                              {brand.name.length > 25
                                ? `${brand.name.slice(0, 25)}...`
                                : brand.name}
                            </Typography>
                          </Stack>
                          <Checkbox
                            sx={{ marginRight: '5px' }}
                            checked={value ? value.includes(brand._id) : false}
                          />
                        </MenuItem>
                      )}
                    />
                  ))}
                </Box>
              </>
            )}
            {tab === FilterTab.Client && (
              <>
                <TextField
                  placeholder="Search"
                  onChange={onSearch}
                  InputProps={{
                    style: {
                      height: '40px',
                      borderRadius: '8px',
                      width: '320px',
                      backgroundColor: 'rgba(244, 244, 244, 1)',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                <Divider
                  sx={{
                    marginY: '10px',
                  }}
                />
                <Box maxHeight={400} overflow="auto">
                  {filteredShells.map((shell) => (
                    <Controller
                      key={shell?._id}
                      name="shellId"
                      control={control}
                      render={({ field }) => (
                        <MenuItem
                          selected={
                            watchClient
                              ? watchClient === shell?._id
                              : filter.shellId === shell?._id
                          }
                          sx={{ display: 'flex', justifyContent: 'space-between' }}
                          onClick={() => field.onChange(shell?._id)}
                        >
                          <Stack flexDirection="row" gap={2} alignItems="center">
                            <Avatar
                              avatar={shell?.avatar || ''}
                              name={shell?.name || ''}
                            />
                            <Typography>{shell?.name}</Typography>
                          </Stack>
                        </MenuItem>
                      )}
                    />
                  ))}
                </Box>
              </>
            )}
            {tab === FilterTab.Created && (
              <>
                <TextField
                  placeholder="Search"
                  onChange={onSearch}
                  InputProps={{
                    style: {
                      height: '40px',
                      borderRadius: '8px',
                      width: '320px',
                      backgroundColor: 'rgba(244, 244, 244, 1)',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                <Divider
                  sx={{
                    marginY: '10px',
                  }}
                />
                <Box maxHeight={400} overflow="auto">
                  {filteredUsers.map((user) => (
                    <Controller
                      key={user._id}
                      name="createdById"
                      control={control}
                      render={({ field }) => (
                        <MenuItem
                          selected={
                            watchCreatedById
                              ? watchCreatedById === user._id
                              : filter.createdById === user._id
                          }
                          sx={{ display: 'flex', justifyContent: 'space-between' }}
                          onClick={() => field.onChange(user._id)}
                        >
                          <Stack flexDirection="row" gap={2} alignItems="center">
                            <Avatar avatar={user.avatar || ''} name={getFullName(user)} />
                            <Typography>{getFullName(user)}</Typography>
                          </Stack>
                        </MenuItem>
                      )}
                    />
                  ))}
                </Box>
              </>
            )}
            {tab === FilterTab.Chain && (
              <List sx={{ maxHeight: 400, width: '100%', overflow: 'auto', mt: 4 }}>
                {chains.map((chain) => (
                  <Controller
                    key={chain._id}
                    name="chainId"
                    control={control}
                    render={({ field }) => (
                      <ListItemButton
                        onClick={() => {
                          field.onChange(chain._id);
                        }}
                        selected={
                          watchChainId
                            ? watchChainId === chain._id
                            : filter.chainId === chain._id
                        }
                      >
                        <Typography>{chain.name}</Typography>
                      </ListItemButton>
                    )}
                  />
                ))}
              </List>
            )}
          </Stack>
        </form>
      </Stack>

      <Stack
        flexDirection="row"
        justifyContent="flex-end"
        gap={1}
        padding="0 1rem 1rem 1rem"
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onClose()}
          data-testid="done-filters-button"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSuccess)}
          data-testid="done-filters-button"
        >
          Done
        </Button>
      </Stack>
    </Dialog>
  );
};
