import { createContext, ReactNode, useState } from 'react';

interface SnackcarContextType {
  successMessage: string | null;
  warningMessage: string | null;
  errorMessage: string | null;
  setSuccessMessage: (message: string | null) => void;
  setWarningMessage: (message: string | null) => void;
  setErrorMessage: (message: string | null) => void;
}

const initialState: SnackcarContextType = {
  successMessage: '',
  warningMessage: '',
  errorMessage: '',
  setSuccessMessage: () => {},
  setWarningMessage: () => {},
  setErrorMessage: () => {},
};

export const SnackbarContext = createContext<SnackcarContextType>(initialState);

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  return (
    <SnackbarContext.Provider
      value={{ successMessage, warningMessage, errorMessage, setSuccessMessage, setWarningMessage, setErrorMessage }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
