import { Card } from '@mui/material';
import BrandForm from './BrandForm';
import { Brand } from '../../__generated__/graphql';
interface Props {
  brandSelected: Brand;
}

const BrandEditor = ({ brandSelected }: Props) => {
  return (
    <Card
      sx={{
        borderRadius: '20px',
        padding: '20px',
        width: '100%',
      }}
    >
      <BrandForm brandSelected={brandSelected} key={brandSelected?._id} isBrandPage isModal={false} />
    </Card>
  );
};

export default BrandEditor;
