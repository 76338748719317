import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ChainsTable from '../../components/Tables/ChainsTable';
import { DeviceContext } from '../../context/DeviceContext';
import { GET_CHAINS_WITH_STATS } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';

const ChainsTab = () => {
  const { isMobile } = useContext(DeviceContext);
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_CHAINS_WITH_STATS, {
    fetchPolicy: 'network-only',
  });
  const { t } = useTranslation();

  if (loading) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <>
      <Stack direction="column" spacing={1} alignItems={isMobile ? 'center' : 'start'}>
        <Stack direction="row" width="100%" textAlign="center">
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            flex={1}
            gap={1}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/content-manager/new-chain')}
            >
              <AddIcon sx={{ marginRight: '10px' }} />
              <Typography fontWeight="bold">{t('New Chain')}</Typography>
            </Button>
          </Stack>
        </Stack>
        <ChainsTable chains={data?.chains || []} />
      </Stack>
    </>
  );
};

export default ChainsTab;
