import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Chain } from '../../__generated__/graphql';
import { timeAgo } from '../../utils';
import { useTranslation } from 'react-i18next';

const HeaderCell = ({ text, sx = {} }: { text: string; sx?: SxProps }) => (
  <TableCell sx={{ color: 'primary.main', fontWeight: 'bold', ...sx }}>
    <Typography fontSize="14px" fontWeight="bold">
      {text}
    </Typography>
  </TableCell>
);

const Cell = ({
  text,
  sx,
  children,
}: {
  text?: string;
  sx?: SxProps;
  children?: React.ReactNode;
}) => (
  <TableCell sx={sx}>
    {children || (
      <Typography fontSize="12px" fontWeight="bold">
        {text}
      </Typography>
    )}
  </TableCell>
);

interface Props {
  chains: Chain[];
}

const ChainsTable = ({ chains }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <HeaderCell text="Chain" />
            <HeaderCell text="Last Update" sx={{ textAlign: 'center' }} />
            <HeaderCell
              text={`Wins A (Since last update)`}
              sx={{ minWidth: '150px', textAlign: 'center' }}
            />
            <HeaderCell
              text="Wins B (Since last update)"
              sx={{ minWidth: '150px', textAlign: 'center' }}
            />
            <HeaderCell text="Actions" sx={{ textAlign: 'center' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {chains.map((chain) => {
            const total =
              (chain.winsASinceLastUpdate || 0) + (chain.winsBSinceLastUpdate || 0);
            const percentageA = chain.winsASinceLastUpdate
              ? (chain.winsASinceLastUpdate / total) * 100
              : 0;
            const percentageB = chain.winsBSinceLastUpdate
              ? (chain.winsBSinceLastUpdate / total) * 100
              : 0;

            return (
              <TableRow
                key={chain._id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  borderLeft: '5px solid',
                  borderColor: 'primary.main',
                }}
              >
                <Cell sx={{ maxWidth: '300px' }}>
                  <Typography fontWeight="bold">{chain?.name}</Typography>
                  <Typography variant="body2">{chain?.description}</Typography>
                </Cell>
                <Cell
                  text={`${timeAgo(new Date(chain.updatedOn), t)} ${t('ago')}`}
                  sx={{ textAlign: 'center' }}
                ></Cell>
                <Cell
                  text={
                    chain.chainB.length
                      ? `${chain?.winsASinceLastUpdate} (${percentageA.toFixed(0)}%)`
                      : '-'
                  }
                  sx={{ textAlign: 'center' }}
                />
                <Cell
                  text={
                    chain.chainB.length
                      ? `${chain?.winsBSinceLastUpdate} (${percentageB.toFixed(0)}%)`
                      : '-'
                  }
                  sx={{ textAlign: 'center' }}
                />
                <Cell sx={{ textAlign: 'center' }}>
                  <IconButton
                    onClick={() =>
                      navigate(`/content-manager/chains/${chain?._id}`, {
                        state: { chain },
                      })
                    }
                  >
                    <EditIcon />
                  </IconButton>
                </Cell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ChainsTable;
