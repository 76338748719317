import { CheckCircle } from '@mui/icons-material';
import { Dialog, Box, Typography, DialogActions, Button } from '@mui/material';
import React from 'react';

interface InviteMemberSUccessProps {
  emailsSent: boolean;
  setEmailsSent: React.Dispatch<React.SetStateAction<boolean>>;
  noSendEmail: boolean;
}

const InviteMemberSuccess: React.FC<InviteMemberSUccessProps> = ({
  emailsSent,
  setEmailsSent,
  noSendEmail,
}) => {
  return (
    <Dialog
      open={emailsSent}
      onClose={() => setEmailsSent(false)}
      disableEscapeKeyDown
      maxWidth={false}
      PaperProps={{
        sx: {
          borderRadius: '10px',
          width: { xs: '100%', sm: '700px' },
          maxHeight: { xs: '100vh', sm: 'calc(100vh - 64px)' },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
        }}
      >
        <CheckCircle sx={{ color: 'hsla(122, 39%, 49%, 1)', fontSize: 60 }} />
        <Typography fontWeight={700} fontSize={23}>
          You have successfully added the new member
        </Typography>
        <Typography fontSize={19} marginY={1}>
          {noSendEmail
            ? 'Member has been added to the team.'
            : 'Invitations remain pending until they join magnettu.'}
        </Typography>

        <DialogActions sx={{ p: '0px 24px 20px 24px' }}>
          <Button
            onClick={() => {
              setEmailsSent(false);
            }}
            variant={'contained'}
            sx={{
              width: '325px',
            }}
          >
            Accept
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default InviteMemberSuccess;
