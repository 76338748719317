import React, { useContext, useState } from 'react';
import { Box, Typography, Button, Stack, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Check, Extension, LinkedIn, OpenInNew, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DeviceContext } from '../context/DeviceContext';

const ContentLayout = ({
  children,
  showConnectAccount = false,
  showDownloadExtension = false,
}: {
  children: React.ReactNode;
  showConnectAccount?: boolean;
  showDownloadExtension?: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showExtension, setShowExtension] = useState(true);
  const { isMobile } = useContext(DeviceContext);

  const handleCloseExtension = () => {
    setShowExtension(false);
  };

  return (
    <Box>
      {/* Barra de información en la parte superior */}
      {showConnectAccount && (
        <Box
          sx={{
            backgroundColor: '#E2E8EF', // Cambia al color de fondo que desees
            padding: '16px',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: showDownloadExtension ? '1px solid #30527C1F' : 'none',
          }}
        >
          <Stack flexDirection="row">
            <LinkedIn sx={{ color: '#30527C', marginRight: '8px' }} fontSize="large" />
            <Stack>
              <Typography fontWeight={700} fontSize={15}>
                {t('Connect your linkedin account')}
              </Typography>
              <Typography fontSize={14}>{t('Link Linkedin')}</Typography>
            </Stack>
          </Stack>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate('/settings');
            }}
            sx={{ fontWeight: 700, marginTop: { xs: '8px', md: '0px' } }}
          >
            {t('Link linkedin in settings')}
          </Button>
        </Box>
      )}
      {showDownloadExtension && showExtension && (
        <Box
          sx={{
            backgroundColor: '#E2E8EF',
            padding: '16px',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative', // Make this Box relative for the Close button
          }}
        >
          {/* Close button for the Download modal only */}
          <Button
            onClick={handleCloseExtension}
            sx={{
              position: 'absolute',
              top: 10,
              right: 8,
              minWidth: 0,
              padding: '8px',
            }}
          >
            <Close />
          </Button>

          <Stack flexDirection="row">
            <Extension sx={{ color: '#6C6C6C', marginRight: '8px' }} fontSize="large" />
            <Stack>
              <Typography fontWeight={700} fontSize={15}>
                {t('Download magnettu’s Extension and activate your analytics')}
              </Typography>
              {isMobile ? (
                <Typography fontSize={14}>
                  {t('Solo se puede instalar en ordenador')}
                </Typography>
              ) : (
                <Stack gap={0.5}>
                  <Stack flexDirection="row" alignItems="center">
                    <Check />
                    <Typography fontSize={14}>
                      {t('Connect your LinkedIn profile to magnettu here ')}
                      <Link
                        onClick={() => navigate('/settings')}
                        sx={{ fontWeight: 'bold', cursor: 'pointer', color: '#30527C' }}
                      >
                        {t('(go to settings)')}
                      </Link>
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    <Check />
                    <Typography fontSize={14}>
                      {t(
                        'Add your LinkedIn profile URL in your account settings essential information',
                      )}
                      <b> {t('(It’s already added)')}</b>
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    <Check />
                    <Typography fontSize={14}>
                      {t('Download Magnettu’s Analytics Chrome Extension')}
                      <b> {t('(skip this if it’s already installed)')} </b>
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    <Check />
                    <Typography fontSize={14}>
                      {t('Visit LinkedIn, then refresh this page')}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Button
            variant="outlined"
            color="primary"
            sx={{ fontWeight: 700, marginTop: { xs: '8px', md: '0px' } }}
            onClick={() => {
              isMobile
                ? handleCloseExtension()
                : window.open(
                    'https://chromewebstore.google.com/detail/magnett%C3%BC-for-linkedin/gbhgeemkcacibokmfjoimkgohejgehap',
                    '_blank',
                  );
            }}
          >
            {isMobile ? t('Close') : t('Get extension')}
            {!isMobile && <OpenInNew sx={{ marginLeft: '8px' }} />}
          </Button>
        </Box>
      )}

      <Box>{children}</Box>
    </Box>
  );
};

export default ContentLayout;
