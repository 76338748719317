import { useQuery } from '@apollo/client';
import { FilterAlt, Queue, Refresh } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderByDirection, OrderByField } from '../../__generated__/graphql';
import RunsTable from '../../components/Tables/RunsTable';
import { DeviceContext } from '../../context/DeviceContext';
import { RunsFilterContext } from '../../context/RunsFilterContext';
import { GET_RUNS } from '../../graphql/queries';
import { OrderBy } from '../../types';
import { PrompterRunsFilter } from '../../components/Filters/PrompterRunsFilter';
import { useTranslation } from 'react-i18next';

const PrompterRunsTab = () => {
  const { isMobile } = useContext(DeviceContext);
  const { filter, setFilter, filterActive } = useContext(RunsFilterContext);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [loading, setLoading] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<OrderBy>({
    field: OrderByField.CreatedOn,
    direction: OrderByDirection.Desc,
  });
  const navigate = useNavigate();
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  const {
    data: dataRuns,
    refetch,
    loading: loadingQuery,
  } = useQuery(GET_RUNS, {
    nextFetchPolicy: 'network-only',
    variables: { filter, orderBy, page, limit },
    onCompleted: () => setLoading(false),
  });

  const runs = dataRuns?.runs.runs || [];
  const total = dataRuns?.runs.total;

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const { t } = useTranslation();

  return (
    <>
      <PrompterRunsFilter
        filter={filter}
        setFilter={setFilter}
        setPage={setPage}
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
      />
      <Stack direction="column" spacing={1} alignItems={isMobile ? 'center' : 'start'}>
        <Stack direction="row" width="100%" textAlign="center">
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            flex={1}
            gap={1}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setFiltersOpen(true)}
              data-testid="filter-button"
              sx={{
                backgroundColor: filterActive ? '#FFD8EB' : 'white',
              }}
            >
              <FilterAlt sx={{ marginRight: '10px' }} />
              <Typography fontWeight="bold">{t('Filter')}</Typography>
            </Button>
            <LoadingButton
              variant="outlined"
              color="primary"
              startIcon={<Refresh />}
              onClick={() => {
                setLoading(true);
                refetch({ filter, orderBy, page, limit }).finally(() => {
                  setLoading(false);
                });
              }}
              data-testid="new-post-button"
              loading={loading || loadingQuery}
            >
              <Typography fontWeight="bold">{t('Refresh')}</Typography>
            </LoadingButton>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => navigate('/content-manager/prompter')}
              data-testid="new-post-button"
            >
              <Typography fontWeight="bold">{t('New prompt')}</Typography>
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Queue />}
              onClick={() => navigate('/content-manager/batch-prompter')}
              data-testid="new-post-button"
            >
              <Typography fontWeight="bold">{t('Run prompts in batch')}</Typography>
            </Button>
          </Stack>
        </Stack>
        {!loadingQuery && (
          <RunsTable
            runs={runs}
            total={total}
            limit={limit}
            setLimit={setLimit}
            page={page}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            onPageChange={onPageChange}
          ></RunsTable>
        )}
      </Stack>
    </>
  );
};

export default PrompterRunsTab;
