import { Box, Grid, Stack } from '@mui/material';
import MuiSlider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { Control, Controller } from 'react-hook-form';

interface SliderProps {
  name: string;
  label: string;
  emoji: string;
  control: Control;
  required?: boolean;
  color?: string;
  disabled? : boolean;
}

const Slider = ({
  emoji,
  name,
  control,
  label,
  required = false,
  color = 'primary.main',
  disabled = false
}: SliderProps) => {
  const marks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <Stack direction={'row'} gap={'24px'}>
          <Typography lineHeight={'24px'} fontSize={16}>
            {emoji}
          </Typography>
          <Typography fontWeight={400} lineHeight={'24px'} fontSize={16}>
            {label}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Box pl={'40px'} pr={'25px'}>
          <Controller
            name={name}
            control={control}
            rules={{ required }}
            render={({ field }) => (
              <MuiSlider
                aria-label={label}
                {...field}
                valueLabelDisplay="auto"
                step={10}
                sx={{
                  color: color,
                }}
                min={0}
                marks={marks}
                max={100}
                disabled={disabled}
              />
            )}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Slider;
