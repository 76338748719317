import { Typography } from '@mui/material';

const FormTitle = ({ title, color = "#C20B63" }: { title: string, color?: string }) => {
  const fontStyles = {
    color: color,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    marginBottom: '16px',
    '@media (min-width:600px)': {
      fontSize: '26px',
      marginBottom: '32px',
    },
  };
  return <Typography sx={fontStyles}>{title}</Typography>;
};

export default FormTitle;
