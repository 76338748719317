import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostAdvocacyParent, PostType } from '../../__generated__/graphql';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import PostEditorModal from '../../components/PostComponents/PostEditorModal';
import AdvocacyPostsTable from '../../components/Tables/AdvocacyPostsTable';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { PostProvider } from '../../context/PostContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { WebSocketProvider } from '../../context/WebSocketContext';
import { DELETE_ADVOCACY_POST } from '../../graphql/mutations';
import { GET_ADVOCACY_PARENT_POSTS } from '../../graphql/queries';

const AdvocacyManager = () => {
  const { isMobile } = useContext(DeviceContext);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [deleteAdvocacyPost] = useMutation(DELETE_ADVOCACY_POST);
  const [openedPostId, setOpenedPost] = useState<string | null>(null);
  const [openNewPost, setOpenNewPost] = useState(false);
  const [deletePost, setDeletePost] = useState<{
    postId: string;
    anyPosted: boolean;
  } | null>(null);

  const brandSelected = user?.brandSelected;

  const { data } = useQuery(GET_ADVOCACY_PARENT_POSTS, {
    fetchPolicy: 'network-only',
  });

  const posts = (data?.advocacyParentPosts as PostAdvocacyParent[]) || [];

  const handlePostEditorClose = () => {
    setOpenNewPost(false);
    setOpenedPost(null);
  };

  const handleDeletePost = (postId: string) => {
    deleteAdvocacyPost({
      variables: {
        postId,
      },
      onCompleted: () => {
        setSuccessMessage(t('Post deleted successfully'));
        setDeletePost(null);
      },
      onError: () => {
        setErrorMessage(t('Error deleting post. Please try again later.'));
        setDeletePost(null);
      },
      update: (cache) => {
        cache.evict({ id: `Post:${postId}` });
        cache.gc();
      },
    });
  };

  let deleteConfirmationContent = t(
    'Are you sure you want to delete these posts? This action cannot be undone.',
  );

  if (deletePost?.anyPosted) {
    deleteConfirmationContent = t(
      'Only the posts that have not been posted will be deleted. Are you sure you want to proceed?',
    );
  }

  return (
    <>
      <ConfirmationDialog
        title={t('Delete Post')}
        content={deleteConfirmationContent}
        onConfirm={() => handleDeletePost(deletePost?.postId!)}
        open={!!deletePost}
        onClose={() => setDeletePost(null)}
        okText={t('Delete')}
        cancelText={t('Cancel')}
      />
      {(!!openedPostId || openNewPost) && (
        <WebSocketProvider postId={openedPostId}>
          <PostProvider
            postId={openedPostId}
            newPostOptions={{
              type: PostType.AdvocacyParent,
              brandId: brandSelected?._id,
              brand: brandSelected,
            }}
          >
            <PostEditorModal onClose={handlePostEditorClose} />
          </PostProvider>
        </WebSocketProvider>
      )}
      <Box bgcolor="hsla(0, 0%, 96%, 1)" height="auto" minHeight="100vh">
        <Stack direction="column" p={4} alignItems={isMobile ? 'center' : 'start'}>
          <Stack direction="column" width="100%" marginBottom="10px">
            <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
              {t('Advocacy')}
            </Typography>
            <Typography variant={'body1'}>
              {t(
                'Create engaging job posts and enable the whole team to share them on LinkedIn in just a few clicks',
              )}{' '}
              {/* Traducción aquí */}
            </Typography>
          </Stack>
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={12}>
              <Card
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: '17px 25px',
                  borderRadius: '8px',
                  width: '100%',
                  boxShadow: '0px 0px 3.6700000762939453px 0px #0000001A',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenNewPost(true)}
                  data-testid="new-post-button"
                  disabled={!!user?.activeTrial && new Date(user.trialEnd) < new Date()}
                  startIcon={<AddIcon />}
                >
                  <Typography fontWeight="bold" noWrap>
                    {t('New Post')}
                  </Typography>
                </Button>
              </Card>
            </Grid>
            <Grid item xs={12} sx={{ height: '100%' }}>
              <AdvocacyPostsTable
                posts={posts}
                onEditPost={(postId) => setOpenedPost(postId)}
                onDeletePost={(postId, anyPosted) => setDeletePost({ postId, anyPosted })}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

export default AdvocacyManager;
