import { createContext, useContext, useState } from 'react';

const FormContext = createContext<any>(null);

export const useFormContext = () => {
  return useContext(FormContext);
};

export const FormProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});

  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);
  const updateFormData = (data: any) => {
    setFormData((prev) => ({ ...prev, ...data }));
  };

  const resetForm = () => {
    setCurrentStep(0);
    setFormData({});
  }

  return (
    <FormContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        nextStep,
        prevStep,
        formData,
        updateFormData,
        resetForm,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
