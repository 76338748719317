import { createContext, ReactNode, useEffect, useState } from 'react';
import { IdeasFilter } from '../__generated__/graphql';

interface IdeasFilterContextType {
  filter: IdeasFilter;
  setFilter: (filter: IdeasFilter) => void;
  filterActive: boolean;
}

const initialState: IdeasFilterContextType = {
  filter: {},
  setFilter: () => {},
  filterActive: false,
};

export const IdeasFilterContext = createContext<IdeasFilterContextType>(initialState);

export const IdeasFilterProvider = ({ children }: { children: ReactNode }) => {
  const storedStateRaw = JSON.parse(localStorage.getItem('ideasFilters') || '{}');
  const storedState = Object.keys(storedStateRaw).reduce<Partial<IdeasFilter>>(
    (acc, key) => {
      if (key in initialState.filter) {
        acc[key as keyof IdeasFilter] = storedStateRaw[key];
      }
      return acc;
    },
    {},
  );
  const defaultState = { ...initialState.filter, ...storedState };
  const [filter, setFilter] = useState<IdeasFilter>(defaultState);

  const filterActive =
    filter.dateFrom ||
    filter.dateTo ||
    filter.brandIds?.length ||
    filter.shellId

    useEffect(() => {
      localStorage.setItem('ideasFilters', JSON.stringify(filter));
    }, [filter]);

  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem('ideasFilters') || '');
    if (filter) {
      setFilter(filter);
    }
  }, []);

  return (
    <IdeasFilterContext.Provider value={{ filter, setFilter, filterActive }}>
      {children}
    </IdeasFilterContext.Provider>
  );
};
