import { gql, useMutation } from '@apollo/client';
import { /* Box, */ Button, /* Chip, */ Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import {
  FormContainer,
  SwitchElement,
  TextFieldElement /* MultiSelectElement */,
} from 'react-hook-form-mui';
import { useLocation, useNavigate } from 'react-router-dom';
import { Shell } from '../../__generated__/graphql';
import { DeviceContext } from '../../context/DeviceContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { CREATE_SHELL, UPDATE_SHELL } from '../../graphql/mutations';
import { /* nameById, */ onlyNumbersInput } from '../../utils';
import Avatar from '../../components/Avatar/Avatar';
import UploadAvatarButton from '../../components/Avatar/UploadAvatarButton';
import UploadLogoButton from '../../components/Logo/UploadLogoButton';
import UploadIconButton from '../../components/IconComponents/UploadIconButton';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
//import { useGetAllCompanies } from '../../services/getAllCompanies';
const inputStyle = {
  maxWidth: '500px',
};

const CreateShell = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { state } = useLocation();
  const shell: Shell = state?.shell;

  const { isMobile } = useContext(DeviceContext);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);

  const [avatar, setAvatar] = useState<string | null>(shell?.avatar || null);
  const [logo, setLogo] = useState<string | null>(shell?.logo || null);
  const [icon, setIcon] = useState<string | null>(shell?.icon || null);
  const [whiteLabel, setWhiteLabel] = useState<boolean>(shell?.whiteLabel || false);
  const [noSendEmail, setNoSendEmail] = useState<boolean>(shell?.noSendEmail || false);

  const [createShell, { loading: loadingCreate }] = useMutation(CREATE_SHELL, {
    onCompleted: () => {
      setSuccessMessage(t('Client created successfully'));
      navigate('/clients');
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
    update: (cache, { data: createShell }) => {
      cache.modify({
        fields: {
          shells(existingShells = []) {
            const newShellRef = cache.writeFragment({
              data: createShell?.createShell.shell,
              fragment: gql`
                fragment NewShell on Shell {
                  _id
                }
              `,
            });
            return [newShellRef, ...existingShells];
          },
        },
      });
    },
  });
  const [updateShell, { loading: loadingUpdate }] = useMutation(UPDATE_SHELL, {
    onCompleted: () => {
      setSuccessMessage(t('Client updated successfully'));
      navigate('/clients');
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
  });

  const loading = loadingCreate || loadingUpdate;

  const onSuccess = async ({
    name,
    title,
    companies,
    advanced,
    corporate,
    advocacy,
    whiteLabel,
    noSendEmail,
  }: {
    name: string;
    title: string;
    companies: string[];
    advanced: number;
    corporate: number;
    advocacy: number;
    whiteLabel: boolean;
    noSendEmail: boolean;
  }) => {
    const input = {
      name,
      companies: companies,
      advanced: Number(advanced),
      corporate: Number(corporate),
      advocacy: Number(advocacy),
      avatar,
      logo,
      icon,
      whiteLabel,
      title,
      noSendEmail: whiteLabel ? noSendEmail : false,
    };

    shell
      ? await updateShell({ variables: { shellId: shell._id, input } })
      : await createShell({ variables: { input } });
  };

  return (
    <>
      <Stack
        direction="column"
        p={isMobile ? '40px 30px 80px 30px' : '40px 25%'}
        spacing={4}
        alignItems={isMobile ? 'center' : 'start'}
      >
        <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
          {shell ? t('Edit') : t('New')} {t('Client')}
        </Typography>
        <FormContainer
          onSuccess={onSuccess}
          defaultValues={{
            name: shell?.name || '',
            title: shell?.title || '',
            whiteLabel: shell?.whiteLabel || false,
            advanced: shell?.plans?.advanced || 0,
            corporate: shell?.plans?.corporate || 0,
            advocacy: shell?.plans?.advocacy || 0,
            noSendEmail: shell?.noSendEmail || false,
          }}
          FormProps={{ style: { width: '100%' } }}
        >
          <Stack sx={{ width: '100%' }} direction="column" spacing={2}>
            <TextFieldElement
              label={t('Name')}
              name="name"
              required
              sx={inputStyle}
              data-testid="new-shell-name-input"
            />
            <TextFieldElement
              label={t('Advocacy')}
              name="advocacy"
              type="number"
              inputProps={{ min: 0 }}
              onKeyPress={onlyNumbersInput}
              required
              sx={inputStyle}
              data-testid="new-shell-advocacy-input"
            />
            <TextFieldElement
              label={t('Advanced')}
              name="advanced"
              type="number"
              inputProps={{ min: 0 }}
              required
              sx={inputStyle}
              onKeyPress={onlyNumbersInput}
              data-testid="new-shell-advanced-input"
            />
            <TextFieldElement
              label={t('Corporate')}
              name="corporate"
              type="number"
              required
              inputProps={{ min: 0 }}
              onKeyPress={onlyNumbersInput}
              sx={inputStyle}
              data-testid="new-shell-corporate-input"
            />
            {avatar ? (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={inputStyle}
              >
                <Avatar name="profile" avatar={avatar} size={64} />
                <Button
                  variant="outlined"
                  component="label"
                  sx={{ ml: 2 }}
                  onClick={() => setAvatar(null)}
                >
                  {t('Remove avatar')}
                </Button>
              </Stack>
            ) : (
              <UploadAvatarButton
                setAvatar={setAvatar}
                setErrorMessage={setErrorMessage}
              />
            )}
            <SwitchElement
              name="whiteLabel"
              label={t('White Label')}
              value={whiteLabel}
              onClick={() => setWhiteLabel(!whiteLabel)}
              data-testid="whiteLabel-switch"
            />
            {whiteLabel && (
              <>
                <TextFieldElement
                  label={t('Title')}
                  name="title"
                  required
                  sx={inputStyle}
                  data-testid="new-shell-title-input"
                />

                {logo ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={inputStyle}
                  >
                    <img src={logo} alt="logo_shell" width="130px" />
                    <Button
                      variant="outlined"
                      component="label"
                      sx={{ ml: 2 }}
                      onClick={() => setLogo(null)}
                    >
                      {t('Remove logo')}
                    </Button>
                  </Stack>
                ) : (
                  <UploadLogoButton setLogo={setLogo} setErrorMessage={setErrorMessage} />
                )}

                {icon ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={inputStyle}
                  >
                    <img src={icon} alt="icon_shell" width="130px" />
                    <Button
                      variant="outlined"
                      component="label"
                      sx={{ ml: 2 }}
                      onClick={() => setIcon(null)}
                    >
                      {t('Remove icon')}
                    </Button>
                  </Stack>
                ) : (
                  <UploadIconButton setIcon={setIcon} setErrorMessage={setErrorMessage} />
                )}
                <SwitchElement
                  name="noSendEmail"
                  label={t('Do not send email invite to new members')}
                  value={noSendEmail}
                  onClick={() => setNoSendEmail(!noSendEmail)}
                  data-testid="whiteLabel-switch"
                />
              </>
            )}

            <LoadingButton
              loading={loading}
              variant="contained"
              type="submit"
              sx={inputStyle}
              data-testid="new-company-save-button"
            >
              {t('Save')}
            </LoadingButton>
          </Stack>
        </FormContainer>
      </Stack>
    </>
  );
};

export default CreateShell;
