import { Add, Close } from '@mui/icons-material';
import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  IconButton,
  Popper,
  Stack,
  Typography,
} from '@mui/material';

interface Props {
  open: boolean;
  anchorElement: HTMLButtonElement | null;
  onClose: () => void;
  topics: string[];
  onTopicsChange: (topics: string[]) => void;
}

const TopicSelectorModal = (props: Props) => {
  const { open, anchorElement, onClose, topics, onTopicsChange } = props;

  const topicSuggestions = [
    'technology',
    'business',
    'health',
    'entertainment',
    'sports',
    'science',
    'politics',
    'education',
    'environment',
    'economy',
  ];

  const containerStyles = {
    bgcolor: 'background.paper',
    border: '1px',
    borderRadius: '4px',
    boxShadow: '0 0 10px rgba(0,0,0,0.2)',
    height: 'auto',
    minHeight: '100px',
    minWidth: '300px',
    paddingY: '25px',
    paddingX: '20px',
    marginTop: '10px',
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorElement}
      sx={{ zIndex: 'modal' }}
      placement="bottom-end"
    >
      <ClickAwayListener
        onClickAway={() => {
          onClose?.();
        }}
      >
        <Box sx={containerStyles}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              onClick={() => {
                onClose?.();
              }}
              sx={{
                position: 'absolute',
                padding: '0px',
                '&:hover': {
                  backgroundColor: 'inherit',
                  cursor: 'pointer',
                },
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Stack
            direction={'row'}
            divider={<Divider orientation="vertical" flexItem />}
            gap={2}
          >
            <Stack direction={'column'}>
              <Typography fontSize="14px" my={1}>
                Topic suggestions
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '10px',
                  marginTop: '10px',
                  maxWidth: '300px',
                }}
              >
                {topicSuggestions
                  ?.filter((topic) => !topics.includes(topic))
                  .slice(0, 5)
                  .map((topic, index) => (
                    <Chip
                      key={index}
                      sx={{
                        background: 'white',
                        height: '30px',
                        border: '1px solid rgba(255, 0, 122, 1)',
                        padding: '0px 5px',
                        '& .MuiChip-label': {
                          color: 'rgba(255, 0, 122, 1)',
                          background: 'white',
                        },
                        '& .MuiChip-deleteIcon': {
                          color: 'rgba(255, 0, 122, 1)',
                        },
                        '&:hover': {
                          backgroundColor: 'white',
                        },
                      }}
                      onClick={() => {
                        onTopicsChange([...topics, topic]);
                      }}
                      deleteIcon={<Add></Add>}
                      label={topic.startsWith('#') ? topic : `#${topic}`}
                      onDelete={() => {
                        onTopicsChange([...topics, topic]);
                      }}
                    />
                  ))}
              </Box>
            </Stack>
            <Stack direction={'column'}>
              <Typography fontSize="14px" my={1}>
                My inspirational topics
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '10px',
                  marginTop: '10px',
                  maxWidth: '300px',
                  minWidth: '200px',
                }}
              >
                {topics?.map((topic, index) => (
                  <Chip
                    key={index}
                    sx={{
                      background: 'white',
                      height: '30px',
                      border: '1px solid rgba(255, 0, 122, 1)',
                      padding: '0px 5px',
                      '& .MuiChip-label': {
                        color: 'rgba(255, 0, 122, 1)',
                        background: 'white',
                      },
                      '& .MuiChip-deleteIcon': {
                        color: 'rgba(255, 0, 122, 1)',
                      },
                    }}
                    label={topic.startsWith('#') ? topic : `#${topic}`}
                    onDelete={() => {
                      onTopicsChange(topics.filter((t) => t === topic));
                    }}
                  />
                ))}
              </Box>
            </Stack>
          </Stack>
          {topics.length > 4 && (
            <Typography fontSize="14px" marginTop="10px" textAlign="center">
              You reached the 5 topics limit
            </Typography>
          )}
        </Box>
      </ClickAwayListener>
    </Popper>
  );
};

export default TopicSelectorModal;
