import { useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Stack,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import {
  Brand,
  Chat,
  PromptWinner,
  PrompterRun,
  PrompterRunLifecycleState,
} from '../../__generated__/graphql';
import { SnackbarContext } from '../../context/SnackbarContext';
import { SELECT_WINNER_CHAIN } from '../../graphql/mutations';
import { GET_CHAINS, GET_RUN } from '../../graphql/queries';
import ChatComponent from './Chat';
import PromptContextForm from './PromptContextForm';

interface PostFormProps {
  brand: Brand;
  retry: () => void;
  run: PrompterRun;
  loading: boolean;
  onBackClick: () => void;
}

enum Steps {
  'Idea',
  'Prompts',
  'Results',
  'Winner',
  'User post',
}

const PrompterResult = ({ brand, run, loading, retry, onBackClick }: PostFormProps) => {
  const navigate = useNavigate();

  const { setErrorMessage } = useContext(SnackbarContext);
  const [activeStep, setActiveStep] = useState(Steps.Results);

  const handleCreatePost = (post: string) =>
    navigate('/content-manager/new-post', {
      state: { body: post, brand, prompterRunId: run._id, ideaId: run.ideaId },
    });

  const { data: chainsData } = useQuery(GET_CHAINS, {
    skip: activeStep !== Steps.Idea,
    fetchPolicy: 'network-only',
  });

  const [selectWinner] = useMutation(SELECT_WINNER_CHAIN, {
    onError: (err) => setErrorMessage('Winner could not be saved: ' + err.message),
    refetchQueries: [GET_RUN],
  });

  const { control, handleSubmit, register } = useForm<{ chainA: Chat[]; chainB: Chat[] }>(
    {
      defaultValues: {
        chainA: run.chainA as Chat[],
        chainB: run.chainB as Chat[],
      },
    },
  );

  const chainAField = useFieldArray({ control, name: 'chainA' });
  const chainBField = useFieldArray({ control, name: 'chainB' });

  const inputStyle = {
    width: '100%',
    maxWidth: '900px',
  };

  const mapChainField = (id: string, chainField: any, name: string) => (
    <Stack key={name} direction={'column'} width={'100%'}>
      {chainField.fields.map((field: any, index: number) => (
        <ChatComponent
          key={field.id}
          chat={field}
          register={register}
          control={control}
          chainId={id as 'chainA' | 'chainB'}
          index={index}
          move={chainField.move}
          remove={chainField.remove}
          disabled={true}
          disableMoveDown={index === chainField.fields.length - 1}
        />
      ))}
    </Stack>
  );

  const handleChangeStep = (step: Steps) => {
    if (step === Steps['User post']) {
      navigate(`/content-manager/edit-post/${run.postId}`);
    }

    if (completed[step]) {
      setActiveStep(step);
    }
  };

  const completed = {
    [Steps.Idea]: true,
    [Steps.Prompts]: true,
    [Steps.Results]: true,
    [Steps.Winner]: !!run.winner,
    [Steps['User post']]: !!run.postId,
  };

  return (
    <Stack sx={{ width: '100%' }} alignItems={'center'} direction="column" spacing={2}>
      <Stack direction="row" spacing={2} width={'50%'}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          nonLinear
          sx={{
            '& .MuiStepConnector-line': { borderColor: 'primary.main' },
            width: '100%',
          }}
        >
          {Object.keys(Steps)
            .filter((key) => typeof Steps[key as keyof typeof Steps] === 'number')
            .map((label, index) => (
              <Step key={label} completed={completed[Steps[label as keyof typeof Steps]]}>
                <StepButton
                  disableRipple
                  onClick={() => handleChangeStep(Steps[label as keyof typeof Steps])}
                >
                  <Typography
                    color={
                      activeStep === Steps[label as keyof typeof Steps]
                        ? 'primary.main'
                        : 'inherit'
                    }
                  >
                    {`${index + 1}. ${label}`}
                  </Typography>
                </StepButton>
              </Step>
            ))}
        </Stepper>
      </Stack>

      {activeStep === Steps.Idea && (
        <>
          <Autocomplete
            getOptionLabel={(run) => run?.name}
            options={[run?.brand!]}
            value={run?.brand}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            onChange={() => {}}
            disabled
            sx={inputStyle}
            renderInput={(params) => <TextField {...params} label="Brands" />}
          />
          <PromptContextForm
            loading={loading || run.lifecycleState !== PrompterRunLifecycleState.Finished}
            chains={chainsData?.chains || []}
            idea={{
              _id: run.ideaId,
              content: run.topicContext!,
              brandId: run.brandId,
              brandName: run.brandName,
              chainId: run.chainId,
              chainName: run.chainName!,
              shellId: run.brand?.shellId!,
              priority: 0
            }}
            direction="column"
          />
        </>
      )}

      {activeStep === Steps.Prompts && (
        <form
          onSubmit={handleSubmit(() => {})}
          style={{ display: 'flex', width: '100%' }}
        >
          <Stack direction={'row'} spacing={2} width={'100%'}>
            {[
              { id: 'chainA', chainField: chainAField, name: 'Chain A' },
              ...(run.chainB
                ? [{ id: 'chainB', chainField: chainBField, name: 'Chain B' }]
                : []),
            ].map(({ id, chainField, name }) => mapChainField(id, chainField, name))}
          </Stack>
        </form>
      )}

      {activeStep === Steps.Results && (
        <FormContainer
          values={run}
          FormProps={{ style: { width: '100%', maxWidth: '1500px' } }}
        >
          <Stack direction={'row'} spacing={2} width={'100%'}>
            {[
              {
                name: 'postA',
                post: run.postA,
              },
              ...(run.chainB
                ? [
                    {
                      name: 'postB',
                      post: run.postB,
                    },
                  ]
                : []),
            ]
              .sort(() => (run.random || 0) - 0.5)
              .map(({ name, post }) => (
                <Stack key={name} direction={'column'} spacing={2} width={'100%'}>
                  <TextFieldElement
                    key={name + 'post'}
                    label="Post"
                    name={name}
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                    }}
                    multiline
                    minRows={20}
                    value={post}
                    disabled={loading}
                    inputProps={{ maxLength: 3000 }}
                    // On disabled font color inherit
                    sx={{ '& textarea': { resize: 'both' } }}
                  />
                  <LoadingButton
                    variant="contained"
                    disabled={loading}
                    onClick={() => {
                      selectWinner({
                        variables: {
                          runId: run._id,
                          winner: name === 'postA' ? PromptWinner.A : PromptWinner.B,
                        },
                      });

                      setActiveStep((curr) => curr + 1);
                    }}
                  >
                    Select as a winner
                  </LoadingButton>
                </Stack>
              ))}
          </Stack>
        </FormContainer>
      )}

      {activeStep === Steps.Winner && (
        <Stack direction={'column'} spacing={2} width={'100%'}>
          <TextField
            label="Winner"
            type="text"
            InputLabelProps={{ shrink: true }}
            multiline
            minRows={20}
            value={run.winner === PromptWinner.A ? run.postA : run.postB}
            InputProps={{
              readOnly: true,
            }}
            inputProps={{ maxLength: 3000 }}
            sx={{
              '& textarea': { resize: 'both' },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'currentColor',
                color: 'currentColor',
              },
            }}
          />
          <LoadingButton
            variant="contained"
            disabled={loading}
            onClick={() =>
              handleCreatePost(run[`post${run.winner as PromptWinner}`] || '')
            }
          >
            Create Post
          </LoadingButton>
        </Stack>
      )}
    </Stack>
  );
};

export default PrompterResult;
