import { useQuery } from '@apollo/client';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Chain,
  PrompterRun,
  PrompterRunLifecycleState,
} from '../../__generated__/graphql';
import PromptContextForm from '../../components/Prompter/PromptContextForm';
import PrompterResult from '../../components/Prompter/PrompterResult';
import { GET_CHAINS, GET_RUN } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';

const inputStyle = {
  width: '100%',
  maxWidth: '900px',
};

const RunDetail = () => {
  const { t } = useTranslation();
  const { runId } = useParams<{ runId: string }>();
  const [showContext, setShowContext] = useState(false);

  const {
    data: runData,
    loading: runLoading,
    error: runError,
    refetch: refetchRun,
  } = useQuery(GET_RUN, {
    variables: { runId: runId || '' },
    skip: !runId,
    fetchPolicy: 'network-only',
  });

  const { data: chainsData } = useQuery(GET_CHAINS, {
    fetchPolicy: 'network-only',
  });

  const { run } = runData || {};

  return (
    <Stack direction="column" p={2} spacing={4} alignItems={'center'}>
      {runLoading && <Typography>{t('Loading...')}</Typography>}
      {runError && (
        <Typography>
          {t('Error')}: {runError.message}
        </Typography>
      )}
      {((runData && runData.run?.lifecycleState !== PrompterRunLifecycleState.Finished) ||
        (runData && showContext)) && (
        <>
          <Typography variant="h4">{t('Prompter')}</Typography>
          <Autocomplete
            getOptionLabel={(run) => run?.name}
            options={[run?.brand!]}
            value={run?.brand}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            onChange={() => {}}
            disabled
            sx={inputStyle}
            renderInput={(params) => <TextField {...params} label={t('Brands')} />}
          />
          <PromptContextForm
            loading={runData!.run?.lifecycleState !== PrompterRunLifecycleState.Finished}
            chains={chainsData?.chains as Chain[]}
            idea={{
              _id: run?.ideaId,
              content: run?.topicContext!,
              brandId: run?.brandId || '',
              brandName: run?.brandName || '',
              chainId: run?.chainId || '',
              chainName: run?.chainName!,
              shellId: run?.brand?.shellId!,
              priority: 0,
            }}
          />
        </>
      )}
      {runData &&
        runData.run?.lifecycleState === PrompterRunLifecycleState.Finished &&
        !showContext && (
          <PrompterResult
            brand={run?.brand!}
            run={run as PrompterRun}
            loading={false}
            retry={() => refetchRun()}
            onBackClick={() => setShowContext(true)}
          />
        )}
    </Stack>
  );
};

export default RunDetail;
