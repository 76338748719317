import Masonry from 'react-masonry-css';
import './masonry.css';

interface Props {
  children: React.ReactNode;
}

const breakpointColumnsObj = {
  default: 5,
  2000: 4,
  1600: 3,
  900: 2,
  700: 1,
};

const MasonryComponent = ({ children }: Props) => {
  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {children}
    </Masonry>
  );
};

export default MasonryComponent;
