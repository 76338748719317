import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import { useFormContext } from '../../contexts/FormContext';
import { useMutation } from '@apollo/client';
import { CREATE_JOB_DESCRIPTION } from '../../../graphql/mutations';
import { JobDescriptionInput } from '../../../__generated__/graphql';
import { Alert } from '@mui/material';


const FormSteps = () => {
  const { currentStep, updateFormData, nextStep } = useFormContext();
  const [sent, setSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [createDescription, { loading, error }] = useMutation(
    CREATE_JOB_DESCRIPTION,
  );

  const handlePartialSubmit = (data: JobDescriptionInput) => {
    updateFormData(data);
    nextStep();
  };

  const handleSubmit = (data: JobDescriptionInput) => {
    createDescription({
      variables: {
        input: {
          ...data,
        },
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
      onCompleted: () => {
        setSent(true);
      },
    });
  };

  const defaultValues = {
    company: '',
    title: '',
    requirements: '',
    location: '',
    modality: '',
    benefits: '',
    companyDescription: '',
    jobDescription: '',
    jobLevel: '',
    language: 'Español',
    tone: {
      friendly: 0,
      personal: 0,
      formal: 0,
      professional: 0,
      motivating: 0,
      creative: 0,
    },
    emojis: false,
    negativesWords: '',
    name: '',
    surname: '',
    email: '',
  };

  const steps = [
    <Step1 onSubmit={handlePartialSubmit} defaultValues={defaultValues} />,
    <Step2 onSubmit={handlePartialSubmit} />,
    <Step3 onSubmit={handlePartialSubmit} />,
    <Step4 onSubmit={handleSubmit} loading={loading} />,
  ];

  const submitFeedback = (
    <Alert
      sx={{ borderRadius: '8px', backgroundColor: '#4CAF50' }}
      variant="filled"
      severity={error ? 'error' : 'success'}
    >
      {error
        ? `Ha ocurrido un error. ${errorMessage}`
        : 'Estamos generando tu oferta personalizada. En unos minutos la recibirás en tu email.'}
    </Alert>
  );

  return <>{!sent ? steps[currentStep] : submitFeedback}</>;
};

export default FormSteps;

