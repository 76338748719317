import { TextField as MuiTextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';
import { Control, Controller } from 'react-hook-form';
import InputLabel from './InputLabel';

interface TextFieldProps {
  control: Control<any>;
  name: string;
  label?: string;
  inputLabel?: string;
  type?: 'number' | 'string';
  required?: boolean;
  rest?: any;
  multiline?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  rules?: any;
  borderColor? : string;
}

const TextField = ({
  control,
  name,
  label,
  multiline,
  required = false,
  placeholder,
  autoFocus,
  type,
  inputLabel,
  disabled = false,
  rules = {},
  borderColor = 'primary.main'
}: TextFieldProps) => {
  const mediaQuery = useMediaQuery('(min-width:600px)');

  return (
    <Stack display={'flex'} width={'100%'} gap={'32px'}>
      {label && <InputLabel required={required} label={label} colorRequired={borderColor} />}
      <Controller
        name={name}
        control={control}
        rules={{ required, ...rules }}
        render={({ field }) => (
          <MuiTextField
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderRadius: '8px',
              },
              '&.Mui-focused fieldset': {
                borderColor: borderColor,
              },
            },
          }}
            type={type === 'number' ? 'number' : 'text'}
            multiline={multiline}
            rows={multiline ? 4 : 1}
            size={!mediaQuery ? 'small' : 'medium'}
            autoFocus={autoFocus}
            placeholder={placeholder}
            label={placeholder ? undefined : (inputLabel || label)}
            disabled={disabled}
            {...field}
          />
        )}
      />
    </Stack>
  );
};

export default TextField;
