import { Stack } from '@mui/system';
import Layout from './Layout';
import { useMediaQuery } from '@mui/material';
import FormSteps from '../../lead-magnets/forms/job-descriptions/FormSteps';
import FormStepper from '../../lead-magnets/components/FormStepper';
import FormTitle from '../../lead-magnets/components/FormTitle';
import { useFormContext } from '../../lead-magnets/contexts/FormContext';
import { useTranslation } from 'react-i18next';

const JobDescription = () => {
  const { t } = useTranslation();
  const { currentStep } = useFormContext();

  const stepsForm = [
    {
      label: t('Información esencial'),
      description: t('Información esencial'),
    },
    {
      label: t('Información opcional'),
      description: t('Información opcional'),
    },
    {
      label: t('Detalles complementarios'),
      description: t('Detalles complementarios'),
    },
    {
      label: t('Información'),
      description: t('Información'),
    },
  ];
  const mediaQuery = useMediaQuery('(max-width:600px)');
  return (
    <Stack
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
      minHeight={'100%'}
      padding={mediaQuery ? '0px' : '40px'}
    >
      <Stack
        width={'100%'}
        mt={2}
        pb={4}
        overflow={'auto'}
        justifyContent={'center'}
        alignItems={'center'}
        display={'flex'}
      >
        <Layout
          stepper={<FormStepper steps={stepsForm} currentStep={currentStep} />}
          forms={
            <Stack
              mt={mediaQuery ? 2.4 : 0}
              height={'100%'}
              display={'flex'}
              width={'100%'}
            >
              <FormTitle title={`${currentStep + 1}. ${stepsForm[currentStep].label}`} />
              <FormSteps />
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};

export default JobDescription;
