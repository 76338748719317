import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import MembersTable from '../../components/Tables/MembersTable';
import { DeviceContext } from '../../context/DeviceContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import {
  INACTIVE_USER,
  INVITE_USERS,
  SEND_INVITE,
  UPDATE_MEMBER,
} from '../../graphql/mutations';
import { GET_USERS_BY_COMPANY } from '../../graphql/queries';
import { getFullName } from '../../utils';
import { useFieldArray, useForm } from 'react-hook-form';
import { BrandContext } from '../../context/BrandContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { User, UserRole } from '../../__generated__/graphql';
import InviteNewMemberModal from '../../components/MembersComponents/InviteNewMemberModal';
import InviteMemberSuccess from '../../components/MembersComponents/InviteMemberSuccess';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

const GroupsMembers = () => {
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceContext);
  const { dataBrands, refetch } = useContext(BrandContext);
  const { user } = useContext(AuthContext);
  const [nameFilter, setNameFilter] = useState<string>('');
  const [openNewMemberModal, setOpenNewMemberModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const { setErrorMessage, setSuccessMessage } = useContext(SnackbarContext);
  const [emailsSent, setEmailsSent] = useState(false);
  const [step, setStep] = useState(0);
  const [optionSelected, setOptionSelected] = useState<'member' | 'manager'>('member');
  const [emailsExist, setEmailsExist] = useState<string[]>([]);
  const [sendInvite, { loading: loadingInvitation }] = useMutation(SEND_INVITE);
  const [updateMember, { loading: loadingUpdateMember }] = useMutation(UPDATE_MEMBER);
  const [showErrors, setShowErrors] = useState(false);

  const schema = yup.object().shape({
    emails: yup
      .array()
      .of(
        yup
          .string()
          .email(t('Must be a valid email'))
          .required(t('This field is required'))
          .test('email-exists', t('This email already exists'), function (value) {
            return !emailsExist.includes(value || '');
          }),
      )
      .required(t('Must have at least one email')),
    brands: yup
      .array()
      .of(yup.array().of(yup.string().required(t('This field is required'))))
      .min(1, t('Must select at least one brand'))
      .required(t('This field is required')),
  });

  const [inactiveUser, { loading: loadingDeleteUser }] = useMutation(INACTIVE_USER);
  const [inviteUsers, { loading: loadingInviteUsers }] = useMutation(INVITE_USERS);
  const { data } = useQuery(GET_USERS_BY_COMPANY, {
    variables: {
      filter: {
        deleted: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleSendInvite = async (idSelected: string, brandManager: boolean) => {
    let success = false;
    try {
      await sendInvite({
        variables: {
          userId: idSelected,
          brandManager: brandManager,
        },
        onCompleted: (data) => {
          if (data.sendInvite.success) {
            setSuccessMessage(t('Invite resent successfully'));

            success = true;
            return success;
          }
          setErrorMessage(data.sendInvite.message || t('Error sending invite'));
        },
        refetchQueries: [GET_USERS_BY_COMPANY],
      });
    } catch (error) {
      console.error(error);
    }

    return success;
  };

  const handleEditMember = async (
    memberSelected: User,
    brands: string[],
    roleSelected: UserRole | null,
  ) => {
    let success = false;

    await updateMember({
      variables: {
        userId: memberSelected._id,
        role: roleSelected || null,
        brands: roleSelected ? brands : [],
      },
      onCompleted: (data) => {
        if (data.updateMember!.success) {
          setSuccessMessage(
            data.updateMember!.message || t('Updated member successfully'),
          );
          success = true;
          refetch();
        } else {
          setErrorMessage(data.updateMember!.message || t('Error updating member'));
        }
      },
      refetchQueries: [GET_USERS_BY_COMPANY],
    });

    return success;
  };

  const users = data?.colleagueUsers || [];
  const filteredUsers = users.filter((user) => {
    const nameEmail = (getFullName(user) + user.email).toLowerCase();

    return nameEmail.includes(nameFilter.toLowerCase());
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      emails: [],
      brands: [[]],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails' as never,
  });

  const { remove: removeBrands, append: appendBrands } = useFieldArray({
    control,
    name: 'brands',
  });

  const onSubmit = (data: any) => {
    setShowErrors(true);

    const usersDetails = data.emails.map((email: string, index: number) => ({
      email,
      ...(optionSelected === 'manager' && {
        role:
          data.brands[index]?.length === dataBrands.length
            ? UserRole.BrandManager
            : UserRole.LimitedBrandManager,
        brands: data.brands[index] || [],
      }),
    }));

    inviteUsers({
      variables: {
        input: {
          usersDetails: usersDetails,
        },
      },
      onCompleted: (data) => {
        if (!data.inviteUsers?.success && data.inviteUsers?.emailsExist?.length) {
          setErrorMessage(data.inviteUsers?.message || t('Error inviting users'));
          setEmailsExist(data.inviteUsers.emailsExist as string[]);

          return;
        }
        handleCloseModal();
        setEmailsSent(true);
      },
      onError: (error) => {
        setErrorMessage(error.message || t('Error inviting users'));
      },
      refetchQueries: [GET_USERS_BY_COMPANY],
    });
  };

  const handleDeleteUser = () => {
    if (!selectedUserId) return null;
    setOpenDeleteModal(false);

    inactiveUser({
      variables: {
        userId: selectedUserId,
      },
      onCompleted: (data) => {
        if (data.inactiveUser?.success) {
          setSuccessMessage(data.inactiveUser?.message || t('User deleted successfully'));
          setSelectedUserId(null);
          return;
        }

        setErrorMessage(data.inactiveUser?.message || t('Error deleting user'));
      },
      refetchQueries: [GET_USERS_BY_COMPANY],
    });
  };

  const handleOpenModal = () => {
    setOpenNewMemberModal(true);
    append('');
    appendBrands([]);
  };

  const handleCloseModal = () => {
    setShowErrors(false);
    setStep(0);
    setOptionSelected('manager');
    setOpenNewMemberModal(false);
    reset();
    setEmailsExist([]);
  };

  useEffect(() => {
    if (emailsExist.length) {
      setShowErrors(true);
      trigger('emails');
    }
  }, [emailsExist, trigger]);

  const handleModalDelete = (userId: string) => {
    if (loadingDeleteUser) return;

    setOpenDeleteModal(true);
    setSelectedUserId(userId);
  };

  const handleCloseModalDelete = () => {
    setSelectedUserId(null);
    setOpenDeleteModal(false);
  };

  const handleAppend = async () => {
    const values = getValues();
    const lastIndex = values.emails.length - 1;

    const isValid = await trigger(`emails.${lastIndex}`);

    if (isValid) {
      append(''); // Appends an empty string to emails

      // Create a copy of the current brands array and append an empty array
      const updatedBrands = [...values.brands, []];
      setValue('brands', updatedBrands);

      setShowErrors(false);
      return;
    }
    setShowErrors(true);
  };

  const handleRemove = (index: number) => {
    const values = getValues();

    remove(index);
    removeBrands(index);

    // Ensure brands is updated to reflect the removal
    const updatedBrands = values.brands.filter((_, i) => i !== index);
    setValue('brands', updatedBrands);

    if (values.emails.length === 0) {
      append('');
      appendBrands([]);
    }
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value);
  };

  return (
    <>
      <Box>
        <Stack direction="column" p={4} alignItems={isMobile ? 'center' : 'start'}>
          <Stack direction="row" width="100%" textAlign="center" marginBottom="10px">
            <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
              {t('Members')}
            </Typography>
          </Stack>
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={12}>
              <Card
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '17px 25px',
                  borderRadius: '8px',
                  width: '100%',
                  boxShadow: '0px 0px 3.6700000762939453px 0px #0000001A',
                }}
              >
                <TextField
                  placeholder={t('Search by name or email')}
                  onChange={onSearch}
                  InputProps={{
                    style: {
                      height: '40px',
                      borderRadius: '8px',
                      width: '320px',
                      backgroundColor: 'rgba(244, 244, 244, 1)',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                <Button
                  variant="contained"
                  color="primary"
                  data-testid="new-user-button"
                  sx={{ width: '250px' }}
                  onClick={handleOpenModal}
                >
                  <AddIcon sx={{ marginRight: '10px' }} />
                  <Typography fontWeight="bold">{t('Invite new member')}</Typography>
                </Button>
              </Card>
            </Grid>
            <Grid item xs={12} sx={{ height: '100%' }}>
              <MembersTable
                filteredMembers={filteredUsers}
                handleModalDelete={handleModalDelete}
                handleSendInvite={handleSendInvite}
                loading={loadingInvitation}
                handleEditMember={handleEditMember}
                loadingUpdateMember={loadingUpdateMember}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <InviteNewMemberModal
        openModal={openNewMemberModal}
        handleCloseModal={handleCloseModal}
        onSubmit={onSubmit}
        fields={fields}
        handleRemove={handleRemove}
        control={control}
        showErrors={showErrors}
        errors={errors}
        loading={loadingInviteUsers}
        dataBrands={dataBrands}
        step={step}
        setStep={setStep}
        optionSelected={optionSelected}
        setOptionSelected={setOptionSelected}
        handleAppend={handleAppend}
        handleSubmit={handleSubmit}
        setShowErrors={setShowErrors}
      />

      <InviteMemberSuccess
        emailsSent={emailsSent}
        setEmailsSent={setEmailsSent}
        noSendEmail={user?.shell?.noSendEmail || false}
      />
      <ConfirmationDialog
        title={`${t('Delete')} ${getFullName(
          users.find((user) => user._id === selectedUserId)!,
        )}`}
        content={t(
          'Are you sure you want to delete this member? This action cannot be undone.',
        )}
        deleteButton
        onConfirm={handleDeleteUser}
        open={openDeleteModal}
        onClose={handleCloseModalDelete}
        okText={t('Delete')}
        cancelText={t('Cancel')}
      />
    </>
  );
};

export default GroupsMembers;
