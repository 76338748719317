import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import { useFormContext } from '../../contexts/FormContext';
import { useMutation } from '@apollo/client';
import { CREATE_JOB_DESCRIPTION_ISPROX } from '../../../graphql/mutations';
import { JobDescriptionIsproxInput } from '../../../__generated__/graphql';

const FormStepsIsprox = () => {
  const { currentStep, updateFormData, nextStep } = useFormContext();
  const [offertJob, setOffertJob] = useState<string[]>([]);
  const [createDescription, { loading }] = useMutation(CREATE_JOB_DESCRIPTION_ISPROX);

  const handlePartialSubmit = (data: JobDescriptionIsproxInput) => {
    updateFormData(data);
    nextStep();
  };

  const handleSubmit = (data: JobDescriptionIsproxInput) => {
    createDescription({
      variables: {
        input: {
          ...data,
          company: data.company || '(no disponible)',
          title: data.title || '(no disponible)',
          requirements: data.requirements || '(no disponible)',
          companyDescription: data.companyDescription || '(no disponible)',
          jobDescription: data.jobDescription || '(no disponible)',
          jobLevel: data.jobLevel || '(no disponible)',
        },
      },
      onCompleted: (data) => {
        setOffertJob(data.createJobDescriptionIsprox.data!!);
        nextStep();
      },
    });
  };

  const defaultValues = {
    company: '',
    title: '',
    requirements: '',
    location: '',
    modality: '',
    benefits: '',
    companyDescription: '',
    jobDescription: '',
    dependecyDescription: '',
    jobLevel: '',
    language: 'Español',
    tone: {
      friendly: 0,
      personal: 0,
      formal: 0,
      professional: 0,
      motivating: 0,
      creative: 0,
    },
    negativesWords: '',
    name: '',
    surname: '',
    email: '',
  };

  const steps = [
    <Step1 onSubmit={handlePartialSubmit} defaultValues={defaultValues} />,
    <Step2 onSubmit={handlePartialSubmit} />,
    <Step3 onSubmit={handleSubmit} loading={loading} />,
    <Step4 offertJob={offertJob} />,
  ];

  return <>{steps[currentStep]}</>;
};

export default FormStepsIsprox;
