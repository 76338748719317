import { useMutation, useQuery } from '@apollo/client';
import { PlayArrow, ReportOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  List,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { Idea, OrderByDirection, OrderByField } from '../../__generated__/graphql';
import { DeviceContext } from '../../context/DeviceContext';
import { GET_BRANDS, GET_CHAINS, GET_IDEAS } from '../../graphql/queries';
import { CREATE_RUN_IN_BATCH } from '../../graphql/mutations';
import { AuthContext } from '../../context/AuthContext';
import { getFullName } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { SnackbarContext } from '../../context/SnackbarContext';
import { useTranslation } from 'react-i18next';

const BatchPrompter = () => {
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceContext);
  const { user } = useContext(AuthContext);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [brandSelectedIds, setBrandSelectedIds] = useState<string[]>([]);
  const [openRow, setOpenRow] = useState<number | null>();
  const [ideasPerBrandId, setIdeasPerBrandId] = useState<Map<string, number>>(new Map());
  const [createRunInBatch] = useMutation(CREATE_RUN_IN_BATCH);
  const { data: chainsData } = useQuery(GET_CHAINS);
  const chains = chainsData?.chains || [];

  const { data: brandsData, loading: loadingBrands } = useQuery(GET_BRANDS, {
    variables: { allBrands: true },
    onCompleted: ({ getBrands }) => {
      const defaultBrandIds = (getBrands || [])
        .filter((brand) => brand.contentCreation?.requireContent)
        .map((brand) => brand._id);

      setBrandSelectedIds(defaultBrandIds);
      setIdeasPerBrandId(new Map(defaultBrandIds.map((brandId) => [brandId, 4])));
    },
  });

  const brandsSelected = brandsData?.getBrands.filter((brand) =>
    brandSelectedIds.includes(brand?._id),
  );

  const { data: dataIdeas } = useQuery(GET_IDEAS, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        brandIds: brandSelectedIds,
      },
      orderBy: { field: OrderByField.Priority, direction: OrderByDirection.Desc },
    },
    skip: !brandSelectedIds,
  });

  const ideas = dataIdeas?.getIdeas.ideas || [];

  if (loadingBrands) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const disabled = brandSelectedIds.some((brandId) => {
    const brandIdeasCount = ideasPerBrandId.get(brandId) || 0;
    const brandIdeas = ideas.filter((idea) => idea.brandId === brandId);
    return brandIdeas.length < brandIdeasCount;
  });

  const handleRunAll = () => {
    const ideasToRun: Idea[] = brandSelectedIds.flatMap((brandId) =>
      ideas
        .filter((idea) => idea.brandId === brandId)
        .sort((a, b) => (b.priority || 0) - (a.priority || 0))
        .slice(0, ideasPerBrandId.get(brandId) || 0),
    );

    createRunInBatch({
      variables: {
        input: ideasToRun.map((idea) => ({
          brandId: idea.brandId,
          brandName: idea.brandName,
          createdById: user?._id,
          createdByFullName: getFullName(user),
          chainId: idea.chainId,
          chainName: chains.find((chain) => chain._id === idea.chainId)?.name,
          topicContext: idea.content,
          ideaId: idea._id,
        })),
      },
      onCompleted: ({ createRunInBatch }) => {
        if (createRunInBatch.success) {
          setSuccessMessage(
            t('{{count}} prompts queued successfully', { count: ideasToRun.length }),
          );
          navigate('/content-manager');
          return;
        }

        setErrorMessage(createRunInBatch.message || t('There was an error'));
      },
    });
  };

  return (
    <>
      <Stack
        direction="column"
        p={isMobile ? '40px 30px 80px 30px' : '40px 80px 80px 80px'}
        spacing={4}
        alignItems={'center'}
      >
        <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
          {t('Batch Prompter')} 🚀
        </Typography>
        <Autocomplete
          multiple
          value={brandSelectedIds
            .map((id) => brandsData?.getBrands.find((brand) => brand._id === id))
            .filter(Boolean)}
          options={brandsData?.getBrands || []}
          disabled={loadingBrands}
          getOptionLabel={(option) => option?.name || t('Unknown')}
          renderTags={(value, getTagProps) => (
            <>
              {value.slice(0, 5).map((option, index) => (
                <Chip label={option?.name} {...getTagProps({ index })} />
              ))}
              {value.length > 5 && (
                <Chip
                  label={t('+{{count}} more', { count: value.length - 5 })}
                  {...getTagProps({ index: -1 })}
                />
              )}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t('Select Brands')}
              placeholder={t('Brands')}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingBrands ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          data-testid="brand-select-multi"
          sx={{ width: '100%' }}
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          onChange={(_, value) => setBrandSelectedIds(value.map((brand) => brand!._id))}
          filterOptions={(options, { inputValue }) =>
            options.filter(
              (option) =>
                option?.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                (option?.user &&
                  option?.user.email.toLowerCase().includes(inputValue.toLowerCase())),
            )
          }
          renderOption={(props, option) => (
            <li {...props} key={option?._id}>
              <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                <Typography fontWeight={500}>{option?.name}</Typography>
                <Typography fontSize={14}>{option?.user?.email}</Typography>
              </Stack>
            </li>
          )}
          disableCloseOnSelect
        />
        {!!brandsSelected && ideas && (
          <>
            <Stack direction="row" justifyContent="flex-end" width={'100%'}>
              <LoadingButton
                startIcon={<PlayArrow />}
                variant="contained"
                sx={{ width: 'fit-content' }}
                disabled={disabled}
                onClick={handleRunAll}
              >
                {t('Run all')}
              </LoadingButton>
            </Stack>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                      {t('Brand')}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      {t('Number of Ideas')}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      {t('Ideas to Run')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {brandsSelected.map((brand, index) => (
                    <React.Fragment key={brand._id}>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          borderLeft: '5px solid',
                          borderColor: 'primary.main',
                          cursor: 'pointer',
                        }}
                        onClick={() => setOpenRow(openRow === index ? null : index)}
                      >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                          {brand.name}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ fontWeight: 'bold', textAlign: 'center' }}
                        >
                          {ideas.filter((idea) => idea.brandId === brand._id).length}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <TextField
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            size="small"
                            value={ideasPerBrandId.get(brand._id) || 0}
                            onClick={(event) => event.stopPropagation()}
                            onChange={(event) => {
                              const value = Number(event.target.value);
                              if (value < 0) return;

                              const updatedIdeasPerBrandId = new Map(ideasPerBrandId);
                              updatedIdeasPerBrandId.set(brand._id, value);
                              setIdeasPerBrandId(updatedIdeasPerBrandId);
                            }}
                            InputProps={{
                              style: { textAlign: 'center', maxWidth: '100px' },
                            }}
                          />
                          {(ideasPerBrandId?.get(brand._id) || 0) >
                            ideas.filter((idea) => idea.brandId === brand._id).length && (
                            <>
                              <ReportOutlined sx={{ mx: 1, color: '#FF4D00' }} />
                              <Typography fontSize={12}>
                                {t('Not enough ideas')}
                              </Typography>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          sx={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            maxWidth: '100px',
                            ...(openRow === index ? {} : { border: 'none' }),
                          }}
                        >
                          <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                              <List>
                                {ideas
                                  .filter((idea) => idea.brandId === brand._id)
                                  .map((idea, ideaIndex) => (
                                    <React.Fragment key={ideaIndex}>
                                      <Stack direction="column" spacing={1}>
                                        <Chip
                                          size="small"
                                          sx={{ width: 'fit-content' }}
                                          label={
                                            idea.priority === 0
                                              ? t('Low')
                                              : idea.priority === 1
                                              ? t('Medium')
                                              : t('High')
                                          }
                                          color={
                                            idea.priority === 0
                                              ? 'success'
                                              : idea.priority === 1
                                              ? 'warning'
                                              : 'error'
                                          }
                                        />
                                        <ListItemText primary={idea.content} />
                                      </Stack>
                                      {ideaIndex <
                                        ideas.filter((idea) => idea.brandId === brand._id)
                                          .length -
                                          1 && <Divider sx={{ my: 1 }} />}
                                    </React.Fragment>
                                  ))}
                              </List>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Stack>
    </>
  );
};

export default BatchPrompter;
