import { useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { useTranslation } from 'react-i18next';
import { UserPlan } from '../../__generated__/graphql';

interface HeaderProps {
  text: string;
}

const Header = ({ text }: HeaderProps) => {
  const { user, userHavePost } = useContext(AuthContext);
  const { isMobile } = useContext(DeviceContext);
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      spacing={2}
    >
      <Stack direction="column" alignItems="flex-start">
        <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
          {user?.brandSelectedId === user?.brandId
            ? `${t('Hi')}, ${user?.firstName ? user?.firstName : user?.email}!`
            : `${t('Content for')} ${user?.brandSelected?.name}!`}
        </Typography>
        {!user?.plan?.includes(UserPlan.Starter) && (
          <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'}>
            {userHavePost ? text : 'Here you will discover posts crafted just for you.'}
          </Typography>
        )}
      </Stack>
      {!user?.plan?.includes(UserPlan.Starter) && (
        <Stack direction="column" alignItems="flex-end">
          <Typography variant={isMobile ? 'subtitle2' : 'body1'}>MAGNETS</Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant={isMobile ? 'h5' : 'h4'} fontWeight="bold">
              {user?.numMagnets}
            </Typography>
            <img
              src="/images/magnet_reward.svg"
              alt="magnettu logo"
              width={isMobile ? 20 : 30}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Header;
