import { Stack, Typography } from '@mui/material';
import PostsList from './PostsList';
import { useTranslation } from 'react-i18next';

const ManageTeam = () => {
  const { t } = useTranslation();
  return (
    <>
      <Stack direction="column" p={3} gap={4}>
        <Stack direction="column" alignItems="flex-start">
          <Typography variant={'h4'} fontWeight="bold">
            {t('Content Managerv2')}
          </Typography>
          <Typography variant={'body1'}>
            {t('Create posts and manage your brands with ease')}
          </Typography>
        </Stack>
        <PostsList />
      </Stack>
    </>
  );
};

export default ManageTeam;
