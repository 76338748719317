import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts'
import { ReportTrendlines } from '../../__generated__/graphql'
import { convertUnixTimeToDate, formatDateMonths } from '../../utils'

interface Props {
  title: string
  countNew: ReportTrendlines[]
  countOld: ReportTrendlines[]
  dateNew: string
  dateOld: string
  type: 'numImpressions' | 'numLikes' | 'numComments' | 'totalShares'
}

const TrendlineCard: FC<Props> = ({
  title,
  countNew,
  countOld,
  dateNew,
  dateOld,
  type
}) => {


  const data = countNew.map((item, index) => {
    const dateFilter1 = convertUnixTimeToDate(item.timeRange);
    const dateFilter2 = convertUnixTimeToDate(countOld[index].timeRange);
    const value1 = countNew.slice(0, index + 1).reduce((acc, curr) => acc + curr[type], 0);
    const value2 = countOld.slice(0, index + 1).reduce((acc, curr) => acc + curr[type], 0);
    const value1Clean = countNew[index][type];
    const value2Clean = countOld[index][type];

    return {
      dateFilter1,
      dateFilter2,
      value1,
      value2,
      value1Clean,
      value2Clean
    };
  });

  const lastValueFilter1 = data[data.length - 1].value1;
  const lastValueFilter2 = data[data.length - 1].value2;

  return (
    <Box width="100%"
      boxShadow={2} borderRadius="10px" padding="20px">
      <Typography fontSize="1.4rem">{title}</Typography>
      <Box display="flex" justifyContent="space-between">
        <Box >
          <Typography color="#CD0061" fontSize="2rem" fontWeight={600}>{lastValueFilter1}</Typography>
          <Typography fontSize="0.8rem" fontStyle="italic">
            {dateNew}
          </Typography>
        </Box>
        <Box>
          <Typography color="#685E64" fontSize="2rem" fontWeight={600}>
            {lastValueFilter2}
          </Typography>
          <Typography fontSize="0.8rem" fontStyle="italic" >
            {dateOld}
          </Typography>
        </Box>
      </Box>


      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <Line type="monotone" dataKey="value1" stroke="#CD0061" name="Filter 1" dot={false} isAnimationActive={false} />
          <Line type="monotone" dataKey="value2" stroke="#685E64" name="Filter 2" dot={false} isAnimationActive={false} />
          <Tooltip
          content={({ payload }) => {
            if (payload!!.length === 0) return null;
            const { dateFilter1, dateFilter2, value1, value2, value1Clean, value2Clean  } = payload!![0].payload;

            return (
              <Box 
              sx={{
                backgroundColor: 'white',
                border: '1px solid rgb(204, 204, 204)',
                padding: '10px',
                whiteSpace: 'nowrap',
                margin: '0px'
              }}>
                <p>{`${formatDateMonths(dateFilter1)}:  ${value1Clean}`}</p>
                <p>Accumulated : {value1} {title}</p>
                <p>{`${formatDateMonths(dateFilter2)}:  ${value2Clean}`}</p>
                <p>Accumulated : {value2} {title}</p>
              </Box>
            );
          }}
          />

        </LineChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default TrendlineCard