import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
interface Props {
  handleOpenModal: () => void;
}

const DemoCard = ({ handleOpenModal }: Props) => {
  return (
    <Card
      sx={{
        width: { xs: '100%', sm: '378.75px' },
        border: 'solid 1px #EBEBEB',
      }}
    >
      <CardHeader
        title={
          <Typography variant="h6" fontWeight="bold">
            Greetings!
          </Typography>
        }
        // subheader="All posts created by your team"
      />
      <CardContent>
        <Typography>
          It seems you haven't added any brand yet, so you currently don't have the
          ability to create content.
          <br />
          <br />
          Please add a brand to start creating content and make the most out of our
          platform.
          <br />
          <br />
          If you need assistance, feel free to contact us at sabrina.michelsen@magnettu.com
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          marginTop: '20px',
        }}
      >
        <Button
          variant="outlined"
          // color="primary"
          sx={{
            width: '100%',
            // borderRadius: '8px',
          }}
          // onClick={() => setOpenSelectUser(true)}
          onClick={handleOpenModal}
        >
          <Typography fontWeight="bold">Create brand</Typography>
        </Button>
      </CardActions>
    </Card>
  );
};

export default DemoCard;
