import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderByDirection, OrderByField, Post, Tag } from '../../__generated__/graphql';
import { SnackbarContext } from '../../context/SnackbarContext';
import { HARD_DELETE_POST } from '../../graphql/mutations';
import { GET_EDIT_POST, GET_POSTS } from '../../graphql/queries';
import { OrderBy } from '../../types';
import { getFullName } from '../../utils';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import PostTags from '../PostComponents/PostTags';

const HeaderCell = ({
  text,
  isOrderable = false,
  active = false,
  direction = OrderByDirection.Asc,
  onClick = () => {},
  sx = {},
}: {
  text: string;
  isOrderable?: boolean;
  active?: boolean;
  direction?: OrderByDirection;
  onClick?: () => void;
  sx?: SxProps;
}) => (
  <TableCell sx={{ color: 'primary.main', fontWeight: 'bold', ...sx }}>
    {isOrderable ? (
      <TableSortLabel
        active={active}
        direction={direction === OrderByDirection.Asc ? 'asc' : 'desc'}
        onClick={onClick}
        sx={{ color: 'primary.main', fontWeight: 'bold' }}
      >
        <Typography fontSize="14px" fontWeight="bold">
          {text}
        </Typography>
      </TableSortLabel>
    ) : (
      <Typography fontSize="14px" fontWeight="bold">
        {text}
      </Typography>
    )}
  </TableCell>
);

const Cell = ({
  text,
  sx,
  children,
}: {
  text?: string;
  sx?: SxProps;
  children?: React.ReactNode;
}) => (
  <TableCell sx={sx}>
    {children || (
      <Typography fontSize="12px" fontWeight="bold">
        {text}
      </Typography>
    )}
  </TableCell>
);

interface Props {
  posts: Post[];
  page: number;
  limit: number;
  setLimit?: (limit: number) => void;
  orderBy?: OrderBy;
  setOrderBy?: (orderBy: OrderBy) => void;
  onPageChange?: (page: number) => void;
  total?: number;
}

const PostsTable = ({
  posts,
  page = 0,
  limit,
  setLimit = () => {},
  orderBy = { field: OrderByField.CreatedOn, direction: OrderByDirection.Desc },
  setOrderBy = () => {},
  onPageChange = () => {},
  total = -1,
}: Props) => {
  const navigate = useNavigate();
  const [deletePostId, setDeletePostId] = useState<string | null>(null);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);
  const [openRow, setOpenRow] = useState<number | null>();

  const [hardDeletePost] = useMutation(HARD_DELETE_POST, {
    onCompleted: () => {
      setSuccessMessage('Post deleted successfully');
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
    awaitRefetchQueries: true,
    refetchQueries: [GET_POSTS, GET_EDIT_POST],
  });

  const onOrderByChange = (field: OrderByField) => {
    setOrderBy({
      field,
      direction:
        orderBy.field === field && orderBy.direction === OrderByDirection.Asc
          ? OrderByDirection.Desc
          : OrderByDirection.Asc,
    });
  };

  const handleDeletePost = (postId: string) => {
    hardDeletePost({
      variables: { postId },
    });
    setDeletePostId(null);
  };

  const printDate = (date: string, time: boolean = false) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: time ? '2-digit' : undefined,
      minute: time ? '2-digit' : undefined,
      // 24 hour time
      hour12: false,
    });
  };

  return (
    <>
      <ConfirmationDialog
        title="Delete Post"
        content="Are you sure you want to delete this post forever? You will not be able to recover it."
        onConfirm={() => handleDeletePost(deletePostId!)}
        open={deletePostId !== null}
        onClose={() => setDeletePostId(null)}
        okText="Delete"
        cancelText="Cancel"
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <HeaderCell
                text="Brand"
                isOrderable
                active={orderBy.field === OrderByField.UserFullName}
                direction={orderBy.direction}
                onClick={() => onOrderByChange(OrderByField.UserFullName)}
              />
              <HeaderCell
                text="Created"
                isOrderable
                active={orderBy.field === OrderByField.CreatedOn}
                direction={orderBy.direction}
                onClick={() => onOrderByChange(OrderByField.CreatedOn)}
              />
              <HeaderCell text="Created by" direction={orderBy.direction} />
              <HeaderCell
                text="Status"
                isOrderable
                active={orderBy.field === OrderByField.LifecycleState}
                direction={orderBy.direction}
                onClick={() => onOrderByChange(OrderByField.LifecycleState)}
              />
              <HeaderCell
                text="Type"
                isOrderable
                active={orderBy.field === OrderByField.Type}
                direction={orderBy.direction}
                onClick={() => onOrderByChange(OrderByField.Type)}
              />
              <HeaderCell
                text="Tags"
                isOrderable
                active={orderBy.field === OrderByField.Tags}
                direction={orderBy.direction}
                onClick={() => onOrderByChange(OrderByField.Tags)}
              />
              <HeaderCell text="Actions" sx={{ textAlign: 'center' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {posts.map((post, index) => (
              <React.Fragment key={post._id}>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    borderLeft: '5px solid',
                    borderColor: 'primary.main',
                  }}
                  onClick={() =>
                    setOpenRow((prevIndex) => (prevIndex === index ? null : index))
                  }
                  style={{ cursor: 'pointer' }}
                  data-testid="post-row"
                >
                  <Cell text={post.brandName || ''} />
                  <Cell text={printDate(post.createdOn)} />
                  <Cell text={getFullName(post.createdBy || null)} />
                  <Cell>
                    <Chip
                      size="small"
                      sx={{ backgroundColor: post.lifecycleStateDisplay?.color }}
                      label={post.lifecycleStateDisplay?.name}
                    ></Chip>
                  </Cell>
                  <Cell text={post.type} />
                  <Cell >
                    {post.tags && post.tags.length > 0 && <PostTags tags={post.tags as Tag[]} />}
                    
                  </Cell>
                  <Cell sx={{ textAlign: 'center' }}>
                    <IconButton
                      onClick={() => navigate(`edit-post/${post._id}`)}
                      data-testid="edit-post-button"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => setDeletePostId(post._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Cell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={7}
                    sx={{
                      paddingBottom: 0,
                      paddingTop: 0,
                      ...(openRow === index ? {} : { border: 'none' }),
                    }}
                  >
                    <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                      <Box margin={1} maxWidth={'800px'}>
                        <div dangerouslySetInnerHTML={{ __html: post.content?.body || '' }} />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                rowsPerPageOptions={[15, 25, 50]}
                rowsPerPage={limit}
                page={posts ? page : 0}
                onPageChange={(_, page) => onPageChange(page)}
                onRowsPerPageChange={(event) => {
                  onPageChange(0);
                  setLimit(+event.target.value);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default PostsTable;
