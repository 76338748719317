import {
  Box,
  CardMedia,
  Stack,
  Typography,
  CircularProgress,
  IconButton,
  Badge,
  Avatar,
} from '@mui/material';
import { Insight } from '../../__generated__/graphql';
import { formatDateToCustomFormat } from '../../utils';
import { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

interface Props {
  insight: Insight;
}

export const InsightContentLinkedIn = ({ insight }: Props) => {
  let media = insight.media || [];
  const boxRef = useRef<HTMLDivElement>(null);
  const date = formatDateToCustomFormat(insight.pubDate);
  const [mediaFailed, setMediaFailed] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const validateMinLetters: boolean =
    !!(insight.content && insight.content.length < 100) ||
    !!(insight.description && insight.description.length < 100);

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTop = 0;
    }
  }, [insight]);

  return (
    <Stack
      direction="column"
      spacing={1}
      width={'100%'}
      flexGrow={1}
      sx={{ maxHeight: 'calc(100vh - 250px)' }}
    >
      <Box
        ref={boxRef}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #BBBBBB',
          p: '1px',
          borderRadius: '4px',
          padding: '10px',
          overflowY: 'scroll',
          minHeight: { xs: 'calc(100vh - 250px)', md: 'auto' },
        }}
      >
        <Box display="flex" flexDirection="column" marginTop="5px">
          <Box display="flex">
            <Box maxHeight="100px">
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <img
                    src="./images/icons/linkedin.png"
                    style={{ width: '30px', height: '30px' }}
                    alt="linkedin"
                  />
                }
              >
                <Avatar
                  alt="profile"
                  src={insight.author?.profilePicture || ''}
                  sx={{
                    width: '60px',
                    height: '60px',
                  }}
                />
              </Badge>
            </Box>

            <Box marginLeft="15px">
              <Typography
                color="text.primary"
                fontWeight={900}
                fontSize="22px"
                marginTop="0.2rem"
              >
                {insight.author?.firstName} {insight.author?.lastName}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                fontSize="14px"
                marginTop="0.8rem"
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    // textDecoration: 'none',
                    color: '#6E6E6E',
                    // borderBottom: '1px solid #6E6E6E',
                  }}
                  href={
                    insight.articleId
                      ? `https://www.linkedin.com/feed/update/${insight.articleId}`
                      : '#'
                  }
                >
                  Ver publicación en linkedin
                </a>{' '}
                / {date}
              </Typography>
              {!!media.length && !mediaFailed && (
                <>
                  {media[0].type?.includes('pdf') ? (
                    <Stack direction="row" gap={1} justifyContent="center">
                      {!!numPages && (
                        <IconButton
                          onClick={handlePrevPage}
                          disabled={currentPage === 1}
                          sx={{
                            width: '30px',
                            height: '30px',
                            marginY: 'auto',
                          }}
                        >
                          <ArrowBackIosNewIcon />
                        </IconButton>
                      )}
                      <Document
                        file={media[0]?.url || undefined}
                        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                        loading={<CircularProgress />}
                      >
                        <Stack direction="column" gap={1}>
                          <Page pageNumber={currentPage} scale={1} width={220} />
                          <Typography textAlign="center">{`Page ${currentPage}`}</Typography>
                        </Stack>
                      </Document>
                      {!!numPages && (
                        <IconButton
                          onClick={handleNextPage}
                          disabled={currentPage === numPages}
                          sx={{
                            width: '30px',
                            height: '30px',
                            marginY: 'auto',
                          }}
                        >
                          <ArrowBackIosNewIcon sx={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                      )}
                    </Stack>
                  ) : (
                    <Box width="400px" marginX="auto" marginY="1rem">
                      <CardMedia
                        component={media[0]?.type?.startsWith('video') ? 'video' : 'img'}
                        controls
                        image={media[0]?.url || undefined}
                        // sx={{ marginBottom: 1, height: loadImage ? 'auto' : '100px", width: "500px' }}
                        data-testid="insight-card-media"
                        onError={() => setMediaFailed(true)}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
          <Typography
            sx={{ marginBottom: 1 }}
            lineHeight="34px"
            fontSize="14px"
            marginTop="1rem"
            minWidth={validateMinLetters ? '600px' : 'auto'}
          >
            {insight?.content || insight.description}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};
