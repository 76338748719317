import { Create, Delete } from '@mui/icons-material';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { BrandContext } from '../../context/BrandContext';
import { Group } from '../../__generated__/graphql';
import { useMutation } from '@apollo/client';
import { UPDATE_GROUP_BY_USER } from '../../graphql/mutations';
import { SnackbarContext } from '../../context/SnackbarContext';
import Avatar from '../Avatar/Avatar';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

interface Props {
  groupSelected: Group | null;
  inputStyle: any;
}

const HeaderGroupEditor = ({
  groupSelected,
  inputStyle,
}:
Props) => {
  const { handleDeleteGroup, refetchGroups } = useContext(BrandContext);
  const { setErrorMessage, setSuccessMessage } = useContext(SnackbarContext);
  const [updateGroup] = useMutation(UPDATE_GROUP_BY_USER);
  const [name, setName] = useState<string>(groupSelected?.name || '');
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const handleBlur = () => {
    handleSave();
  };

  const handleSave = () => {
    if (name === groupSelected?.name) {
      setIsEditing(false);
      return;
    }

    if (name.trim() !== '') {
      updateGroup({
        variables: {
          input: {
            groupId: groupSelected!._id,
            name: name,
          },
        },
        onCompleted: (data) => {
          if (data.updateGroup.success) {
            setSuccessMessage(data.updateGroup.message || 'Group updated successfully');
            refetchGroups();
            return;
          }

          setErrorMessage(data.updateGroup.message || 'Error updating group');
          setName(groupSelected!.name);
        },
      });
    } else {
      setName(groupSelected!.name);
    }

    setIsEditing(false);
  };

  const ButtonDeleteGroup = () => {
    return (
      <Button
        startIcon={
          <Delete
            sx={{
              color: '#D32F2F',
            }}
          />
        }
        variant="outlined"
        sx={{
          color: '#D32F2F',
          fontWeight: 700,
          borderRadius: '10px',
          borderColor: '#D32F2F',
        }}
        onClick={() => setOpenModalDelete(true)}
      >
        Delete Group
      </Button>
    );
  };

  return (
    <>
      <Stack
        flexDirection="row"
        gap={2}
        marginBottom={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          flexDirection="row"
          gap={2}
          alignItems="center"
        >
          <Avatar
            name={name}
            backgroundColor={groupSelected?.backgroundColor || undefined}
            size={50}
          />
          {isEditing ? (
            <TextField
              required
              value={name}
              onChange={(value) => {
                setName(value.target.value);
              }}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              sx={{ ...inputStyle, width: '200px' }}
              autoFocus
              variant="standard"
            />
          ) : (
            <Stack flexDirection="row" gap={2}>
              <Typography fontWeight={700} fontSize={20}>{name}</Typography>
              <Create
                onClick={toggleEditMode}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </Stack>
          )}
        </Stack>

        <ButtonDeleteGroup />
        <ConfirmationDialog
          title="Are you sure you want to delete this group?"
          content={<>
          <Divider sx={{
            marginBottom: '10px',
          }}/>
          Deleting this group will delete all the posts inside it.
          Do you wish to proceed?
          <Divider sx={{
            marginTop: '10px',
          
          }}/>
          
          </>
          }
          open={openModalDelete}
          onClose={() => setOpenModalDelete(false)}
          onConfirm={() => handleDeleteGroup(groupSelected!._id)}
          okText='Delete'
          deleteButton
        />
      </Stack>
    </>
  );
};

export default HeaderGroupEditor;
