import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import {
  FollowersMonthly,
  FollowersMonthlyDetail,
  MetricsCompanyResponse,
  MonthlyDataEntry,
} from '../../__generated__/graphql';
import { formatPostsCountDifference } from '../../utils';
import CardReport from '../Reports/CardReport';
import GraphicReportCompanyImpressions from '../Reports/GraphicReportCompanyImpressions';
import GraphicReportCompanyTeamPostsFollowers from '../Reports/GraphicReportCompanyTeamPostsFollowers';
import GraphicReportCompanyTeamPostsFollowersForDays from '../Reports/GraphicReportCompanyTeamPostsFollowersForDays';
import CompanyPerformanceV2 from './CompanyPerformancev2';
import { useTranslation } from 'react-i18next';
import DatePickerRange from '../DatePicker/DatePickerRange';

interface Props {
  metricsCompany: MetricsCompanyResponse | null;
  differenceInDays: number;
  dateInit: Date;
  dateEnd: Date;
  dateFilter: (dateInit: string, dateEnd: string) => void;
  loadingButton: boolean;
  monthlyData: MonthlyDataEntry[];
  followersMonthly: FollowersMonthly[];
  followersMonthlyDetail: FollowersMonthlyDetail[];
  followersCurrent: number;
}

const CompanyPerformance = ({
  metricsCompany,
  differenceInDays,
  dateInit,
  dateEnd,
  dateFilter,
  loadingButton,
  monthlyData,
  followersMonthly,
  followersCurrent,
  followersMonthlyDetail,
}: Props) => {
  const { t } = useTranslation();


  return (
    <>
      <DatePickerRange
        dateInit={dateInit}
        dateEnd={dateEnd}
        dateFilter={dateFilter}
        loadingButton={loadingButton}
      />

      {loadingButton ? (
        <CircularProgress
          sx={{
            display: 'block',
            margin: 'auto',
          }}
        />
      ) : (
        <Grid
          container
          display="flex"
          gap={2}
          alignContent="center"
          flexDirection={{ xs: 'column', md: 'row' }}
          flexWrap={{ xs: 'wrap', md: 'nowrap' }}
        >
          <Grid item sm={6} md={3}>
            <CardReport
              title={t('Company followers')}
              lastDays={`${t('in the last')} ${differenceInDays} ${t('days')}`}
              comparation={formatPostsCountDifference(
                metricsCompany?.followers || 0,
                metricsCompany?.followers30DaysAgo || 0,
              )}
            >
              <Typography fontSize="2rem" textAlign="center" mr="5px">
                {metricsCompany?.followers?.toLocaleString('es-ES') || '0'}
              </Typography>
            </CardReport>
          </Grid>
          <Grid item sm={6} md={3}>
            <CardReport
              title={t('Impressions')}
              lastDays={`${t('vs the previous')} ${differenceInDays} ${t('days')}`}
              subtitle
              differenceInDays={differenceInDays}
              comparation={formatPostsCountDifference(
                metricsCompany?.impressions || 0,
                metricsCompany?.impressions30DaysAgo || 0,
              )}
            >
              <Typography fontSize="2rem" textAlign="center" mr="5px">
                {metricsCompany?.impressions?.toLocaleString('es-ES') || '0'}
              </Typography>
              <Typography fontSize="1rem" textAlign="center">
                {' '}
                {t('Impressions')}
              </Typography>
            </CardReport>
          </Grid>
          <Grid item sm={6} md={3}>
            <CardReport
              title={t('Interactions')}
              lastDays={`${t('vs the previous')} ${differenceInDays} ${t('days')}`}
              subtitle
              comparation={formatPostsCountDifference(
                metricsCompany?.interactions || 0,
                metricsCompany?.interactions30DaysAgo || 0,
              )}
              differenceInDays={differenceInDays}
            >
              <Typography fontSize="2rem" textAlign="center" mr="5px">
                {metricsCompany?.interactions?.toLocaleString('es-ES') || '0'}
              </Typography>
              <Typography fontSize="1rem" textAlign="center">
                {' '}
                {t('Interactions')}
              </Typography>
            </CardReport>
          </Grid>
          <Grid item sm={6} md={3}>
            <CardReport
              title="Engagement"
              subtitle
              comparation={' '}
              differenceInDays={differenceInDays}
            >
              <Box>
                <Typography marginTop="1rem" fontSize="1rem" textAlign="center" mr="5px">
                  {metricsCompany &&
                  metricsCompany.interactions &&
                  metricsCompany.impressions
                    ? `${(
                        ((metricsCompany?.interactions || 0) /
                          (metricsCompany.impressions || 0)) *
                        100
                      ).toFixed(2)}% `
                    : 'N/A'}
                  {!!metricsCompany?.interactions && !!metricsCompany?.impressions && (
                    <span>
                      {t('audience reaction per')}
                      <span
                        style={{
                          color: '#CD0061',
                          display: 'inline-block',
                          marginLeft: '0.5rem',
                          marginRight: '0.5rem',
                        }}
                      >
                        {t('company post')}
                      </span>
                    </span>
                  )}
                </Typography>
              </Box>
            </CardReport>
          </Grid>
        </Grid>
      )}

      <Box
        width="100%"
        marginTop="2rem"
        justifyContent="space-between"
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        {!!monthlyData?.length && (
          <Box width={{ xs: '100%', sm: '49%' }}>
            <GraphicReportCompanyImpressions metric={monthlyData} />
          </Box>
        )}
        {!!followersMonthly?.length && (
          <Box width={{ xs: '100%', sm: '49%' }}>
            <GraphicReportCompanyTeamPostsFollowers
              followers={followersCurrent}
              followersMonthly={followersMonthly}
            />
          </Box>
        )}
      </Box>
      {!!followersMonthlyDetail?.length && (
        <Box width={{ xs: '100%' }} marginY="2rem">
          <GraphicReportCompanyTeamPostsFollowersForDays
            followers={followersCurrent}
            followersMonthlyDetail={followersMonthlyDetail}
          />
        </Box>
      )}

      {!!monthlyData?.length && (
        <CompanyPerformanceV2
          key={metricsCompany?.impressions}
          dateInit={dateInit}
          dateEnd={dateEnd}
          differenceInDays={differenceInDays}
        />
      )}
    </>
  );
};

export default CompanyPerformance;
