import { useMutation } from '@apollo/client';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { Editor, useEditor } from '@tiptap/react';
import { ReactNode, createContext, useContext, useState } from 'react';
import {
  ExtractThumbnailDataMutation,
  Post,
  PostContentMedia,
  PostType,
  Thumbnail,
  User,
} from '../__generated__/graphql';
import { suggestion } from '../components/TextEditor/Suggestion';
import { EXTRACT_THUMBNAIL_DATA } from '../graphql/mutations';
import usePost, { UsePostReturnType } from '../hooks/usePost';
import { uploadThumbnailMedia } from '../utils';
import { DeviceContext } from './DeviceContext';
import { WebSocketContext } from './WebSocketContext';
import { AuthContext } from '../../src/context/AuthContext';

interface PostProviderProps {
  children: ReactNode;
  postId: string | null;
  newPostOptions?: Partial<Post>;
  content?: string;
  onChange?: (html: string, json: any) => void;
  offline?: boolean;
  fetchThumbnail?: (thumbnail: Thumbnail) => void;
  thumbnailData?: Thumbnail | null;
  setLoadingThumbnail?: (loading: boolean) => void;
  setErrorThumbnail?: (error: boolean) => void;
  loadingThumbnail?: boolean;
  isLink?: boolean;
}

const cleanHtmlHanlder = (html: string): string => {
  const elementHtml = document.createElement('br');
  elementHtml.innerHTML = html;
  let modifiedHtml = html.replaceAll('\n', '<br>');
  modifiedHtml = modifiedHtml.replaceAll('</p>', '<br><br></p>');
  const lastBrIndex = modifiedHtml.lastIndexOf('<br><br>');
  if (lastBrIndex !== -1) {
    modifiedHtml =
      modifiedHtml.substring(0, lastBrIndex) + modifiedHtml.substring(lastBrIndex + 4);
  }

  return modifiedHtml;
};

interface PostContextType {
  postState?: UsePostReturnType;
  editor?: Editor;
}

export interface IThumbnail {
  title: string;
  url: string;
  description?: string;
  image?: string;
  show: boolean;
}

export function extractUrlsFromText(text: string, paste: boolean = false, user: User) {
  // Expresión regular para encontrar URLs
  const urlRegex =
    /((https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/a-zA-Z0-9#?&=._-]*)?)/g;

  if (paste) {
    // Si es un pegado, devolver todas las URLs encontradas
    const urls = text.match(urlRegex) || [];
    if (user?.shell?.utm) {
      return urls.map((url) => `${url}?${user?.shell?.utm}`);
    }
    return urls;
  } else {
    // Si no es un pegado, solo devolver las URLs que tienen un espacio, salto de línea o <br> después
    const matches = [];
    let match;

    while ((match = urlRegex.exec(text)) !== null) {
      let url = match[0];
      const endIndex = match.index + url.length;
      const restOfText = text.slice(endIndex);

      // Verificar si el carácter después de la URL es un espacio, salto de línea o <br>
      const nextChars = restOfText.slice(0, 4); // Tomamos los siguientes 4 caracteres para verificar '<br>'
      if (
        nextChars.startsWith(' ') ||
        nextChars.startsWith('\n') ||
        nextChars.startsWith('\r') ||
        nextChars.startsWith('<br') || // Puede ser <br> o <br/>
        restOfText === '' // Si es el final del texto
      ) {
        if (user?.shell?.utm) {
          url = `${url}?${user.shell.utm}`;
        }
        matches.push(url);
      }
    }

    return matches;
  }
}

export const PostContext = createContext<PostContextType>({});

export const PostProvider = ({
  children,
  postId,
  content,
  onChange,
  fetchThumbnail,
  newPostOptions = {},
  thumbnailData,
  setErrorThumbnail,
  setLoadingThumbnail,
  loadingThumbnail: loadingThumbnailProp,
  isLink = false,
}: PostProviderProps) => {
  const { user } = useContext(AuthContext);
  const postState = usePost(postId, newPostOptions, isLink);
  const { isMobile } = useContext(DeviceContext);
  const { socket } = useContext(WebSocketContext);
  const [extractMetaTags] = useMutation(EXTRACT_THUMBNAIL_DATA);
  const [thumbnails, setThumbnails] = useState<Map<string, ExtractThumbnailDataMutation>>(
    new Map(),
  );
  const [currentThumbnailUrl, setCurrentThumbnailUrl] = useState<string>();

  let editable = true;
  if (postState.status.isEditDisabled) {
    editable = false;
  }
  if (socket !== null && !socket.connected) {
    editable = false;
  } else if (
    postState.post.type === PostType.Advocacy &&
    !postState.post.advocacy?.editable
  ) {
    editable = false;
  }

  const handleLoadingThumbnail =
    setLoadingThumbnail || postState.handlers.handleLoadingThumbnail;
  const handleErrorThumbnail =
    setErrorThumbnail || postState.handlers.handleErrorThumbnail;

  async function fetchMetaTags(url: string) {
    if (thumbnails.has(url)) {
      return thumbnails.get(url)?.extractThumbnailData;
    }

    handleLoadingThumbnail(true);

    const response = await extractMetaTags({
      variables: { url },
      onCompleted: (data) => {
        setThumbnails(new Map(thumbnails).set(url, data));
        if (!data?.extractThumbnailData) {
          handleErrorThumbnail(true);
          postState.handlers.handleShowErrorThumbnail(true);
        }

        handleLoadingThumbnail(false);
        return data;
      },
      onError: (error) => {
        handleErrorThumbnail(true);
        postState.handlers.handleShowErrorThumbnail(true);
        handleLoadingThumbnail(false);
      },
    });

    if (response) {
      return response.data?.extractThumbnailData;
    }

    return null;
  }

  async function handleThumbnail(htmlContent: string, paste: boolean = false) {
    if (!!postState.post.content?.media?.length) return;

    if (
      postState.post.content?.thumbnail?.title &&
      postState.status.showThumbnail &&
      paste
    )
      return;

    if (!user) return;
    const urls = extractUrlsFromText(htmlContent, paste, user);
    const url = urls[0] as string;
    setCurrentThumbnailUrl(url);

    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (!url || urls.length === 0 || (url !== currentThumbnailUrl && currentThumbnailUrl))
      return;
    //Primer caso, no tengo thumbnail
    if (!postState.post.content?.thumbnail?.title && !thumbnailData?.title) {
      if (postState.status.thumbnailData?.source === url) {
        const { __typename: _, media, ...thumbnailData } = postState.status.thumbnailData;
        const { __typename: __, ...thumbailMedia } = media as PostContentMedia;
        postState.handlers.handleUploadThumbnail({
          media: thumbailMedia,
          ...thumbnailData,
        });

        return;
      }

      const metaTags = await fetchMetaTags(url);
      if (metaTags) {
        const thumbnailMedia = await uploadThumbnailMedia(metaTags?.imageBlob || '');

        const thumnailData = {
          media: thumbnailMedia,
          source: url,
          title: metaTags.title,
          description: metaTags.description,
        };

        if (fetchThumbnail) fetchThumbnail(thumnailData);
        else postState.handlers.handleUploadThumbnail(thumnailData);
      }
      return;
    }

    // Segundo caso: no muestro thumbnail y la URL es distinta a la que tengo
    if (
      !postState.status.showThumbnail &&
      url !== postState.post.content?.thumbnail?.source
    ) {
      const metaTags = await fetchMetaTags(url);
      if (metaTags) {
        const thumbnailMedia = await uploadThumbnailMedia(metaTags?.imageBlob || '');
        postState.handlers.handleUploadThumbnail({
          media: thumbnailMedia,
          source: url,
          title: metaTags.title,
          description: metaTags.description,
        });
      }
      return;
    }

    // Tercer caso: tengo thumbnail y lo estoy mostrando
    if (postState.status.showThumbnail && postState.post.content?.thumbnail?.title) {
      return;
    }

    // Cuarto caso: tengo thumbnail y no lo estoy mostrando, pero la URL es la misma
    if (
      typeof url === 'string' &&
      typeof postState.post.content?.thumbnail?.source === 'string' &&
      url === postState.post.content?.thumbnail?.source
    ) {
      postState.handlers.handleShowThumbnail();
      return;
    }
  }

  const editor = useEditor(
    {
      content: content || postState.post.content?.body || postState.post.content?.json,
      editorProps: {
        transformPastedHTML: cleanHtmlHanlder,
      },
      extensions: [
        Document,
        Text,
        History.extend({
          addKeyboardShortcuts() {
            return {
              'Mod-z': () => this.editor.commands.undo(),
              'Mod-y': () => this.editor.commands.redo(),
              'Mod-Shift-z': () => this.editor.commands.redo(),
            };
          },
        }),
        Paragraph.configure({
          HTMLAttributes: {
            class: 'tiptap-p',
          },
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion: suggestion,
          // TODO: Use real data instead of mock data
          //suggestion: mentionSuggestionOptions,
        }),
        HardBreak.extend({
          addKeyboardShortcuts() {
            return {
              Enter: () => this.editor.commands.setHardBreak(),
            };
          },
        }),
      ],
      autofocus: !isMobile,
      editable: editable,
      injectCSS: false,
      onUpdate: async ({ editor, transaction }) => {
        const isPaste = transaction.getMeta('uiEvent') === 'paste';

        const htmlContent = editor.getHTML();
        postState.handlers.handleBodyChange(htmlContent, editor.getJSON());
        if (onChange) {
          onChange(editor.getHTML(), editor.getJSON());
        }

        if (isPaste) {
          handleThumbnail(htmlContent, true);
        } else {
          handleThumbnail(htmlContent);
        }
      },
    },

    [postState.post._id, editable],
  );

  if (!postState || !editor)
    return <PostContext.Provider value={{}}>{children}</PostContext.Provider>;

  return (
    <PostContext.Provider value={{ postState, editor }}>{children}</PostContext.Provider>
  );
};
