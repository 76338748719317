import { ArrowDownward, ArrowUpward, RemoveCircleOutline } from '@mui/icons-material';
import { IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { UseFormRegister } from 'react-hook-form';
import { Chain } from '../../__generated__/graphql';

interface Props {
  register: UseFormRegister<Chain>;
  chainId: 'chainA' | 'chainB';
  index: number;
  outputKeyIndex: number;
  move: (from: number, to: number) => void;
  remove: (index: number) => void;
  disabled: boolean;
  disableMoveDown?: boolean;
}

const OutputKey = ({
  register,
  chainId,
  index,
  outputKeyIndex,
  move,
  remove,
  disabled,
  disableMoveDown = false,
}: Props) => {
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'start'}
      spacing={1}
      sx={{ border: 'solid 1px #EBEBEB', borderRadius: 1, p: 1 }}
    >
      <Stack direction={'column'} spacing={1} width={'100%'}>
        <TextField
          variant="standard"
          label="Output key "
          placeholder="Variable name. e.g. post_draft"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: disabled,
          }}
          {...register(`${chainId}.${index}.outputKeys.${outputKeyIndex}.key`, {
            required: 'This field is required',
            pattern: {
              value: /^[a-zA-Z_]*$/,
              message: 'Invalid output key. Only letters and underscores are allowed',
            },
          })}
        />
        <TextField
          variant="standard"
          label="Description"
          placeholder="Description of the expected output"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: disabled
          }}
          multiline
          {...register(`${chainId}.${index}.outputKeys.${outputKeyIndex}.description`)}
        />
      </Stack>
      <Stack direction={'row'} spacing={0}>
        <IconButton
          disabled={outputKeyIndex === 0}
          disableRipple
          onClick={() => move(outputKeyIndex, outputKeyIndex - 1)}
          sx={{ '&:hover': { color: 'red' } }}
        >
          <ArrowUpward sx={{ width: 20 }} />
        </IconButton>
        <IconButton
          disabled={disableMoveDown}
          disableRipple
          onClick={() => move(outputKeyIndex, outputKeyIndex + 1)}
          sx={{ '&:hover': { color: 'red' } }}
        >
          <ArrowDownward sx={{ width: 20 }} />
        </IconButton>
        <Tooltip title={`Remove output key`} placement="top">
          <IconButton
            disableRipple
            onClick={() => remove(outputKeyIndex)}
            sx={{ '&:hover': { color: 'red' } }}
          >
            <RemoveCircleOutline sx={{ width: 20 }} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default OutputKey;
