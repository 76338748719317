import { createContext, ReactNode, useEffect, useState } from 'react';
import { RunsFilter } from '../__generated__/graphql';

interface RunsFilterContextType {
  filter: RunsFilter;
  setFilter: (filter: RunsFilter) => void;
  filterActive: boolean;
}

const initialState: RunsFilterContextType = {
  filter: {},
  setFilter: () => {},
  filterActive: false,
};

export const RunsFilterContext = createContext<RunsFilterContextType>(initialState);

export const RunsFilterProvider = ({ children }: { children: ReactNode }) => {
  const storedStateRaw = JSON.parse(localStorage.getItem('prompterRunsFilters') || '{}');
  const storedState = Object.keys(storedStateRaw).reduce<Partial<RunsFilter>>(
    (acc, key) => {
      if (key in initialState.filter) {
        acc[key as keyof RunsFilter] = storedStateRaw[key];
      }
      return acc;
    },
    {},
  );
  const defaultState = { ...initialState.filter, ...storedState };
  const [filter, setFilter] = useState<RunsFilter>(defaultState);

  const filterActive =
    filter.lifecycleState?.length ||
    filter.dateFrom ||
    filter.dateTo ||
    filter.brandIds?.length ||
    filter.createdById ||
    filter.shellId ||
    filter.chainId;

  useEffect(() => {
    localStorage.setItem('prompterRunsFilters', JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem('prompterRunsFilters') || '');
    if (filter) {
      setFilter(filter);
    }
  }, []);

  return (
    <RunsFilterContext.Provider value={{ filter, setFilter, filterActive }}>
      {children}
    </RunsFilterContext.Provider>
  );
};
