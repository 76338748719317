import { Person } from '@mui/icons-material';
import { Avatar as MuiAvatar, SxProps, Theme } from '@mui/material';

interface Props {
  name?: string;
  avatar?: string;
  size?: number;
  backgroundColor?: string;
  lastName?: string;
  onClick?: () => void;
  onContextMenu?: () => void;
  active?: boolean;
  sx?: SxProps<Theme>;
}

const Avatar = ({
  name,
  avatar,
  size,
  backgroundColor = '#BDBDBD',
  lastName,
  onClick,
  onContextMenu = () => {},
  sx = {},
}: Props) => {
  return (
    <MuiAvatar
      alt={`${name} avatar`}
      src={avatar}
      sx={{
        width: size || 32,
        height: size || 32,
        backgroundColor: backgroundColor + ' !important',
        fontSize: '1rem',
        ...sx,
      }}
      onClick={onClick}
      onContextMenu={(e) => {
        e.preventDefault();
        onContextMenu();
      }}
    >
      {!name && !avatar && (
        <Person sx={{ fontSize: `${size ? size / 32 + 'rem' : '1rem'}` }} />
      )}
      {name && name[0]}
      {lastName && lastName[0]}
    </MuiAvatar>
  );
};

export default Avatar;
