import { Favorite } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

interface Props {
  handleModalNewPost: () => void;
}

const PostDemoCard = ({ handleModalNewPost }: Props) => {
  return (
    <Card
      sx={{
        width: { xs: '100%', sm: '378.75px' },
        border: 'solid 1px #EBEBEB',
      }}
    >
      <CardHeader
        title={
          <Stack direction={'column'} gap={1}>
            <Typography fontSize="18px" fontWeight="bold">
              Still haven't created any posts. Create one now!
            </Typography>
          </Stack>
        }
      />
      <CardContent style={{ paddingTop: 0 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={1}
        >
          <Skeleton variant="circular" width={40} height={40} animation={false} />
          <Skeleton variant="text" width="30%" height={20} animation={false} />
          <Favorite
            sx={{
              color: 'rgba(41, 41, 41, 0.11)',
            }}
          />
        </Stack>
        <Skeleton variant="text" width="80%" height={20} animation={false} />
        <Skeleton variant="text" width="60%" height={20} animation={false} />
        <Skeleton
          variant="text"
          width="40%"
          height={20}
          style={{ marginBottom: 16 }}
          animation={false}
        />

        <Skeleton variant="rectangular" width="100%" height={200} animation={false} />
      </CardContent>
      <CardActions sx={{ paddingX: '10px', paddingBottom: '10px' }}>
        <Button
          fullWidth
          sx={{ backgroundColor: 'rgba(247, 247, 247, 1)', border: 'none' }}
          onClick={handleModalNewPost}
          data-testid="share-button"
        >
          <Typography fontWeight="bold">Create first post</Typography>
        </Button>
      </CardActions>
    </Card>
  );
};

export default PostDemoCard;
