export const propertyOptions = [
  { label: 'Alquiler', value: 'alquiler' },
  { label: 'Venta', value: 'venta' },
];

export const propertySquareMeterOptions = [
  { label: '80 mt2', value: 80 },
  { label: '90 mt2', value: 90 },
  { label: '100 mt2', value: 100 },
  { label: '200 mt2', value: 200 },
  { label: '250 mt2', value: 250 },
  { label: '300 mt2', value: 300 },
];

export const propertyFlourOptions = Array.from({ length: 30 }, (_, i) => ({
  label: `${i + 1}`,
  value: i + 1,
}));

export const messageTonality = [
  {
    emoji: '🌟',
    name: 'friendly',
    label: 'Amigable',
  },
  {
    emoji: '😊',
    name: 'personal',
    label: 'Cercano',
  },
  {
    emoji: '💼',
    name: 'formal',
    label: 'Formal',
  },
  {
    emoji: '🚀',
    name: 'creative',
    label: 'Creativo',
  },
  {
    emoji: '👩‍💼',
    name: 'professional',
    label: 'Profesional',
  },
  {
    emoji: '🏢',
    name: 'commercial',
    label: 'Comercial',
  },
];

export const selectOneToTwentyFive = Array.from({ length: 10 }, (_, i) => ({
  label: `${i}`,
  value: i
}));


export const propertyAttributes = [
  'Aire acondicionado',
  'Ascensor',
  'Garaje',
  'Piscina',
  'Terraza',
  'Balcón',
  'Jardín',
  'Patio',
  'Cocina Equipada',
  'Entorno Tranquilo',
  'Centro',
];
