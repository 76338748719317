import { InsertEmoticon, Send } from '@mui/icons-material';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useRef, useState } from 'react';
import { MessagesTeam, RoleChat } from '../../__generated__/graphql';
import { DeviceContext } from '../../context/DeviceContext';
import { getFullName } from '../../utils';
import Message from './Message';
import EmojiSelector from '../SelectorComponents/EmojiSelector';
import { useTranslation } from 'react-i18next';

const Chat = ({
  messages,
  handleSendMessage,
  writing,
}: {
  messages: MessagesTeam[];
  handleSendMessage: (message: string) => void;
  writing: boolean;
}) => {
  const [message, setMessage] = useState('');
  const [emojiComponentOpen, setEmojiComponentOpen] = useState(false);

  const { isMobile } = useContext(DeviceContext);
  const ref = useRef<HTMLDivElement>(null);
  const anchorRef = useRef(null);

  const isSameSender = (current: MessagesTeam, previous: MessagesTeam) => {
    return (
      previous && current.userId === previous.userId && current.role === previous.role
    );
  };

  const getMt = (current: MessagesTeam, previous: MessagesTeam) => {
    if (isSameSender(current, previous)) return 0.1;
    return 1;
  };

  const handleEmojiComponentOpen = () => {
    setEmojiComponentOpen(true);
  };

  const handleEmojiInputChange = (emoji: string) => {
    setMessage((message) => message + emoji);
  };

  function getRelativeDateString(inputDate: Date): string {
    // Today's date for comparison and calculations
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today to the start of the day

    // Normalize input date to the start of the day for accurate comparison
    const date = new Date(inputDate);
    date.setHours(0, 0, 0, 0);

    // Calculate the difference in days
    const diffDays = (today.getTime() - date.getTime()) / (1000 * 3600 * 24);

    if (diffDays < 1) {
      return 'Today';
    } else if (diffDays < 2) {
      return 'Yesterday';
    } else if (diffDays < 7) {
      // Get the day of the week and format it
      return `Last ${date.toLocaleDateString('en-US', { weekday: 'long' })}`;
    } else {
      // Return the localized date string for dates older than 7 days
      return date.toLocaleDateString();
    }
  }

  let messageComponents = [];
  for (let i = messages.length - 1; i >= 0; i--) {
    const message = messages[i];

    if (message.role !== RoleChat.User && message.role !== RoleChat.System) continue;
    const previousMessage = messages[i - 1];
    const index = messages.length - 1 - i;

    let avatar = null;
    let name = '';
    if (message.userOrBrand?.__typename === 'User') {
      avatar = message.userOrBrand.avatar;
      name = getFullName(message.userOrBrand);
    } else if (message.userOrBrand?.__typename === 'Brand') {
      avatar = message.userOrBrand.account?.avatar;
      name = message.userOrBrand.name;
    }

    const messageComponent = (
      <Message
        userOrBrand={message.userOrBrand}
        key={'message-' + index}
        role={message.role}
        text={message.content}
        mt={getMt(message, previousMessage)}
        avatar={avatar || null}
        showAvatarAndName={!isSameSender(message, previousMessage)}
        time={message.time}
        name={name || 'Unknown'}
      />
    );
    messageComponents.push({
      type: 'message',
      date: new Date(message.time),
      component: messageComponent,
    });
  }

  const uniqueDays = Array.from(
    new Set(messages.map((message) => new Date(message.time).setHours(0, 0, 0, 0))),
  );

  const dateComponents = uniqueDays.map((date) => ({
    type: 'date',
    date: new Date(date),
    component: (
      <Box textAlign={'center'} my={0.5} key={`date-chip-${date}`}>
        <Chip size="small" label={getRelativeDateString(new Date(date))} />
      </Box>
    ),
  }));

  messageComponents.push(...dateComponents);
  messageComponents.sort((a, b) => {
    return b.date.getTime() - a.date.getTime();
  });

  messageComponents = messageComponents.map((c) => c.component);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Enter') {
      event.preventDefault();
      setMessage('');
      handleSendMessage(message);
    }
  };
  const { t } = useTranslation();

  return (
    <Stack direction="column" height="100%" spacing={1}>
      {!isMobile && (
        <>
          <Typography variant="h6" p={2} pb={1} fontWeight={'bold'}>
            {t('Comment')}
          </Typography>
          <Divider></Divider>
        </>
      )}
      <Stack
        direction={'column-reverse'}
        style={{ overflowY: 'auto' }}
        flexGrow={1}
        p={2}
      >
        {messageComponents}
      </Stack>
      <Stack direction={'row'} sx={{ p: 2, paddingTop: 0, marginTop: 0 }}>
        <TextField
          inputRef={ref}
          key="chat-input"
          variant="outlined"
          autoComplete="off"
          fullWidth
          value={message}
          size="small"
          multiline
          maxRows={5}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={t('Type a comment...')}
          onKeyDown={(e) => {
            if (!writing) handleKeyDown(e);
          }}
          InputProps={{
            disabled: writing,
            style: {
              borderRadius: '8px',
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={writing || !message}
                  disableRipple
                  onClick={(e) => {
                    e.preventDefault();
                    setMessage('');
                    handleSendMessage(message);
                    ref.current?.focus();
                  }}
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderWidth: '1px',
                borderColor: '#BBBBBB',
              },
              '&.Mui-focused fieldset': {
                borderWidth: '1px',
                borderColor: '#BBBBBB',
              },
            },
          }}
        />
        {!isMobile && (
          <IconButton
            data-testid="emoji-button"
            onClick={handleEmojiComponentOpen}
            ref={anchorRef}
          >
            <InsertEmoticon />
          </IconButton>
        )}
        <EmojiSelector
          open={emojiComponentOpen}
          anchorElement={anchorRef.current}
          onClose={() => setEmojiComponentOpen(false)}
          onChange={(emoji) => handleEmojiInputChange(emoji)}
        />
      </Stack>
    </Stack>
  );
};

export default Chat;
