import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useController } from 'react-hook-form';

import InputLabel from './InputLabel';

interface ButtonsGroupsProps {
  name: string; // name prop for react-hook-form integration
  control: any; // control prop for react-hook-form integration
  options: string[];
  label: string;
  required: boolean
}

const ButtonsGroups = ({ options, name, control, label, required = false }: ButtonsGroupsProps) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const handleButtonClick = (option: string) => {
    if (value.includes(option)) {
      // Remove option from the selected list
      onChange(value.filter((item: string) => item !== option));
    } else {
      // Add option to the selected list
      onChange([...value, option]);
    }
  };

  return (
    <Stack gap={'32px'}>
      <InputLabel label={label} required/>
      <Stack
        gap={'10px'}
        justifyContent={'center'}
        alignItems={'center'}
        direction={'row'}
        flexWrap={'wrap'}
      >
        {options.map((option, index) => (
          <Button
            onClick={() => handleButtonClick(option)}
            key={index}
            sx={{
              padding: '8px 22px',
              borderRadius: '8px',
              fontWeight: 700,
              backgroundColor: value.includes(option) ? '#FFEBF5' : '#FFFFFF',
              color: value.includes(option) ? 'primary' : '#A69BA1',
              borderColor: value.includes(option) ? 'primary' : '#A69BA1',
            }}
            variant={'outlined'}
          >
            {option}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};

export default ButtonsGroups;
