import { useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { FC, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ReportTrendlines } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { GET_METRICS_TO_REPORTS } from '../../graphql/queries';
import { formatDateMonths } from '../../utils';
import GraphicPieChart from '../Reports/GraphicPieChart';
import TrendlineCard from '../Reports/TrendlineCard';
import { useTranslation } from 'react-i18next';

interface Props {
  dateInit: Date;
  dateEnd: Date;
  differenceInDays: number;
}

const CompanyPerformanceV2: FC<Props> = ({ dateInit, dateEnd, differenceInDays }) => {
  const { user } = useContext(AuthContext);

  const init = new Date(dateInit);
  const end = new Date(dateEnd);

  const defaultDateInit = init.toISOString().substring(0, 10);
  const defaultDateEnd = end.toISOString().substring(0, 10);

  init.setDate(init.getDate() - 1);
  const twoWeeksAgoInit = new Date(init) 
  twoWeeksAgoInit.setDate(twoWeeksAgoInit.getDate() - differenceInDays);
  const twoWeeksAgoEnd = new Date(init);

  const defaultDateInitTwo = twoWeeksAgoInit.toISOString().substring(0, 10);
  const defaultDateEndTwo = twoWeeksAgoEnd.toISOString().substring(0, 10);


  const {
    data: metricActivity,
    loading,
    refetch,
  } = useQuery(GET_METRICS_TO_REPORTS, {
    variables: {
      shellId: user?.shellId as string,
      startDateOne: defaultDateInit,
      dateEndOne: defaultDateEnd,
      startDateTwo: defaultDateInitTwo,
      dateEndTwo: defaultDateEndTwo,
    },
    skip: !user?.brandSelectedId,
    notifyOnNetworkStatusChange: true,
  });


  const { register, control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      startDateOne: defaultDateInit,
      dateEndOne: defaultDateEnd,
      startDateTwo: defaultDateInitTwo,
      dateEndTwo: defaultDateEndTwo,
    },
  });

  const onSearch = (data: any) => {
    refetch({
      shellId: user?.shellId as string,
      startDateOne: data.startDateOne,
      dateEndOne: data.dateEndOne,
      startDateTwo: data.startDateTwo,
      dateEndTwo: data.dateEndTwo,
    });
  };

  const watchStartDateOne = watch('startDateOne');
  const watchDateEndOne = watch('dateEndOne');
  const watchStartDateTwo = watch('startDateTwo');
  const watchDateEndTwo = watch('dateEndTwo');

  useEffect(() => {
    if (user?.brandSelectedId) {
      // handleGetData();
      refetch();
    }
  }, [user?.brandSelectedId, refetch]);

  useEffect(() => {
    if (watchStartDateOne && watchDateEndOne) {
      const startDateOne = new Date(watchStartDateOne);
      const dateEndOne = new Date(watchDateEndOne);

      const timeDifference = dateEndOne.getTime() - startDateOne.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

      const startDateTwo = new Date(startDateOne);
      startDateTwo.setDate(startDateTwo.getDate() - daysDifference - 1);

      const dateEndTwo = new Date(dateEndOne);
      dateEndTwo.setDate(dateEndTwo.getDate() - daysDifference - 1);

      const startDateTwoISO = startDateTwo.toISOString().substring(0, 10);
      const dateEndTwoISO = dateEndTwo.toISOString().substring(0, 10);

      setValue('startDateTwo', startDateTwoISO);
      setValue('dateEndTwo', dateEndTwoISO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchStartDateOne, watchDateEndOne]);
  const { t } = useTranslation();
  return (
    <>
      <Box gap={2} alignContent="center">
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize="1.6rem" fontWeight={600}>
            {t('Trendlines')}
          </Typography>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box>
              <Box display="flex" gap={2}>
                <Controller
                  name={'startDateOne'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      size="small"
                      label="From"
                      type="date"
                      variant="standard"
                      sx={{
                        '& label': {
                          marginTop: value ? '0px' : '-20px',
                        },
                        '& label.Mui-focused': {
                          marginTop: '0px',
                        },
                        '& input': {
                          pl: '0!important',
                        },
                      }}
                      {...register('startDateOne')}
                    />
                  )}
                />

                <Controller
                  name={'dateEndOne'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      size="small"
                      label="To"
                      type="date"
                      variant="standard"
                      sx={{
                        '& label': {
                          marginTop: value ? '0px' : '-20px',
                        },
                        '& label.Mui-focused': {
                          marginTop: '0px',
                        },
                        '& input': {
                          pl: '0!important',
                        },
                      }}
                      {...register('dateEndOne')}
                    />
                  )}
                />
              </Box>

              <Box display="flex" gap={2}>
                <Controller
                  name={'startDateTwo'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      size="small"
                      label={t('From')}
                      type="date"
                      variant="standard"
                      disabled
                      // value={watchStartDateOne + 7}
                      sx={{
                        '& label': {
                          marginTop: value ? '0px' : '-20px',
                        },
                        '& label.Mui-focused': {
                          marginTop: '0px',
                        },
                        '& input': {
                          pl: '0!important',
                        },
                      }}
                      {...register('startDateTwo')}
                    />
                  )}
                />

                <Controller
                  name={'dateEndTwo'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      size="small"
                      label={t('To')}
                      type="date"
                      variant="standard"
                      disabled
                      sx={{
                        '& label': {
                          marginTop: value ? '0px' : '-20px',
                        },
                        '& label.Mui-focused': {
                          // color: 'black',
                          marginTop: '0px',
                        },
                        '& input': {
                          pl: '0!important',
                        },
                      }}
                      {...register('dateEndTwo')}
                    />
                  )}
                />
              </Box>
            </Box>

            <LoadingButton
              variant="contained"
              onClick={handleSubmit(onSearch)}
              loading={loading}
            >
              {t('Filter')}
            </LoadingButton>
          </Box>
        </Box>

        {metricActivity &&
          !!metricActivity.getMetricsToReports?.selectorOne.length &&
          !!metricActivity.getMetricsToReports.selectorTwo.length && (
            <Grid container marginTop=".1rem" spacing={4}>
              <Grid item xs={12} md={6}>
                <TrendlineCard
                  title="Impressions"
                  countNew={
                    metricActivity.getMetricsToReports!!.selectorOne as ReportTrendlines[]
                  }
                  countOld={
                    metricActivity!!.getMetricsToReports!!
                      .selectorTwo as ReportTrendlines[]
                  }
                  dateNew={`${formatDateMonths(watchStartDateOne)} - ${formatDateMonths(
                    watchDateEndOne,
                  )}`}
                  dateOld={`${formatDateMonths(watchStartDateTwo)} - ${formatDateMonths(
                    watchDateEndTwo,
                  )}`}
                  type="numImpressions"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TrendlineCard
                  title={t('Comments')}
                  countNew={
                    metricActivity.getMetricsToReports!!.selectorOne as ReportTrendlines[]
                  }
                  countOld={
                    metricActivity!!.getMetricsToReports!!
                      .selectorTwo as ReportTrendlines[]
                  }
                  dateNew={`${formatDateMonths(watchStartDateOne)} - ${formatDateMonths(
                    watchDateEndOne,
                  )}`}
                  dateOld={`${formatDateMonths(watchStartDateTwo)} - ${formatDateMonths(
                    watchDateEndTwo,
                  )}`}
                  type="numComments"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TrendlineCard
                  title={t('Reactions')}
                  countNew={
                    metricActivity.getMetricsToReports!!.selectorOne as ReportTrendlines[]
                  }
                  countOld={
                    metricActivity!!.getMetricsToReports!!
                      .selectorTwo as ReportTrendlines[]
                  }
                  dateNew={`${formatDateMonths(watchStartDateOne)} - ${formatDateMonths(
                    watchDateEndOne,
                  )}`}
                  dateOld={`${formatDateMonths(watchStartDateTwo)} - ${formatDateMonths(
                    watchDateEndTwo,
                  )}`}
                  type="numLikes"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TrendlineCard
                  title={t('Shares')}
                  countNew={
                    metricActivity.getMetricsToReports!!.selectorOne as ReportTrendlines[]
                  }
                  countOld={
                    metricActivity!!.getMetricsToReports!!
                      .selectorTwo as ReportTrendlines[]
                  }
                  dateNew={`${formatDateMonths(watchStartDateOne)} - ${formatDateMonths(
                    watchDateEndOne,
                  )}`}
                  dateOld={`${formatDateMonths(watchStartDateTwo)} - ${formatDateMonths(
                    watchDateEndTwo,
                  )}`}
                  type="totalShares"
                />
              </Grid>
            </Grid>
          )}

        <Box marginTop="4rem">
          <Typography component="span" fontSize="1.6rem" fontWeight={600}>
            {t('Visitor attributes')}
          </Typography>
          <Typography
            component="span"
            fontSize="1rem"
            marginLeft="2rem"
            fontStyle="italic"
          >
            {watchStartDateOne} - {watchDateEndOne}
          </Typography>
        </Box>
        <Typography component="h6" fontSize="1.4rem">
          {t('Your company page has been visited')}{' '}
          {metricActivity?.getMetricsToReports?.totalViews || 0} {t('times')}
        </Typography>

        <Grid container marginTop="2rem">
          {!!metricActivity &&
            !!metricActivity.getMetricsToReports?.pieData.length &&
            metricActivity?.getMetricsToReports!!.pieData?.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <GraphicPieChart
                  data={item!!.statsArray as { name: string; value: number }[]}
                  name={item!!.id!!}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default CompanyPerformanceV2;
