import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { JobDescriptionIsproxInput } from '../../../__generated__/graphql';
import FormActions from '../../components/FormActions';
import InputLabel from '../../components/InputLabel';
import Select from '../../components/Select';
import Slider from '../../components/Slider';
import { useFormContext } from '../../contexts/FormContext';
import { colorPrincipal, colorSecundario, messageTonality } from './consts';

const Step3 = ({ onSubmit, loading }: { loading: boolean, onSubmit: (data: JobDescriptionIsproxInput) => void }) => {
  const { updateFormData, formData, prevStep } = useFormContext();
  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues: formData,
  });

  const handleGoBack = () => {
    const currentData = getValues();
    updateFormData(currentData);
    prevStep();
  };

  const language = [
    { label: 'Español', value: 'Español' },
    { label: 'Inglés', value: 'Inglés' },
    { label: 'Catalán', value: 'Catalán' },
  ];

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      overflow={'auto'}
      gap={'32px'}
    >
      <Select
        options={language}
        name="language"
        label="¿En que idioma quieres hacer esta oferta?"
        required
        control={control}
        borderColor={colorPrincipal}
        disabled={loading}
        
      />
      <InputLabel label="¿Cómo describirías el tono o estilo de la oferta?" />
      <Stack direction={'column'} gap={'18px'}>
        {messageTonality.map((tonality) => {
          return (
            <Slider
              key={tonality.name}
              label={tonality.label}
              name={`tone.${tonality.name}`}
              emoji={tonality.emoji}
              control={control}
              color={colorPrincipal}
              disabled={loading}
            />
          );
        })}
      </Stack>
      <FormActions
        isValid={formState.isValid}
        handleGoBack={handleGoBack}
        lastStep
        buttonId="lm-job-description-step-3-button"
        colorButton={colorPrincipal}
        colorHover={colorSecundario}
        loading={loading}
      />
    </Stack>
  );
};

export default Step3;
