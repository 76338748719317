import { Card } from '@mui/material';
import { Group } from '../../__generated__/graphql';
import BrandGroupsForm from './BrandGroupsForm';
interface Props {
  groupSelected: Group;
}

const BrandGroupEditor = ({ groupSelected }: Props) => {
  return (
    <Card
      sx={{
        borderRadius: '20px',
        padding: '20px',
        width: '100%',
      }}
    >
      <BrandGroupsForm groupSelected={groupSelected} key={groupSelected?._id} />
    </Card>
  );
};

export default BrandGroupEditor;
