import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface DatePickerRangeProps {
  dateInit: Date;
  dateEnd: Date;
  dateFilter: (dateInit: string, dateEnd: string) => void;
  loadingButton: boolean;
}

const DatePickerRange: FC<DatePickerRangeProps> = ({
  dateInit,
  dateEnd,
  loadingButton,
  dateFilter,
}) => {
  const { t } = useTranslation();
  const { register, control, watch } = useForm({
    defaultValues: {
      dateInit: dateInit.toISOString().substring(0, 10),
      dateEnd: dateEnd.toISOString().substring(0, 10),
    },
  });

  const dateInitValue = watch('dateInit');
  const dateEndValue = watch('dateEnd');

  return (
    <Stack flexDirection="row" gap={2} justifyContent="flex-end" marginBottom={2}>
      <Controller
        name="dateInit"
        control={control}
        rules={{
          validate: (value) => {
            if (dateEndValue && value >= dateEndValue) {
              return 'La fecha inicial debe ser anterior a la fecha final';
            }
            return true;
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            size="small"
            label="From"
            type="date"
            variant="standard"
            // onChange={onChange}
            value={value}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: dateEndValue
                ? new Date(
                    new Date(dateEndValue).setDate(new Date(dateEndValue).getDate() - 1),
                  )
                    .toISOString()
                    .split('T')[0]
                : undefined,
            }}
            error={!!error}
            helperText={error ? error.message : null}
            sx={{
              '& label': {
                marginTop: value ? '0px' : '-20px',
              },
              '& label.Mui-focused': {
                marginTop: '0px',
              },
              '& input': {
                pl: '0!important',
              },
            }}
            {...register('dateInit')}
          />
        )}
      />

      <Controller
        name="dateEnd"
        control={control}
        rules={{
          validate: (value) => {
            if (dateInitValue && value <= dateInitValue) {
              return 'La fecha final debe ser posterior a la fecha inicial';
            }
            return true;
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            size="small"
            label="To"
            type="date"
            variant="standard"
            // onChange={onChange}
            value={value}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: dateInitValue
                ? new Date(
                    new Date(dateInitValue).setDate(
                      new Date(dateInitValue).getDate() + 1,
                    ),
                  )
                    .toISOString()
                    .split('T')[0]
                : undefined,
            }}
            error={!!error}
            helperText={error ? error.message : null}
            sx={{
              '& label': {
                marginTop: value ? '0px' : '-20px',
              },
              '& label.Mui-focused': {
                marginTop: '0px',
              },
              '& input': {
                pl: '0!important',
              },
            }}
            {...register('dateEnd')}
          />
        )}
      />

      <LoadingButton
        variant="contained"
        disabled={watch('dateInit') === '' || watch('dateEnd') === ''}
        onClick={() => dateFilter(watch('dateInit'), watch('dateEnd'))}
        loading={loadingButton}
      >
         {t('Filter')}
      </LoadingButton>
    </Stack>
  );
};

export default DatePickerRange;
