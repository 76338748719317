import { LoadingButton } from '@mui/lab';
import { ChangeEvent, FC, useState } from 'react';
import { isValidContent, uploadMedia } from '../../utils';

const inputStyle = {
  maxWidth: '500px',
};

interface UploadLogoButtonProps {
  setLogo: (logo: string) => void;
  setErrorMessage: (message: string) => void;
  buttonProps?: {
    variant?: 'text' | 'outlined' | 'contained';
    sx?: object;
  };
}

const UploadLogoButton: FC<UploadLogoButtonProps> = ({
  setErrorMessage,
  setLogo,
  buttonProps,
}) => {
  const [loading, setLoading] = useState(false);


  const handleUploadLogo = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    setLoading(true);
    uploadMedia(e.target.files[0], 'companyLogo')
      .then((url) => {
        setLogo(url.url!);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
      });
  };

  return (
    <LoadingButton
      variant="outlined"
      sx={inputStyle}
      component="label"
      aria-haspopup="true"
      loading={loading}
      {...buttonProps}
    >
      <input hidden accept=".svg" type="file" onChange={handleUploadLogo} />
      Upload logo
    </LoadingButton>
  );
};

export default UploadLogoButton;
