import { useMutation, useQuery } from '@apollo/client';
import { Clear, Schedule } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MultiSelectElement, TextFieldElement } from 'react-hook-form-mui';
import { useNavigate, useParams } from 'react-router-dom';
import { UserLifecycleState, UserPlan, UserRole } from '../../__generated__/graphql';
import Avatar from '../../components/Avatar/Avatar';
import UploadAvatarButton from '../../components/Avatar/UploadAvatarButton';
import DatePicker from '../../components/DatePicker/DatePicker';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { CREATE_USER, SEND_INVITE, UPDATE_USER } from '../../graphql/mutations';
import { GET_SHELLS, GET_USER } from '../../graphql/queries';
import { getShellNameById } from '../../utils';
import { useTranslation } from 'react-i18next';

const inputStyle = {
  maxWidth: '500px',
};

const CreateUser = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const navigate = useNavigate();

  const { isMobile } = useContext(DeviceContext);
  const { user: loggedInUser, refetchCurrentUser } = useContext(AuthContext);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);

  const [trialStartOpen, setTrialStartOpen] = useState<boolean>(false);
  const [trialEndOpen, setTrialEndOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const currentDate = useMemo(() => new Date(), []);

  const { data: shellsData } = useQuery(GET_SHELLS);
  const shells = shellsData?.shells || [];

  const { data, loading } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    variables: { userId: userId! },
    skip: !userId,
  });
  const user = data?.user;

  const { control, handleSubmit } = useForm<{
    avatar: string;
    lifecycleState: UserLifecycleState;
    email: string;
    firstName: string;
    lastName: string;
    role: UserRole[];
    shellId: string;
    sendInvite: boolean;
    trialStart: Date;
    trialEnd: Date;
    activeTrial: boolean;
    plan: UserPlan[];
  }>({
    defaultValues: {
      avatar: user?.avatar || '',
      lifecycleState: user?.lifecycleState || UserLifecycleState.Active,
      email: user?.email || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      role: user?.role || [UserRole.User],
      shellId: user?.shellId || '',
      sendInvite: false,
      trialStart: user?.trialStart || currentDate,
      trialEnd:
        user?.trialEnd || new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000),
      activeTrial: user?.activeTrial || false,
      plan: user?.plan || [],
    },
    values: {
      avatar: user?.avatar || '',
      lifecycleState: user?.lifecycleState || UserLifecycleState.Active,
      email: user?.email || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      role: user?.role || [UserRole.User],
      shellId: user?.shellId || '',
      sendInvite: false,
      trialStart: user?.trialStart || currentDate,
      trialEnd:
        user?.trialEnd || new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000),
      activeTrial: user?.activeTrial || false,
      plan: user?.plan || [],
    },
  });

  const [sendInvite] = useMutation(SEND_INVITE, {
    onCompleted: () => {
      setSuccessMessage(t('Invite sent successfully'));
    },
    onError: (error) => {
      setErrorMessage(t(`Invite couldn't be sent: ${error.message}`));
    },
  });

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: ({ createUser }) => {
      if (!createUser.success) {
        setErrorMessage(createUser.message || t('There was an error creating the user'));
        return;
      }
      setSuccessMessage(createUser.message || t('User created successfully'));
      navigate('/users');
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: ({ updateUser }) => {
      if (!updateUser.success) {
        setErrorMessage(updateUser.message || t('There was an error updating the user'));
        return;
      }

      setSuccessMessage(updateUser.message || t('User updated successfully'));
      if (user?._id === loggedInUser?._id) {
        refetchCurrentUser();
      }

      navigate('/users');
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
  });

  const onSuccess = ({
    lifecycleState,
    email,
    firstName,
    lastName,
    role,
    plan,
    shellId,
    trialStart,
    trialEnd,
    avatar,
    activeTrial,
  }: {
    lifecycleState: UserLifecycleState;
    email: string;
    firstName: string;
    lastName: string;
    plan: UserPlan[];
    role: UserRole[];
    shellId: string;
    sendInvite: boolean;
    trialStart: Date;
    trialEnd: Date;
    avatar: string;
    activeTrial: boolean;
  }) => {
    const input = {
      lifecycleState,
      email,
      firstName,
      lastName,
      role,
      plan,
      shellId,
      shellName: getShellNameById(shells, shellId),
      avatar,
      trialStart,
      trialEnd,
      activeTrial,
    };

    user
      ? updateUser({ variables: { userId: user._id, input } })
      : createUser({ variables: { input: { ...input } } });
  };

  const disabledOption = (currentSelection: UserPlan[], plan: UserPlan): boolean => {
    const isAdvancedSelected = currentSelection.includes(UserPlan.Advanced);
    const isAdvocacySelected = currentSelection.includes(UserPlan.Advocacy);
    const isCorporateSelected = currentSelection.includes(UserPlan.Corporate);

    switch (plan) {
      case UserPlan.Inactive:
        return isAdvancedSelected || isAdvocacySelected || isCorporateSelected;
      case UserPlan.Advocacy:
        return (
          (isAdvancedSelected && !isCorporateSelected) ||
          (isCorporateSelected && isAdvancedSelected)
        );
      case UserPlan.Advanced:
        return (
          (isAdvocacySelected && !isCorporateSelected) ||
          (isCorporateSelected && isAdvocacySelected)
        );
      case UserPlan.Corporate:
        return isAdvancedSelected && isAdvocacySelected;
      default:
        return false;
    }
  };

  const handleStartScheduleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setTrialStartOpen(true);
  };

  const handleEndScheduleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setTrialEndOpen(true);
  };

  const handleSendInvite = () => {
    setOpen(false);
    sendInvite({
      variables: {
        userId: user!._id,
      },
    });
  };

  return (
    <Stack
      direction="column"
      p={isMobile ? '40px 30px 80px 30px' : '40px 25%'}
      spacing={4}
      alignItems={isMobile ? 'center' : 'start'}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
            {user ? t('Edit') : t('New')} {t('User')}
          </Typography>
          <form onSubmit={handleSubmit(onSuccess)} style={{ width: '100%' }}>
            <Stack sx={{ width: '100%' }} direction="column" spacing={2}>
              <Controller
                name="lifecycleState"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={Object.values(UserLifecycleState)}
                    getOptionLabel={(option) => option}
                    filterOptions={(options) => {
                      return options.filter((option) =>
                        [UserLifecycleState.Active, UserLifecycleState.Inactive].includes(
                          option,
                        ),
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Lifecycle State')}
                        required
                        sx={inputStyle}
                      />
                    )}
                    onChange={(_, data) => field.onChange(data)}
                  />
                )}
              />
              <Controller
                name="plan"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    value={value}
                    options={Object.values(UserPlan)}
                    getOptionDisabled={(option) => disabledOption(value, option)}
                    multiple
                    disableCloseOnSelect
                    size="medium"
                    onChange={(e, data) => {
                      if (data.length === 0) {
                        data = [UserPlan.Inactive];
                      } else if (data.length > 0 && data.includes(UserPlan.Inactive)) {
                        data = data.filter((plan) => plan !== UserPlan.Inactive);
                      }

                      onChange(data);
                    }}
                    data-testid="new-plan-user-select"
                    renderInput={(params) => (
                      <TextField {...params} label={t('Plan')} sx={inputStyle} />
                    )}
                  />
                )}
              />
              <Stack direction={'row'} sx={inputStyle} gap={1}>
                <Controller
                  name="trialStart"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <TextField
                        sx={{ flexGrow: 1 }}
                        label={t('Trial start date')}
                        value={value ? new Date(value).toLocaleString() : ''}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          readOnly: true,
                          endAdornment: value && (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                onChange(null);
                              }}
                            >
                              <Clear />
                            </IconButton>
                          ),
                        }}
                      />
                      <IconButton
                        onClick={handleStartScheduleClick}
                        disableRipple
                        data-testid="post-schedule"
                      >
                        <Schedule />
                      </IconButton>
                      <DatePicker
                        date={value}
                        disablePast={false}
                        open={trialStartOpen}
                        anchorElement={anchorEl!}
                        onClose={() => setTrialStartOpen(false)}
                        onSubmit={(date) => {
                          onChange(date);
                          setTrialStartOpen(false);
                        }}
                        primaryActionTitle={t('Schedule')}
                      />
                    </>
                  )}
                />
              </Stack>
              <Stack direction={'row'} sx={inputStyle} gap={1}>
                <Controller
                  name="trialEnd"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <TextField
                        sx={{ flexGrow: 1 }}
                        label={t('Trial end date')}
                        value={value ? new Date(value).toLocaleString() : ''}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          readOnly: true,
                          endAdornment: value && (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                onChange(null);
                              }}
                            >
                              <Clear />
                            </IconButton>
                          ),
                        }}
                      />
                      <IconButton
                        onClick={handleEndScheduleClick}
                        data-testid="post-schedule"
                        disableRipple
                      >
                        <Schedule />
                      </IconButton>
                      <DatePicker
                        date={value}
                        disablePast={false}
                        open={trialEndOpen}
                        anchorElement={anchorEl!}
                        onClose={() => setTrialEndOpen(false)}
                        onSubmit={(date) => {
                          onChange(date);
                          setTrialEndOpen(false);
                        }}
                        primaryActionTitle={t('Schedule')}
                      />
                    </>
                  )}
                />
              </Stack>
              <Stack direction="row" alignItems={'center'}>
                <Controller
                  name="activeTrial"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Checkbox
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    );
                  }}
                />
                <Typography sx={{ maxWidth: '400px' }}>
                  <b>{t('Active Trial')}: </b>
                  {t('The user is on trial')}
                </Typography>
              </Stack>
              <Divider sx={inputStyle} />
              <TextFieldElement
                label={t('Email')}
                name="email"
                control={control}
                type="email"
                required
                sx={inputStyle}
                data-testid="new-user-email-input"
              />
              <TextFieldElement
                label={t('First Name')}
                name="firstName"
                control={control}
                required
                sx={inputStyle}
                data-testid="new-user-first-name-input"
              />
              <TextFieldElement
                label={t('Last Name')}
                name="lastName"
                control={control}
                required
                sx={inputStyle}
                data-testid="new-user-last-name-input"
              />
              <MultiSelectElement
                label={t('Role')}
                name="role"
                control={control}
                options={Object.values(UserRole)}
                required
                sx={inputStyle}
                data-testid="new-user-role-select"
              />
              <Controller
                name="shellId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={shells}
                    getOptionLabel={(option) => option.name}
                    value={shells.find((shell) => shell._id === value) || null} // Find the shell object that matches the field value or null if not found
                    onChange={(event, newValue) => {
                      onChange(newValue?._id); // Update the form field with the _id of the selected shell
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Client')}
                        required
                        sx={inputStyle}
                        data-testid="new-user-shell-select"
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="avatar"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    {value ? (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={inputStyle}
                      >
                        <Avatar name="profile" avatar={value} size={64} />
                        <Button
                          variant="outlined"
                          component="label"
                          sx={{ ml: 2 }}
                          onClick={() => onChange(null)}
                        >
                          {t('Remove avatar')}
                        </Button>
                      </Stack>
                    ) : (
                      <UploadAvatarButton
                        setAvatar={onChange}
                        setErrorMessage={setErrorMessage}
                      />
                    )}
                  </>
                )}
              />
              {user && !user.uid && (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => setOpen(true)}
                    sx={inputStyle}
                  >
                    {t('Send invite')}
                  </Button>
                  <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t('Confirm Action')}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t('Are you sure you want to send the invite?')}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpen(false)}>{t('Cancel')}</Button>
                      <Button variant="contained" onClick={handleSendInvite} autoFocus>
                        {t('Yes')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}
              <Button
                variant="contained"
                type="submit"
                sx={inputStyle}
                data-testid="new-user-save-button"
              >
                {t('Save')}
              </Button>
            </Stack>
          </form>
        </>
      )}
    </Stack>
  );
};

export default CreateUser;
