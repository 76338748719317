import { Box, Avatar as MuiAvatar, Stack, Tooltip } from '@mui/material';
import Avatar from './Avatar';
import { InfoOutlined } from '@mui/icons-material';

interface Props {
  avatars: {
    id: string;
    name?: string;
    avatar?: string;
    backgroundColor?: string;
    lastName?: string;
    active: boolean;
  }[];
  onClick?: (id: string) => void;
  onContextMenu?: (id: string) => void;
  onClickSurplus?: () => void;
  size?: number;
  sizeAvatar?: number;
  max: number;
  titleTooltip: string;
  infoTooltip?: string;
  separator?: number;
}

export const AvatarGroup = ({
  avatars,
  max,
  size,
  sizeAvatar = 40,
  onClick = () => {},
  onContextMenu = () => {},
  onClickSurplus,
  titleTooltip,
  infoTooltip,
  separator = -0.3,
}: Props) => {
  return (
    <Stack direction="row" gap={1}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& > *': { margin: separator },
        }}
      >
        {avatars.slice(0, max).map((avatar, index) => (
          <Tooltip key={avatar.id} title={avatar.name}>
            <Box
              sx={{
                zIndex: 8 - index,
                '&:hover': {
                  zIndex: 8,
                },
              }}
            >
              <Avatar
                name={avatar?.name || ''}
                backgroundColor={avatar?.backgroundColor || undefined}
                avatar={avatar?.avatar || undefined}
                size={sizeAvatar}
                onClick={() => onClick(avatar.id)}
                onContextMenu={() => onContextMenu(avatar.id)}
                sx={{
                  borderColor: avatar.active ? 'primary.main' : '#fff',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  cursor: 'pointer',
                }}
              />
            </Box>
          </Tooltip>
        ))}

        {avatars.length > max && (
          <Tooltip title={titleTooltip}>
            <Box
              sx={{
                zIndex: max + 1,
              }}
            >
              <MuiAvatar
                alt={`+${avatars.length - 7}`}
                sx={{
                  width: size,
                  height: size,
                  backgroundColor: '#bbb !important',
                  fontSize: '1rem',
                  cursor: 'pointer',
                  '&:hover': {
                    zIndex: max + 1,
                  },
                }}
                onClick={onClickSurplus}
              >
                {`+${avatars.length - max}`}
              </MuiAvatar>
            </Box>
          </Tooltip>
        )}
      </Box>
      {infoTooltip && (
        <Tooltip title={infoTooltip}>
          <InfoOutlined color="primary" sx={{ width: '15px' }} />
        </Tooltip>
      )}
    </Stack>
  );
};
