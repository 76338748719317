import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material';
import { Insight } from '../../__generated__/graphql';
import { capitalize } from '../../utils';
import { useTranslation } from 'react-i18next';

interface Props {
  insight: Insight;
  handleReviewButton: () => void;
}

const InsightCard = (props: Props) => {
  const { t } = useTranslation();
  const { insight, handleReviewButton } = props;
  const [loadImage, setLoadImage] = useState(!!!insight.imgUrl);
  let media = insight.imgUrl || insight.videoUrl || [];
  const subtitle = new Date(insight.pubDate).toLocaleDateString();
  const [mediaFailed, setMediaFailed] = useState(false);

  let description =
    insight.description?.slice(0, 200) || insight.content?.slice(0, 200) || '';
  if (
    (insight.description && insight.description.length > 200) ||
    (insight.content && insight.content.length > 200)
  ) {
    description += '...';
  }

  return (
    <Card
      sx={{
        border: 'solid 1px #EBEBEB',
      }}
      data-testid="insight-card"
    >
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between" alignSelf="center">
            <Box display="flex">
              <Box maxHeight="100px">
                <img
                  src={insight.author?.profilePicture || './images/icons/news.png'}
                  style={{ width: '25px', height: '25px' }}
                  alt="linkedin"
                />
              </Box>
              <Box marginLeft="10px">
                <Typography variant="body2" color="text.primary" fontWeight={700}>
                  {capitalize(insight.source || '')}
                </Typography>
                <Typography variant="body2" color="text.primary">
                  {insight.author?.description}
                </Typography>
              </Box>
            </Box>

            <Typography variant="body2" color="text.primary" fontSize="14px">
              {subtitle}
            </Typography>
          </Box>
        }
        titleTypographyProps={{
          variant: 'body2',
          color: 'text.primary',
        }}
      />
      <CardContent
        sx={{
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
      >
        <Typography fontWeight={700}>{insight.title}</Typography>
        <Box marginY="1rem">
          {!!insight.keywords?.length &&
            insight.keywords?.slice(0, 4).map((keyword, idx) => (
              <Typography variant="subtitle2" key={idx} display="inline">
                #{keyword}{' '}
              </Typography>
            ))}
        </Box>

        <Typography mb={2}>{description}</Typography>

        {/* <Typography mb={2}>{insight.keywords}</Typography> */}
      </CardContent>
      {!!media!!.length && !mediaFailed && (
        <CardMedia
          component={insight.imgUrl?.includes('mp4') ? 'video' : 'img'}
          controls
          image={insight.imgUrl || insight.videoUrl || undefined}
          sx={{ marginBottom: 1, height: loadImage ? 'auto' : '100px' }}
          data-testid="insight-card-media"
          onLoad={() => setLoadImage(true)}
          onLoadedData={() => setLoadImage(true)}
          onError={() => setMediaFailed(true)}
        />
      )}
      <CardActions sx={{ paddingX: '10px', paddingBottom: '10px' }}>
        <Button
          fullWidth
          sx={{ backgroundColor: '#F7F7F7', border: 'none' }}
          onClick={handleReviewButton}
          data-testid="share-button"
        >
          <Typography fontWeight="bold">{t('View')}</Typography>
        </Button>
      </CardActions>
    </Card>
  );
};

export default InsightCard;
