import {
  Box,
  circularProgressClasses,
  CircularProgress as CircularProgressMui,
  Typography,
} from '@mui/material';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

interface Props {
  progress: number;
  withLabel: boolean;
  size: number;
}

const CircularProgressDeterminate = ({ progress, withLabel, size }: Props) => {
  return (
    <div style={{ height: size, width: size, position: 'relative' }}>
      <CircularProgressMui
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[200],
          position: 'absolute',
          left: 0,
        }}
        size={size}
        thickness={4}
        value={100}
      />
      <CircularProgressMui
        variant={progress ? 'determinate' : 'indeterminate'}
        sx={{
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          color: (theme) => theme.palette.primary.main,
        }}
        size={size}
        thickness={4}
        value={progress}
      />
      {withLabel && (
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            fontSize={size * 0.25}
          >{`${Math.round(progress)}%`}</Typography>
        </Box>
      )}
    </div>
  );
};

export default CircularProgressDeterminate;
