export enum StatusCode {
  SCHEDULE_POST_ERROR,
  DELETE_POST_ERROR,
  SAVE_POST_ERROR,
  LINKEDIN_POST_ERROR,
  LINKEDIN_UNPUBLISH_ERROR,
  LINKEDIN_TOKEN_NOT_FOUND,
  LINKEDIN_TOKEN_ABOUT_TO_EXPIRE,
  CONTENT_ASSISTANT_ERROR,
  LINKEDIN_TOKEN_ERROR
}

export enum DefaultErrorMessage {
  SAVE_POST_ERROR = 'There was an error saving your post',
  DELETE_POST_ERROR = 'There was an error deleting your post',
  SCHEDULE_POST_ERROR = 'There was an error scheduling your post',
  LINKEDIN_POST_ERROR = 'There was an error posting on LinkedIn',
  LINKEDIN_UNPUBLISH_ERROR = 'There was an error unpublishing your post on LinkedIn',
  LINKEDIN_TOKEN_NOT_FOUND = 'Please connect your LinkedIn account from the settings page.',
  LINKEDIN_TOKEN_ABOUT_TO_EXPIRE = "magnettu's connection with your LinkedIn is about to expire. Please renew your access token from the settings page.",
  CONTENT_ASSISTANT_ERROR = 'There was an error creating the draft. Please try again later.',
  LINKEDIN_TOKEN_ERROR = 'Your connection with LinkedIn has expired. Please reconnect it from the settings page.',
  LINKEDIN_TOKEN_ERROR_BRAND = 'The brand connection with LinkedIn has expired. Please reconnect it from the Brands section.'
}
